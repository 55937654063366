// @flow
import React, { Fragment } from 'react';
import moment, { relativeTimeThreshold } from 'moment';
import 'moment/locale/da';
import classNames from 'classnames';
import { commitMutation, graphql } from 'react-relay';

import coverGradientUrl from '../../images/cover-gradient.png';
import { Collapsible, FormattedDate, Icon, withToast, type ToastProps } from '../../components';
import { placeholderCoverImageForTitle } from '../../models';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import { MediaSearchBar, ReviewerSearchBar, withManageSearch, type ManageSearchProps } from '../SearchManager';
import ReviewGradeDescription from '../NewsAndReviewPaginator/ReviewGradeDescription';
import { NEWS_TYPE_DESCRIPTION, ENTITY_NEWS_TYPE_PREFIX, TITLE_NEWS_TYPE_PREFIX, ONLINE_MEDIA_TYPE_IDS } from './data';
import { ContentPublishingDescriptionHeadline, TitlesDescription, } from './utils';
import { render } from 'react-dom';
import { Checkbox, Form, Input, Select, Textarea } from '../../components';
import AddNewMediaDataWrapper from '../AddNewMedia'
import AddNewReviewer from '../AddNewReviewer'
import { LinkDatePicker } from '../../components/DatePicker';

/** Edit review mutation. */
const editReviewMutation = graphql`
mutation EditReviewCardeditReviewMutation($input: EditReviewInput!) {
  editReview(input: $input) {
    review {
      id
      grade
      
      content
      date
      headline
      quotes
	  quotesCreatedBy {
		  id
	  }
    }
	viewer {
		id
		email
		isAdministrator
		isSuperAdmin
	}
  }
}`;

/** Delete review mutation. */
const deleteReviewMutation = graphql`
mutation EditReviewCardReviewDeleteMutation($input: DeleteReviewInput!) {
  deleteReview(input: $input) {
    viewer {
      email
	  isAdministrator
	  isSuperAdmin
    }
  }
}`;

type EditReviewProps = {
	content: any,
	viewer: any,
	shouldDisplayPublishingHouse: any,
} & EnvironmentProps & ToastProps;
type EditReviewState = {
	loading: string,
	submitting: boolean,
	error: any,
	extraActions: any,
	reviewerId: string,
	reviewerName: string,
	mediaId: string,
	titleIds: string[],
	date: string,
	displayDate: string,
	headline: string,
	underHeading: string,
	content: string,
	url: string,
	quotes: {value: string, editable: boolean}[],
	quotesCreatedBy: any[],
	gradingScales: any,
	pickedGradingScaleIndex: number,
	showAllGradingScales: boolean,
	grade: number,
	summaryHeadline: string,
	originalContent: string,
	mediaUrl: string,
	reviewId: string,
	createdBy: any,
	deleteReview: boolean,
	primaryQuoteIndex: number,
	reviewerSearchFilters: any,
	mediaSearchFilters: any,
	mediaName: string
};

/** Edit review item. */
class EditReviewCard extends React.Component<EditReviewProps, EditReviewState>{
	collapsibleChild:any = React.createRef();
	state = {
		loading: '',
		submitting: false,
		extraActions: [],
		error: null,
		reviewId: this.props.content.id,
		reviewerId: this.props.content.reviewer?.id,
		reviewerName: `${this.props.content.reviewer?.firstName || ''} ${this.props.content.reviewer?.lastName || ''}` ,
		mediaId: this.props.content.media?.id,
		mediaUrl: this.props.content.media?.url || '',
		titleIds: this.props.content.referencedTitles?.map(t => t.id),
		displayDate: moment(this.props.content.date).format('DD. MMMM YYYY', 'da'),
		date: this.props.content.date,
		headline: this.props.content.headline,
		underHeading: this.props.content.underHeading,
		content: this.props.content.content,
		url: this.props.content.url,
		quotes: this.props.content.quotes.map((q, i) => { return {value: q, editable: this.props.content.quotesCreatedBy[i]?.id === this.props.viewer?.id || false }}),
		quotesCreatedBy: this.props.content.quotesCreatedBy || [],
		gradingScales: [{...this.props.content.gradingScale, symbol: this.props.content?.gradingScale?.symbol || ''}],
		grade: this.props.content.grade || 0,
		summaryHeadline: this.props.content.summaryHeadline,
		originalContent: this.props.content.originalContent,
		createdBy: this.props.content.createdBy?.id || null,
		hasFullEditingRights: false,
		deleteReview: false,
		primaryQuoteIndex: 0,
		pickedGradingScaleIndex: 0,
		showAllGradingScales: false,
		reviewerSearchFilters: this.props.content.reviewer ? [{id: this.props.content.reviewer?.id, type: 'Reviewer', value:this.props.content.reviewer?.id, label: `${this.props.content.reviewer?.firstName} ${this.props.content.reviewer?.lastName}`}] : [],
		mediaSearchFilters: this.props.content.media ? [{id: this.props.content.media?.id, type: 'Media', value: this.props.content.media?.name}]  : [],
		mediaName: this.props.content.media?.name,
	};


	handleSubmit = () => {
		// evt.preventDefault();

		const {
			environment,
		} = this.props;

		const {
			loading,
			submitting,
			extraActions,
			error,
			reviewerId,
			mediaId,
			titleIds,
			date,
			headline: rawHeadline,
			underHeading: rawUnderHeading,
			content,
			url: rawUrl,
			quotes: rawQuotes,
			quotesCreatedBy: rawQuotesCreatedBy,
			gradingScales,
			pickedGradingScaleIndex,
			grade,
			summaryHeadline: rawSummaryHeadline,
			originalContent,
			mediaUrl,
			reviewId,
			createdBy,
			deleteReview,
			primaryQuoteIndex
		} = this.state;

		if(deleteReview){
			const payload = {id: reviewId};
			this.setState({
				submitting: true,
			  }, () => commitMutation(environment, {
				mutation: deleteReviewMutation,
				variables: {
				  input: {
					clientMutationId: '1',
					...payload,
				  },
				},
	 
				onCompleted: (data, errors) => {
				  const { toast } = this.props;
		  
				  if (errors?.length) {
					this.setState({
					  submitting: false,
					  error: 'Der er opstået en fejl.',
					});
				  }
				  else {
					toast('Anmeldelsen blev slettet');
		  
					this.onOverrideClose();
				  }
				},
			  }));
			  return;
		}

		if (mediaId.trim() === '') {
			this.setState({
			  error: 'Anmeldelsen skal være tilknyttet et medie.',
			  submitting: false,
			});
			return;
		}

		const headline = rawHeadline.trim();

		if (headline === '') {
			this.setState({
			  error: 'Anmeldelsen skal have en overskrift.',
			  submitting: false,
			});
			return;
		}

		const underHeading = rawUnderHeading.trim();

		if(underHeading.length > 700) {
			this.setState({
				error: 'Underrubrik eller kort uddrag for langt. Maks. 700 tegn.',
				submitting: false,
			  });
			  return;
		}

		const url = rawUrl.trim();
		if(url) {
			const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
			'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
			const isCorrectUrl = !!pattern.test(url);
			
			if(!isCorrectUrl) {
				this.setState({
					error: 'Webadressen er ikke korrekt (husk http ...)',
					submitting: false,
				});
				return;
			}
		}
		
		const quotes = [rawQuotes.find((q,i) => i === primaryQuoteIndex)?.value.trim() || '', ...rawQuotes.filter((q, i) => i != primaryQuoteIndex).map(q => q.value.trim() || '')];
		const quotesCreatedBy = [rawQuotesCreatedBy.find((q,i) => i === primaryQuoteIndex)?.id || '', ...rawQuotesCreatedBy.filter((q, i) => i != primaryQuoteIndex).map(q => q.id || '')];
	
		if(quotes.findIndex(q => q.length > 140) >= 0) {
			this.setState({
				error: 'Et eller flere citater er for langt. Maks. 140 tegn. Del evt. op i flere citater.',
				submitting: false,
			  });
			  return;
		}

		if(quotes.length != quotesCreatedBy.length) {
			this.setState({
				error: 'Something went wrong with quotes',
				submitting: false,
			  });
			return;
		}

		const summaryHeadline = rawSummaryHeadline.trim();
		const payload = {
			reviewId,
			reviewerId,
			mediaId,
			titleIds,
			date,
			headline,
			underHeading,
			content,
			url,
			quotes,
			quotesCreatedBy,
			gradingScale: gradingScales[pickedGradingScaleIndex],
			grade,
			summaryHeadline,
			originalContent,
			createdBy
		};
		
		this.setState({
			submitting: true,
		  }, () => commitMutation(environment, {
			mutation: editReviewMutation,
			variables: {
			  input: {
				clientMutationId: '1',
				...payload,
			  },
			},
 
			onCompleted: (data, errors) => {
			  const { toast } = this.props;
			  if (errors?.length) {
				this.setState({
				  submitting: false,
				  loading: '',
				  error: 'Der er opstået en fejl.',
				});
			  }
			  else {
				const review = data.editReview.review;
				toast('Anmeldelsen er ændret');

				this.onOverrideClose();
				this.setState({
					submitting: false,
					loading: '',
					quotes: review.quotes.map((q, i) => { return {value: q, editable: review.quotesCreatedBy[i].id === data.editReview.viewer?.id || false }}),
					quotesCreatedBy: review.quotesCreatedBy,
					primaryQuoteIndex: 0,
				});
			  }
			},
		  }));
	}

	onOverrideClose = () => {
		this.collapsibleChild.current.toggleExpanded();
	}

	onMediaSubmit = (filters) => {
		const media = filters[0];
		if(media) {
			media["gradingScales"] = media?.gradingScales?.map((gs) => {return { ...gs, symbol: gs.symbol || ''}});
		}
		const grade = media?.gradingScales[0].max === 5 ? 3 : media?.gradingScales[0].max === 6 ? 4 : media?.gradingScales[0].max === 10 ? 7 : media?.gradingScales[0].max >= 100 ? 67 : 0;
		this.setState({mediaName: media?.label || '', mediaId: media?.id || '', mediaUrl: media?.url || '', grade, gradingScales: media?.gradingScales || [], pickedGradingScaleIndex: 0});

		if(media?.gradingScales?.length === 1  && media?.gradingScales[0]?.max >= 100){
			const grade = 67;
			this.setState({grade});
		}
	}

	onNewMediaSubmit = (media) => {
		this.setState({mediaSearchFilters: [{
			id: media.id,
			label: `${media.name}`,
			type: 'Reviewer',
			value: media.id,
			gradingScales: media.gradingScales.map((gs) => {return { ...gs, symbol: gs.symbol || ''}}),
		}], reviewerId: media?.id || ''});
		this.onMediaSubmit([media]);
	}
	
	onReviewerSubmit = (filters) => {
		this.setState({reviewerId: filters[0]?.id || '', reviewerName: filters[0]?.label || ''});
	}

	onNewReviewerSubmit = (reviewer) => {
		this.setState({reviewerSearchFilters: [{
			id: reviewer.id,
			label: `${reviewer.firstName || ''} ${reviewer.lastName || ''}`,
			type: 'Reviewer',
			value: reviewer.id
		}] });
	}

	onQuoteChange = (newQuote, i) => {
		const quotes = [...this.state.quotes];
		quotes[i].value = newQuote;
		this.setState({ quotes });
	}

	onMakePrimaryQuote = (value, i) => {
		this.setState({ primaryQuoteIndex: i });
	}

	onDeleteQuote = (i) => {
		const quotes = this.state.quotes.filter((q, idx) => i != idx);
		const quotesCreatedBy = this.state.quotesCreatedBy.filter((q, idx) => i != idx);
		this.setState({ quotes, quotesCreatedBy });
	}

	onGradeInputChange = (idx, hasHalf, e, pickedGradingScaleIndex, hasFullEditingRights, setNull?) => {
		if(!hasFullEditingRights){
			return;
		}
		if(setNull){
			e.stopPropagation();
		} else {
			e.preventDefault();
			e.stopPropagation();
		}

		const grade = idx + (hasHalf ? 0.5 : 0);
		
		this.setState({grade, pickedGradingScaleIndex});
	}

	renderGradingScales = (hasFullEditingRights) => {
		if(this.state.gradingScales.length === 1 && this.state.gradingScales[0].max >= 100) {
			return null;
		}
		return (
			<div className={`grading-scales`}>
				{ this.state.gradingScales.length > 1 && this.state.gradingScales.findIndex((gradingScale, idx) => idx!=0 && gradingScale.max < 100) != -1 ?
					<Collapsible.Item itemClassName={'no-cover'} expanded={this.state.showAllGradingScales}
					header={
						<Fragment>
						{this.renderGradingScale(this.state.gradingScales[0], 0, hasFullEditingRights)}
						<a className="standard-color versals add-new-button" onClick={ () => this.setState({showAllGradingScales: !this.state.showAllGradingScales})} >
							<Icon name="add" />
							Vis mere
						</a> 
						</Fragment>
					}>
					{this.state.gradingScales.map((gradingScale, idx) => idx!=0 && gradingScale.max < 100 && this.renderGradingScale(gradingScale, idx))}
				</Collapsible.Item>
				:
					this.renderGradingScale(this.state.gradingScales[0], 0, hasFullEditingRights)
				}

			</div>
		);
	}

	renderGradingScale = (gradingScale, idx, hasFullEditingRights) => {
		const mainGradingScaleMin = gradingScale.min;
		const mainGradingScaleMax = gradingScale.max;
		const precision = gradingScale.precision || 1;
		const formatedPrecision = precision > 0.5 ? 1 : 0.5;
		const symbol = mainGradingScaleMax < 100 && gradingScale.symbol === '' ? 'stars' : gradingScale.symbol;
		const reviewId = this.state.titleIds[0]; // todo double check
		const currentGrade = this.state.grade || 0;
		const closestFormattedGrade = formatedPrecision === 1 ? Math.floor(currentGrade) : Math.floor(currentGrade)+(currentGrade % 1 >= 0.5 ? 0.5 : 0);
		const formatedMax = formatedPrecision === 1 ? mainGradingScaleMax : mainGradingScaleMax * 2;
		const displaySymbol = symbol === 'hearts' ? 'hjerter' : symbol === 'stars' ?  'stjerner' : '';
		let children = [];
	
		if(symbol != null || mainGradingScaleMax < 100) {
			if(formatedPrecision === 0.5) {
				for (let i = mainGradingScaleMin; i < formatedMax; i++) {
					const wholeGrade = Math.floor(i/2 + 0.5);
					const hasHalf = (i/2 + 0.5) % 1 === 0 ? false : true;
					
					const checked = this.state.pickedGradingScaleIndex === idx && closestFormattedGrade === (i+1)/2;
					children.unshift(
						<Fragment key={i}>
							<input disabled={!hasFullEditingRights} onChange={()=>{}} checked={checked} type="radio" id={`${reviewId}-rating-${i}-${formatedMax}`} name={`${symbol}-rate${mainGradingScaleMax}`} value={`${ hasHalf ? wholeGrade + 0.5 : wholeGrade }/${mainGradingScaleMax} ${displaySymbol}`} />
							<label onClick={(e)=> this.onGradeInputChange(wholeGrade, hasHalf, e, idx, hasFullEditingRights)} className={`${i} ${hasHalf ? 'half' : ''}`} htmlFor={`${reviewId}-rating-${i}-${formatedMax}`} title={`${wholeGrade}${hasHalf ? ' 1/2' : ''} ${displaySymbol}`}></label>
						</Fragment>
					);
				}
			} else {
				for (let i = mainGradingScaleMin; i < formatedMax; i++) {
					const wholeGrade = i+1;
					const hasHalf = false;
					
					const checked = this.state.pickedGradingScaleIndex === idx && closestFormattedGrade === wholeGrade;
					children.unshift(
						<Fragment key={i}>
							<input onChange={()=>{}} checked={checked} type="radio" id={`${reviewId}-rating-${i}-${formatedMax}`} name={`${symbol}-rate${mainGradingScaleMax}`} value={`${ hasHalf ? wholeGrade + 0.5 : wholeGrade }/${mainGradingScaleMax} ${displaySymbol}`} />
							<label onClick={(e)=> this.onGradeInputChange(wholeGrade, hasHalf, e, idx, hasFullEditingRights)} className={`${i} ${hasHalf ? 'half' : ''}`} htmlFor={`${reviewId}-rating-${i}-${formatedMax}`} title={`${wholeGrade}${hasHalf ? ' 1/2' : ''} ${displaySymbol}`}></label>
						</Fragment>
					);
				}
				
			}
		}
		const checked = this.state.pickedGradingScaleIndex === idx && currentGrade === 0;
		return (
			<Fragment key={idx}>
				<div className={`rating  ${hasFullEditingRights ? 'editable' : ''} ${symbol}-rating`}>{children}</div>
				<Checkbox key={`null-${idx}`} label={`Nul ${displaySymbol}`} value={checked} onChange={(val, e) => this.onGradeInputChange(0, false, e, idx, hasFullEditingRights, true)} />
			</Fragment>
			
		);
	}

	render(){
		const {
			content, viewer, shouldDisplayPublishingHouse 
		} = this.props;
		const {
			loading,
			submitting,
			extraActions,
			error,
			reviewerId,
			reviewerName,
			mediaId,
			titleIds,
			displayDate,
			date,
			headline,
			underHeading,
			url,
			quotes,
			grade,
			summaryHeadline,
			originalContent,
			mediaUrl,
			reviewId,
			createdBy,
			deleteReview,
			primaryQuoteIndex,
			reviewerSearchFilters,
			mediaSearchFilters,
			gradingScales,
			pickedGradingScaleIndex,
			mediaName,
		} = this.state;

		const pickedGradingScale = gradingScales[pickedGradingScaleIndex];

		const {collapsibleChild} = this;
		const referencedTitles = content?.referencedTitles?.filter(({ publishingHouses }) => publishingHouses?.some(({ id }) => shouldDisplayPublishingHouse(id)));
		const referencedEntities = content?.referencedEntities?.filter(({ publishingHouseIds }) => publishingHouseIds?.some(id => shouldDisplayPublishingHouse(id)));
	
		const coverImageUrls =
			referencedTitles?.
				map(({ coverImage }) => coverImage?.sizes?.
				find(({ name }) => name === '450x')?.url)?.
				filter(url => !!url);
		const coverImageUrl = coverImageUrls?.length > 0
			? coverImageUrls[0]
			: referencedTitles?.length > 0
				? placeholderCoverImageForTitle(referencedTitles[0])
				: null;
		const { media } = content;
		const isOnlineMedia = ONLINE_MEDIA_TYPE_IDS.includes(media?.mediaType?.id);
		const hasFullEditingRights = createdBy === viewer?.id || viewer.isSuperAdmin || false;
		const reviewTitle = referencedTitles[0]?.title;

		return (
			<Collapsible.Item ref={collapsibleChild}
				header={
					<Fragment>
						{media && 
							<span className={ classNames('media-icon', media.mediaType?.id === 'TWVkaWFUeXBlOjU=' ? 'blog-icon': media.colorClass)}>
								{media.initials}
							</span>
						}
						{(content.gradeNormalized || content.media) &&
							<p className="headline1">
								<ReviewGradeDescription review={content} />
								{media && ` ${isOnlineMedia ? 'fra' : 'i'} ${media.name}`}
							</p>}
	
						{referencedTitles?.length > 0 &&
							<p className="headline2">
								<TitlesDescription titles={referencedTitles} />
							</p>}
	
						<ContentPublishingDescriptionHeadline content={content} />
						{!collapsibleChild.current?.expanded && quotes?.length > 0 && quotes[0].value.length > 0 && <p className="bog-review-quote">{quotes[0]?.value || ''}</p>}
						
						{coverImageUrl &&
							<img
								className="news-cover right hide-on-small-only"
								src={coverImageUrl} />}
	
						{coverImageUrl &&
							<img
								className="news-cover-shadow right hide-on-small-only"
								src={coverGradientUrl}
							/>}
					</Fragment>
				}>
				
				{
					hasFullEditingRights &&
					<div className="xs-margin-top">
						<MediaSearchBar filters={mediaSearchFilters} onSubmit={this.onMediaSubmit} />
						<AddNewMediaDataWrapper onSubmit={this.onNewMediaSubmit.bind(this)} />
					</div>
				}
				
				{
					quotes.map((q, i) => {
						return (
							<div className="lg-margin-top bog-review-edit-quote" key={i}>
								<Textarea disabled={quotes[i].editable || viewer.isSuperAdmin ? false : true} value={quotes[i].value} label={i === primaryQuoteIndex ? 'Tilføj primært citat' : 'Ekstra citat'} onChange={(quote) => this.onQuoteChange(quote, i)}  />
								<Checkbox label="Primært citat" value={i === primaryQuoteIndex} onChange={(value) => this.onMakePrimaryQuote(value, i)} />
								{ quotes[i].editable || viewer.isSuperAdmin ?  <a className="right delete-quote" onClick={() => this.onDeleteQuote(i)}> <Icon name="clear"/> Slet dette citat </a> : ''}
							</div>
						);
					})
				}
				<a className="standard-color versals add-new-button" onClick={ () => this.setState({quotes: [...this.state.quotes, {value: '', editable: true}], quotesCreatedBy: [...this.state.quotesCreatedBy, viewer]})} >
					<Icon name="add" />
					Tilføj citat
				</a>

				{ //todo reviews without reviewer id error
					(hasFullEditingRights || !reviewerId) && 
						<div className="lg-margin-top">
							<ReviewerSearchBar filters={reviewerSearchFilters} onSubmit={this.onReviewerSubmit} />
							<AddNewReviewer onSubmit={this.onNewReviewerSubmit.bind(this)} />
						</div>
				}
				<Form
					onSubmit={this.handleSubmit}
					error={this.state.error}
					loading={this.state.loading}
					leftActions={this.state.extraActions}
					submitting={submitting}>

					{
						<div className="lg-margin-top">
							<Input disabled={!hasFullEditingRights} value={headline} onChange={headline => this.setState({headline})} autoComplete="off" label="Overskrift" />
						</div>
					}

					{
						<Textarea disabled={!!content.underHeading.trim() && !hasFullEditingRights} value={underHeading}  label="Evt. underrubrik eller kort uddrag" onChange={underHeading => this.setState({ underHeading })}  />
					}

				
					{mediaId && gradingScales.length > 0 &&
						<div className="lg-margin-top">
							<p>Vurdering: {pickedGradingScale.max >= 100 && grade == 0 ? 'Endnu ingen' : `${grade}/${pickedGradingScale.max ? pickedGradingScale.max : '100'} ${pickedGradingScale.symbol === 'hearts' ? 'hjerter' : pickedGradingScale.symbol === 'stars' ?  'stjerner' : ''}`}</p>
							{this.renderGradingScales(hasFullEditingRights)}
						</div>
					}

					<div className="sm-margin-top">
						<Input disabled={!hasFullEditingRights && content.url.length > 0} value={url} onChange={url => this.setState({url})} autoComplete="off" label="Link" />
						{mediaUrl && 
							<a className="standard-color versals add-new-button" target="_blank" href={`https://www.google.dk/search?q=site:${mediaUrl} ${reviewTitle} ${reviewerName}`}>
								<Icon name="link" />
								Søg efter online-udgave
							</a> }
						{!mediaUrl && mediaName &&
							<a className="standard-color versals add-new-button" target="_blank" href={`https://www.google.dk/search?q=${mediaName} ${reviewTitle} ${reviewerName}`}>
								<Icon name="link" />
								Søg efter online-udgave
							</a> }
						
					</div>
				
					{
						hasFullEditingRights ?
							<div className="lg-margin-top review-date-picker">
								<LinkDatePicker
								minDate={1}
								value={date}
								customFormat="DD. MMMM YYYY"
								onChange={(newDate) => this.setState({date: newDate, displayDate: moment(newDate).format('DD. MMMM YYYY', 'da')})}
								/>
							</div>
						: <Input value={displayDate} onChange={date => date} disabled={true} autoComplete="off" label="Dato" />
					}
					{
						hasFullEditingRights &&
							<div className="lg-margin-top">
								<Checkbox label="Slet denne anmeldelse" value={deleteReview} onChange={(deleteReview) => this.setState({deleteReview: !this.state.deleteReview})} />
							</div>
					}

				</Form>

			</Collapsible.Item>
		);
	}
}

export default withToast(withEnvironment(EditReviewCard));