/**
 * @flow
 * @relayHash 6bdedf2fbeda8932f7f6ddd922c8354f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FaqPageQueryVariables = {||};
export type FaqPageQueryResponse = {|
  +faqs: $ReadOnlyArray<{|
    +id: string,
    +question: string,
    +answer: string,
  |}>
|};
export type FaqPageQuery = {|
  variables: FaqPageQueryVariables,
  response: FaqPageQueryResponse,
|};
*/


/*
query FaqPageQuery {
  faqs {
    id
    question
    answer
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "faqs",
    "storageKey": null,
    "args": null,
    "concreteType": "IntraFaq",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "question",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "answer",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FaqPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FaqPageQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "FaqPageQuery",
    "id": null,
    "text": "query FaqPageQuery {\n  faqs {\n    id\n    question\n    answer\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f2381dfcd22cb94a26a3c5cf4fc7a2f8';
module.exports = node;
