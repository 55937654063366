// @flow
import React from 'react';
import { graphql } from 'react-relay';

import { Dropdown, Icon } from '../../components';
import FilterDescriptionLabel from '../../containers/FilterDescriptionLabel';
import TitlePaginator from '../../containers/TitlePaginator';
import { withFeatures, type FeaturesProps } from '../../containers/FeaturesProvider';
import { PopularityTitleCard, ReviewTitleCard } from '../../containers/TitleCard';
import { withSearchSettings, type SearchSettingsProps } from '../../containers/SearchSettings';
import { DEFAULT_TITLE_SORT_VALUE, TITLE_SORT_OPTIONS, VALID_TITLE_SORT_VALUES } from '../../models';
import { type RouterProps } from '../../utils/router';
import { QueryRenderer } from '../../utils/relay';

type TitlesResultsPageProps = {
  mode: 'popular' | 'reviews',
  searchMode: 'STORE' | 'PUBLISHING_HOUSE',
  shouldDisplayPublishingHouse: (id: ?string) => boolean,
} & RouterProps<{}> & SearchSettingsProps & FeaturesProps;

class TitlesResultsPage extends React.Component<TitlesResultsPageProps> {
  handleResetClick = (evt) => {
    evt.preventDefault();

    this.props.resetSearchSettings();
  }

  render() {
    const { mode, searchSettings, setSort, searchMode, shouldDisplayPublishingHouse } = this.props;
    const sort = VALID_TITLE_SORT_VALUES[mode][searchSettings.sort] || DEFAULT_TITLE_SORT_VALUE[mode];
    const sortOption = TITLE_SORT_OPTIONS[mode].find(({ value }) => value === sort);
    const orderBy = sortOption.orderBy;
    const order = sortOption.orderBy === 'PUBLISHED_FIRST' && (searchSettings.published === 'next_14_days' || searchSettings.published === 'future') ? 'ASC' : sortOption.order;

    return (
      <div id="popular">
        <div className="section-headline">
          <Dropdown
            alignment="right"
            closeOnClick={false}
            trigger={({ toggleDropdown, ref }) =>
              <span className="dropdown-trigger btn-flat right" onClick={toggleDropdown} ref={ref}>
                <Icon name="filter_list" className="grey-text light" />
              </span>
            }
          >
            {TITLE_SORT_OPTIONS[mode].map(({ value, label }) =>
              <Dropdown.Item key={value}>
                <label>
                  <input
                    name="sort"
                    type="radio"
                    value={value}
                    checked={sort === value}
                    onChange={() => setSort(value === DEFAULT_TITLE_SORT_VALUE ? null : value)}
                  />
                  <span>{label}</span>
                </label>
              </Dropdown.Item>
            )}

            <Dropdown.Divider />

            <Dropdown.Item>
              <a href="#" className="waves-effect waves-light" onClick={this.handleResetClick}>
                <i className="material-icons standard-color">refresh</i>
                <span className="dropdown-date">Nulstil søgeindstillinger</span>
              </a>
            </Dropdown.Item>
          </Dropdown>

          {sortOption && `${sortOption.label}. `}
          <FilterDescriptionLabel mode="titles" />
        </div>

        <QueryRenderer
          variables={{
            excludeDigital: searchSettings.excludeDigital,
            excludeUnavailable: searchSettings.excludeUnavailable,
            filterBy: searchSettings.filterBy,
            order,
            orderBy,
            published: searchSettings.published?.toUpperCase(),
            search: searchSettings.searchQuery,
            mode: searchMode,
            onlyFavorites: searchSettings.onlyFavorites,
            onlyWithNotificationTypes:
          searchSettings.showOnlyNotifications
            ? searchSettings.notificationTypes
            : null,
          }}
          query={graphql`
query TitlesResultsPageQuery(
  $orderBy: TitleOrderBy,
  $order: Order,
  $search: String,
  $excludeDigital: Boolean,
  $excludeUnavailable: Boolean,
  $filterBy: [ID!],
  $published: PublishedFilter,
  $mode: SearchMode!,
  $onlyFavorites: Boolean!,
  $onlyWithNotificationTypes: [CustomerNotificationSubscriptionType!]
) {
  ...TitlePaginator_query @arguments(
    orderBy: $orderBy,
    order: $order,
    search: $search,
    excludeDigital: $excludeDigital,
    excludeUnavailable: $excludeUnavailable,
    filterBy: $filterBy,
    published: $published,
    mode: $mode,
    onlyFavorites: $onlyFavorites,
    onlyWithNotificationTypes: $onlyWithNotificationTypes
  )

  viewer {
    ...BaseTitleCard_viewer
  }
}`}
          render={({ error, props }) => {
            if (error) {
              return <div className="no-results-headline">Et eller andet gik galt. Prøv venligst igen.</div>;
            }

            if (!props) {
              return <div className="no-results-headline">Indlæser...</div>;
            }

            return (
              <TitlePaginator
                excludeDigital={searchSettings.excludeDigital}
                excludeUnavailable={searchSettings.excludeUnavailable}
                onlyFavorites={searchSettings.onlyFavorites}
                query={props}
                titleCard={mode === 'popular' ? PopularityTitleCard : ReviewTitleCard}
                shouldDisplayPublishingHouse={shouldDisplayPublishingHouse}
              />
            );
          }}
        />

      </div>
    );
  }
}

export default withFeatures(withSearchSettings(TitlesResultsPage));
