// @flow
import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import MainLayoutOverrideManagerContext from './MainLayoutOverrideManagerContext';
import type { MainLayoutOverrideProps } from './MainLayoutOverride';

export default function withMainLayoutOverride<Props: MainLayoutOverrideProps>(Component: React$ComponentType<Props>): React$ComponentType<$Diff<Props, MainLayoutOverrideProps>> {
  function C(props) {
    return (
      <MainLayoutOverrideManagerContext.Consumer>
        {searchSettingsContext => <Component {...props} {...searchSettingsContext} />}
      </MainLayoutOverrideManagerContext.Consumer>
    );
  }

  C.WrappedComponent = Component;
  C.displayName = `withMainLayoutOverride(${Component.displayName || Component.name || 'unnamed'})`;

  return hoistStatics(C, Component);
}
