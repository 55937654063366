// @flow

const REVIEW_FILTER_TYPES:{
	value: string,
	label: string,
	filterBy: string[]
}[] = [{
	value: 'withoutQuote',
	label: 'Kun anmeldelser uden citat',
	filterBy: ['']
},
{
	value: 'onlyGood',
	label: 'Kun "gode" anmeldelser',
	filterBy: ['']
},
{
	value: 'withoutLink',
	label: 'Kun anmeldelser uden link',
	filterBy: ['']
},
{
	value: 'onlyOwn',
	label: 'Kun egne anmeldelser',
	filterBy: ['']
}];

const ONLINE_MEDIA_TYPE_IDS = [
	'TWVkaWFUeXBlOjU=', // Blog
	'TWVkaWFUeXBlOjY=', // Onlinemedie
	'TWVkaWFUeXBlOjk=', // Onlinemedie uden citat
];

const TITLE_NEWS_TYPE_PREFIX = {
	FEATURE_ARTICLE: 'Stor artikel om ',
	ARTICLE: 'Artikel om ',
	EXCERPT: 'Uddrag af ',
	COMMENT_ABOUT: 'Kommentar om ',
	COMMENT_BY: 'Kommentar af ',
	MENTION: 'Omtale af ',
	FEATURE_INTERVIEW: 'Stort interview om ',
	INTERVIEW: 'Interview om ',
	COMPETITION: 'Konkurrence om ',
};

const ENTITY_NEWS_TYPE_PREFIX = {
	FEATURE_ARTICLE: 'Stor artikel om ',
	ARTICLE: 'Artikel om ',
	EXCERPT: 'Uddrag af ',
	COMMENT_ABOUT: 'Kommentar om ',
	COMMENT_BY: 'Kommentar af ',
	MENTION: 'Omtale af ',
	FEATURE_INTERVIEW: 'Stort interview med ',
	INTERVIEW: 'Interview med ',
	COMPETITION: 'Konkurrence med ',
};

const NEWS_TYPE_DESCRIPTION = {
	FEATURE_ARTICLE: 'Denne store artikel',
	ARTICLE: 'Denne artikel',
	EXCERPT: 'Dette uddrag',
	COMMENT_ABOUT: 'Denne kommentar',
	COMMENT_BY: 'Denne kommentar',
	MENTION: 'Denne omtale',
	FEATURE_INTERVIEW: 'Dette store interview',
	INTERVIEW: 'Dette interview',
	COMPETITION: 'Denne konkurrence',
};

export {
	REVIEW_FILTER_TYPES,
	NEWS_TYPE_DESCRIPTION,
	ENTITY_NEWS_TYPE_PREFIX,
	TITLE_NEWS_TYPE_PREFIX,
	ONLINE_MEDIA_TYPE_IDS,
}