// @flow
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { FormattedNumber } from 'react-intl';

import Collapsible from '../Collapsible';
import Icon from '../Icon';

/** Sidebar section props. */
export type SidebarSectionProps = {
  initiallyExpanded?: ?boolean,
  children?: ?React$Node,
  count?: ?number,
  headerHighlighted?: ?boolean,
  icon: string,
  iconRotate?: ?(90 | 180 | 270),
  label: string,
  onHeaderClick?: ?() => any,
  headerClassName?: ?string,
  truncateLabel?: ?boolean,
};

/** Sidebar section. */
const SidebarSection: React$ComponentType<SidebarSectionProps> = function SidebarSection({
  children,
  count,
  headerHighlighted,
  icon,
  iconRotate,
  initiallyExpanded,
  label,
  headerClassName,
  onHeaderClick,
  truncateLabel,
}: SidebarSectionProps) {
  return (
    <li className="no-padding">
      <Collapsible accordion>
        <Collapsible.Item
          header={
            <Fragment>
              {truncateLabel && <Icon name={icon} rotate={iconRotate} />}
              {label}
              {!truncateLabel && <Icon name={icon} rotate={iconRotate} />}
              {count !== null &&
            count !== undefined &&
             <span className="new badge" id="number-of-stars" data-badge-caption="">
               <FormattedNumber value={count} />
             </span>}
            </Fragment>
          }
          headerNode="a"
          onHeaderClick={onHeaderClick}
          headerHighlighted={headerHighlighted}
          initiallyExpanded={initiallyExpanded}
          headerClassName={classNames(
            headerClassName,
            truncateLabel && 'truncate'
          )}
        >
          {children}
        </Collapsible.Item>
      </Collapsible>
    </li>
  );
};

export default SidebarSection;
