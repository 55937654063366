/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BaseTitleCard_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PopularityTitleCard_viewer$ref: FragmentReference;
declare export opaque type PopularityTitleCard_viewer$fragmentType: PopularityTitleCard_viewer$ref;
export type PopularityTitleCard_viewer = {|
  +$fragmentRefs: BaseTitleCard_viewer$ref,
  +$refType: PopularityTitleCard_viewer$ref,
|};
export type PopularityTitleCard_viewer$data = PopularityTitleCard_viewer;
export type PopularityTitleCard_viewer$key = {
  +$data?: PopularityTitleCard_viewer$data,
  +$fragmentRefs: PopularityTitleCard_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "PopularityTitleCard_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BaseTitleCard_viewer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'af0fbf6c90aa682f92277eb3ebe38178';
module.exports = node;
