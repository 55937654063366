// @flow
import moment from 'moment';
import dk from 'date-fns/locale/da';
import React, { Fragment } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import DatePicker from 'react-datepicker';

import { FormattedDate, Icon, Radio } from '../../components';
import type { MessageStatusSelector_viewer } from './__generated__/MessageStatusSelector_viewer.graphql';
import {UnforwardedLinkDatePickerLabel,LinkDatePickerLabel,LinkDatePicker } from '../../components/DatePicker';

/** Message recipient selector props. */
export type MessageStatusSelectorProps = {
  viewer: MessageStatusSelector_viewer,

  /** Status. */
  status: 'ACTIVE' | 'INACTIVE' | 'TIMED',

  /** Active from. */
  activeFrom: string,

  /** Active to. */
  activeTo: string,

  /** Earliest valid date. */
  minDate: string,

  /** Change handler. */
  onChange: ({
    /** Status. */
    status: 'ACTIVE' | 'INACTIVE' | 'TIMED',

    /** Active from. */
    activeFrom: string,

    /** Active to. */
    activeTo: string,
  }) => any,
};

/** Message recipient selector. */
class MessageStatusSelector extends React.Component<MessageStatusSelectorProps> {
  handleActiveFromChange = (activeFrom) => {
    const { activeTo, onChange } = this.props;

    const activeFromMoment = moment(activeFrom);
    const activeToMoment = moment(activeTo);
    const activeToMax = activeFromMoment.clone().add(1, 'month');

    onChange({
      status: 'TIMED',
      activeFrom,
      activeTo: activeToMoment.isBefore(activeFromMoment) ? activeFrom : activeToMoment.isAfter(activeToMax) ? activeToMax.format('YYYY-MM-DD') : activeTo,
    });
  }

  handleActiveToChange = (activeTo) => {
    const { activeFrom, onChange } = this.props;

    const activeFromMoment = moment(activeFrom);
    const activeToMoment = moment(activeTo);

    onChange({
      status: 'TIMED',
      activeFrom: activeToMoment.isBefore(activeFromMoment) ? activeTo : activeFrom,
      activeTo,
    });
  }

  render() {
    const {
      activeFrom,
      activeTo,
      minDate,
      onChange,
      status,
      viewer,
    } = this.props;

    return (
      <div className="row toprow message-status-selector">
        {viewer.group.type === 'BOOKSELLER' &&
         <div className="col s12 m12 l12">
           <Radio
             name="status"
             value="ACTIVE"
             label="Permanent aktiv"
             checked={status === 'ACTIVE'}
             onChange={status => onChange({ status, activeFrom, activeTo })}
           />
         </div>}

        <div className="col s12 m12 l12">
          <Radio
            name="status"
            value="TIMED"
            label={
              <Fragment>
                {'Aktiv fra '}
                <LinkDatePicker
                  minDate={minDate}
                  value={activeFrom}
                  onChange={this.handleActiveFromChange}
                />
                {' til '}
                <LinkDatePicker
                  minDate={activeFrom}
                  maxDate={moment(activeFrom).add(1, 'month').format('YYYY-MM-DD')}
                  value={activeTo}
                  onChange={this.handleActiveToChange}
                />
              </Fragment>
            }
            checked={status === 'TIMED'}
            onChange={status => onChange({ status, activeFrom, activeTo })}
          />
        </div>

        <div className="col s12 m12 l12">
          <Radio
            name="status"
            value="INACTIVE"
            label="Inaktiv"
            checked={status === 'INACTIVE'}
            onChange={status => onChange({ status, activeFrom, activeTo })}
          />
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(
  MessageStatusSelector, {
    viewer: graphql`
fragment MessageStatusSelector_viewer on Customer {
  group {
    type
  }
}`,
  }
);
