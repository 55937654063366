// @flow
import React, { Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';

import Icon from '../Icon';

/**
 * Application layout override props.
 */
export type AppLayoutOverrideProps = {
  content: React$Node,
  sidebar: React$Node,
  extraTabs: React$Node,
  onOverrideClose: ?() => any,
};

/**
 * Application layout override.
 *
 * Application layout, that overlays the primary application layout temporarily.
 */
const AppLayoutOverride: React$ComponentType<AppLayoutOverrideProps> = function AppLayoutOverride({
  content,
  sidebar,
  extraTabs,
  onOverrideClose,
  ...rest
}: AppLayoutOverrideProps) {
  return (
    <Fragment>
      <CSSTransition
        timeout={250}
        classNames="sidenav-override"
        mountOnEnter
        unmountOnExit
        {...rest}
      >
        {sidebar}
      </CSSTransition>

      <CSSTransition
        timeout={250}
        classNames="content-override"
        mountOnEnter
        unmountOnExit
        {...rest}
      >
        <div className="content-override">
          {onOverrideClose &&
          <div className="iframe-header">
            <div className="close-button">
              <span
                className="iframe-close sidenav-close"
                onClick={() => onOverrideClose()}  >
                <Icon name="close" />
              </span>
            </div>
			{extraTabs}
          </div>}
		  
          <div className="sidenav-right-wrapper">
            {content}
          </div>
        </div>
      </CSSTransition>
    </Fragment>
  );
};

AppLayoutOverride.forwardProps = function forwardProps(props) {
  return {
    in: props.in,
    exit: props.exit,
    enter: props.enter,
    onExited: props.onExited,
    onOverrideClose: props.onOverrideClose,
  };
};

export default AppLayoutOverride;
