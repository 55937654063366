// @flow
import classNames from 'classnames';
import { Set as ImmutableSet } from 'immutable';
import moment from 'moment';
import React, { Fragment } from 'react';
import { commitMutation, graphql } from 'react-relay';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';

import { REVIEW_FILTER_TYPES } from './data.js';
import TitlePaginator from '../../containers/TitlePaginator';
import { ReviewTitleCard } from '../../containers/TitleCard';
import { withSearchSettings, type SearchSettingsProps } from '../../containers/SearchSettings';
import { AppLayout, Checkbox, Input, Sidebar, Textarea, Collapsible, Icon, withToast, TabNav, type ToastProps } from '../../components';
import type { Viewer } from '../../models';
import { joinNaturally, joinNodesNaturally } from '../../utils/language';
import EditReviewsPaginator from './EditReviewsPaginator';
import AddNewReviewCard from '../AddNewReview/AddNewReviewCard';
import type { PublishersEditLayoutOverrideDataWrapperQueryResponse } from './__generated__/PublishersEditLayoutOverrideDataWrapperQuery.graphql';


export type PublishersEditLayoutOverrideProps = {
	viewer: Viewer,
	currentTab: string,
	query: {
		error: ?Error,
		data: ?PublishersEditLayoutOverrideDataWrapperQueryResponse,
	},
} & SearchSettingsProps & EnvironmentProps & ToastProps;


type PublishersEditLayoutOverrideState = {
	prevData: ?PublishersEditLayoutOverrideDataWrapperQueryResponse,
	titles: {}[],
	tabs: string[],
	activeTab: string,
	excludeFilters: string[]
};

class PublishersEditLayoutOverride extends React.Component<PublishersEditLayoutOverrideProps, PublishersEditLayoutOverrideState> {
	state = {
		titles: [],
		tabs: ['add-reviews', 'add-news', 'add-other'],
		activeTab: 'add-reviews',
		prevData: null,
		excludeFilters: []
	};

	static getDerivedStateFromProps(props: PublishersEditLayoutOverrideProps, prevState: PublishersEditLayoutOverrideState): ?$Shape<PublishersEditLayoutOverrideState> {
		const data = props.query.data;
		 
		if (!data || prevState.prevData) {
			return null;
		}

		const { titles } = data;

		return {
			prevData: data,
			tabs: ['add-reviews', 'add-news', 'add-other'],
			titles: titles || [''],
			activeTab: 'add-reviews',
			excludeFilters: []
		};
	}

	onToggleSidebarFilter(filterType: string) {
		let newExcludeFilters = [];
		if(this.state.excludeFilters.includes(filterType)) {
			newExcludeFilters  = this.state.excludeFilters.filter(f => f != filterType);
		} else {
			newExcludeFilters = [filterType, ...this.state.excludeFilters];
		}
		this.setState({excludeFilters: newExcludeFilters});
	}

	onSidebarHeaderClick() {

	}
	
	isTabActive = (tab) => {
		return this.state.activeTab === tab;
	}

	onTabClick = (activeTab) => {
		this.setState({activeTab});
	}

	renderExtraTabs() {
		return (
			<TabNav>
				<TabNav.Tab active linkTo="/" linkPreservesQuery={false} onClick={()=>this.onTabClick('add-reviews')} isActive={()=>this.isTabActive('add-reviews')}>
					TILFØJ ANMELDELSER
				</TabNav.Tab>
				<TabNav.Tab linkTo="/" linkPreservesQuery={false} onClick={()=>this.onTabClick('add-news')} isActive={()=>this.isTabActive('add-news')}>
					TILFØJ NYHEDER
				</TabNav.Tab>
				<TabNav.Tab linkTo="/" linkPreservesQuery={false} onClick={()=>this.onTabClick('add-other')} isActive={()=>this.isTabActive('add-other')}>
					TILFØJ ANDET
				</TabNav.Tab>
			</TabNav>);
	}

	renderSidebar() {
		return (
			<Sidebar>
				<li className="no-padding">
					<Collapsible accordion>
						<Collapsible.Item
							header={
								<Fragment>
									<Icon name="filter_list" />
									<span class="grey-text">Vis  (undervejs)</span>
							</Fragment>
							}
							headerNode="a"
							onHeaderClick={this.onSidebarHeaderClick}

							initiallyExpanded={true}
							headerClassName={classNames(
								"sidebar-header"
							)} >
							<ul>
								{REVIEW_FILTER_TYPES.map(({ value, label }) =>
									<Fragment key={value}>
										<li key={value}>
											<label>
												<input
													disabled={true}
													type="checkbox"
													className="filled-in"
													checked={this.state.excludeFilters && this.state.excludeFilters.includes(value)}
													onChange={() => this.onToggleSidebarFilter(value)}
												/>
												<span className="black-text">{label}</span>
											</label>
										</li>
									</Fragment>
								)}
							</ul>
						</Collapsible.Item>
					</Collapsible>
				</li>
			</Sidebar>

		);
	}

	printTitles(props) {
		return true;
	}

	renderReviews(props) {
		return (
			<Fragment>
				<div className="edit-content">
					{ props.query?.data?.titles?.edges?.length && 
						<div className="section-headline"> { props.query?.data?.titles?.edges?.length > 1 ? `Tilføj anmeldelser` : `Tilføj anmeldelse` }</div>
					}
					
					<ul>
						{
							<Collapsible key={0} popout className="reviews-list">
							{ props.query?.data?.titles?.edges?.map(({node}) => 
								{
									return (
									<AddNewReviewCard 
									key={node.id}
									content={node}
									viewer={props.query?.data?.viewer}
									shouldDisplayPublishingHouse={() => { return ('c') }} />
								)
								}
								)
							}
							</Collapsible>
						}
					</ul>
				
					{props.query.data &&
						<EditReviewsPaginator
							query={props.query.data}
							
							headline={({ children }) => (
								<div className="section-headline">
									{children}
								</div>
							)}
							shouldDisplayPublishingHouse={() => { return ('c') }}
						/>}
				</div>
			</Fragment>
		);
	}

	renderNewsReplacement() {
		return (
			<Fragment>
			<div className="edit-news">
				<div className="card">
					<div className="row">
						<div className="col s3 m2">
							<Icon className="orange-text text-darken-4 medium" name="new_releases" />
						</div>
						<div className="col s9 m10">
							<h6 className="text-bold">Her kommer om kort tid en mulighed for også at tilføje nyheder, interviews, omtaler og kommentarer om forlagets bøger og forfattere.</h6>
						</div>
					</div>
 				</div>
			</div>
			</Fragment>
		);
	}

	renderOtherReplacement() {
		return (
			<Fragment>
				<div className="edit-news">
					<div className="card">
						<div className="row">
							<div className="col s3 m2">
								<Icon className="orange-text text-darken-3 medium" name="new_releases" />
							</div>
							<div className="col s9 m10">
								<h6 className="text-bold">Her kommer inden længe muligheden for også at tilføje læseprøver, lektør-udtalelser, PR-materiale, markedsføringstiltag og meget andet om forlagets bøger og forfattere.</h6>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);

	}
	renderContent(error, props) {
		return (
			<Fragment>
				{error &&
					'Et eller andet gik galt. Prøv venligst igen.'}
				{!props.query.data &&
					'Indlæser...'}
				{ this.state.activeTab=== 'add-reviews' ? this.renderReviews(props) :
				this.state.activeTab === 'add-news' ? this.renderNewsReplacement() :
				this.renderOtherReplacement() 
				}
			</Fragment>
		);
	}

	render() {
		const {
			query: {
				error,
				data,
			},
		} = this.props;
		const {
			titles
		} = this.state;

		return (
			<AppLayout.Override
				{...AppLayout.Override.forwardProps(this.props)}
				sidebar={this.renderSidebar()}
				extraTabs={this.renderExtraTabs()}
				content={this.renderContent(error, this.props)}
			/>
		);
	}
}

export default withToast(withEnvironment(PublishersEditLayoutOverride));
