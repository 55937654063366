// @flow
import { Set as ImmutableSet } from 'immutable';
import moment from 'moment';
import React, { Fragment } from 'react';
import { commitMutation, graphql } from 'react-relay';

import { AppLayout, Checkbox, Input, Sidebar, Textarea, withToast, type ToastProps } from '../../components';
import type { Viewer } from '../../models';
import { joinNaturally, joinNodesNaturally } from '../../utils/language';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import MessageAttachments, { type MessageAttachment } from '../MessageAttachments';
import MessageLinks, { type MessageLink } from '../MessageLinks';
import MessageRecipientSelector from '../MessageRecipientSelector';
import MessageStatusSelector from '../MessageStatusSelector';
import type { NewMessageLayoutOverrideDataWrapperQueryResponse } from './__generated__/NewMessageLayoutOverrideDataWrapperQuery.graphql';

/** Create message mutation. */
const createMessageMutation = graphql`
mutation NewMessageLayoutOverrideCreateMessageMutation($input: CreateMessageInput!) {
  createMessage(input: $input) {
    message {
      id
    }

    viewer {
      ...UnreadMessageList_viewer
    }
  }
}`;

/** Print layout override props. */
export type NewMessageLayoutOverrideProps = {
  viewer: Viewer,
  query: {
    error: ?Error,
    data: ?NewMessageLayoutOverrideDataWrapperQueryResponse,
  },
} & SearchSettingsProps & EnvironmentProps & ToastProps;

/** Print layout override state. */
type NewMessageLayoutOverrideState = {
  titles: {}[],
  entities: {}[],
  publishingHouses: {}[],
  referencedIds: ImmutableSet<string>,
  prevData: ?NewMessageLayoutOverrideDataWrapperQueryResponse,
  status: 'ACTIVE' | 'INACTIVE' | 'TIMED',
  activeFrom: string,
  activeTo: string,
  links: MessageLink[],
  attachments: MessageAttachment[],
  subject: string,
  body: string,
  includeLinkToBogportalen: boolean,
  recipientIds: ImmutableSet<string>,
  error: ?string,
  submitting: boolean,
};

/** Print layout override. */
class NewMessageLayoutOverride extends React.Component<NewMessageLayoutOverrideProps, NewMessageLayoutOverrideState> {
  state = {
    titles: [],
    entities: [],
    publishingHouses: [],
    prevData: null,
    referencedIds: ImmutableSet(),
    status: 'TIMED',
    activeFrom: moment().format('YYYY-MM-DD'),
    activeTo: moment().add(7, 'days').format('YYYY-MM-DD'),
    links: [],
    attachments: [],
    body: '',
    subject: '',
    includeLinkToBogportalen: true,
    recipientIds: ImmutableSet(),
    submitting: false,
    error: null,
  };

  static getDerivedStateFromProps(
    props: NewMessageLayoutOverrideProps,
    prevState: NewMessageLayoutOverrideState
  ): ?$Shape<NewMessageLayoutOverrideState> {
    const data = props.query.data;
    if (prevState.prevData === data) {
      return null;
    }

    const titles = [];
    const entities = [];
    const publishingHouses = [];
    const allIds = new Set();
    const nodes = data?.nodes;
    const publishingHouseIds = data?.viewer.group.publishingHouseIds ? new Set(data.viewer.group.publishingHouseIds) : null;

    if (nodes) {
      nodes.forEach(node => {
        allIds.add(node.id);

        switch (node.__typename) {
          case 'Title': {
            const publications = node.publications.filter(({ publishingHouse: { id } }) => !publishingHouseIds || publishingHouseIds.has(id));

            if (publications.length) {
              titles.push({
                ...node,
                publications,
              });
              publications.forEach(({ id }) => allIds.add(id));
            }
            break;
          }

          case 'Entity':
            entities.push(node);
            allIds.add(node.id);
            break;

          case 'PublishingHouse':
            if (!publishingHouseIds) {
              publishingHouses.push(node);
              allIds.add(node.id);
            }

            break;
        }
      });
    }

    return {
      titles,
      entities,
      publishingHouses,
      referencedIds: prevState.prevData
        ? prevState.referencedIds.filter(id => allIds.has(id))
        : ImmutableSet(allIds),
      prevData: data,
      recipientIds: data?.viewer?.messageRecipients?.length === 1
        ? ImmutableSet([data.viewer.messageRecipients[0].id])
        : prevState.recipientIds,
    };
  }

  handleSubmit = (evt) => {
    evt.preventDefault();

    if (this.state.loading) {
      return;
    }

    // Clean and validate the data.
    const {
      environment,
    } = this.props;
    const {
      activeFrom,
      activeTo,
      attachments: rawAttachments,
      body: rawBody,
      includeLinkToBogportalen,
      links: rawLinks,
      recipientIds: rawRecipientIds,
      referencedIds: rawReferencedIds,
      status,
      subject: rawSubject,
    } = this.state;
	
    const subject = rawSubject.trim();
    const body = rawBody.trim();
    const recipientIds = rawRecipientIds.toArray();
    const referencedIds = rawReferencedIds.toArray();
    const links = MessageLinks.serialize(rawLinks);
    const { attachments, uploadables } = MessageAttachments.serializeForCreate(rawAttachments);

    if (subject === '') {
      this.setState({
        error: 'Meddelelsen skal have en overskrift.',
        submitting: false,
      });
      return;
    }

    if (recipientIds.length === 0) {
      this.setState({
        error: 'Vælg mindst 1 modtager.',
        submitting: false,
      });
      return;
    }

    const payload = {
      activeFrom,
      activeTo,
      attachments,
      body,
      includeLinkToBogportalen,
      links,
      recipientIds,
      referencedIds,
      status,
      subject,
    };

    this.setState({
      submitting: true,
    }, () => commitMutation(environment, {
      mutation: createMessageMutation,
      variables: {
        input: {
          clientMutationId: '1',
          ...payload,
        },
      },
      uploadables,
      onCompleted: (data, errors) => {
        const { toast } = this.props;

        if (errors?.length) {
          this.setState({
            submitting: false,
            error: 'Der er opstået en fejl.',
          });
        }
        else {
          toast('Meddelelsen er oprettet');

          this.props.onOverrideClose();
        }
      },
    }));
  }

  render() {
    const {
      query: {
        error,
        data,
      },
    } = this.props;
    const {
      activeFrom,
      activeTo,
      attachments,
      body,
      entities,
      error: submitError,
      includeLinkToBogportalen,
      links,
      publishingHouses,
      recipientIds,
      referencedIds,
      status,
      subject,
      submitting,
      titles,
    } = this.state;

    const canSelectRecipients = data?.viewer?.messageRecipients?.length > 1;
    const referencedNodes = [];
    let selectedPublicationCount = 0;
    let selectedPhysicalPublicationCount = 0;

    titles.forEach(({ id, title, publications }) => {
      const selectedCount = publications.reduce(
        (accum, { id }) => accum + (referencedIds.has(id) ? 1 : 0),
        0
      );
      const selectedPhysicalCount = publications.reduce(
        (accum, { id, isDigital }) => accum + (referencedIds.has(id) && !isDigital ? 1 : 0),
        0
      );
      selectedPublicationCount += selectedCount;
      selectedPhysicalPublicationCount += selectedPhysicalCount;

      if (selectedCount) {
        referencedNodes.push(
          <Fragment key={id}>
            <strong>{title}</strong>
            {selectedCount === publications.length
              ? ' (alle udgaver)'
              : ` (${selectedCount} udgave${selectedCount === 1 ? '' : 'r'})`}
          </Fragment>
        );
      }
    });

    entities.forEach(({ id, name }) => {
      if (referencedIds.has(id)) {
        referencedNodes.push(
          <strong key={id}>
            {name}
          </strong>
        );
      }
    });

    publishingHouses.forEach(({ id, name }) => {
      if (referencedIds.has(id)) {
        referencedNodes.push(
          <strong key={id}>
            {name}
          </strong>
        );
      }
    });

    return (
      <AppLayout.Override
        {...AppLayout.Override.forwardProps(this.props)}
        sidebar={
          <Sidebar>
            {titles.map(({ id, title, authors, hasMoreAuthors, publications }) => {
              let heading = `"${title}"`;
              if (authors?.length) {
                heading += ' af ' + joinNaturally(authors.map(({ name }) => name));

                if (hasMoreAuthors) {
                  heading += ' m.fl.';
                }
              }

              return (
                <Sidebar.Section
                  label={heading}
                  icon="book"
                  initiallyExpanded
                  truncateLabel
                  key={id}
                >
                  <ul>
                    {publications.map(({ id, isbn13, format }) =>
                      <li key={id}>
                        <label>
                          <input
                            type="checkbox"
                            checked={referencedIds.has(id)}
                            onChange={() => this.setState({
                              referencedIds: referencedIds.has(id) ? referencedIds.remove(id) : referencedIds.add(id),
                            })}
                            className="filled-in"
                          />
                          <span className="black-text truncate">{isbn13} - {format}</span>
                        </label>
                      </li>
                    )}
                  </ul>
                </Sidebar.Section>
              );
            })}

            {entities.length > 0 &&
             <Sidebar.Section
               label="Forfattere"
               icon="person"
               initiallyExpanded
             >
               <ul>
                 {entities.map(({ id, name }) =>
                   <li key={id}>
                     <label>
                       <input
                         type="checkbox"
                         checked={referencedIds.has(id)}
                         onChange={() => this.setState({
                           referencedIds:
                    referencedIds.has(id)
                      ? referencedIds.remove(id)
                      : referencedIds.add(id),
                         })}
                         className="filled-in"
                       />
                       <span className="black-text truncate">{name}</span>
                     </label>
                   </li>
                 )}
               </ul>
             </Sidebar.Section>}

            {publishingHouses.length > 0 &&
             <Sidebar.Section
               label="Forlag"
               icon="library_books"
               initiallyExpanded
             >
               <ul>
                 {publishingHouses.map(({ id, name }) =>
                   <li key={id}>
                     <label>
                       <input
                         type="checkbox"
                         checked={referencedIds.has(id)}
                         onChange={() => this.setState({
                           referencedIds:
                    referencedIds.has(id)
                      ? referencedIds.remove(id)
                      : referencedIds.add(id),
                         })}
                         className="filled-in"
                       />
                       <span className="black-text truncate">{name}</span>
                     </label>
                   </li>
                 )}
               </ul>
             </Sidebar.Section>}
          </Sidebar>
        }
        content={
          <Fragment>
            {error &&
           'Et eller andet gik galt. Prøv venligst igen.'}

            {!data &&
           'Indlæser...'}

            {!!data &&
             <form
               className="row"
               onSubmit={this.handleSubmit}
             >
               {canSelectRecipients &&
               <div className="col s12">
                 <div className="section-headline">
                   {referencedNodes.length > 0
                     ? 'Vælg modtagere af meddelelse om '
                     : 'Vælg modtagere af generel meddelelse'}
                   {joinNodesNaturally(referencedNodes)}
                 </div>

                 <div className="card">
                   <MessageRecipientSelector
                     viewer={data.viewer}
                     selectedIds={recipientIds}
                     onChange={recipientIds => this.setState({ recipientIds })}
                   />
                 </div>
               </div>}

               <div className="col s12">
                 <div className="section-headline">
                   {canSelectRecipients
                     ? 'Meddelelsens indhold'
                     : referencedNodes.length > 0
                       ? 'Vælg indhold af meddelelse om '
                       : 'Vælg indhold af generel meddelelse'}
                   {!canSelectRecipients &&
                    joinNodesNaturally(referencedNodes)}
                 </div>

                 <div className="card">
                   <Input
                     value={subject}
                     label="En fængende overskrift"
                     onChange={subject => this.setState({ subject })}
                   />

                   <Textarea
                     value={body}
                     label="Yderligere oplysninger"
                     onChange={body => this.setState({ body })}
                   />

                   <Checkbox
                     label="Tilføj link til Bogportalen"
                     value={(selectedPublicationCount === 1 && selectedPhysicalPublicationCount === 1) && includeLinkToBogportalen}
                     onChange={includeLinkToBogportalen => this.setState({ includeLinkToBogportalen })}
                     disabled={!(selectedPublicationCount === 1 && selectedPhysicalPublicationCount === 1)}
                   />
                 </div>
               </div>

               <div className="col s12 m6">
                 <div className="section-headline">
                   Tilføj link
                 </div>

                 <MessageLinks
                   value={links}
                   onChange={links => this.setState({ links })}
                 />
               </div>

               <div className="col s12 m6">
                 <div className="section-headline">
                   Tilføj fil til download
                 </div>

                 <MessageAttachments
                   value={attachments}
                   onChange={attachments => this.setState({ attachments })}
                 />
               </div>

               <div className="col s12">
                 <div className="section-headline">
                   Vælg datoer for aktiv periode og opret meddelelsen
                 </div>

                 <div className="card">
                   <MessageStatusSelector
                     viewer={data.viewer}
                     status={status}
                     activeFrom={activeFrom}
                     activeTo={activeTo}
                     onChange={({ status, activeFrom, activeTo }) => this.setState({ status, activeFrom, activeTo })}
                     minDate={moment().format('YYYY-MM-DD')}
                   />

                   <div className="row bottomrow">
                     <div className="col s12 m6">
                       <div className="red-text">{submitError}</div>
                     </div>
                     <div className="col s12 m6">
                       <button
                         className="waves-effect waves-light btn-large right standard-bg-color"
                         type="submit"
                         disabled={submitting}
                       >
                         OPRET MEDDELELSE
                       </button>
                     </div>
                   </div>
                 </div>

               </div>
             </form>}
          </Fragment>
        }
      />
    );
  }
}

export default withToast(withEnvironment(NewMessageLayoutOverride));
