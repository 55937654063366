// @flow
import React, { Fragment } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { Checkbox, Form, Input, Select } from '../../components';
import {
  CATEGORY_GROUPS,
  CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPES,
  CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPE_BY_VALUE,
  parseNotification,
  serializeNotification,
} from '../../models';
import type { NotificationSubscriptionForm_notificationSubscription } from './__generated__/NotificationSubscriptionForm_notificationSubscription.graphql';

const DEFAULT_TYPE = CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPES[0].value;
const CATEGORY_GROUP_OPTIONS = [{
  label: 'alle genrer',
  value: null,
}].concat(
  CATEGORY_GROUPS.map(({ label, value }) => ({
    label: label.charAt(0).toLowerCase() + label.slice(1),
    value,
  }))
);

export type NotificationSubscriptionFormProps = {
  link: ?NotificationSubscriptionForm_notificationSubscription,
  loading: ?boolean,
  onDelete: ?() => any,
  onSubmit: () => any,
  error: ?string,
};

type NotificationSubscriptionFormState = {
  categoryGroup: null | string,
  description: null | string,
  notification: string,
  type: string,
  wantsDeletion: boolean,
};

class NotificationSubscriptionForm extends React.Component<NotificationSubscriptionFormProps, NotificationSubscriptionFormState> {
  constructor(props: NotificationSubscriptionFormProps) {
    super(props);

    const { notificationSubscription } = props;

    if (notificationSubscription) {
      this.state = {
        categoryGroup: notificationSubscription.categoryGroup,
        description: notificationSubscription.description || '',
        notification: notificationSubscription.notification,
        type: notificationSubscription.type,
        wantsDeletion: false,
      };
    }
    else {
      this.state = {
        categoryGroup: null,
        description: '',
        notification: CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPE_BY_VALUE[DEFAULT_TYPE].defaultNotification,
        type: DEFAULT_TYPE,
        wantsDeletion: false,
      };
    }
  }

  handleSubmit = () => {
    const { categoryGroup, description, notification, type, wantsDeletion } = this.state;
    const { onDelete, onSubmit } = this.props;

    if (wantsDeletion) {
      if (confirm('Er du sikker på at du vil slette denne notifikation?')) {
        onDelete();
      }
    }
    else {
      onSubmit({ categoryGroup, description, notification, type });
    }
  }

  renderNotificationFields(): React$Node {
    const { categoryGroup, notification } = this.state;
    const parsedNotification = parseNotification(notification);

    const pushNotificationChange = (change) => {
      this.setState({
        notification: serializeNotification({
          ...parsedNotification,
          ...change,
        }),
      });
    };

    switch (parsedNotification?.type) {
      case 'BESTSELLER':
        return (
          <Fragment>
            <span style={{ fontSize: '18px' }}>Bøger i kategorien </span>
            <Select
              bare
              value={categoryGroup}
              options={CATEGORY_GROUP_OPTIONS}
              onChange={categoryGroup => this.setState({ categoryGroup })}
            />
            <br />

            <span style={{ fontSize: '18px' }}>der i de seneste </span>
            <Select
              bare
              value={parsedNotification.sustainedDuration}
              options={[
                { value: '2d', label: '2 dage' },
                { value: '7d', label: '7 dage' },
                { value: '14d', label: '2 uger' },
                { value: '28d', label: '4 uger' },
              ]}
              onChange={sustainedDuration => pushNotificationChange({ sustainedDuration })}
            />
            <br />

            <span style={{ fontSize: '18px' }}>har haft en popularitet på mere end </span>
            <Select
              bare
              value={parsedNotification.minScore.toString()}
              options={[
                { value: '5000', label: '5.000' },
                { value: '10000', label: '10.000' },
                { value: '50000', label: '50.000' },
                { value: '100000', label: '100.000' },
              ]}
              onChange={value => pushNotificationChange({ minScore: parseInt(value, 10) })}
            />
          </Fragment>
        );

      case 'SUDDEN_RISE':
        return (
          <Fragment>
            <span style={{ fontSize: '18px' }}>Bøger, der indenfor de sidste </span>
            <Select
              bare
              value={parsedNotification.overDuration}
              options={[
                { value: '24h', label: '24 timer' },
                { value: '2d', label: '2 dage' },
                { value: '7d', label: '7 dage' },
              ]}
              onChange={overDuration => pushNotificationChange({ overDuration })}
            />
            <br />

            <span style={{ fontSize: '18px' }}>er steget i popularitet med mere end </span>
            <Select
              bare
              value={parsedNotification.percentageRise.toString()}
              options={[
                { value: '50', label: '50 procent' },
                { value: '100', label: '100 procent' },
                { value: '250', label: '250 procent' },
                { value: '500', label: '500 procent' },
              ]}
              onChange={value => pushNotificationChange({ percentageRise: parseInt(value, 10) })}
            />
            <br />

            <span style={{ fontSize: '18px' }}>fra en popularitet på mere end </span>
            <Select
              bare
              value={parsedNotification.minBaseScore.toString()}
              options={[
                { value: '100', label: '100' },
                { value: '500', label: '500' },
                { value: '1000', label: '1.000' },
                { value: '5000', label: '5.000' },
              ]}
              onChange={value => pushNotificationChange({ minBaseScore: parseInt(value, 10) })}
            />
          </Fragment>
        );

      case 'STEADYSELLER':
        return (
          <Fragment>
            <span style={{ fontSize: '18px' }}>Bøger, der er udkommet for mere end </span>
            <Select
              bare
              value={parsedNotification.minPublishedAgo}
              options={[
                { value: '6m', label: '6 måneder' },
                { value: '12m', label: '12 måneder' },
                { value: '24m', label: '24 måneder' },
              ]}
              onChange={minPublishedAgo => pushNotificationChange({ minPublishedAgo })}
            />
            <br />

            <span style={{ fontSize: '18px' }}>siden, og i de seneste </span>
            <Select
              bare
              value={parsedNotification.sustainedDuration}
              options={[
                { value: '6m', label: '6 måneder' },
                { value: '12m', label: '12 måneder' },
                { value: '24m', label: '24 måneder' },
              ]}
              onChange={sustainedDuration => pushNotificationChange({ sustainedDuration })}
            />
            <br />

            <span style={{ fontSize: '18px' }}>har haft en popularitet på mere end </span>
            <Select
              bare
              value={parsedNotification.minScore.toString()}
              options={[
                { value: '500', label: '500' },
                { value: '1000', label: '1.000' },
                { value: '2000', label: '2.000' },
              ]}
              onChange={value => pushNotificationChange({ minScore: parseInt(value, 10) })}
            />
          </Fragment>
        );

      default:
        return null;
    }
  }

  render() {
    const { notificationSubscription, error, loading } = this.props;
    const { description, type, wantsDeletion } = this.state;

    return (
      <Form
        onSubmit={this.handleSubmit}
        error={error}
        loading={loading}
      >
        {false &&
         (!notificationSubscription ||
        !notificationSubscription.isDefault) &&
        <Input
          value={description}
          onChange={description => this.setState({ description })}
          autoComplete="off"
          label="Kort beskrivelse af denne notifikation"
        />}

        {!notificationSubscription &&
        <Select
          value={type}
          onChange={type => this.setState({
            categoryGroup: null,
            notification: CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPE_BY_VALUE[type].defaultNotification,
            type,
          })}
          label="Notifikation"
          options={
            CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPES.map(({ value, description }) => ({ value, label: description }))
          }
        />}

        {this.renderNotificationFields()}

        {notificationSubscription &&
         !notificationSubscription.isDefault &&
         <div className="col s12 m6 l4">
           <Checkbox
             label="Slet denne notifikation"
             value={wantsDeletion}
             onChange={wantsDeletion => this.setState({ wantsDeletion })}
           />
         </div>}
      </Form>
    );
  }
}

export default createFragmentContainer(NotificationSubscriptionForm, {
  notificationSubscription: graphql`
fragment NotificationSubscriptionForm_notificationSubscription on CustomerNotificationSubscription {
  categoryGroup
  description
  id
  isDefault
  notification
  type
}`,
});
