// @flow
const MEDIA_TYPE_OPTIONS: {
  value: string,
  label: string,
  filterBy: string[],
}[] = [{
  value: 'national_newspaper',
  label: 'Landsdækkende aviser',
  filterBy: [
    'TWVkaWFUeXBlOjE=', // Avis
  ],
}, {
  value: 'regional_newspaper',
  label: 'Regionale aviser',
  filterBy: [
    'TWVkaWFUeXBlOjI=', // Regional avis
  ],
}, {
  value: 'periodical',
  label: 'Ugeblade og magasiner',
  filterBy: [
    'TWVkaWFUeXBlOjM=', // Ugeblad
  ],
}, {
  value: 'trade',
  label: 'Fagblade',
  filterBy: [
    'TWVkaWFUeXBlOjQ=', // Fagblad
  ],
}, {
  value: 'online',
  label: 'Blogs og online-medier',
  filterBy: [
    'TWVkaWFUeXBlOjU=', // Blog
    'TWVkaWFUeXBlOjY=', // Onlinemedie
    'TWVkaWFUeXBlOjk=', // Onlinemedie uden citat
  ],
}];
const VALID_MEDIA_TYPE_VALUES = MEDIA_TYPE_OPTIONS.reduce((memo, option) => {
  memo[option.value] = option.value;
  return memo;
}, {});
const FILTER_BY_BY_MEDIA_TYPE_VALUE = MEDIA_TYPE_OPTIONS.reduce((memo, option) => {
  memo[option.value] = option.filterBy;
  return memo;
}, {});

const CATEGORY_OPTIONS: {
  value: string,
  label: string,
  filterBy: string[],
  subCategories: {
    value: string,
    label: string,
    filterBy: string[],
  }[],
}[] = [{
  value: 'skoenlitteratur',
  label: 'Skønlitteratur',
  filterBy: ['Q2F0ZWdvcnk6Mg=='],
  subCategories: [{
    value: 'romaner',
    label: 'Romaner og noveller',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MTI='],
  }, {
    value: 'krimi',
    label: 'Krimi og spænding',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6Mg=='],
  }, {
    value: 'digte',
    label: 'Digte',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MTU='],
  }, {
    value: 'tegneserier',
    label: 'Tegneserier/graphic novels',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6MjE=',
      'U2VhcmNoQ2F0ZWdvcnk6NjE=',
      'U2VhcmNoQ2F0ZWdvcnk6NjI=',
    ],
  }, {
    value: 'ungdom',
    label: 'Ungdomsbøger',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MjY='],
  }, {
    value: 'boern',
    label: 'Børne- og billedbøger',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6Mw==',
      'U2VhcmNoQ2F0ZWdvcnk6Njk=',
    ],
  }],
}, {
  value: 'biografi',
  label: 'Biografier og erindringer',
  filterBy: ['Q2F0ZWdvcnk6MzE='],
  subCategories: [],
}, {
  value: 'faglitteratur',
  label: 'Faglitteratur',
  filterBy: ['Q2F0ZWdvcnk6MQ=='],
  subCategories: [{
    value: 'mad',
    label: 'Mad og drikke',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MzI='],
  }, {
    value: 'sundhed',
    label: 'Krop, sind og sundhed',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6NA==', 'U2VhcmNoQ2F0ZWdvcnk6NTA='],
  }, {
    value: 'kultur',
    label: 'Historie, kultur, religion og filosofi',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6MzU=',
      'U2VhcmNoQ2F0ZWdvcnk6NjY=',
      'U2VhcmNoQ2F0ZWdvcnk6MTE=',
      'U2VhcmNoQ2F0ZWdvcnk6NjM=',
      'U2VhcmNoQ2F0ZWdvcnk6NTI=',
      'U2VhcmNoQ2F0ZWdvcnk6Mzk=',
    ],
  }, {
    value: 'erhverv',
    label: 'Politik, økonomi, erhverv og jura',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6OA==',
      'U2VhcmNoQ2F0ZWdvcnk6NDY=',
      'U2VhcmNoQ2F0ZWdvcnk6NjA=',
      'U2VhcmNoQ2F0ZWdvcnk6NjU=',
    ],
  }, {
    value: 'musik_film',
    label: 'Musik, film og teater',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6MTM=',
      'U2VhcmNoQ2F0ZWdvcnk6NDI=',
      'U2VhcmNoQ2F0ZWdvcnk6Njg=',
    ],
  }, {
    value: 'kunst',
    label: 'Kunst, design og arkitektur',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6Nw==',
      'U2VhcmNoQ2F0ZWdvcnk6NDM=',
    ],
  }, {
    value: 'male',
    label: 'Malebøger',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6NjQ='],
  }, {
    value: 'fritid',
    label: 'Bolig, sport og fritid',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6MTQ=',
      'U2VhcmNoQ2F0ZWdvcnk6NTE=',
    ],
  }, {
    value: 'natur',
    label: 'Natur og husdyr',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6Ng=='],
  }, {
    value: 'naturvidenskab',
    label: 'Naturvidenskab, matematik og IT',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6MTA=',
      'U2VhcmNoQ2F0ZWdvcnk6Mzg=',
    ],
  }, {
    value: 'faglitteratur_boern',
    label: 'Faglitteratur for børn',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MTk='],
  }, {
    value: 'kort',
    label: 'Kort og rejseførere',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MzY='],
  }, {
    value: 'kalendere_aar',
    label: 'Kalendere og årspublikationer',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6Njc=',
      'U2VhcmNoQ2F0ZWdvcnk6MTc=',
    ],
  }, {
    value: 'leksika',
    label: 'Leksika og ordbøger',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6NQ=='],
  }, {
    value: 'skole',
    label: 'Skole- og lærebøger',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6OQ=='],
  }],
}];
const VALID_CATEGORY_VALUES = CATEGORY_OPTIONS.reduce((memo, option) => {
  memo[option.value] = option.value;
  option.subCategories.forEach(({ value }) => { memo[value] = value });
  return memo;
}, {});
const FILTER_BY_BY_CATEGORY_VALUE = CATEGORY_OPTIONS.reduce((memo, option) => {
  memo[option.value] = option.filterBy;
  option.subCategories.forEach(({ value, filterBy }) => { memo[value] = filterBy });
  return memo;
}, {});
const VALID_MESSAGES_MODE_OPTIONS = {
  active: 'active',
  all: 'all',
  sent: 'sent',
};


export {
  CATEGORY_OPTIONS,
  FILTER_BY_BY_CATEGORY_VALUE,
  FILTER_BY_BY_MEDIA_TYPE_VALUE,
  MEDIA_TYPE_OPTIONS,
  VALID_CATEGORY_VALUES,
  VALID_MEDIA_TYPE_VALUES,
  VALID_MESSAGES_MODE_OPTIONS,
};
