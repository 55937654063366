// @flow
import React, { Fragment } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import classNames from 'classnames';
import { FormattedNumber } from 'react-intl';
import BillboardChart from 'react-billboardjs';

import { DevelopmentIcon, Modal } from '../../components';
import BaseTitleCard from './BaseTitleCard';
import type { BaseTitleCard_title } from './__generated__/BaseTitleCard_title.graphql';
import type { BaseTitleCard_viewer } from './__generated__/BaseTitleCard_viewer.graphql';

function getChartConfiguration(title: BaseTitleCard_title) {
  const history = title.popularityScore120DayHistory;

  if (!history?.length) {
    return {};
  }

  // Determine the value range.
  let minValue = history[0].score;
  let maxValue = minValue;

  history.forEach(({ score }) => {
    if (score < minValue) {
      minValue = score;
    }
    else if (score > maxValue) {
      maxValue = score;
    }
  });

  // Calculate Y-axis divider lines.
  const length = Math.log(maxValue - minValue) * Math.LOG10E + 1 | 0;
  const delesmed = Math.pow(10, (length - 1));
  const forskel = maxValue - minValue;
  const afrundetforskel = Math.round(forskel / delesmed) * delesmed;

  const a = ~~(afrundetforskel / 4);
  const b = ~~(afrundetforskel / 2);
  const c = ~~(afrundetforskel * 3 / 4);
  const d = afrundetforskel > maxValue ? maxValue : afrundetforskel;

  // Determine the headline.
  let headline = `"${title.title}"`;

  if (title.authors?.length > 0) {
    headline += ` af ${title.authors.map(({ name }) => name).join(', ')}${title.hasMoreAuthors ? ' m.fl.' : ''}`;
  }

  return {
    title: {
      text: headline + '\nPopularitet seneste 4 måneder',
      padding: {
        bottom: 24,
      },
      position: 'top-left',
    },
    data: {
      x: 'date',
      rows: [
        ['date', 'popularitet'],
      ].concat(title.popularityScore120DayHistory.map(({ date, score }) => [date, score])),
      type: 'area-spline',
    },
    grid: {
      x: {
        lines: [{
          value: title.datePublishedFirst,
          text: 'Udgivelsesdato',
        }],
      },
      y: {
        show: true,
      },
    },

    point: {
      show: false,
    },
    color: {
      pattern: [
        'white',
      ],
    },
    transition: {
      duration: 1200,
    },
    line: {
      classes: [
        'line-class-data2',
      ],
    },
    zoom: {
      enabled: {
        type: 'drag',
      },
    },
    legend: {
      show: false,
    },
    axis: {
      x: {
        tick: {
          format: '%d-%m-%Y',
          count: 6,
          show: true,
        },
        type: 'timeseries',
      },
      y: {
        max: maxValue,
        min: 0,
        tick: {
          values: [0, minValue, a, b, c, d, maxValue],
          show: false,
          text: {
            show: true,
          },
        },
      },
    },
  };
}

/** Popularity-rendering title card props. */
type PopularityTitleCardProps = {
  excludeDigital?: ?boolean,
  excludeUnavailable?: ?boolean,
  showingOnlyFavorites: boolean,
  shouldDisplayPublishingHouse: (id: ?string) => boolean,
  title: BaseTitleCard_title,
  viewer: BaseTitleCard_viewer,
};

/** Popularity-rendering title card state. */
type PopularityTitleCardState = {
  modalOpen: boolean,
};

/** Popularity-rendering title card. */
class PopularityTitleCard extends React.Component<PopularityTitleCardProps, PopularityTitleCardState> {
  state = {
    modalOpen: false,
  }

  handleScoreClick = () => {
    this.setState({
      modalOpen: true,
    });
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const {
      beforePublications,
      excludeDigital,
      excludeUnavailable,
      shouldDisplayPublishingHouse,
      showingOnlyFavorites,
      title,
      viewer,
    } = this.props;
    const { modalOpen } = this.state;

    return (
      <BaseTitleCard
        excludeDigital={excludeDigital}
        excludeUnavailable={excludeUnavailable}
        showingOnlyFavorites={showingOnlyFavorites}
        shouldDisplayPublishingHouse={shouldDisplayPublishingHouse}
        beforePublications={beforePublications}
        title={title}
        viewer={viewer}
        renderScores={({
          popularityScore,
          popularityScoreYesterday,
          popularityScoreWeekAgo,
        }) => {
          const popularityScoreYesterdayChange = popularityScoreYesterday
            ? (100 * (popularityScore - popularityScoreYesterday) / popularityScoreYesterday).toFixed(1)
            : null;
          const popularityScoreWeekAgoChange = popularityScoreWeekAgo
            ? (100 * (popularityScore - popularityScoreWeekAgo) / popularityScoreWeekAgo).toFixed(1)
            : null;

          return (
            <Fragment>
              <div className="pop-score right">
                <div className="minor-pop-score valign-wrapper">
                  <DevelopmentIcon direction={
                    popularityScoreYesterdayChange === null
                      ? 'unknown'
                      : popularityScoreYesterdayChange >= 0.1
                        ? 'up'
                        : popularityScoreYesterdayChange > -0.1
                          ? 'stable'
                          : 'down'
                  }
                  />

                  {popularityScoreYesterdayChange === null
                    ? 'Ikke beregnet'
                    : <Fragment><FormattedNumber value={popularityScoreYesterdayChange} />% siden i går</Fragment>}
                </div>

                <div className="minor-pop-score valign-wrapper">
                  <DevelopmentIcon direction={
                    popularityScoreWeekAgoChange === null
                      ? 'unknown'
                      : popularityScoreWeekAgoChange >= 0.1
                        ? 'up'
                        : popularityScoreWeekAgoChange > -0.1
                          ? 'stable'
                          : 'down'
                  }
                  />

                  {popularityScoreWeekAgoChange === null
                    ? 'Ikke beregnet'
                    : <Fragment><FormattedNumber value={popularityScoreWeekAgoChange} />% seneste uge</Fragment>}
                </div>

                <div
                  className="major-pop-score major-pop-score--clickable"
                  onClick={this.handleScoreClick}
                >
                  <div className={classNames('meta-pop-score', popularityScore > 999999 && 'meta-pop-score--high')}>
                    {popularityScore !== 0 &&
                   <FormattedNumber value={popularityScore} />}
                  </div>
                  <div className="small-text">popularitet i dag</div>
                </div>
              </div>

              <Modal
                open={modalOpen}
                onClose={this.handleModalClose}
                className="chart-modal"
              >
                <div className="popularity-chart">
                  <BillboardChart
                    {...getChartConfiguration(title)}
                  />
                </div>
              </Modal>
            </Fragment>
          );
        }}
      />
    );
  }
}

export default createFragmentContainer(
  PopularityTitleCard, {
    title: graphql`
fragment PopularityTitleCard_title on Title {
  datePublishedFirst
  hasMoreAuthors
  title

  authors {
    id
    name
  }

  popularityScore120DayHistory {
    date
    score
  }

  ...BaseTitleCard_title
}`,
    viewer: graphql`
fragment PopularityTitleCard_viewer on Customer {
  ...BaseTitleCard_viewer
}`,
  }
);
