// @flow
import React from 'react';
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';

import { withRouter } from '../../utils/router';

const QueryPreservingLink = withRouter(
  function QueryPreservingLink({ children, to, location, exact, className, activeClassName, overrideQuery }) {
    let search = location.search;

    if (overrideQuery) {
      search = queryString.stringify({
        ...queryString.parse(search),
        ...overrideQuery,
      });
    }

    return (
      <NavLink
        to={{
          pathname: to,
          search,
        }}
        activeClassName={activeClassName}
        className={className}
        exact={exact}
      >
        {children}
      </NavLink>
    );
  }
);

export default QueryPreservingLink;
