// @flow
import React from 'react';
import classNames from 'classnames';

import CollapsibleItem from './CollapsibleItem';

/** Collapsible props. */
export type CollapsibleProps = {
  accordion?: ?boolean,
  children?: ?React$Node,
  className?: ?string,
  expandable?: ?boolean,
  id?: ?string,
  popout?: ?boolean,
};

/**
 * Collapsible.
 *
 * Designed to behave in the way the MaterializeCSS collapsible behaves.
 */
export default class Collapsible extends React.Component<CollapsibleProps> {
  static Item = CollapsibleItem;

  render() {
    const { className, children, id, popout, expandable, accordion } = this.props;

    return (
      <ul
        className={classNames('collapsible', className, {
          popout,
          expandable,
          accordion,
        })}
        id={id}
      >
        {children}
      </ul>
    );
  }
}
