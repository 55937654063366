// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import { withToast, type ToastProps } from '../../components';
import { type SearchFilter } from '../../models';
import type { ManageSearchProps } from './ManageSearchProps';
import withManageSearch from './withManageSearch';

/** Filter adder props. */
type FilterAdderProps = {
  children: ({
    addFilter: (filter: {
      /** Discovered label. */
      label?: ?string,
    } & SearchFilter) => void,
  }) => React$Node,
} & ToastProps & ManageSearchProps;

/** Filter adder. */
const FilterAdder: React$ComponentType<FilterAdderProps> = function FilterAdder({
  children,
  toast,
  filters,
  setFilters,
}) {
  const addFilter = (filter: {
    label?: ?string,
  } & SearchFilter) => {
    if (!filters.some(({ id }) => id === filter.id)) {
      toast(`${filter.label} er oprettet som søgefilter`);
      setFilters([...filters, filter]);
    }
  };

  return children({
    addFilter,
  });
};

export default withManageSearch(withToast(FilterAdder));
