/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MessageStatusSelector_viewer$ref: FragmentReference;
declare export opaque type MessageStatusSelector_viewer$fragmentType: MessageStatusSelector_viewer$ref;
export type MessageStatusSelector_viewer = {|
  +group: ?{|
    +type: string
  |},
  +$refType: MessageStatusSelector_viewer$ref,
|};
export type MessageStatusSelector_viewer$data = MessageStatusSelector_viewer;
export type MessageStatusSelector_viewer$key = {
  +$data?: MessageStatusSelector_viewer$data,
  +$fragmentRefs: MessageStatusSelector_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "MessageStatusSelector_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "group",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerGroup",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c7c78c51a1301976f815e41f8356d2ac';
module.exports = node;
