/**
 * @flow
 * @relayHash 730343ad297d55b03118ee65deb3f5f5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditReviewInput = {|
  clientMutationId: string,
  quotes?: ?$ReadOnlyArray<?string>,
  date: string,
  reviewerId?: ?string,
  mediaId: string,
  originalContent?: ?string,
  titleIds?: ?$ReadOnlyArray<string>,
  underHeading?: ?string,
  reviewId: string,
  gradingScale: CreateReviewGradingScaleInput,
  grade: number,
  createdBy: string,
  headline: string,
  url?: ?string,
  summaryHeadline?: ?string,
  content?: ?string,
  quotesCreatedBy?: ?$ReadOnlyArray<?string>,
|};
export type CreateReviewGradingScaleInput = {|
  min: number,
  max: number,
  symbol: string,
  precision: number,
|};
export type EditReviewCardeditReviewMutationVariables = {|
  input: EditReviewInput
|};
export type EditReviewCardeditReviewMutationResponse = {|
  +editReview: ?{|
    +review: ?{|
      +id: string,
      +grade: ?number,
      +content: ?string,
      +date: ?any,
      +headline: string,
      +quotes: ?$ReadOnlyArray<string>,
      +quotesCreatedBy: ?$ReadOnlyArray<{|
        +id: string
      |}>,
    |},
    +viewer: ?{|
      +id: string,
      +email: string,
      +isAdministrator: boolean,
      +isSuperAdmin: boolean,
    |},
  |}
|};
export type EditReviewCardeditReviewMutation = {|
  variables: EditReviewCardeditReviewMutationVariables,
  response: EditReviewCardeditReviewMutationResponse,
|};
*/


/*
mutation EditReviewCardeditReviewMutation(
  $input: EditReviewInput!
) {
  editReview(input: $input) {
    review {
      id
      grade
      content
      date
      headline
      quotes
      quotesCreatedBy {
        id
      }
    }
    viewer {
      id
      email
      isAdministrator
      isSuperAdmin
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "EditReviewInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "editReview",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditReviewPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "review",
        "storageKey": null,
        "args": null,
        "concreteType": "Review",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "grade",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "content",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "date",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "headline",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "quotes",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "quotesCreatedBy",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAdministrator",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isSuperAdmin",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EditReviewCardeditReviewMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "EditReviewCardeditReviewMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "EditReviewCardeditReviewMutation",
    "id": null,
    "text": "mutation EditReviewCardeditReviewMutation(\n  $input: EditReviewInput!\n) {\n  editReview(input: $input) {\n    review {\n      id\n      grade\n      content\n      date\n      headline\n      quotes\n      quotesCreatedBy {\n        id\n      }\n    }\n    viewer {\n      id\n      email\n      isAdministrator\n      isSuperAdmin\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ba389aa6f7807189044844020b0ac0d9';
module.exports = node;
