/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type LinkForm_link$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type LinkList_viewer$ref: FragmentReference;
declare export opaque type LinkList_viewer$fragmentType: LinkList_viewer$ref;
export type LinkList_viewer = {|
  +id: string,
  +name: string,
  +links: ?$ReadOnlyArray<{|
    +id: string,
    +url: string,
    +description: string,
    +$fragmentRefs: LinkForm_link$ref,
  |}>,
  +$refType: LinkList_viewer$ref,
|};
export type LinkList_viewer$data = LinkList_viewer;
export type LinkList_viewer$key = {
  +$data?: LinkList_viewer$data,
  +$fragmentRefs: LinkList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "LinkList_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "links",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerLink",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "LinkForm_link",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c65c9141b6e60fd266e2384c9304d63';
module.exports = node;
