// @flow
import React from 'react';
import { graphql } from 'react-relay';

import { AppLayout } from '../../components';
import { QueryRenderer } from '../../utils/relay';
import { withManageSearch, type ManageSearchProps } from '../SearchManager';
import PublishersEditLayoutOverride from './PublishersEditLayoutOverride';

function PublishersEditLayoutOverrideDataWrapper({filters, ...forwardedProps}: any) {

	const {
		searchSettings,
	} = forwardedProps;

	const titleIds = filters.reduce((memo, { type, id }) => {
		if (type === 'Title') {
		  memo.push(id);
		}
		return memo;
	  }, []);

	const variables = {
		count:  10,
		cursor: 0,
		titleIds,
		filterBy: searchSettings.filterBy,
		order: 'DESC',
		orderBy: 'DATE',
		search: searchSettings.searchQuery,
		includeNews: false,
		includeReviews: true,
		includeGeneralMessages: false,
		mode: 'PUBLISHING_HOUSE',
		onlyFavorites: searchSettings.onlyFavorites,
		excludeDigital: searchSettings.excludeDigital,
		published: searchSettings.published?.toUpperCase(),
		onlyWithNotificationTypes: searchSettings.showOnlyNotifications  ? searchSettings.notificationTypes : null,
		excludeUnavailable: searchSettings.excludeUnavailable,
		includeTitles: titleIds.length > 0
	};

	return (
		<QueryRenderer
			query={graphql`
				query PublishersEditLayoutOverrideDataWrapperQuery(
					$count: Int!,
					$cursor: String,
					$orderBy: NewsAndReviewOrderBy,
					$order: Order,
					$search: String,
					$filterBy: [ID!],
					$titleIds: [ID!],
					$includeNews: Boolean,
					$includeReviews: Boolean,
					$includeGeneralMessages: Boolean,
					$mode: SearchMode!,
					$onlyFavorites: Boolean!,
					$excludeDigital: Boolean,
					$excludeUnavailable: Boolean,
					$published: PublishedFilter,
					$onlyWithNotificationTypes: [CustomerNotificationSubscriptionType!],
					$includeTitles: Boolean!,
				) {
			
				titles(
					first: $count, 
					after: $cursor, 
					search: $search, 
					filterBy: $titleIds, 
					published: $published, 
					excludeDigital: $excludeDigital, 
					excludeUnavailable: $excludeUnavailable, 
					mode: $mode, 
					onlyFavorites: $onlyFavorites, 
					onlyWithNotificationTypes: $onlyWithNotificationTypes) 
				@include(if: $includeTitles)
				@connection(key: "PublishersEditLayoutOverride_titles") {
					edges {
						node {
							id
							title
							authors {
								name
							}
							publishingHouses {
								name
							}
							coverImage {
								sizes {
									name
									url
								}
								id
							}
						}
					}
				}

				...EditReviewsPaginator_query @arguments(
					orderBy: $orderBy,
					order: $order,
					search: $search,
					filterBy: $filterBy,
					includeNews: $includeNews,
					includeReviews: $includeReviews,
					includeGeneralMessages: $includeGeneralMessages,
					mode: $mode,
					onlyFavorites: $onlyFavorites
				)

				viewer {
					group {
						type
						publishingHouseIds
					}
					id
					isSuperAdmin
					isAdministrator
				}
		}`}
		variables={variables}
		render={({ error, props }) => {
			return (<PublishersEditLayoutOverride {...AppLayout.Override.forwardProps(forwardedProps)} query={{ error, data: props }} /> );
		}}
	/>
);
}

export default withManageSearch(PublishersEditLayoutOverrideDataWrapper);
