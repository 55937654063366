// @flow
import moment from 'moment';

/** Formatted date props. */
export type FormattedDateProps = {
  value: string,
  customFormat: ?string
};

/** Formatted date. */
const FormattedDate: React$ComponentType<FormattedDateProps> = function FormattedDate({
  value,
  customFormat
}: FormattedDateProps) {
  return moment(value).format(customFormat ? customFormat : 'D.M.YYYY');
};

export default FormattedDate;
