/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FavoriteStar_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PublicationCard_viewer$ref: FragmentReference;
declare export opaque type PublicationCard_viewer$fragmentType: PublicationCard_viewer$ref;
export type PublicationCard_viewer = {|
  +$fragmentRefs: FavoriteStar_viewer$ref,
  +$refType: PublicationCard_viewer$ref,
|};
export type PublicationCard_viewer$data = PublicationCard_viewer;
export type PublicationCard_viewer$key = {
  +$data?: PublicationCard_viewer$data,
  +$fragmentRefs: PublicationCard_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "PublicationCard_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "FavoriteStar_viewer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a84e67c76b34d82579306c3067599eb5';
module.exports = node;
