/**
 * @flow
 * @relayHash be9e4b81910aba125e6c6616524f606b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type LinkList_viewer$ref = any;
export type CreateCustomerLinkInput = {|
  description: string,
  url: string,
  clientMutationId: string,
|};
export type LinkListCreateLinkMutationVariables = {|
  input: CreateCustomerLinkInput
|};
export type LinkListCreateLinkMutationResponse = {|
  +createCustomerLink: ?{|
    +viewer: ?{|
      +$fragmentRefs: LinkList_viewer$ref
    |}
  |}
|};
export type LinkListCreateLinkMutation = {|
  variables: LinkListCreateLinkMutationVariables,
  response: LinkListCreateLinkMutationResponse,
|};
*/


/*
mutation LinkListCreateLinkMutation(
  $input: CreateCustomerLinkInput!
) {
  createCustomerLink(input: $input) {
    viewer {
      ...LinkList_viewer
      id
    }
  }
}

fragment LinkForm_link on CustomerLink {
  id
  description
  url
}

fragment LinkList_viewer on Customer {
  id
  name
  links {
    id
    url
    description
    ...LinkForm_link
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateCustomerLinkInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LinkListCreateLinkMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createCustomerLink",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCustomerLinkPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "LinkList_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LinkListCreateLinkMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createCustomerLink",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCustomerLinkPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "links",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomerLink",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "LinkListCreateLinkMutation",
    "id": null,
    "text": "mutation LinkListCreateLinkMutation(\n  $input: CreateCustomerLinkInput!\n) {\n  createCustomerLink(input: $input) {\n    viewer {\n      ...LinkList_viewer\n      id\n    }\n  }\n}\n\nfragment LinkForm_link on CustomerLink {\n  id\n  description\n  url\n}\n\nfragment LinkList_viewer on Customer {\n  id\n  name\n  links {\n    id\n    url\n    description\n    ...LinkForm_link\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6e250ee208611a2600ac07345cbe185c';
module.exports = node;
