// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames';
import queryString from 'query-string';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';

import {
  Collapsible,
  Icon,
  Sidebar,
} from '../../components';
import { CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPES } from '../../models';
import {
  CATEGORY_OPTIONS,
  MEDIA_TYPE_OPTIONS,
  PUBLISHED_OPTIONS,
  withSearchSettings,
  type SearchSettingsProps,
} from '../SearchSettings';
import { withRouter, type RouterProps } from '../../utils/router';
import { withFeatures, type FeaturesProps } from '../FeaturesProvider';
import type { MainSidebar_viewer } from './__generated__/MainSidebar_viewer.graphql';

const BOGINTRA_LINK_EXPRESSION = /^https?:\/\/(next\.)?bogintra\.dk/;

/** Main sidebar container props. */
export type MainSidebarProps = {
  viewer: ?MainSidebar_viewer,
  mode: 'PUBLISHING_HOUSE' | 'STORE',
  onSettingsClick: () => any,
} & RouterProps<{}> & SearchSettingsProps & FeaturesProps;

/**
 * Main sidebar container.
 */
class MainSidebar extends React.Component<MainSidebarProps> {
  _prevViewer: ?MainSidebar_viewer = null;

  toggleMode = () => {
    const { mode, location, history } = this.props;

    history.push({
      ...location,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        mode: mode === 'STORE' ? undefined : 'store',
      }),
    });
  }

  render() {
    const {
      features: {
        messages: messagesEnabled,
      },
      mode,
      onSettingsClick,
      resetCategories,
      resetMediaTypes,
      resetSearchSettings,
      searchSettings,
      setExcludeDigital,
      setExcludeUnavailable,
      setMessagesMode,
      setOnlyFavorites,
      setPublished,
      setShowNotifications,
      setShowOnlyNotifications,
      toggleCategory,
      toggleMediaType,
      toggleNotificationType,
      viewer: viewerProp,
    } = this.props;

    const categories = (searchSettings.categories || []).reduce((memo, category) => {
      memo[category] = true;
      return memo;
    }, {});

    if (viewerProp && viewerProp !== this._prevViewer) {
      this._prevViewer = viewerProp;
    }

    const viewer = viewerProp || this._prevViewer;

    return (
      <Sidebar>
        <Sidebar.Section
          count={viewer?.favoriteIds?.length}
          icon="star_border"
          label="Stjernemarkerede"
          headerHighlighted={searchSettings.onlyFavorites}
          onHeaderClick={() => setOnlyFavorites(!searchSettings.onlyFavorites)}
        />

        <Sidebar.Section
          icon="label"
          label="Kategorier/genrer"
        >
          <ul>
            <li>
              <label>
                <input
                  type="checkbox"
                  className={classNames('filled-in', searchSettings.categories ? 'indeterminate' : null)}
                  checked={!searchSettings.categories}
                  onChange={() => resetCategories()}
                />
                <span className="black-text">Alle bøger</span>
              </label>
            </li>

            {CATEGORY_OPTIONS.map(({ value, label, subCategories }) =>
              <Fragment key={value}>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      className={classNames('filled-in', !categories[value] && subCategories.some(({ value }) => categories[value]) ? 'indeterminate' : null)}
                      checked={!!categories[value]}
                      onChange={() => toggleCategory(value)}
                    />
                    <span className="black-text"><strong>{label}</strong></span>
                  </label>
                </li>
                {subCategories.length > 0 &&
                 <Collapsible>
                   <Collapsible.Item
                     headerClassName="category-callout"
                     header={<Icon name="arrow_left" />}
                   >
                     <ul>
                       {subCategories.map(({ value, label }) =>
                         <li key={value}>
                           <label>
                             <input
                               type="checkbox"
                               className="filled-in"
                               checked={!!categories[value]}
                               onChange={() => toggleCategory(value)}
                             />
                             <span className="black-text">{label}</span>
                           </label>
                         </li>)}
                     </ul>
                   </Collapsible.Item>
                 </Collapsible>}
              </Fragment>
            )}
          </ul>
        </Sidebar.Section>

        <Sidebar.Section
          label="Medier"
          icon="import_contacts"
        >
          <ul>
            <li>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  checked={!searchSettings.mediaTypes}
                  onChange={() => resetMediaTypes()}
                />
                <span className="black-text">Alle medier</span>
              </label>
            </li>
            {MEDIA_TYPE_OPTIONS.map(({ value, label }) =>
              <li key={value}>
                <label>
                  <input
                    type="checkbox"
                    value={value}
                    className="medier filled-in"
                    checked={!!(searchSettings.mediaTypes && searchSettings.mediaTypes.includes(value))}
                    onChange={() => toggleMediaType(value)}
                  />
                  <span className="black-text">{label}</span>
                </label>
              </li>
            )}
            <li>
              <label>
                <input type="checkbox" disabled="disabled" className="filled-in" /><span className="grey-text">TV og radio (undervejs)</span>
              </label>
            </li>
          </ul>

        </Sidebar.Section>

        <Sidebar.Section
          label="Udgivelsesdato"
          icon="date_range"
        >

          <ul>
            {PUBLISHED_OPTIONS.map(({ value, label }) =>
              <li key={value}>
                <label>
                  <input
                    name="publication-date"
                    type="radio"
                    value={value}
                    checked={searchSettings.published === value}
                    onChange={() => setPublished(value)}
                  />
                  <span className="black-text">{label}</span>
                </label>
              </li>)}
          </ul>

        </Sidebar.Section>

        <Sidebar.Section
          label="Udgaver"
          icon="chrome_reader_mode"
        >
          <ul>
            <li>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  onChange={() => setExcludeDigital(!searchSettings.excludeDigital)}
                  checked={searchSettings.excludeDigital}
                  value="on"
                />
                <span className="black-text">Vis ikke digitale bøger</span>
              </label>
            </li>
            <li>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  onChange={() => setExcludeUnavailable(!searchSettings.excludeUnavailable)}
                  checked={searchSettings.excludeUnavailable}
                  value="on"
                />
                <span className="black-text">Vis ikke udsolgte bøger</span>
              </label>
            </li>
          </ul>
        </Sidebar.Section>

        {messagesEnabled &&
        <Sidebar.Section
          label="Meddelelser"
          icon="message"
        >
          <ul>
            <li>
              <label>
                <input
                  type="radio"
                  className="filled-in"
                  onChange={() => setMessagesMode('active')}
                  checked={searchSettings.messagesMode === 'active'}
                  value="on"
                />
                <span className="black-text">Vis aktive meddelelser</span>
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  className="filled-in"
                  onChange={() => setMessagesMode('all')}
                  checked={searchSettings.messagesMode === 'all'}
                  value="on"
                />
                <span className="black-text">Vis alle meddelelser</span>
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  className="filled-in"
                  onChange={() => setMessagesMode('sent')}
                  checked={searchSettings.messagesMode === 'sent'}
                  value="on"
                />
                <span className="black-text">Vis mine meddelelser</span>
              </label>
            </li>
          </ul>
        </Sidebar.Section>}

        <Sidebar.Section
          label="Notifikationer"
          icon="notifications_active"
        >
          <ul>
            <li>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  onChange={() => setShowNotifications(!searchSettings.showNotifications)}
                  checked={searchSettings.showNotifications}
                  value="on"
                />
                <span className="black-text">Vis aktuelle notfikationer</span>
              </label>
            </li>
            <li>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  onChange={() => setShowOnlyNotifications(!searchSettings.showOnlyNotifications)}
                  checked={searchSettings.showOnlyNotifications}
                  value="on"
                />
                <span className="black-text">Vis kun aktuelle notfikationer</span>
              </label>
            </li>
            {CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPES.map(({ value, shortDescription }) =>
              <li key={value}>
                <label>
                  <input
                    type="checkbox"
                    className="filled-in"
                    onChange={() => toggleNotificationType(value)}
                    checked={searchSettings.notificationTypes.includes(value)}
                    value="on"
                  />
                  <span className="black-text">Vis {shortDescription.toLowerCase()}</span>
                </label>
              </li>
            )}
          </ul>
        </Sidebar.Section>

        <Sidebar.Section
          label="Nulstil søgeindstillinger"
          icon="refresh"
          onHeaderClick={() => resetSearchSettings()}
        />

        {viewer?.group?.type === 'PUBLISHING_HOUSE' &&
        <Sidebar.Section
          label={`Vis som ${mode === 'PUBLISHING_HOUSE' ? 'boghandler' : 'forlag'}`}
          icon="remove_red_eye"
          onHeaderClick={this.toggleMode}
        />}

        {viewer?.links?.length > 0 &&
        <Sidebar.Section
          label="Links"
          icon="link"
        >
          <ul>
            {viewer.links.map(link =>
              <li key={link.id}>
                <a
                  href={link.url}
                  className="menu-links truncate"
                  title={link.description}
                  target={link.url.match(BOGINTRA_LINK_EXPRESSION) ? null : '_blank'}
                >
                  {link.description}
                </a>
              </li>
            )}
          </ul>
        </Sidebar.Section>}

        <Sidebar.Section
          label="Logget ind"
          icon="fas fa-sign-in-alt"
        >
          <ul>
            <li className="login-info">
              Du er logget ind som {viewer?.name}
            </li>
            <li className="login">
              <a
                href="#"
                onClick={evt => { evt.preventDefault(); onSettingsClick() }}
                className="collapsible-header waves-effect logout"
              >
                Indstillinger
                <Icon name="settings" />
              </a>

              <Link to="/logout" className="collapsible-header waves-effect logout">
                Log ud
                <Icon name="fas fa-sign-out-alt" />
              </Link>
            </li>
          </ul>
        </Sidebar.Section>
      </Sidebar>
    );
  }
}

export default createFragmentContainer(withFeatures(withRouter(withSearchSettings(MainSidebar))), {
  viewer: graphql`fragment MainSidebar_viewer on Customer {
    favoriteIds
    name

    links {
      id
      description
      url
    }

    group {
      type
    }
  }`,
});
