// @flow
function checkPossibleAlignments(el, container, bounding, offset) {
  const canAlign = {
    top: true,
    right: true,
    bottom: true,
    left: true,
    spaceOnTop: null,
    spaceOnRight: null,
    spaceOnBottom: null,
    spaceOnLeft: null,
  };

  const containerAllowsOverflow = getComputedStyle(container).overflow === 'visible';
  const containerRect = container.getBoundingClientRect();
  const containerHeight = Math.min(containerRect.height, window.innerHeight);
  const containerWidth = Math.min(containerRect.width, window.innerWidth);
  const elOffsetRect = el.getBoundingClientRect();

  const scrollLeft = container.scrollLeft;
  const scrollTop = container.scrollTop;

  const scrolledX = bounding.left - scrollLeft;
  const scrolledYTopEdge = bounding.top - scrollTop;
  const scrolledYBottomEdge = bounding.top + elOffsetRect.height - scrollTop;

  // Check for container and viewport for left
  canAlign.spaceOnRight = !containerAllowsOverflow
    ? containerWidth - (scrolledX + bounding.width)
    : window.innerWidth - (elOffsetRect.left + bounding.width);
  if (canAlign.spaceOnRight < 0) {
    canAlign.left = false;
  }

  // Check for container and viewport for Right
  canAlign.spaceOnLeft = !containerAllowsOverflow
    ? scrolledX - bounding.width + elOffsetRect.width
    : elOffsetRect.right - bounding.width;
  if (canAlign.spaceOnLeft < 0) {
    canAlign.right = false;
  }

  // Check for container and viewport for Top
  canAlign.spaceOnBottom = !containerAllowsOverflow
    ? containerHeight - (scrolledYTopEdge + bounding.height + offset)
    : window.innerHeight - (elOffsetRect.top + bounding.height + offset);
  if (canAlign.spaceOnBottom < 0) {
    canAlign.top = false;
  }

  // Check for container and viewport for Bottom
  canAlign.spaceOnTop = !containerAllowsOverflow
    ? scrolledYBottomEdge - (bounding.height - offset)
    : elOffsetRect.bottom - (bounding.height + offset);
  if (canAlign.spaceOnTop < 0) {
    canAlign.bottom = false;
  }

  return canAlign;
}

export function getDropdownPosition({
  triggerNode,
  contentNode,
  coverTrigger,
  alignment,
}) {
  const triggerBRect = triggerNode.getBoundingClientRect();
  const dropdownBRect = contentNode.getBoundingClientRect();

  let idealHeight = dropdownBRect.height;
  let idealWidth = dropdownBRect.width;
  let idealXPos = triggerBRect.left - dropdownBRect.left;
  let idealYPos = triggerBRect.top - dropdownBRect.top;
  let isScrollable = false;

  const dropdownBounds = {
    left: idealXPos,
    top: idealYPos,
    height: idealHeight,
    width: idealWidth,
  };

  // Countainer here will be closest ancestor with overflow: hidden
  const closestOverflowParent = contentNode.offsetParent
    ? contentNode.offsetParent
    : contentNode.parentNode;

  const alignments = checkPossibleAlignments(
    triggerNode,
    closestOverflowParent,
    dropdownBounds,
    coverTrigger ? 0 : triggerBRect.height
  );

  let verticalAlignment = 'top';
  let horizontalAlignment = alignment;
  idealYPos += coverTrigger ? 0 : triggerBRect.height;

  // Reset isScrollable
  if (!alignments.top) {
    if (alignments.bottom) {
      verticalAlignment = 'bottom';
    }
    else {
      isScrollable = true;

      // Determine which side has most space and cutoff at correct height
      if (alignments.spaceOnTop > alignments.spaceOnBottom) {
        verticalAlignment = 'bottom';
        idealHeight += alignments.spaceOnTop;
        idealYPos -= alignments.spaceOnTop;
      }
      else {
        idealHeight += alignments.spaceOnBottom;
      }
    }
  }

  // If preferred horizontal alignment is possible
  if (!alignments[horizontalAlignment]) {
    const oppositeAlignment = horizontalAlignment === 'left' ? 'right' : 'left';
    if (alignments[oppositeAlignment]) {
      horizontalAlignment = oppositeAlignment;
    }
    else {
      // Determine which side has most space and cutoff at correct height
      if (alignments.spaceOnLeft > alignments.spaceOnRight) {
        horizontalAlignment = 'right';
        idealWidth += alignments.spaceOnLeft;
        idealXPos -= alignments.spaceOnLeft;
      }
      else {
        horizontalAlignment = 'left';
        idealWidth += alignments.spaceOnRight;
      }
    }
  }

  if (verticalAlignment === 'bottom') {
    idealYPos =
          idealYPos - dropdownBRect.height + (coverTrigger ? triggerBRect.height : 0);
  }
  if (horizontalAlignment === 'right') {
    idealXPos = idealXPos - dropdownBRect.width + triggerBRect.width;
  }
  return {
    x: idealXPos,
    y: idealYPos,
    verticalAlignment,
    horizontalAlignment,
    height: idealHeight,
    width: idealWidth,
    isScrollable,
  };
}

export function stylesFromDropdownPosition(positionInfo) {
  if (!positionInfo) {
    return {
      left: null,
      top: null,
      height: null,
      width: null,
      transformOrigin: null,
    };
  }

  return {
    left: positionInfo.x + 'px',
    top: positionInfo.y + 'px',
    height: positionInfo.height + 'px',
    width: positionInfo.width + 'px',
    transformOrigin: `${
      positionInfo.horizontalAlignment === 'left' ? '0' : '100%'
    } ${positionInfo.verticalAlignment === 'top' ? '0' : '100%'}`,
  };
}
