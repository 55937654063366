// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import { createPaginationContainer, graphql } from 'react-relay';
import classNames from 'classnames';

import coverGradientUrl from '../../images/cover-gradient.png';
import { Collapsible, FormattedDate, Icon } from '../../components';
import { placeholderCoverImageForTitle } from '../../models';
import { FilterAdder } from '../SearchManager';
import { withSearchSettings, type SearchSettingsProps } from '../SearchSettings';
import { withFeatures, type FeaturesProps } from '../FeaturesProvider';
import ReviewGradeDescription from '../NewsAndReviewPaginator/ReviewGradeDescription';
import EditReviewCard from './EditReviewCard';
import { ONLINE_MEDIA_TYPE_IDS } from './data';

/** Split news and reviews by day. */
function splitNewsAndReviewsByDay(newsAndReviews): Object[][] {
	const result = [];
	let group = [];

	newsAndReviews?.forEach(node => {
		if (group.length && node.date !== group[0].date) {
			result.push(group);
			group = [];
		}

		group.push(node);
	});

	if (group.length) {
		result.push(group);
	}

	return result;
}

/** Split news and reviews by media. */
function splitNewsAndReviewsByTypeAndMedia(newsAndReviews): { messages: Object[], newsAndReviewGroups: Object[][] } {
	const result = [];
	const onlineResult = [];
	const messages = [];
	let group = [];

	newsAndReviews.forEach(node => {
		if (node.__typename === 'Message') {
			if (!node.isDeleted) {
				messages.push(node);
			}
		}
		else if (ONLINE_MEDIA_TYPE_IDS.includes(node.media?.mediaType?.id)) {
			onlineResult.push(node);
		}
		else {
			if (group.length && node.media?.id !== group[0].media?.id) {
				result.push(group);
				group = [];
			}

			group.push(node);
		}
	});

	if (group.length) {
		result.push(group);
	}

	if (onlineResult.length) {
		result.push(onlineResult);
	}

	return {
		messages,
		newsAndReviewGroups: result,
	};
}


/** News and review paginator props. */
export type EditReviewsPaginatorProps = {
	shouldDisplayPublishingHouse: (id: ?string) => boolean,
	query: any,
} & SearchSettingsProps & FeaturesProps & any;

/** News and review paginator. */
class EditReviewsPaginator extends React.Component<EditReviewsPaginatorProps> {
	handleLoadMoreClick = (evt) => {
		evt.preventDefault();

		const { relay } = this.props;
		if (relay.isLoading()) {
			return;
		}

		relay.loadMore(25);
	}

	renderMediaGroup = (mediaGroup, idx) => {
		const {
			features,
			query: { viewer },
			shouldDisplayPublishingHouse,
			searchSettings: {
				messagesMode,
			},
		} = this.props;
	
		const subgroups = [];
		
		mediaGroup.forEach(content => {
			subgroups.push([{ content }]);
		});
		
		return (
			<Fragment key={idx}>
				{subgroups.map((items, idx) =>
					<Collapsible key={idx} popout className="reviews-list">
						{items.map(({ content }, idx) =>
							<Fragment key={idx}>
								<EditReviewCard
									content={content}
									viewer={viewer}
									shouldDisplayPublishingHouse={shouldDisplayPublishingHouse}
								/>
							</Fragment>
						)}
					</Collapsible>
				)}
			</Fragment>
		);
	}

	renderDay = (newsAndReviews, idx) => {
		const { headline, query: { viewer } } = this.props;
		const date = newsAndReviews[0]?.date;
		const HeadlineComponent = headline;
		const todayDate = moment().format('YYYY-MM-DD');

		// Split news and reviews by media.
		const { newsAndReviewGroups } = splitNewsAndReviewsByTypeAndMedia(newsAndReviews);

		return (
			<Fragment key={idx}>
				<HeadlineComponent>
					{todayDate === date && 'I dag - '}
					{date && <FormattedDate value={date} />}
				</HeadlineComponent>

				{newsAndReviewGroups.map(this.renderMediaGroup)}
			</Fragment>
		);
	}

	render() {
		
		const {
			headline: HeadlineComponent,
			query: { newsAndReviews: connection, titles, viewer },
			relay,
		} = this.props;

		if (connection?.totalCount === 0) {
			return (
				<Fragment>
					<HeadlineComponent />
					<div className="no-results-headline">Ingen resultater. Prøv at ændre søgeord eller søgefiltre.</div>
				</Fragment>
			);
		}

		const reviews = connection?.edges.map(({ node }) => node);
		const reviewsGroupedByDays = splitNewsAndReviewsByDay(reviews);

		return (
			<Fragment>
				{reviewsGroupedByDays.map(this.renderDay)}

				{relay.hasMore() &&
					<div className="center-align">
						<a
							className="btn-floating btn-large pulse standard-bg-color"
							onClick={this.handleLoadMoreClick}
						>
							<Icon name="expand_more" />
						</a>
					</div>}
			</Fragment>
		);
	}
}

export default createPaginationContainer(withFeatures(withSearchSettings(EditReviewsPaginator)),
	{
query: graphql`
fragment EditReviewsPaginator_query on Query
@argumentDefinitions(
	count: {type: "Int", defaultValue: 25}
	cursor: {type: "String"}
	orderBy: {type: "NewsAndReviewOrderBy"}
	order: {type: "Order"}
	search: {type: "String"}
	filterBy: {type: "[ID!]"}
	includeNews: {type: "Boolean"}
	includeReviews: {type: "Boolean"}
	includeGeneralMessages: {type: "Boolean"}
	mode: {type: "SearchMode!"}
	onlyFavorites: {type: "Boolean!"}
) {
	newsAndReviews(
		first: $count,
		after: $cursor,
		orderBy: $orderBy,
		order: $order,
		search: $search,
		filterBy: $filterBy,
		includeNews: $includeNews,
		includeReviews: $includeReviews,
		includeGeneralMessages: $includeGeneralMessages,
		mode: $mode,
		onlyFavorites: $onlyFavorites
	) @connection(key: "EditReviewsPaginator_newsAndReviews") {
	totalCount

    edges {
      node {
        __typename
 
        ... on Review {
          id
          headline
          summaryHeadline
          underHeading
          content
		  originalContent
          quotes
         
          date
          grade
          gradeNormalized
          url
		  quotesCreatedBy {
            email
            id
          }
		  createdBy {
			id
            isSuperAdmin
            isAdministrator
			name
          }

          gradingScale {
            min
            max
            symbol
			precision
          }

          media {
            id
            name
            initials
            colorClass
            limitOriginalContentDisplay
			url
            mediaType {
              id
            }
          }

          reviewer {
            id
            firstName
            lastName
          }

          referencedTitles {
            id
            title
            hasMoreAuthors
            datePublishedFirst
            yearPublishedFirst

            authors {
              id
              name
            }

            publishingHouses {
              id
              name
            }

            coverImage {
              sizes {
                name
                url
              }
            }

            publications {
              format
              isbn13
              isDigital
              availability
            }

          }
        }
      }
    }
  }

  viewer {
	  id
	  isAdministrator
	  isSuperAdmin
  }
}`,
	},
	{
		direction: 'forward',
		getConnectionFromProps(props) {
			return props?.query?.newsAndReviews;
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			};
		},
		getVariables(props, { count, cursor }, { orderBy, order, search, filterBy, includeNews, includeReviews, includeGeneralMessages, mode, onlyFavorites }) {
			return {
				count,
				cursor,
				orderBy,
				order,
				search,
				filterBy,
				includeNews,
				includeReviews,
				includeGeneralMessages,
				mode,
				onlyFavorites,
			};
		},
		query: graphql`
query EditReviewsPaginatorQuery(
	$count: Int!,
	$cursor: String,
	$orderBy: NewsAndReviewOrderBy,
	$order: Order,
	$search: String,
	$filterBy: [ID!],
	$includeNews: Boolean,
	$includeReviews: Boolean,
	$includeGeneralMessages: Boolean,
	$mode: SearchMode!,
	$onlyFavorites: Boolean!,
) {
  ...EditReviewsPaginator_query @arguments(
	count: $count,
    cursor: $cursor,
    orderBy: $orderBy,
    order: $order,
    search: $search,
    filterBy: $filterBy,
    includeNews: $includeNews,
    includeReviews: $includeReviews,
    includeGeneralMessages: $includeGeneralMessages,
    mode: $mode,
    onlyFavorites: $onlyFavorites
	
  )

  viewer {
    id
  }
}`,
	}
);
