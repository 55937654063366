// @flow
import React, { Fragment } from 'react';
import { commitMutation, createFragmentContainer, graphql } from 'react-relay';
import copyToClipboard from 'copy-to-clipboard';

import { withToast, type ToastProps } from '../../components';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import { formatPossessiveName } from '../../utils/ownership';
import CustomerForm from './CustomerForm';

/** Update non-department customer mutation. */
const updateNonDepartmentCustomerMutation = graphql`
mutation ViewerProfileUpdateNonDepartmentCustomerMutation($input: UpdateNonDepartmentCustomerInput!) {
  updateNonDepartmentCustomer(input: $input) {
    customer {
      id
      type
      name
      isAdministrator
      hasBogintraAccess
      isNewsletterSubscriber

      group {
        name
        reference
      }

      ...CustomerForm_customer
    }
  }
}`;

/** Update department customer mutation. */
const updateDepartmentCustomerMutation = graphql`
mutation ViewerProfileUpdateDepartmentCustomerMutation($input: UpdateDepartmentCustomerInput!) {
  updateDepartmentCustomer(input: $input) {
    customer {
      id
      type
      name
      isAdministrator
      hasBogintraAccess
      isNewsletterSubscriber

      group {
        name
        reference
      }

      ...CustomerForm_customer
    }
  }
}`;

export type ViewerProfileProps = {
  viewer: ViewerProfile_viewer,
} & EnvironmentProps & ToastProps;

type ViewerProfileState = {
  loading: boolean,
  counter: number,
  error: ?string,
};

class ViewerProfile extends React.Component<ViewerProfileProps, ViewerProfileState> {
  state = {
    loading: false,
    counter: 1,
    error: null,
  };

  handleCopyEditLongTermToken = (evt) => {
    evt.preventDefault();

    const { toast, viewer: { longTermToken } } = this.props;

    copyToClipboard(`https://bogintra.dk/login?token=${encodeURIComponent(longTermToken)}`);
    toast('Permanent link til Bogintra er kopieret til din udklipsholder. Indsæt med Ctrl + V');
  }

  handleSubmit = (data) => {
    if (!data.email || !data.email.trim() || !data.name || !data.name.trim()) {
      this.setState({
        error: 'Navn og email-adresse skal udfyldes',
      });

      return;
    }

    this.setState({
      loading: true,
      error: null,
    }, () => {
      const { environment, viewer } = this.props;
      const isDepartment = viewer.type === 'DEPARTMENT';

      commitMutation(environment, {
        mutation: isDepartment ? updateDepartmentCustomerMutation : updateNonDepartmentCustomerMutation,
        variables: {
          input: {
            id: viewer.id,
            clientMutationId: '1',
            ...data,
          },
        },
        onCompleted: (data, errors) => {
          const { toast } = this.props;

          if (errors?.length) {
            this.setState({
              loading: false,
              error: 'Der er opstået en fejl.',
            });
          }
          else {
            toast('Din profil er opdateret');

            this.setState({
              counter: this.state.counter + 1,
              loading: false,
            });
          }
        },
      });
    });
  }

  render() {
    const { viewer } = this.props;
    const { loading, error, counter } = this.state;

    return (
      <Fragment>
        <div className="section-headline">
          Rediger {formatPossessiveName(viewer.name)} profil
        </div>

        <div className="card">
          <CustomerForm
            key={counter}
            customer={viewer}
            onSubmit={this.handleSubmit}
            viewer={viewer}
            loading={loading}
            error={error}
            extraActions={
              viewer.longTermToken &&
               <a
                 href="#"
                 onClick={this.handleCopyEditLongTermToken}
                 className="right"
               >
                 <i className="material-icons standard-color tooltipped" data-rh-at="top" data-rh="Kopier permanent link til bogintra">link</i>
               </a>
            }
          />
        </div>
      </Fragment>
    );
  }
}

export default createFragmentContainer(
  withToast(withEnvironment(ViewerProfile)), {
    viewer: graphql`
fragment ViewerProfile_viewer on Customer {
  id
  name
  type
  longTermToken

  ...CustomerForm_customer
  ...CustomerForm_viewer
}`,
  }
);
