// @flow
import React, { Fragment } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';

import { Collapsible, Icon } from '../../components';
import { parseNotification } from '../../models';
import MessageCard from '../MessageCard';
import NotificationCard from '../NotificationCard';
import { withFeatures, type FeaturesProps } from '../FeaturesProvider';
import { withSearchSettings, type SearchSettingsProps } from '../SearchSettings';

/** Title paginator props. */
export type TitlePaginatorProps = {
  excludeDigital?: ?boolean,
  excludeUnavailable?: ?boolean,
  shouldDisplayPublishingHouse: (id: ?string) => boolean,
  titleCard: React$ElementType,
} & SearchSettingsProps & FeaturesProps;

/** Title paginator. */
class TitlePaginator extends React.Component<TitlePaginatorProps> {
  handleLoadMoreClick = (evt) => {
    evt.preventDefault();

    const { relay } = this.props;
    if (relay.isLoading()) {
      return;
    }

    relay.loadMore(10);
  }

  render() {
    const {
      excludeDigital,
      excludeUnavailable,
      features,
      onlyFavorites,
      titleCard: TitleCard,
      query: { titles, viewer },
      relay,
      shouldDisplayPublishingHouse,
      searchSettings: {
        messagesMode,
        notificationTypes,
        showNotifications,
      },
    } = this.props;

    if (titles.totalCount === 0) {
      return (
        <div className="no-results-headline">Ingen resultater. Prøv at ændre søgeord eller søgefiltre.</div>
      );
    }

    return (
      <Fragment>
        {titles.edges.map(({ node }) => {
          const displayNotifications = (node.activeNotifications || []).map(parseNotification).filter(notification => notificationTypes.includes(notification?.type));
          const hasNotifications = showNotifications && displayNotifications.length > 0;
          const hasMessages = features.messages && node.relatedMessages?.length > 0;

          return (
            <TitleCard
              key={node.id}
              excludeDigital={excludeDigital}
              excludeUnavailable={excludeUnavailable}
              title={node}
              viewer={viewer}
              showingOnlyFavorites={onlyFavorites}
              shouldDisplayPublishingHouse={shouldDisplayPublishingHouse}
              beforePublications={
                (hasMessages || hasNotifications) &&
                  <Collapsible className="title-messages">
                    {hasMessages &&
                     node.relatedMessages
                       .filter(msg =>
                         !msg.isDeleted &&
                        (msg.isActive || messagesMode !== 'active') &&
                                (msg.senderId === viewer.id || messagesMode !== 'sent')
                       )
                       .map(msg =>
                         <MessageCard
                           viewer={viewer}
                           message={msg}
                           key={msg.id}
                         />
                       )}

                    {hasNotifications &&
                     displayNotifications.map((notification, idx) =>
                       <NotificationCard
                         key={idx}
                         title={node}
                         notification={notification}
                       />
                     )}
                  </Collapsible>
              }
            />
          );
        })}

        {relay.hasMore() &&
        <div className="center-align">
          <a
            className="btn-floating btn-large pulse standard-bg-color"
            onClick={this.handleLoadMoreClick}
          >
            <Icon name="expand_more" />
          </a>
        </div>}
      </Fragment>
    );
  }
}

export default createPaginationContainer(
  withFeatures(withSearchSettings(TitlePaginator)),
  {
    query: graphql`
fragment TitlePaginator_query on Query
@argumentDefinitions(
  count: {type: "Int", defaultValue: 10}
  cursor: {type: "String"}
  orderBy: {type: "TitleOrderBy"}
  order: {type: "Order"}
  search: {type: "String"}
  excludeDigital: {type: "Boolean"}
  excludeUnavailable: {type: "Boolean"}
  filterBy: {type: "[ID!]"}
  published: {type: "PublishedFilter"}
  mode: {type: "SearchMode!"}
  onlyFavorites: {type: "Boolean!"},
  onlyWithNotificationTypes: {type: "[CustomerNotificationSubscriptionType!]"}
) {
  titles(
    first: $count,
    after: $cursor,
    orderBy: $orderBy,
    order: $order,
    search: $search,
    excludeDigital: $excludeDigital,
    excludeUnavailable: $excludeUnavailable,
    filterBy: $filterBy,
    published: $published,
    mode: $mode,
    onlyFavorites: $onlyFavorites,
    onlyWithNotificationTypes: $onlyWithNotificationTypes
  ) @connection(key: "TitlePaginator_titles") {
    totalCount

    edges {
      node {
        id
        activeNotifications

        relatedMessages {
          id
          isActive
          isDeleted

          ...MessageCard_message
        }

        ...BaseTitleCard_title
        ...PopularityTitleCard_title
        ...NotificationCard_title
      }
    }
  }

  viewer {
    ...BaseTitleCard_viewer
    ...PopularityTitleCard_viewer
    ...MessageCard_viewer
  }
}`,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props?.query?.titles;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, { orderBy, order, search, excludeDigital, excludeUnavailable, filterBy, published, mode, onlyFavorites, onlyWithNotificationTypes }) {
      return {
        count,
        cursor,
        orderBy,
        order,
        search,
        excludeDigital,
        excludeUnavailable,
        filterBy,
        published,
        mode,
        onlyFavorites,
        onlyWithNotificationTypes,
      };
    },
    query: graphql`
query TitlePaginatorQuery(
  $count: Int!,
  $cursor: String,
  $orderBy: TitleOrderBy,
  $order: Order,
  $search: String,
  $excludeDigital: Boolean,
  $excludeUnavailable: Boolean,
  $filterBy: [ID!],
  $published: PublishedFilter,
  $mode: SearchMode!,
  $onlyFavorites: Boolean!,
  $onlyWithNotificationTypes: [CustomerNotificationSubscriptionType!]
) {
  ...TitlePaginator_query @arguments(
    count: $count,
    cursor: $cursor,
    orderBy: $orderBy,
    order: $order,
    search: $search,
    excludeDigital: $excludeDigital,
    excludeUnavailable: $excludeUnavailable,
    filterBy: $filterBy,
    published: $published,
    mode: $mode,
    onlyFavorites: $onlyFavorites,
    onlyWithNotificationTypes: $onlyWithNotificationTypes
  )

  viewer {
    id

    ...BaseTitleCard_viewer
    ...PopularityTitleCard_viewer
  }
}`,
  }
);
