// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import { createPaginationContainer, graphql } from 'react-relay';
import classNames from 'classnames';

import coverGradientUrl from '../../images/cover-gradient.png';
import { Collapsible, FormattedDate, Icon } from '../../components';
import { placeholderCoverImageForTitle } from '../../models';
import FavoriteStar from '../FavoriteStar';
import MessageCard from '../MessageCard';
import { FilterAdder } from '../SearchManager';
import { withSearchSettings, type SearchSettingsProps } from '../SearchSettings';
import { withFeatures, type FeaturesProps } from '../FeaturesProvider';
import ReviewGradeDescription from './ReviewGradeDescription';
import ContentChips from './ContentChips';

const ONLINE_MEDIA_TYPE_IDS = [
  'TWVkaWFUeXBlOjU=', // Blog
  'TWVkaWFUeXBlOjY=', // Onlinemedie
  'TWVkaWFUeXBlOjk=', // Onlinemedie uden citat
];

const TITLE_NEWS_TYPE_PREFIX = {
  FEATURE_ARTICLE: 'Stor artikel om ',
  ARTICLE: 'Artikel om ',
  EXCERPT: 'Uddrag af ',
  COMMENT_ABOUT: 'Kommentar om ',
  COMMENT_BY: 'Kommentar af ',
  MENTION: 'Omtale af ',
  FEATURE_INTERVIEW: 'Stort interview om ',
  INTERVIEW: 'Interview om ',
  COMPETITION: 'Konkurrence om ',
};

const ENTITY_NEWS_TYPE_PREFIX = {
  FEATURE_ARTICLE: 'Stor artikel om ',
  ARTICLE: 'Artikel om ',
  EXCERPT: 'Uddrag af ',
  COMMENT_ABOUT: 'Kommentar om ',
  COMMENT_BY: 'Kommentar af ',
  MENTION: 'Omtale af ',
  FEATURE_INTERVIEW: 'Stort interview med ',
  INTERVIEW: 'Interview med ',
  COMPETITION: 'Konkurrence med ',
};

const NEWS_TYPE_DESCRIPTION = {
  FEATURE_ARTICLE: 'Denne store artikel',
  ARTICLE: 'Denne artikel',
  EXCERPT: 'Dette uddrag',
  COMMENT_ABOUT: 'Denne kommentar',
  COMMENT_BY: 'Denne kommentar',
  MENTION: 'Denne omtale',
  FEATURE_INTERVIEW: 'Dette store interview',
  INTERVIEW: 'Dette interview',
  COMPETITION: 'Denne konkurrence',
};

function naturalJoinEntities(entities) {
  if (entities.length === 0) {
    return '';
  }

  if (entities.length === 1) {
    return entities[0].name;
  }

  return entities.slice(0, entities.length - 1).map(e => e.name).join(', ') + ' og ' + entities[entities.length - 1].name;
}

function TitleDescription({
  title: {
    title,
    authors,
    hasMoreAuthors,
  },
}) {
  return (
    <Fragment>
      {title}
      {authors?.length > 0 &&
       ` af ${naturalJoinEntities(authors)}${hasMoreAuthors ? ' m.f.l.' : ''}`}
    </Fragment>
  );
}

function TitlesDescription({
  titles,
}) {
  return (
    <Fragment>
      <TitleDescription title={titles[0]} />
      {titles.length === 2
        ? ' og 1 anden bog'
        : titles.length > 2
          ? ` og ${titles.length - 1} andre bøger`
          : null}
    </Fragment>
  );
}

function ContentPublishingDescriptionHeadline({
  content: {
    referencedTitles,
  },
}) {
  if (!referencedTitles?.length) {
    return null;
  }

  const today = moment();
  const { datePublishedFirst, yearPublishedFirst, publishingHouses } = referencedTitles[0];
  if (!datePublishedFirst && !yearPublishedFirst && !(publishingHouses?.length > 0)) {
    return null;
  }

  return (
    <p className="headline3">
      {datePublishedFirst
        ? (moment(datePublishedFirst).isSameOrBefore(today)
          ? <Fragment>Udkom <FormattedDate value={datePublishedFirst} /></Fragment>
          : <Fragment>Udkommer <FormattedDate value={datePublishedFirst} /></Fragment>)
        : yearPublishedFirst
          ? (yearPublishedFirst <= today.year()
            ? `Udkom ${yearPublishedFirst}`
            : `Udkommer ${yearPublishedFirst}`)
          : null}
      {publishingHouses?.length > 0 &&
       ` på ${publishingHouses[0].name}`}
    </p>
  );
};

function ContentAction({
  content: {
    media,
    url,
  },
}) {
  if (!url) {
    return (
      <div className="action-more-disabled">
        IKKE LAGT ONLINE
      </div>
    );
  }

  const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;

  return (
    <div className="action-more">
      <a
        className="standard-color versals"
        href={url}
        target="_blank"
      >
        Læs {ONLINE_MEDIA_TYPE_IDS.includes(media?.mediaType?.id) ? 'hos' : 'i'} {media?.name}
      </a>
      <a
        className="facebook-link right"
        href={fbShareUrl}
        onClick={evt => {
          evt.preventDefault();
          window.open(
            fbShareUrl,
            'facebookwindow',
            'left=20,top=20,width=640,height=580,toolbar=0,resizable=1'
          );
        }}
      >
        <i className="fab fa-facebook-f standard-color"></i>
      </a>
    </div>
  );
}

/** Split news and reviews by day. */
function splitNewsAndReviewsByDay(newsAndReviews): Object[][] {
  const result = [];
  let group = [];

  newsAndReviews?.forEach(node => {
    if (group.length && node.date !== group[0].date) {
      result.push(group);
      group = [];
    }

    group.push(node);
  });

  if (group.length) {
    result.push(group);
  }

  return result;
}

/** Split news and reviews by media. */
function splitNewsAndReviewsByTypeAndMedia(newsAndReviews): { messages: Object[], newsAndReviewGroups: Object[][] } {
  const result = [];
  const onlineResult = [];
  const messages = [];
  let group = [];

  newsAndReviews.forEach(node => {
    if (node.__typename === 'Message') {
      if (!node.isDeleted) {
        messages.push(node);
      }
    }
    else if (ONLINE_MEDIA_TYPE_IDS.includes(node.media?.mediaType?.id)) {
      onlineResult.push(node);
    }
    else {
      if (group.length && node.media?.id !== group[0].media?.id) {
        result.push(group);
        group = [];
      }

      group.push(node);
    }
  });

  if (group.length) {
    result.push(group);
  }

  if (onlineResult.length) {
    result.push(onlineResult);
  }

  return {
    messages,
    newsAndReviewGroups: result,
  };
}

/** Content item. */
function ContentItem({
  content,
  viewer,
  shouldDisplayPublishingHouse,
}) {
  const referencedTitles = content?.referencedTitles?.filter(({ publishingHouses }) => publishingHouses?.some(({ id }) => shouldDisplayPublishingHouse(id)));
  const referencedEntities = content?.referencedEntities?.filter(({ publishingHouseIds }) => publishingHouseIds?.some(id => shouldDisplayPublishingHouse(id)));

  const coverImageUrls =
    referencedTitles?.map(
      ({ coverImage }) => coverImage?.sizes?.find(({ name }) => name === '450x')?.url
    )?.filter(url => !!url);
  const coverImageUrl = coverImageUrls?.length > 0
    ? coverImageUrls[0]
    : referencedTitles?.length > 0
      ? placeholderCoverImageForTitle(referencedTitles[0])
      : null;
  const { media } = content;
  const isOnlineMedia = ONLINE_MEDIA_TYPE_IDS.includes(media?.mediaType?.id);

  return (
    <Collapsible.Item
      itemClassName={coverImageUrl ? null : 'no-cover'}
      header={
        <Fragment>
          {media &&
           <FilterAdder>
             {({ addFilter }) =>
               <span
                 className={classNames(
                   'media-icon',
               media.mediaType?.id === 'TWVkaWFUeXBlOjU='
                 ? 'blog-icon'
                 : media.colorClass
                 )}
                 onClick={evt => {
                   evt.stopPropagation();
                   addFilter({
                     id: media.id,
                     type: 'Media',
                     label: media.name,
                   });
                 }}
               >
                 {media.initials}
               </span>}
           </FilterAdder>}

          {content.__typename === 'Review' &&
       (content.gradeNormalized ||
        content.media) &&
       <p className="headline1">
         <ReviewGradeDescription review={content} />
         {media && ` ${isOnlineMedia ? 'fra' : 'i'} ${media.name}`}
       </p>}

          {content.__typename === 'Review' &&
       referencedTitles?.length > 0 &&
       <p className="headline2">
         <TitlesDescription titles={referencedTitles} />
       </p>}

          {content.__typename === 'News' &&
         <p className="headline2">
           {content.type &&
            (referencedTitles?.length
              ? TITLE_NEWS_TYPE_PREFIX
              : ENTITY_NEWS_TYPE_PREFIX)[content.type]}

           {referencedTitles?.length > 0
             ? <TitlesDescription titles={referencedTitles} />
             : referencedEntities?.length > 0
               ? <Fragment>
                 {referencedEntities.length <= 3
                   ? naturalJoinEntities(referencedEntities)
                   : `${referencedEntities.slice(0, 3).map(e => e.name).join(', ')} og ${referencedEntities.length === 4 ? '1 anden forfatter' : (referencedEntities.length - 3).toString() + ' andre forfattere'}`}
               </Fragment>
               : null}

           {content.type && media && <br />}

           {media && `${isOnlineMedia ? 'hos' : 'i'} ${media.name}`}
         </p>}

          <ContentPublishingDescriptionHeadline content={content} />

          {content.__typename === 'News' &&
       (content.reviewer ||
        content.headline ||
        content.summaryHeadline ||
        content.underheading ||
        content.details) &&
      <div className="article-container">
        {(content.headline || content.summaryHeadline) &&
         <p>{content.summaryHeadline || content.headline}</p>}

        {content.details &&
         <p>
           {content.details}
           <span className="extract">&nbsp;[Kort referat]</span>
         </p>}

        {!content.details &&
         content.underHeading &&
         <p>
           {content.underHeading}
           <span className="extract">&nbsp;[Kort uddrag]</span>
         </p>}

        {content.reviewer &&
         <div className="byline">Af {`${content.reviewer.firstName} ${content.reviewer.lastName}`}</div>}
      </div>}

          {content.__typename === 'Review' &&
       content.quotes?.length > 0 &&
       <p className="bog-review-quote">{content.quotes[0]}</p>}

          <FavoriteStar
            id={content.id}
            viewer={viewer}
            description={
              content.__typename === 'Review'
                ? 'Denne anmeldelse'
                : NEWS_TYPE_DESCRIPTION[content.type] || 'Denne artikel'
            }
          />

          {coverImageUrl &&
           <FilterAdder>
             {({ addFilter }) =>
               <img
                 className="news-cover right hide-on-small-only"
                 src={coverImageUrl}
                 onClick={evt => {
                   evt.stopPropagation();
                   addFilter({
                     id: referencedTitles[0].id,
                     type: 'Title',
                     label: referencedTitles[0].title,
                   });
                 }}
               />}
           </FilterAdder>}

          {coverImageUrl &&
         <img
           className="news-cover-shadow right hide-on-small-only"
           src={coverGradientUrl}
         />}
        </Fragment>
      }
    >

      {content.__typename === 'Review' &&
       content.quotes?.length > 1 &&
       content.quotes.map((quote, idx) => (idx === 0 || (idx >= 2 && content?.media?.limitOriginalContentDisplay)) ? null : <p key={idx} className="bog-review-quote">{quote}</p>)}

      {content.__typename === 'Review' &&
       (content.headline ||
        content.summaryHeadline ||
       content.content ||
       content.underHeading ||
      content.reviewer) &&
     <div className="article-container">
       {(content.summaryHeadline || content.headline) &&
       <p>{content.summaryHeadline || content.headline}</p>}

       {content.content &&
       <p>
         {content.content}
         <span className="extract">&nbsp;[Kort uddrag]</span>
       </p>}

       {!content.content &&
        content.underHeading &&
         <p>
           {content.underHeading}
           <span className="extract">&nbsp;[Kort referat]</span>
         </p>}

       {content.reviewer &&
       <div className="byline">Anmeldt af {`${content.reviewer.firstName} ${content.reviewer.lastName}`}</div>}
     </div>}

      <ContentChips content={content} shouldDisplayPublishingHouse={shouldDisplayPublishingHouse} />

      <ContentAction content={content} />
    </Collapsible.Item>
  );
}

/** News and review paginator props. */
export type NewsAndReviewPaginatorProps = {
  shouldDisplayPublishingHouse: (id: ?string) => boolean,
} & SearchSettingsProps & FeaturesProps;

/** News and review paginator. */
class NewsAndReviewPaginator extends React.Component<NewsAndReviewPaginatorProps> {
  handleLoadMoreClick = (evt) => {
    evt.preventDefault();

    const { relay } = this.props;
    if (relay.isLoading()) {
      return;
    }

    relay.loadMore(25);
  }

  renderMediaGroup = (mediaGroup, idx) => {
    const {
      features,
      query: { viewer },
      shouldDisplayPublishingHouse,
      searchSettings: {
        messagesMode,
      },
    } = this.props;

    const subgroups = [];
    let groupable = false;

    mediaGroup.forEach(content => {
      const messages = ((features.messages && content.relatedMessages) || []).filter(msg =>
        (msg.isActive || messagesMode !== 'active') &&
    	(msg.senderId === viewer.id || messagesMode !== 'sent')
      );

      if (groupable && !messages.length) {
        subgroups[subgroups.length - 1].push({ content, messages });
      }
      else {
        subgroups.push([{ content, messages }]);
      }

      groupable = !messages.length;
    });

    return (
      <Fragment key={idx}>
        {subgroups.map((items, idx) =>
          <Collapsible key={idx} popout className="news-and-reviews-list">
            {items.map(({ content, messages }, idx) =>
              <Fragment key={idx}>
                <ContentItem
                  content={content}
                  viewer={viewer}
                  shouldDisplayPublishingHouse={shouldDisplayPublishingHouse}
                />

                {messages.map(message => {
                  const referencedIds =
                     (message.aggregateReferencedTitles?.map(({ id }) => id) || [])
                       .concat(message.referencedEntities?.map(({ id }) => id) || [])
                       .concat(message.referencedPublishingHouses?.map(({ id }) => id) || []);

                  return (
                    <MessageCard
                      message={message}
                      viewer={viewer}
                      actionLinks={
                        referencedIds.length
                          ? [{
                            label: 'SØG ALLE TITLER',
                            linkTo: `/mest-populaere-boeger?filters=${encodeURIComponent(referencedIds.join(','))}&media_types=all&published=any`,
                          }]
                          : null
                      }
                      key={message.id}
                    />
                  );
                })}
              </Fragment>
            )}
          </Collapsible>
        )}
      </Fragment>
    );
  }

  renderDay = (newsAndReviews, idx) => {
    const { firstHeadline, additionalHeadline, query: { viewer } } = this.props;
    const date = newsAndReviews[0]?.date;
    const HeadlineComponent = idx === 0 ? firstHeadline : additionalHeadline;
    const todayDate = moment().format('YYYY-MM-DD');

    // Split news and reviews by media.
    const { messages, newsAndReviewGroups } = splitNewsAndReviewsByTypeAndMedia(newsAndReviews);

    return (
      <Fragment key={idx}>
        <HeadlineComponent>
          {todayDate === date && 'I dag - '}
          {date && <FormattedDate value={date} />}
        </HeadlineComponent>

        {messages &&
        <Collapsible popout>
          {messages.map(msg =>
            <MessageCard
              message={msg}
              viewer={viewer}
              key={msg.id}
            />
          )}
        </Collapsible>}

        {newsAndReviewGroups.map(this.renderMediaGroup)}
      </Fragment>
    );
  }

  render() {

    const {
      firstHeadline: HeadlineComponent,
      query: { newsAndReviews: connection },
      relay,
    } = this.props;
 
 

    if (connection?.totalCount === 0) {
      return (
        <Fragment>
          <HeadlineComponent />
          <div className="no-results-headline">Ingen resultater. Prøv at ændre søgeord eller søgefiltre.</div>
        </Fragment>
      );
    }

    const newsAndReviews = connection?.edges.map(({ node }) => node);
    const newsAndReviewsGroupedByDays = splitNewsAndReviewsByDay(newsAndReviews);

    return (
      <Fragment>
        {newsAndReviewsGroupedByDays.map(this.renderDay)}

        {relay.hasMore() &&
        <div className="center-align">
          <a
            className="btn-floating btn-large pulse standard-bg-color"
            onClick={this.handleLoadMoreClick}
          >
            <Icon name="expand_more" />
          </a>
        </div>}
      </Fragment>
    );
  }
}

export default createPaginationContainer(
  withFeatures(withSearchSettings(NewsAndReviewPaginator)),
  {
    query: graphql`
fragment NewsAndReviewPaginator_query on Query
@argumentDefinitions(
  count: {type: "Int", defaultValue: 25}
  cursor: {type: "String"}
  orderBy: {type: "NewsAndReviewOrderBy"}
  order: {type: "Order"}
  search: {type: "String"}
  filterBy: {type: "[ID!]"}
  includeNews: {type: "Boolean"}
  includeReviews: {type: "Boolean"}
  includeGeneralMessages: {type: "Boolean"}
  mode: {type: "SearchMode!"}
  onlyFavorites: {type: "Boolean!"}
) {
  newsAndReviews(
    first: $count,
    after: $cursor,
    orderBy: $orderBy,
    order: $order,
    search: $search,
    filterBy: $filterBy,
    includeNews: $includeNews,
    includeReviews: $includeReviews,
    includeGeneralMessages: $includeGeneralMessages,
    mode: $mode,
    onlyFavorites: $onlyFavorites
  ) @connection(key: "NewsAndReviewPaginator_newsAndReviews") {
    totalCount

    edges {
      node {
        __typename

        ... on Message {
          id
          date
          isDeleted

          aggregateReferencedTitles {
            id
          }

          referencedPublishingHouses {
            id
          }

          referencedEntities {
            id
          }

          ...MessageCard_message
        }

        ... on News {
          id
          headline
          summaryHeadline
          details
          url
          date
          type

          media {
            id
            name
            initials
            colorClass
            limitOriginalContentDisplay

            mediaType {
              id
            }
          }

          reviewer {
            id
            firstName
            lastName
          }

          referencedTitles {
            id
            title
            hasMoreAuthors
            datePublishedFirst
            yearPublishedFirst

            authors {
              id
              name
            }

            coverImage {
              sizes {
                name
                url
              }
            }

            publishingHouses {
              id
              name
            }

            publications {
              format
              isbn13
              isDigital
              availability
            }
          }

          referencedEntities {
            id
            name
            publishingHouseIds
          }

          relatedMessages {
            id
            isActive
            isDeleted

            aggregateReferencedTitles {
              id
            }

            referencedPublishingHouses {
              id
            }

            referencedEntities {
              id
            }

            ...MessageCard_message
          }
        }

        ... on Review {
          id
          headline
          summaryHeadline
          underHeading
          content
          quotes
          date
          grade
          gradeNormalized
          url

          gradingScale {
            min
            max
            symbol
          }

          media {
            id
            name
            initials
            colorClass
            limitOriginalContentDisplay

            mediaType {
              id
            }
          }

          reviewer {
            id
            firstName
            lastName
          }

          referencedTitles {
            id
            title
            hasMoreAuthors
            datePublishedFirst
            yearPublishedFirst

            authors {
              id
              name
            }

            publishingHouses {
              id
              name
            }

            coverImage {
              sizes {
                name
                url
              }
            }

            publications {
              format
              isbn13
              isDigital
              availability
            }
          }

          relatedMessages {
            id
            isActive
            isDeleted

            aggregateReferencedTitles {
              id
            }

            referencedPublishingHouses {
              id
            }

            referencedEntities {
              id
            }

            ...MessageCard_message
          }
        }
      }
    }
  }

  viewer {
    ...FavoriteStar_viewer
    ...MessageCard_viewer
  }
}`,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props?.query?.newsAndReviews;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, { orderBy, order, search, filterBy, includeNews, includeReviews, includeGeneralMessages, mode, onlyFavorites }) {
      return {
        count,
        cursor,
        orderBy,
        order,
        search,
        filterBy,
        includeNews,
        includeReviews,
        includeGeneralMessages,
        mode,
        onlyFavorites,
      };
    },
    query: graphql`
query NewsAndReviewPaginatorQuery(
  $count: Int!,
  $cursor: String,
  $orderBy: NewsAndReviewOrderBy,
  $order: Order,
  $search: String,
  $filterBy: [ID!],
  $includeNews: Boolean,
  $includeReviews: Boolean,
  $includeGeneralMessages: Boolean,
  $mode: SearchMode!,
  $onlyFavorites: Boolean!
) {
  ...NewsAndReviewPaginator_query @arguments(
    count: $count,
    cursor: $cursor,
    orderBy: $orderBy,
    order: $order,
    search: $search,
    filterBy: $filterBy,
    includeNews: $includeNews,
    includeReviews: $includeReviews,
    includeGeneralMessages: $includeGeneralMessages,
    mode: $mode,
    onlyFavorites: $onlyFavorites
  )

  viewer {
    id

    ...FavoriteStar_viewer
  }
}`,
  }
);
