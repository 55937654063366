// @flow
import React from 'react';
import { graphql } from 'react-relay';

import { type Viewer } from '../../models';
import { QueryRenderer } from '../../utils/relay';
import AddNewMedia from './AddNewMedia';



function AddNewMediaDataWrapper({
  ...forwardedProps
}: any) {

  return (
    <QueryRenderer
      query={graphql`
query AddNewMediaDataWrapperQuery {
	mediaTypes {
		name
		id
	}
}`}
    
      render={({ error, props }) => {
        return (
          <AddNewMedia
		  	{ ...forwardedProps}
            query={{ error, data: props }}
          />
        );
      }}
    />
  );
}

export default AddNewMediaDataWrapper;
