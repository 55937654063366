/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MessageRecipientSelector_viewer$ref: FragmentReference;
declare export opaque type MessageRecipientSelector_viewer$fragmentType: MessageRecipientSelector_viewer$ref;
export type MessageRecipientSelector_viewer = {|
  +group: ?{|
    +type: string
  |},
  +messageRecipients: ?$ReadOnlyArray<{|
    +id: string,
    +parentId: ?string,
    +name: string,
    +type: string,
    +customerWithBogintraAccessCount: number,
  |}>,
  +$refType: MessageRecipientSelector_viewer$ref,
|};
export type MessageRecipientSelector_viewer$data = MessageRecipientSelector_viewer;
export type MessageRecipientSelector_viewer$key = {
  +$data?: MessageRecipientSelector_viewer$data,
  +$fragmentRefs: MessageRecipientSelector_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MessageRecipientSelector_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "group",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerGroup",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "messageRecipients",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerGroup",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "parentId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "customerWithBogintraAccessCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '60a3178cfd46026f08fa7bea8444834f';
module.exports = node;
