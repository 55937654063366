// @flow
import React from 'react';

/** Field wrapper props. */
export type FieldWrapperProps = {
  children: React$Node,
  focused: boolean,
  hasValue: boolean,
  label: string,
};

/** Field wrapper. */
const FieldWrapper: React$ComponentType<FieldWrapperProps> = function FieldWrapper({
  children,
  focused,
  hasValue,
  label,
}: FieldWrapperProps) {
  return (
    <div className="field-wrapper">
      <div className="standardinput">
        <div className="input-field">
          {children}
          <label className={focused || hasValue ? 'active' : null}>{label}</label>
        </div>
      </div>
    </div>
  );
};

export default FieldWrapper;
