/**
 * @flow
 * @relayHash 2dccaaa74d49828f5a2a0cce7fcb1052
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollectionType = "ANNUAL_PUBLICATION" | "BESTSELLER_LIST" | "BOOK_COLLECTION" | "LITERATURE_AWARD" | "OTHER" | "SERIES" | "%future added value";
export type SearchManagerProviderMissingLabelQueryVariables = {|
  ids: $ReadOnlyArray<string>
|};
export type SearchManagerProviderMissingLabelQueryResponse = {|
  +nodes: $ReadOnlyArray<?{|
    +__typename: string,
    +id: string,
    +title?: string,
    +firstName?: string,
    +lastName?: string,
    +name?: string,
    +type?: ?CollectionType,
  |}>
|};
export type SearchManagerProviderMissingLabelQuery = {|
  variables: SearchManagerProviderMissingLabelQueryVariables,
  response: SearchManagerProviderMissingLabelQueryResponse,
|};
*/


/*
query SearchManagerProviderMissingLabelQuery(
  $ids: [ID!]!
) {
  nodes(ids: $ids) {
    __typename
    id
    ... on Title {
      title
    }
    ... on Reviewer {
      firstName
      lastName
    }
    ... on Media {
      name
    }
    ... on Entity {
      name
    }
    ... on PublishingHouse {
      name
    }
    ... on Collection {
      type
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ids",
    "type": "[ID!]!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "nodes",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": null,
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "__typename",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "type": "Title",
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "InlineFragment",
        "type": "Reviewer",
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "InlineFragment",
        "type": "Media",
        "selections": (v2/*: any*/)
      },
      {
        "kind": "InlineFragment",
        "type": "Entity",
        "selections": (v2/*: any*/)
      },
      {
        "kind": "InlineFragment",
        "type": "PublishingHouse",
        "selections": (v2/*: any*/)
      },
      {
        "kind": "InlineFragment",
        "type": "Collection",
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SearchManagerProviderMissingLabelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchManagerProviderMissingLabelQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SearchManagerProviderMissingLabelQuery",
    "id": null,
    "text": "query SearchManagerProviderMissingLabelQuery(\n  $ids: [ID!]!\n) {\n  nodes(ids: $ids) {\n    __typename\n    id\n    ... on Title {\n      title\n    }\n    ... on Reviewer {\n      firstName\n      lastName\n    }\n    ... on Media {\n      name\n    }\n    ... on Entity {\n      name\n    }\n    ... on PublishingHouse {\n      name\n    }\n    ... on Collection {\n      type\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '391bff88be17ee0ea8f230d945e953f7';
module.exports = node;
