/**
 * @flow
 * @relayHash dcbbc0bd9259c045de5b88700102edc7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollectionType = "ANNUAL_PUBLICATION" | "BESTSELLER_LIST" | "BOOK_COLLECTION" | "LITERATURE_AWARD" | "OTHER" | "SERIES" | "%future added value";
export type ManagedSearchBarSuggestionsQueryVariables = {|
  search: string
|};
export type ManagedSearchBarSuggestionsQueryResponse = {|
  +titles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: string,
        +authors: ?$ReadOnlyArray<{|
          +name: string
        |}>,
        +coverImage: ?{|
          +sizes: ?$ReadOnlyArray<{|
            +name: string,
            +url: string,
          |}>
        |},
      |}
    |}>
  |},
  +collections: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +type: ?CollectionType,
      |}
    |}>
  |},
  +entities: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +reviewers: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstName: string,
        +lastName: string,
      |}
    |}>
  |},
  +medias: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +mediaType: ?{|
          +id: string
        |},
      |}
    |}>
  |},
  +publishingHouses: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |},
|};
export type ManagedSearchBarSuggestionsQuery = {|
  variables: ManagedSearchBarSuggestionsQueryVariables,
  response: ManagedSearchBarSuggestionsQueryResponse,
|};
*/


/*
query ManagedSearchBarSuggestionsQuery(
  $search: String!
) {
  titles(first: 5, search: $search, orderBy: POPULARITY_SCORE, order: DESC) {
    edges {
      node {
        id
        title
        authors {
          name
          id
        }
        coverImage {
          sizes {
            name
            url
          }
          id
        }
      }
    }
  }
  collections(first: 5, search: $search) {
    edges {
      node {
        id
        name
        type
      }
    }
  }
  entities(first: 5, search: $search) {
    edges {
      node {
        id
        name
      }
    }
  }
  reviewers(first: 5, search: $search) {
    edges {
      node {
        id
        firstName
        lastName
      }
    }
  }
  medias(first: 5, search: $search) {
    edges {
      node {
        id
        name
        mediaType {
          id
        }
      }
    }
  }
  publishingHouses(first: 5, search: $search, orderBy: TITLE_COUNT, order: DESC) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 5
},
v2 = {
  "kind": "Literal",
  "name": "order",
  "value": "DESC"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "POPULARITY_SCORE"
  },
  (v3/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "sizes",
  "storageKey": null,
  "args": null,
  "concreteType": "PublicationCoverImageSize",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = [
  (v1/*: any*/),
  (v3/*: any*/)
],
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "collections",
  "storageKey": null,
  "args": (v9/*: any*/),
  "concreteType": "CollectionConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "CollectionEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Collection",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v7/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "type",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v11 = [
  (v5/*: any*/),
  (v7/*: any*/)
],
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "entities",
  "storageKey": null,
  "args": (v9/*: any*/),
  "concreteType": "EntityConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "EntityEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Entity",
          "plural": false,
          "selections": (v11/*: any*/)
        }
      ]
    }
  ]
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reviewers",
  "storageKey": null,
  "args": (v9/*: any*/),
  "concreteType": "ReviewerConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ReviewerEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Reviewer",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "medias",
  "storageKey": null,
  "args": (v9/*: any*/),
  "concreteType": "MediaConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "MediaEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Media",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v7/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "mediaType",
              "storageKey": null,
              "args": null,
              "concreteType": "MediaType",
              "plural": false,
              "selections": [
                (v5/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "publishingHouses",
  "storageKey": null,
  "args": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": "TITLE_COUNT"
    },
    (v3/*: any*/)
  ],
  "concreteType": "PublishingHouseConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "PublishingHouseEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "PublishingHouse",
          "plural": false,
          "selections": (v11/*: any*/)
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ManagedSearchBarSuggestionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "titles",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "TitleConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "TitleEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Title",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "authors",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Entity",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "coverImage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PublicationCoverImage",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      (v10/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ManagedSearchBarSuggestionsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "titles",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "TitleConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "TitleEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Title",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "authors",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Entity",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v5/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "coverImage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PublicationCoverImage",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      (v10/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ManagedSearchBarSuggestionsQuery",
    "id": null,
    "text": "query ManagedSearchBarSuggestionsQuery(\n  $search: String!\n) {\n  titles(first: 5, search: $search, orderBy: POPULARITY_SCORE, order: DESC) {\n    edges {\n      node {\n        id\n        title\n        authors {\n          name\n          id\n        }\n        coverImage {\n          sizes {\n            name\n            url\n          }\n          id\n        }\n      }\n    }\n  }\n  collections(first: 5, search: $search) {\n    edges {\n      node {\n        id\n        name\n        type\n      }\n    }\n  }\n  entities(first: 5, search: $search) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  reviewers(first: 5, search: $search) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n  medias(first: 5, search: $search) {\n    edges {\n      node {\n        id\n        name\n        mediaType {\n          id\n        }\n      }\n    }\n  }\n  publishingHouses(first: 5, search: $search, orderBy: TITLE_COUNT, order: DESC) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dcf692dcafe2f160751e246ca1e0e586';
module.exports = node;
