/**
 * @flow
 * @relayHash dca30f209faaa6b872364bb55f23bc58
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CustomerForm_customer$ref = any;
export type UpdateNonDepartmentCustomerInput = {|
  phoneNumber?: ?string,
  isNewsletterSubscriber: boolean,
  position?: ?string,
  email: string,
  isAdministrator: boolean,
  groupId: string,
  id: string,
  name: string,
  hasBogintraAccess: boolean,
  clientMutationId: string,
|};
export type CustomerListUpdateNonDepartmentCustomerMutationVariables = {|
  input: UpdateNonDepartmentCustomerInput
|};
export type CustomerListUpdateNonDepartmentCustomerMutationResponse = {|
  +updateNonDepartmentCustomer: ?{|
    +customer: ?{|
      +id: string,
      +type: string,
      +name: string,
      +isAdministrator: boolean,
      +hasBogintraAccess: boolean,
      +isNewsletterSubscriber: boolean,
      +group: ?{|
        +name: string,
        +reference: ?string,
      |},
      +$fragmentRefs: CustomerForm_customer$ref,
    |}
  |}
|};
export type CustomerListUpdateNonDepartmentCustomerMutation = {|
  variables: CustomerListUpdateNonDepartmentCustomerMutationVariables,
  response: CustomerListUpdateNonDepartmentCustomerMutationResponse,
|};
*/


/*
mutation CustomerListUpdateNonDepartmentCustomerMutation(
  $input: UpdateNonDepartmentCustomerInput!
) {
  updateNonDepartmentCustomer(input: $input) {
    customer {
      id
      type
      name
      isAdministrator
      hasBogintraAccess
      isNewsletterSubscriber
      group {
        name
        reference
        id
      }
      ...CustomerForm_customer
    }
  }
}

fragment CustomerForm_customer on Customer {
  id
  name
  email
  phoneNumber
  position
  isAdministrator
  hasBogintraAccess
  isNewsletterSubscriber
  address
  postalCode
  city
  type
  group {
    id
    reference
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateNonDepartmentCustomerInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdministrator",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasBogintraAccess",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isNewsletterSubscriber",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reference",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CustomerListUpdateNonDepartmentCustomerMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNonDepartmentCustomer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNonDepartmentCustomerPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "group",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomerGroup",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/)
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "CustomerForm_customer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CustomerListUpdateNonDepartmentCustomerMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNonDepartmentCustomer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNonDepartmentCustomerPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "group",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomerGroup",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "phoneNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "position",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "address",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "postalCode",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "city",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CustomerListUpdateNonDepartmentCustomerMutation",
    "id": null,
    "text": "mutation CustomerListUpdateNonDepartmentCustomerMutation(\n  $input: UpdateNonDepartmentCustomerInput!\n) {\n  updateNonDepartmentCustomer(input: $input) {\n    customer {\n      id\n      type\n      name\n      isAdministrator\n      hasBogintraAccess\n      isNewsletterSubscriber\n      group {\n        name\n        reference\n        id\n      }\n      ...CustomerForm_customer\n    }\n  }\n}\n\nfragment CustomerForm_customer on Customer {\n  id\n  name\n  email\n  phoneNumber\n  position\n  isAdministrator\n  hasBogintraAccess\n  isNewsletterSubscriber\n  address\n  postalCode\n  city\n  type\n  group {\n    id\n    reference\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b018ea511bcbf7638c20544b65b6890f';
module.exports = node;
