// @flow
import React from 'react';

import TabNavTab from './TabNavTab';

/** Tab nav props. */
export type TabNavProps = {
  children: React$Node,
};

/** Tab nav. */
export default class TabNav extends React.Component<TabNavProps> {
  static Tab = TabNavTab;

  render() {
    return (
      <ul className="tabs tabs-fixed-width">
        {this.props.children}
      </ul>
    );
  }
}
