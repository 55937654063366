// @flow
import React from 'react';

/** Radio props. */
export type RadioProps<T> = {
  disabled?: ?boolean,
  label: React$Node,
  onChange: (value: T) => any,
  value: T,
  checked: boolean,
};

/** Radio. */
export default class Radio<T> extends React.Component<RadioProps<T>> {
  handleChange = (evt) => {
    const { onChange, value } = this.props;

    onChange(value);
  }

  render() {
    const {
      checked,
      disabled,
      label,
    } = this.props;

    return (
      <label>
        <input
          type="radio"
          checked={checked}
          onChange={this.handleChange}
          className="filled-in"
          disabled={disabled}
        />
        <span className="black-text">{label}</span>
      </label>
    );
  }
}
