/**
 * @flow
 * @relayHash 9e7e7dc7020f7b192bb03be55fa51b27
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateCustomerLinkInput = {|
  description: string,
  url: string,
  clientMutationId: string,
|};
export type MainLayoutCreateLinkMutationVariables = {|
  input: CreateCustomerLinkInput
|};
export type MainLayoutCreateLinkMutationResponse = {|
  +createCustomerLink: ?{|
    +viewer: ?{|
      +links: ?$ReadOnlyArray<{|
        +id: string,
        +url: string,
        +description: string,
      |}>
    |}
  |}
|};
export type MainLayoutCreateLinkMutation = {|
  variables: MainLayoutCreateLinkMutationVariables,
  response: MainLayoutCreateLinkMutationResponse,
|};
*/


/*
mutation MainLayoutCreateLinkMutation(
  $input: CreateCustomerLinkInput!
) {
  createCustomerLink(input: $input) {
    viewer {
      links {
        id
        url
        description
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateCustomerLinkInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "links",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomerLink",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MainLayoutCreateLinkMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createCustomerLink",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCustomerLinkPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MainLayoutCreateLinkMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createCustomerLink",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCustomerLinkPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "MainLayoutCreateLinkMutation",
    "id": null,
    "text": "mutation MainLayoutCreateLinkMutation(\n  $input: CreateCustomerLinkInput!\n) {\n  createCustomerLink(input: $input) {\n    viewer {\n      links {\n        id\n        url\n        description\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '962c6b61d33d4cc73f30556ebe1d48b0';
module.exports = node;
