/**
 * @flow
 * @relayHash 7de167bd78e529a48cf33650473456bf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CustomerForm_customer$ref = any;
type CustomerForm_viewer$ref = any;
type CustomerList_viewer$ref = any;
type LinkList_viewer$ref = any;
type NotificationSubscriptionList_viewer$ref = any;
type ViewerProfile_viewer$ref = any;
export type SettingsLayoutOverrideQueryVariables = {||};
export type SettingsLayoutOverrideQueryResponse = {|
  +viewer: {|
    +id: string,
    +name: string,
    +type: string,
    +isAdministrator: boolean,
    +managableCustomers: ?$ReadOnlyArray<{|
      +id: string,
      +type: string,
    |}>,
    +group: ?{|
      +type: string
    |},
    +$fragmentRefs: CustomerForm_customer$ref & CustomerForm_viewer$ref & CustomerList_viewer$ref & LinkList_viewer$ref & NotificationSubscriptionList_viewer$ref & ViewerProfile_viewer$ref,
  |}
|};
export type SettingsLayoutOverrideQuery = {|
  variables: SettingsLayoutOverrideQueryVariables,
  response: SettingsLayoutOverrideQueryResponse,
|};
*/


/*
query SettingsLayoutOverrideQuery {
  viewer {
    id
    name
    type
    isAdministrator
    managableCustomers {
      id
      type
    }
    group {
      type
      id
    }
    ...CustomerForm_customer
    ...CustomerForm_viewer
    ...CustomerList_viewer
    ...LinkList_viewer
    ...NotificationSubscriptionList_viewer
    ...ViewerProfile_viewer
  }
}

fragment CustomerForm_customer on Customer {
  id
  name
  email
  phoneNumber
  position
  isAdministrator
  hasBogintraAccess
  isNewsletterSubscriber
  address
  postalCode
  city
  type
  group {
    id
    reference
  }
}

fragment CustomerForm_viewer on Customer {
  id
  isAdministrator
  group {
    id
    name
    descendentGroups {
      id
      name
    }
  }
}

fragment CustomerList_viewer on Customer {
  id
  group {
    id
  }
  managableCustomers {
    id
    type
    name
    isAdministrator
    hasBogintraAccess
    isNewsletterSubscriber
    longTermToken
    group {
      name
      reference
      id
    }
    ...CustomerForm_customer
  }
  ...CustomerForm_viewer
  ...ViewerProfile_viewer
}

fragment LinkForm_link on CustomerLink {
  id
  description
  url
}

fragment LinkList_viewer on Customer {
  id
  name
  links {
    id
    url
    description
    ...LinkForm_link
  }
}

fragment NotificationSubscriptionForm_notificationSubscription on CustomerNotificationSubscription {
  categoryGroup
  description
  id
  isDefault
  notification
  type
}

fragment NotificationSubscriptionList_viewer on Customer {
  id
  name
  notificationSubscriptions {
    id
    categoryGroup
    description
    isDefault
    notification
    type
    ...NotificationSubscriptionForm_notificationSubscription
  }
}

fragment ViewerProfile_viewer on Customer {
  id
  name
  type
  longTermToken
  ...CustomerForm_customer
  ...CustomerForm_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdministrator",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasBogintraAccess",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isNewsletterSubscriber",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "longTermToken",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reference",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phoneNumber",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "position",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "address",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "postalCode",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SettingsLayoutOverrideQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "managableCustomers",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "CustomerForm_customer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "CustomerForm_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "CustomerList_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "LinkList_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "NotificationSubscriptionList_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "ViewerProfile_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SettingsLayoutOverrideQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "managableCustomers",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              (v1/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "group",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomerGroup",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v7/*: any*/),
                  (v0/*: any*/)
                ]
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/),
              (v7/*: any*/),
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "descendentGroups",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomerGroup",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ]
              }
            ]
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "links",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerLink",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              (v14/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "notificationSubscriptions",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerNotificationSubscription",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "categoryGroup",
                "args": null,
                "storageKey": null
              },
              (v14/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isDefault",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "notification",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SettingsLayoutOverrideQuery",
    "id": null,
    "text": "query SettingsLayoutOverrideQuery {\n  viewer {\n    id\n    name\n    type\n    isAdministrator\n    managableCustomers {\n      id\n      type\n    }\n    group {\n      type\n      id\n    }\n    ...CustomerForm_customer\n    ...CustomerForm_viewer\n    ...CustomerList_viewer\n    ...LinkList_viewer\n    ...NotificationSubscriptionList_viewer\n    ...ViewerProfile_viewer\n  }\n}\n\nfragment CustomerForm_customer on Customer {\n  id\n  name\n  email\n  phoneNumber\n  position\n  isAdministrator\n  hasBogintraAccess\n  isNewsletterSubscriber\n  address\n  postalCode\n  city\n  type\n  group {\n    id\n    reference\n  }\n}\n\nfragment CustomerForm_viewer on Customer {\n  id\n  isAdministrator\n  group {\n    id\n    name\n    descendentGroups {\n      id\n      name\n    }\n  }\n}\n\nfragment CustomerList_viewer on Customer {\n  id\n  group {\n    id\n  }\n  managableCustomers {\n    id\n    type\n    name\n    isAdministrator\n    hasBogintraAccess\n    isNewsletterSubscriber\n    longTermToken\n    group {\n      name\n      reference\n      id\n    }\n    ...CustomerForm_customer\n  }\n  ...CustomerForm_viewer\n  ...ViewerProfile_viewer\n}\n\nfragment LinkForm_link on CustomerLink {\n  id\n  description\n  url\n}\n\nfragment LinkList_viewer on Customer {\n  id\n  name\n  links {\n    id\n    url\n    description\n    ...LinkForm_link\n  }\n}\n\nfragment NotificationSubscriptionForm_notificationSubscription on CustomerNotificationSubscription {\n  categoryGroup\n  description\n  id\n  isDefault\n  notification\n  type\n}\n\nfragment NotificationSubscriptionList_viewer on Customer {\n  id\n  name\n  notificationSubscriptions {\n    id\n    categoryGroup\n    description\n    isDefault\n    notification\n    type\n    ...NotificationSubscriptionForm_notificationSubscription\n  }\n}\n\nfragment ViewerProfile_viewer on Customer {\n  id\n  name\n  type\n  longTermToken\n  ...CustomerForm_customer\n  ...CustomerForm_viewer\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '75e56143121d6faae5ed9f88638fb56b';
module.exports = node;
