/**
 * @flow
 * @relayHash bad4cf141d1156b707352a6aecc9ca7e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PrintLayoutOverrideQueryVariables = {|
  titleIds: $ReadOnlyArray<string>
|};
export type PrintLayoutOverrideQueryResponse = {|
  +nodes: $ReadOnlyArray<?{|
    +title?: string,
    +hasMoreAuthors?: boolean,
    +publications?: ?$ReadOnlyArray<{|
      +isbn13: ?string
    |}>,
    +authors?: ?$ReadOnlyArray<{|
      +name: string
    |}>,
    +coverImage?: ?{|
      +sizes: ?$ReadOnlyArray<{|
        +name: string,
        +url: string,
      |}>
    |},
  |}>
|};
export type PrintLayoutOverrideQuery = {|
  variables: PrintLayoutOverrideQueryVariables,
  response: PrintLayoutOverrideQueryResponse,
|};
*/


/*
query PrintLayoutOverrideQuery(
  $titleIds: [ID!]!
) {
  nodes(ids: $titleIds) {
    __typename
    ... on Title {
      title
      hasMoreAuthors
      publications {
        isbn13
        id
      }
      authors {
        name
        id
      }
      coverImage {
        sizes {
          name
          url
        }
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "titleIds",
    "type": "[ID!]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "titleIds"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasMoreAuthors",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isbn13",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "sizes",
  "storageKey": null,
  "args": null,
  "concreteType": "PublicationCoverImageSize",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PrintLayoutOverrideQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": true,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Title",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "publications",
                "storageKey": null,
                "args": null,
                "concreteType": "Publication",
                "plural": true,
                "selections": [
                  (v4/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "authors",
                "storageKey": null,
                "args": null,
                "concreteType": "Entity",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "coverImage",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicationCoverImage",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PrintLayoutOverrideQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Title",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "publications",
                "storageKey": null,
                "args": null,
                "concreteType": "Publication",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "authors",
                "storageKey": null,
                "args": null,
                "concreteType": "Entity",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v7/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "coverImage",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicationCoverImage",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PrintLayoutOverrideQuery",
    "id": null,
    "text": "query PrintLayoutOverrideQuery(\n  $titleIds: [ID!]!\n) {\n  nodes(ids: $titleIds) {\n    __typename\n    ... on Title {\n      title\n      hasMoreAuthors\n      publications {\n        isbn13\n        id\n      }\n      authors {\n        name\n        id\n      }\n      coverImage {\n        sizes {\n          name\n          url\n        }\n        id\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e1ee017f6cf42f0523428beec6846c26';
module.exports = node;
