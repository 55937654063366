// @flow

/** Print format. */
export type PrintFormat = {
  id: string,
  label: string,
};

/** Print formats. */
export const PRINT_FORMATS: PrintFormat[] = [{
  id: 'a4staaende',
  label: 'A4 stående',
}, {
  id: 'a4liggende',
  label: 'A4 liggende',
}, {
  id: 'a3staaende',
  label: 'A3 stående',
}, {
  id: 'a3liggende',
  label: 'A3 liggende',
}];
