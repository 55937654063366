// @flow
import React from 'react';

/** Checkbox props. */
export type CheckboxProps = {
  disabled?: ?boolean,
  label: string,
  onChange: (value: boolean, event: any) => any,
  value: ?boolean,
};

/** Checkbox. */
export default class Checkbox extends React.Component<CheckboxProps> {
  handleChange = (evt: any) => {
    const { onChange, value } = this.props;
	
    onChange(!value, evt);
  }

  render() {
    const {
      disabled,
      label,
      value,
    } = this.props;

    return (
      <label>
        <input
          type="checkbox"
          checked={value || false}
          onChange={this.handleChange}
          className="filled-in"
          disabled={disabled}
        />
        <span className="black-text">{label}</span>
      </label>
    );
  }
}
