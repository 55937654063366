// @flow
import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import SearchManagerContext from './SearchManagerContext';
import type { ManageSearchProps } from './ManageSearchProps';

export default function withManageSearch<Props: ManageSearchProps>(Component: React$ComponentType<Props>): React$ComponentType<$Diff<Props, ManageSearchProps>> {
  function C(props) {
    return (
      <SearchManagerContext.Consumer>
        {searchSettingsContext => <Component {...props} {...searchSettingsContext} />}
      </SearchManagerContext.Consumer>
    );
  }

  C.WrappedComponent = Component;
  C.displayName = `withManageSearch(${Component.displayName || Component.name || 'unnamed'})`;

  return hoistStatics(C, Component);
}
