// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { commitMutation, graphql } from 'react-relay';

import { Icon, Input, Modal, withToast, type ToastProps } from '../../components';
import { type Viewer } from '../../models';
import { MediaSearchBar } from '../SearchManager';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';

 
/** Create Reviewer mutation. */
const createReviewerMutation = graphql`
mutation AddNewReviewerCardcreateReviewerMutation($input: CreateReviewerInput!) {
  createReviewer(input: $input) {
    reviewer {
      id
    }
  }
}`;

type AddNewReviewerProps = EnvironmentProps & ToastProps & {
	onSubmit: (reviewer:any) => any
};

type AddNewReviewerState = {
	loading: string,
	submitting: boolean,
	error: any,
	errorField: string,
	showModal: boolean,
	firstName: string,
	lastName: string,
	chosenMediaId: string
};


/** Content item. */
class AddNewReviewerCard extends React.Component<AddNewReviewerProps, AddNewReviewerState>{
	state = {
		loading: '',
		submitting: false,
		showModal: false,
		error: null,
		errorField: '',
		chosenMediaId: '',
		firstName: '',
		lastName: '',
	};

	handleSubmit = (evt) => {
	 
		evt.preventDefault();
		if (this.state.loading) {
			return;
		}
		const {
			environment,
		} = this.props;

		const {
			chosenMediaId,
			firstName,
			lastName,
		} = this.state;
  
		if (firstName.trim() === '') {
			this.setState({
			  error: 'Anmelderen skal have et fornavn.',
			  errorField: 'firstName',
			  submitting: false,
			});
			return;
		}
  
		if (chosenMediaId.trim() === '') {
			this.setState({
			  error: 'Anmelderen skal være tilknyttet et medie.',
			  errorField: 'firstName',
			  submitting: false,
			});
			return;
		}

		const payload = {
			mediaId: chosenMediaId,
			firstName,
			lastName,
		};

		this.setState({
			submitting: true,
			loading: 'loading'
		  }, () => commitMutation(environment, {
			mutation: createReviewerMutation,
			variables: {
			  input: {
				clientMutationId: '1',
				...payload,
			  },
			},
 
			onCompleted: (data, errors) => {
			  const { toast, onSubmit } = this.props;
	  
			  if (errors?.length) {
				this.setState({
				  loading: '',
				  submitting: false,
				  error: 'Der er opstået en fejl.',
				});
			  }
			  else {
				onSubmit(Object.assign({}, data.createReviewer.reviewer, {lastName, firstName}));
				toast('Anmelderen er oprettet');
				this.setState({
						loading: '',
						submitting: false,
						showModal: false,
						error: null,
						chosenMediaId: '',
						firstName: '',
						lastName: '',
				});
			  }
			},
		  }));
	}

	toggleModal(){
		this.setState({showModal: !this.state.showModal});
	}

	onChooseMedia(pickedMedia){
		this.setState({chosenMediaId: pickedMedia[0].id});
	}

	render() {
		const {
			loading,
			submitting,
			chosenMediaId,
			firstName,
			lastName,
			error
		} = this.state;
		
		return(
			<Fragment>
				<div className="add-new-reviewer-modal">
					<Modal open={this.state.showModal} onClose={this.toggleModal.bind(this)}>
						<div className="modal-content">
							<div className="modal-headline">
								Opret ny anmelder
							</div>
							<div>
								<div className="row">
									<Input validate value={firstName} onChange={firstName => this.setState({firstName})} autoComplete="on" label="Fornavn" />
									<Input validate value={lastName} onChange={lastName => this.setState({lastName})} autoComplete="on" label="Efternavn" />
								</div>

								<div className="row">
									<MediaSearchBar onSubmit={this.onChooseMedia.bind(this)} />
								</div>
							
								<div className="row">
									<div className="col">
									{error &&
									<div className="red-text">
										{error}
									</div>}
									</div>
									<div className="col right">
										<button type="submit" onClick={this.handleSubmit} className="waves-effect waves-light btn-large right standard-bg-color" disabled={submitting}>
											GODKEND
										</button>
									</div>
								</div>
							</div>
						</div>
					</Modal>

				</div>

				<a className="standard-color versals add-new-button" onClick={this.toggleModal.bind(this)} >
					<Icon name="add" />
					Opret ny anmelder
				</a>
			</Fragment>
		);
	}
}

export default withToast(withEnvironment(AddNewReviewerCard));