// @flow
import React from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Portal from '../Portal';

/** Modal props. */
export type ModalProps = {
  /** Modal content. */
  children: React$Node,

  /** Modal class name. */
  className?: ?string,

  /** Open. */
  open?: ?boolean,

  /** Close callback. */
  onClose?: ?() => any,
};

/**
 * Modal.
 *
 * Transitions modeled after https://github.com/Dogfalo/materialize/blob/v1-dev/js/modal.js.
 *
 * TODO: Prevent document scrolling.
 * TODO: Escape key closing.
 */
export default class Modal extends React.Component<ModalProps> {
  handleOverlayClick = () => {
    this.props.onClose?.(); // eslint-disable-line no-unused-expressions
  }

  handleModalClick = (evt: SyntheticMouseEvent) => {
    evt.stopPropagation();
  }

  render() {
    const { children, className, open } = this.props;

    return (
      <CSSTransition
        timeout={250}
        classNames="css-modal"
        mountOnEnter
        unmountOnExit
        in={open}
        appear={true}
      >
        <Portal>
          <div className="css-modal">
            <div
              className="modal-overlay"
              style={{
                zIndex: 1000,
              }}
              onClick={this.handleOverlayClick}
            />

            <div
              className={classNames('modal', className)}
              onClick={this.handleModalClick}
              style={{
                zIndex: 1001,
              }}
            >
              {children}
            </div>
          </div>
        </Portal>
      </CSSTransition>
    );
  }
}
