/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MainSidebar_viewer$ref: FragmentReference;
declare export opaque type MainSidebar_viewer$fragmentType: MainSidebar_viewer$ref;
export type MainSidebar_viewer = {|
  +favoriteIds: ?$ReadOnlyArray<string>,
  +name: string,
  +links: ?$ReadOnlyArray<{|
    +id: string,
    +description: string,
    +url: string,
  |}>,
  +group: ?{|
    +type: string
  |},
  +$refType: MainSidebar_viewer$ref,
|};
export type MainSidebar_viewer$data = MainSidebar_viewer;
export type MainSidebar_viewer$key = {
  +$data?: MainSidebar_viewer$data,
  +$fragmentRefs: MainSidebar_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "MainSidebar_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "favoriteIds",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "links",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerLink",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "group",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerGroup",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7c4c796cb4a822e5a45e1eb85c035524';
module.exports = node;
