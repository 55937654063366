// @flow
import React from 'react';

import { FilterAdder } from '../SearchManager';

/** Content chips props. */
type ContentChipsProps = {

};

/** Content chips. */
const ContentChips: React$ComponentType<ContentChipsProps> = function ContentChips({
  content: {
    __typename,
    media,
    reviewer,
    referencedEntities,
    referencedTitles,
  },
  shouldDisplayPublishingHouse,
}: ContentChipsProps) {
  const chips = [];

  referencedTitles?.filter(({ publishingHouses }) => publishingHouses?.some(({ id }) => shouldDisplayPublishingHouse(id)))?.forEach(title => { // eslint-disable-line no-unused-expressions
    chips.push({
      filter: {
        id: title.id,
        type: 'Title',
        label: title.title,
      },
      typeLabel: 'titel',
    });

    title.authors?.forEach(entity => { // eslint-disable-line no-unused-expressions
      chips.push({
        filter: {
          id: entity.id,
          type: 'Entity',
          label: entity.name,
        },
        typeLabel: 'forfatter',
      });
    });

    title.publishingHouses?.forEach(ph => { // eslint-disable-line no-unused-expressions
      if (shouldDisplayPublishingHouse(ph.id)) {
        chips.push({
          filter: {
            id: ph.id,
            type: 'PublishingHouse',
            label: ph.name,
          },
          typeLabel: 'forlag',
        });
      }
    });

    title.publications?.forEach(publication => { // eslint-disable-line no-unused-expressions
      if (!publication.isDigital && publication.availability === 'AVAILABLE') {
        chips.push({
          url: `https://www.bogportalen.dk/webapp/wcs/stores/servlet/SimpleSearchCmd?sType=SimpleSearch&searchString=${publication.isbn13}`,
          format: publication.format,
        });
      }
    });
  });

  referencedEntities?.filter(({ publishingHouseIds }) => publishingHouseIds?.some(id => shouldDisplayPublishingHouse(id))).forEach(entity => { // eslint-disable-line no-unused-expressions
    chips.push({
      filter: {
        id: entity.id,
        type: 'Entity',
        label: entity.name,
      },
      typeLabel: 'forfatter',
    });
  });

  if (media) {
    chips.push({
      filter: {
        id: media.id,
        type: 'Media',
        label: media.name,
      },
      typeLabel: 'medie',
    });
  }

  if (reviewer) {
    chips.push({
      filter: {
        id: reviewer.id,
        type: 'Reviewer',
        label: `${reviewer.firstName} ${reviewer.lastName}`,
      },
      typeLabel: __typename === 'Review' ? 'anmelder' : 'journalist',
    });
  }

  if (!chips.length) {
    return null;
  }

  return (
    <div className="news-links">
      {chips.map((chip, idx) =>
        chip.filter
          ? (
            <FilterAdder key={idx}>
              {({ addFilter }) =>
                <a
                  className="waves-effect waves-light btn standard-bg-color white-text"
                  onClick={() => addFilter(chip.filter)}
                >
                  <span className="light-standard-color">{chip.typeLabel}:&nbsp;</span>
                  {chip.filter.label}
                </a>}
            </FilterAdder>
          )
          : (
            <a
              className="waves-effect waves-dark btn white"
              href={chip.url}
              target="_blank"
              key={idx}
            >
              <span className="standard-color">Bogportalen:&nbsp;</span>
              <span className="black-text">{chip.format}</span>
            </a>
          )
      )}
    </div>
  );
};

export default ContentChips;
