// @flow
import React, { Fragment } from 'react';
import { commitMutation, graphql, createFragmentContainer } from 'react-relay';

import { Icon, Modal, withToast, type ToastProps } from '../../components';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import { formatPossessiveName } from '../../utils/ownership';
import type { Viewer } from '../../models';
import LinkForm from './LinkForm';

/** Create link mutation. */
const createLinkMutation = graphql`
mutation LinkListCreateLinkMutation($input: CreateCustomerLinkInput!) {
  createCustomerLink(input: $input) {
    viewer {
      ...LinkList_viewer
    }
  }
}
`;

/** Update link mutation. */
const updateLinkMutation = graphql`
mutation LinkListUpdateLinkMutation($input: UpdateCustomerLinkInput!) {
  updateCustomerLink(input: $input) {
    viewer {
      ...LinkList_viewer
    }
  }
}
`;

/** Delete link mutation. */
const deleteLinkMutation = graphql`
mutation LinkListDeleteLinkMutation($input: DeleteCustomerLinkInput!) {
  deleteCustomerLink(input: $input) {
    viewer {
      ...LinkList_viewer
    }
  }
}`;

/** Position link before other link mutation. */
const positionLinkMutation = graphql`
mutation LinkListPositionCustomerLinkBeforeOtherMutation($input: PositionCustomerLinkBeforeOtherInput!) {
  positionCustomerLinkBeforeOther(input: $input) {
    viewer {
      ...LinkList_viewer
    }
  }
}`;

/** Customer list props. */
export type LinkListProps = {
  viewer: Viewer,
} & EnvironmentProps & ToastProps;

/** Customer list state. */
export type LinkListState = {
  actionState: null | {
    action: 'new',
    loading: boolean,
  } | {
    action: 'edit',
    customer: {},
    loading: boolean,
    error: ?string,
  };
};

/** Customer list. */
class LinkList extends React.Component<LinkListProps, LinkListState> {
  state = {
    actionState: null,
  };

  handleNewClick = (evt) => {
    evt.preventDefault();

    this.setState({
      actionState: {
        action: 'new',
        loading: false,
      },
    });
  }

  handleEditSubmit = (data) => {
    if (!data.description || !data.description.trim() || !data.url || !data.url.trim()) {
      this.setState({
        actionState: {
          ...this.state.actionState,
          error: 'URL og anker-tekst skal udfyldes',
        },
      });

      return;
    }

    const { viewer } = this.props;

    const currentPosition = viewer.links.findIndex(l => l.id === this.state.actionState.link.id) + 1;
    const position = parseInt(data.position, 10);
    const linkAfterPosition = viewer?.links?.[currentPosition > position ? position - 1 : position];

    this.setState({
      actionState: {
        ...this.state.actionState,
        loading: true,
        error: null,
      },
    }, () => {
      const { environment } = this.props;
      const { actionState: { link } } = this.state;

      commitMutation(environment, {
        mutation: updateLinkMutation,
        variables: {
          input: {
            id: link.id,
            clientMutationId: '1',
            url: data.url,
            description: data.description,
          },
        },
        onCompleted: (data, errors) => {
          if (errors?.length) {
            this.setState({
              actionState: {
                ...this.state.actionState,
                loading: false,
                error: 'Der er opstået en fejl.',
              },
            });
          }
          else {
            commitMutation(environment, {
              mutation: positionLinkMutation,
              variables: {
                input: {
                  id: link.id,
                  clientMutationId: '2',
                  beforeId: linkAfterPosition?.id || null,
                },
              },
              onCompleted: (data, errors) => {
                const { toast } = this.props;

                if (errors?.length) {
                  this.setState({
                    actionState: {
                      ...this.state.actionState,
                      loading: false,
                      error: 'Der er opstået en fejl.',
                    },
                  });
                }
                else {
                  toast('Linket er opdateret');

                  this.setState({
                    actionState: null,
                  });
                }
              },
            });
          }
        },
      });
    });
  }

  handleNewSubmit = (data) => {
    if (!data.description || !data.description.trim() || !data.url || !data.url.trim()) {
      this.setState({
        actionState: {
          ...this.state.actionState,
          error: 'URL og anker-tekst skal udfyldes',
        },
      });

      return;
    }

    this.setState({
      actionState: {
        ...this.state.actionState,
        loading: true,
        error: null,
      },
    }, () => {
      const { environment } = this.props;

      commitMutation(environment, {
        mutation: createLinkMutation,
        variables: {
          input: {
            clientMutationId: '1',
            ...data,
          },
        },
        onCompleted: (data, errors) => {
          const { toast } = this.props;

          if (errors?.length) {
            this.setState({
              actionState: {
                ...this.state.actionState,
                loading: false,
                error: 'Der er opstået en fejl.',
              },
            });
          }
          else {
            toast('Linket er oprettet');

            this.setState({
              actionState: null,
            });
          }
        },
      });
    });
  }

  handleDelete = () => {
    this.setState({
      actionState: {
        ...this.state.actionState,
        loading: true,
        error: null,
      },
    }, () => {
      const { environment } = this.props;
      const { actionState: { link } } = this.state;

      commitMutation(environment, {
        mutation: deleteLinkMutation,
        variables: {
          input: {
            id: link.id,
            clientMutationId: '1',
          },
        },
        onCompleted: (data, errors) => {
          const { toast } = this.props;

          if (errors?.length) {
            this.setState({
              actionState: {
                ...this.state.actionState,
                loading: false,
                error: 'Der er opstået en fejl.',
              },
            });
          }
          else {
            toast('Linket er slettet');

            this.setState({
              actionState: null,
            });
          }
        },
      });
    });
  }

  render() {
    const { actionState } = this.state;
    const { viewer } = this.props;

    return (
      <Fragment>
        <div className="section-headline">
          Rediger {formatPossessiveName(viewer.name)} links
        </div>

        {actionState?.action === 'new' &&
         <Modal
           open={true}
           onClose={() => !actionState.loading && this.setState({ actionState: null })}
         >
           <div className="modal-content">
             <div className="modal-headline">
               Opret nyt link
             </div>

             <LinkForm
               link={null}
               onSubmit={this.handleNewSubmit}
               loading={actionState.loading}
               error={actionState.error}
             />
           </div>
         </Modal>}

        {actionState?.action === 'edit' &&
         <Modal
           open={true}
           onClose={() => !actionState.loading && this.setState({ actionState: null })}
         >
           <div className="modal-content">
             <div className="modal-headline">
               Rediger link
             </div>

             <LinkForm
               position={viewer.links.findIndex(l => l.id === actionState.link.id) + 1}
               link={actionState.link}
               onSubmit={this.handleEditSubmit}
               onDelete={this.handleDelete}
               loading={actionState.loading}
               error={actionState.error}
             />
           </div>
         </Modal>}

        <div className="card">
          <a
            href="#"
            className="btn-floating halfway-fab waves-effect waves-light white modal-trigger"
            onClick={this.handleNewClick}
          >
            <Icon name="add" className="black-text" />
          </a>

          <table className="highlight responsive-table">
            <thead>
              <tr>
                <th></th>
                <th className="space12">#</th>
                <th>Anker-tekst</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {viewer.links?.map((link, idx) =>
                <tr key={link.id}>
                  <td>
                    <a
                      href="#"
                      onClick={evt => {
                        evt.preventDefault();
                        this.setState({
                          actionState: {
                            action: 'edit',
                            loading: false,
                            link,
                            error: null,
                          },
                        });
                      }}
                    >
                      <Icon name="edit" className="standard-color edit-icon" />
                    </a>
                  </td>
                  <td>{idx + 1}</td>
                  <td>{link.description}</td>
                  <td><a href={link.url} target="_blank">{link.url}</a></td>
                </tr>)}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

export default createFragmentContainer(
  withToast(withEnvironment(LinkList)), {
    viewer: graphql`
fragment LinkList_viewer on Customer {
  id
  name

  links {
    id
    url
    description

    ...LinkForm_link
  }
}`,
  }
);
