/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type LinkForm_link$ref: FragmentReference;
declare export opaque type LinkForm_link$fragmentType: LinkForm_link$ref;
export type LinkForm_link = {|
  +id: string,
  +description: string,
  +url: string,
  +$refType: LinkForm_link$ref,
|};
export type LinkForm_link$data = LinkForm_link;
export type LinkForm_link$key = {
  +$data?: LinkForm_link$data,
  +$fragmentRefs: LinkForm_link$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "LinkForm_link",
  "type": "CustomerLink",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e73f2c1ca1eb35b5f86771f21903379f';
module.exports = node;
