// @flow
import React from 'react';

/** Dropdown item props. */
export type DropdownItemProps = {
  children: React$Node,
};

/** Dropdown item. */
const DropdownItem: React$ComponentType<DropdownItemProps> = function DropdownItem({
  children,
}: DropdownItemProps) {
  return (
    <li>
      {children}
    </li>
  );
};

export default DropdownItem;
