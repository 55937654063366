// @flow
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { Checkbox, Form, Input } from '../../components';
import type { LinkForm_link } from './__generated__/LinkForm_link.graphql';

export type LinkFormProps = {
  link: ?LinkForm_link,
  loading: ?boolean,
  position: ?number,
  onDelete: ?() => any,
  onSubmit: () => any,
  error: ?string,
};

type LinkFormState = {
  position: string,
  description: string,
  url: string,
  wantsDeletion: boolean,
  hasPosition: boolean,
};

class LinkForm extends React.Component<LinkFormProps, LinkFormState> {
  constructor(props: LinkFormProps) {
    super(props);

    const { link, position } = props;
    const hasPosition = position !== null && position !== undefined;

    if (link) {
      this.state = {
        position: hasPosition ? position.toString() : '',
        description: link.description,
        url: link.url,
        wantsDeletion: false,
        hasPosition,
      };
    }
    else {
      this.state = {
        position: hasPosition ? position.toString() : '',
        description: '',
        url: '',
        wantsDeletion: false,
        hasPosition,
      };
    }
  }

  handleSubmit = () => {
    const { hasPosition, wantsDeletion } = this.state;
    const { onDelete, onSubmit } = this.props;

    if (wantsDeletion) {
      if (confirm('Er du sikker på at du vil slette dette link?')) {
        onDelete();
      }
    }
    else {
      const baseData = {
        description: this.state.description,
        url: this.state.url,
      };

      onSubmit(hasPosition ? {
        ...baseData,
        position: this.state.position,
      } : baseData);
    }
  }

  render() {
    const { link, error, loading } = this.props;
    const {
      description,
      hasPosition,
      position,
      url,
      wantsDeletion,
    } = this.state;

    return (
      <Form
        onSubmit={this.handleSubmit}
        error={error}
        loading={loading}
      >
        <Input
          value={description}
          onChange={description => this.setState({ description })}
          autoComplete="off"
          label="Anker-tekst"
        />

        <Input
          value={url}
          onChange={url => this.setState({ url })}
          autoComplete="off"
          label="URL"
        />

        {hasPosition &&
        <Input
          value={position}
          onChange={position => this.setState({ position })}
          autoComplete="off"
          label="Nummer i rækkefølge fra 1 til..."
        />}

        {link &&
         <div className="col s12 m6 l4">
           <Checkbox
             label="Slet dette link"
             value={wantsDeletion}
             onChange={wantsDeletion => this.setState({ wantsDeletion })}
           />
         </div>}
      </Form>
    );
  }
}

export default createFragmentContainer(LinkForm, {
  link: graphql`
fragment LinkForm_link on CustomerLink {
  id
  description
  url
}`,
});
