/**
 * @flow
 * @relayHash 8b6dee8f4ae0c2aceb78a32f71bd248e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MediaSearchBarSuggestionsQueryVariables = {|
  search: string
|};
export type MediaSearchBarSuggestionsQueryResponse = {|
  +medias: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +url: string,
        +gradingScales: ?$ReadOnlyArray<?{|
          +max: ?number,
          +min: ?number,
          +precision: ?number,
          +symbol: ?string,
        |}>,
        +mediaType: ?{|
          +id: string
        |},
      |}
    |}>
  |}
|};
export type MediaSearchBarSuggestionsQuery = {|
  variables: MediaSearchBarSuggestionsQueryVariables,
  response: MediaSearchBarSuggestionsQueryResponse,
|};
*/


/*
query MediaSearchBarSuggestionsQuery(
  $search: String!
) {
  medias(first: 5, search: $search) {
    edges {
      node {
        id
        name
        url
        gradingScales {
          max
          min
          precision
          symbol
        }
        mediaType {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "medias",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 5
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "MediaConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "MediaEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Media",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "gradingScales",
                "storageKey": null,
                "args": null,
                "concreteType": "GradingScale",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "max",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "min",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "precision",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "symbol",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "mediaType",
                "storageKey": null,
                "args": null,
                "concreteType": "MediaType",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MediaSearchBarSuggestionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaSearchBarSuggestionsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "MediaSearchBarSuggestionsQuery",
    "id": null,
    "text": "query MediaSearchBarSuggestionsQuery(\n  $search: String!\n) {\n  medias(first: 5, search: $search) {\n    edges {\n      node {\n        id\n        name\n        url\n        gradingScales {\n          max\n          min\n          precision\n          symbol\n        }\n        mediaType {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7fd4581f3994a71424974449ae5c27bc';
module.exports = node;
