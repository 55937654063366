/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CustomerForm_customer$ref = any;
type CustomerForm_viewer$ref = any;
type ViewerProfile_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomerList_viewer$ref: FragmentReference;
declare export opaque type CustomerList_viewer$fragmentType: CustomerList_viewer$ref;
export type CustomerList_viewer = {|
  +id: string,
  +group: ?{|
    +id: string
  |},
  +managableCustomers: ?$ReadOnlyArray<{|
    +id: string,
    +type: string,
    +name: string,
    +isAdministrator: boolean,
    +hasBogintraAccess: boolean,
    +isNewsletterSubscriber: boolean,
    +longTermToken: ?string,
    +group: ?{|
      +name: string,
      +reference: ?string,
    |},
    +$fragmentRefs: CustomerForm_customer$ref,
  |}>,
  +$fragmentRefs: CustomerForm_viewer$ref & ViewerProfile_viewer$ref,
  +$refType: CustomerList_viewer$ref,
|};
export type CustomerList_viewer$data = CustomerList_viewer;
export type CustomerList_viewer$key = {
  +$data?: CustomerList_viewer$data,
  +$fragmentRefs: CustomerList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CustomerList_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "group",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerGroup",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "managableCustomers",
      "storageKey": null,
      "args": null,
      "concreteType": "Customer",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isAdministrator",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "hasBogintraAccess",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isNewsletterSubscriber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "longTermToken",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "group",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomerGroup",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "reference",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "CustomerForm_customer",
          "args": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CustomerForm_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ViewerProfile_viewer",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d165758eae361d71e2083337515fb4e';
module.exports = node;
