/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CustomerForm_customer$ref = any;
type CustomerForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ViewerProfile_viewer$ref: FragmentReference;
declare export opaque type ViewerProfile_viewer$fragmentType: ViewerProfile_viewer$ref;
export type ViewerProfile_viewer = {|
  +id: string,
  +name: string,
  +type: string,
  +longTermToken: ?string,
  +$fragmentRefs: CustomerForm_customer$ref & CustomerForm_viewer$ref,
  +$refType: ViewerProfile_viewer$ref,
|};
export type ViewerProfile_viewer$data = ViewerProfile_viewer;
export type ViewerProfile_viewer$key = {
  +$data?: ViewerProfile_viewer$data,
  +$fragmentRefs: ViewerProfile_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ViewerProfile_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "longTermToken",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CustomerForm_customer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CustomerForm_viewer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0063902cc81183a159fbcdab9ed71614';
module.exports = node;
