// @flow
import moment from 'moment';
import dk from 'date-fns/locale/da';
import React, { Fragment } from 'react';
import DatePicker from 'react-datepicker';

import { FormattedDate, Icon, Radio } from '..';

/** Link date picker label. */
function UnforwardedLinkDatePickerLabel({
	value,
	onClick,
	customFormat
  }, ref) {
	return (
	  <a
		href="#"
		onClick={evt => { evt.preventDefault(); onClick() }}
		className="standard-color"
		ref={ref}
	  >
		<Icon name="date_range" />
		<FormattedDate customFormat={customFormat} value={value} />
	  </a>
	);
}
  
 const LinkDatePickerLabel = React.forwardRef(UnforwardedLinkDatePickerLabel);
  
  /** Link date picker. */
  function LinkDatePicker({
	minDate,
	maxDate,
	onChange,
	value,
	customFormat
  }: any) {
	return (
		<div className="date-picker-container">
 		<DatePicker
			locale={dk}
			dateFormat={"yyyy-MM-dd" } 
			selected={moment(value).toDate()}
			onChange={value => onChange(moment(value).format('YYYY-MM-DD'))}
			minDate={moment(minDate).toDate()}
			maxDate={maxDate ? moment(maxDate).toDate() : null}
			customInput={<LinkDatePickerLabel customFormat={customFormat} />}
		/>
		</div>
	);
  }
  
  export {
	UnforwardedLinkDatePickerLabel,
	LinkDatePickerLabel,
	LinkDatePicker
  }