/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BaseTitleCard_title$ref = any;
type BaseTitleCard_viewer$ref = any;
type MessageCard_message$ref = any;
type MessageCard_viewer$ref = any;
type NotificationCard_title$ref = any;
type PopularityTitleCard_title$ref = any;
type PopularityTitleCard_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TitlePaginator_query$ref: FragmentReference;
declare export opaque type TitlePaginator_query$fragmentType: TitlePaginator_query$ref;
export type TitlePaginator_query = {|
  +titles: ?{|
    +totalCount: number,
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +activeNotifications: $ReadOnlyArray<string>,
        +relatedMessages: ?$ReadOnlyArray<{|
          +id: string,
          +isActive: boolean,
          +isDeleted: boolean,
          +$fragmentRefs: MessageCard_message$ref,
        |}>,
        +$fragmentRefs: BaseTitleCard_title$ref & PopularityTitleCard_title$ref & NotificationCard_title$ref,
      |}
    |}>,
  |},
  +viewer: {|
    +$fragmentRefs: BaseTitleCard_viewer$ref & PopularityTitleCard_viewer$ref & MessageCard_viewer$ref
  |},
  +$refType: TitlePaginator_query$ref,
|};
export type TitlePaginator_query$data = TitlePaginator_query;
export type TitlePaginator_query$key = {
  +$data?: TitlePaginator_query$data,
  +$fragmentRefs: TitlePaginator_query$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TitlePaginator_query",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "titles"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "orderBy",
      "type": "TitleOrderBy",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "order",
      "type": "Order",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "excludeDigital",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "excludeUnavailable",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "filterBy",
      "type": "[ID!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "published",
      "type": "PublishedFilter",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "mode",
      "type": "SearchMode!",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "onlyFavorites",
      "type": "Boolean!",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "onlyWithNotificationTypes",
      "type": "[CustomerNotificationSubscriptionType!]",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "titles",
      "name": "__TitlePaginator_titles_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "excludeDigital",
          "variableName": "excludeDigital"
        },
        {
          "kind": "Variable",
          "name": "excludeUnavailable",
          "variableName": "excludeUnavailable"
        },
        {
          "kind": "Variable",
          "name": "filterBy",
          "variableName": "filterBy"
        },
        {
          "kind": "Variable",
          "name": "mode",
          "variableName": "mode"
        },
        {
          "kind": "Variable",
          "name": "onlyFavorites",
          "variableName": "onlyFavorites"
        },
        {
          "kind": "Variable",
          "name": "onlyWithNotificationTypes",
          "variableName": "onlyWithNotificationTypes"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "published",
          "variableName": "published"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "TitleConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TitleEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Title",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "activeNotifications",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "relatedMessages",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Message",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "isActive",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "isDeleted",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "MessageCard_message",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "BaseTitleCard_title",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "PopularityTitleCard_title",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "NotificationCard_title",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "viewer",
      "storageKey": null,
      "args": null,
      "concreteType": "Customer",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "BaseTitleCard_viewer",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "PopularityTitleCard_viewer",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "MessageCard_viewer",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '580fd16db018f37973badce48f92303f';
module.exports = node;
