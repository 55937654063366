// @flow
import React from 'react';
import ReactDOM from 'react-dom';

/** Portal container singleton. */
let portalContainer: ?HTMLDivElement = null;

/** Portal props. */
export type PortalProps = {
  children: React$Node,
};

/** Portal. */
export default class Portal extends React.Component<PortalProps> {
  element: HTMLDivElement;

  constructor(props: PortalProps) {
    super(props);

    if (!portalContainer) {
      portalContainer = document.createElement('div');
      portalContainer.setAttribute('bog-portal-container', '');

      // flow-ignore-next-line
      document.body.appendChild(portalContainer);
    }

    this.element = document.createElement('div');
    portalContainer.appendChild(this.element);
  }

  componentWillUnmount() {
    if (portalContainer) {
      portalContainer.removeChild(this.element);
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}
