// @flow
import React from 'react';

/** Fullscreen loader props. */
export type FullscreenLoaderProps = {};

/** Fullscreen loader. */
const FullscreenLoader: React$ComponentType<FullscreenLoaderProps> = function FullscreenLoader(props: FullscreenLoaderProps) {
  return (
    <div className="fullscreen-loader">
      <div className="fullscreen-loader-inner">
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      </div>
    </div>
  );
};

export default FullscreenLoader;
