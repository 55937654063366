// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import 'moment/locale/da';
import classNames from 'classnames';
import { commitMutation, graphql } from 'react-relay';
import DatePicker from 'react-datepicker';

import coverGradientUrl from '../../images/cover-gradient.png';
import { Collapsible, FormattedDate, Icon, withToast, type ToastProps } from '../../components';
import { placeholderCoverImageForTitle, type Viewer } from '../../models';
import { MediaSearchBar, ReviewerSearchBar, withManageSearch, type ManageSearchProps } from '../SearchManager';
import { FilterAdder } from '../SearchManager';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import { NEWS_TYPE_DESCRIPTION, ENTITY_NEWS_TYPE_PREFIX, TITLE_NEWS_TYPE_PREFIX, ONLINE_MEDIA_TYPE_IDS } from '../PublishersEditLayoutOverride/data';
import {ContentAction, ContentPublishingDescriptionHeadline, TitlesDescription, TitleDescription, naturalJoinEntities, } from '../PublishersEditLayoutOverride/utils';
import { Checkbox, Form, Input, Select, Textarea } from '../../components';
import AddNewMediaDataWrapper from '../AddNewMedia'
import AddNewReviewerCard from '../AddNewReviewer'
import {UnforwardedLinkDatePickerLabel,LinkDatePickerLabel,LinkDatePicker } from '../../components/DatePicker';

/** Create review mutation. */
const createReviewMutation = graphql`
mutation AddNewReviewCardcreateReviewMutation($input: CreateReviewInput!) {
  createReview(input: $input) {
    review {
      id
      grade
      
      content
      date
      headline
      quotes
    }
	viewer {
		email
		id
	}
  }
}`;

type AddNewReviewProps = {
	content: any,
	viewer: Viewer,
	shouldDisplayPublishingHouse: any,
} & EnvironmentProps & ToastProps ;

type AddNewReviewState = {
	loading: boolean,
	submitting: boolean,
	error: any,
	extraActions: any,
	reviewerId: string,
	createdBy: string,
	mediaId: string,
	titleIds: string[],
	date: string,
	displayDate: string,
	headline: string,
	underHeading: string,
	content: string,
	url: string,
	quotes: string[],
	quotesCreatedBy: any[],
	grade: number,
	gradingScales: any,
	pickedGradingScaleIndex: number,
	summaryHeadline: string,
	originalContent: string,
	mediaUrl: string,
	showAllGradingScales: boolean,
	primaryQuoteIndex: number,
	reviewerSearchFilters: any,
	mediaSearchFilters: any,
	mediaName:any,
	reviewerName:any,
};


/** Content item. */
class  AddNewReviewCard extends React.Component<AddNewReviewProps, AddNewReviewState>{
	collapsibleChild: any = React.createRef();
	reviewerSearchBar: any = React.createRef();

	state = {
		loading: false,
		submitting: false,
		extraActions: [],
		error: null,
		reviewerId: '',
		createdBy: this.props.viewer?.id || '',
		mediaId: '',
		mediaUrl: '',
		titleIds: [this.props.content.id],
		displayDate: moment().format('DD. MMMM YYYY', 'da'),
		date: moment().format('YYYY-MM-DD'),
		headline: '',
		underHeading: '',
		content: '',
		url: '',
		quotes: [''],
		quotesCreatedBy: [],
		gradingScales: [Object.assign({}, {
			min: 0,
			max: 0,
			symbol: '',
			precision: 1
		})],
		pickedGradingScaleIndex: 0,
		grade: 0,
		summaryHeadline: '',
		originalContent: '',
		primaryQuoteIndex: 0,
		showAllGradingScales: false,
		reviewerSearchFilters: [],
		mediaSearchFilters: [],
		mediaName: '',
		reviewerName: ''
	};

	handleSubmit = () => {
		// evt.preventDefault();
		
		if (this.state.loading) {
			return;
		}

		const {
			environment,
		} = this.props;

		const {
			loading,
			submitting,
			extraActions,
			error,
			reviewerId,
			mediaId,
			titleIds,
			date,
			headline: rawHeadline,
			underHeading: rawUnderHeading,
			content,
			url: rawUrl,
			quotes: rawQuotes,
			quotesCreatedBy: rawQuotesCreatedBy,
			pickedGradingScaleIndex,
			gradingScales,
			summaryHeadline: rawSummaryHeadline,
			originalContent,
			mediaUrl,
			createdBy,
			grade,
			primaryQuoteIndex
		} = this.state;

		if (mediaId.trim() === '') {
			this.setState({
			  error: 'Anmeldelsen skal være tilknyttet et medie.',
			  submitting: false,
			});
			return;
		}

		const headline = rawHeadline.trim();

		if (headline === '') {
			this.setState({
			  error: 'Anmeldelsen skal have en overskrift.',
			  submitting: false,
			});
			return;
		} 

		const underHeading = rawUnderHeading.trim();

		if(underHeading.length > 700) {
			this.setState({
				error: 'Underrubrik eller kort uddrag for langt. Maks. 700 tegn.',
				submitting: false,
			  });
			  return;
		}

		const url = rawUrl.trim();
		if(url) {
			const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
			'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
			const isCorrectUrl = !!pattern.test(url);
			
			if(!isCorrectUrl) {
				this.setState({
					error: 'Webadressen er ikke korrekt (husk http ...)',
					submitting: false,
				});
				return;
			}
		}

		const quotes = [rawQuotes.find((q,i) => i === primaryQuoteIndex)?.trim() || '', ...rawQuotes.filter((q, i) => i != primaryQuoteIndex).map(q => q.trim() || '')];
		const quotesCreatedBy = [rawQuotesCreatedBy.find((q,i) => i === primaryQuoteIndex)?.id || '', ...rawQuotesCreatedBy.filter((q, i) => i != primaryQuoteIndex).map(q => q.id || '')];

		if(quotes.findIndex(q => q.length > 140) >= 0) {
			this.setState({
				error: 'Et eller flere citater er for langt. Maks. 140 tegn. Del evt. op i flere citater.',
				submitting: false,
			  });
			  return;
		}

		if(quotes.length != quotesCreatedBy.length) {
			this.setState({
				error: 'Something went wrong with quotes',
				submitting: false,
			  });
			return;
		}
		const summaryHeadline = rawSummaryHeadline.trim();
		const payload = {
			reviewerId,
			mediaId,
			titleIds,
			date,
			headline,
			underHeading,
			content,
			url,
			quotes,
			quotesCreatedBy,
			gradingScale: gradingScales[pickedGradingScaleIndex],
			grade,
			summaryHeadline,
			originalContent,
			createdBy,
		};

		this.setState({
			submitting: true,
		  }, () => commitMutation(environment, {
			mutation: createReviewMutation,
			variables: {
			  input: {
				clientMutationId: '1',
				...payload,
			  },
			},
 
			onCompleted: (data, errors) => {
			  const { toast } = this.props;
	  
			  if (errors?.length) {
				this.setState({
				  loading: false,
				  submitting: false,
				  error: 'Der er opstået en fejl.',
				});
			  }
			  else {
				toast('Anmeldelsen er oprettet');
	  
				this.onOverrideClose();
			  }
			},
		  }));
	}


	onMediaSubmit = (filters) => {
		const media = filters[0];

		if(media) {
			media["gradingScales"] = media?.gradingScales?.map((gs) => {return { ...gs, symbol: gs.symbol || ''}});
		}
		const grade = media?.gradingScales[0].max === 5 ? 3 : media?.gradingScales[0].max === 6 ? 4 : media?.gradingScales[0].max === 10 ? 7 : media?.gradingScales[0].max >= 100 ? 67 : 0;
		this.setState({mediaName: media?.label || '', mediaId: media?.id || '', mediaUrl: media?.url || '', grade, gradingScales: media?.gradingScales || [], pickedGradingScaleIndex: 0});
		
		if(media?.gradingScales?.length === 1  && media?.gradingScales[0]?.max >= 100){
			const grade = 67;
			this.setState({grade});
		}
	}

	onNewMediaSubmit = (media) => {
		this.setState({mediaSearchFilters: [{
			id: media.id,
			label: `${media.name}`,
			type: 'Reviewer',
			value: media.id,
			gradingScales: media.gradingScales.map((gs) => {return { ...gs, symbol: gs.symbol || ''}}),
		}], reviewerId: media?.id || ''});
		this.onMediaSubmit([media]);
	}

	onReviewerSubmit = (filters) => {
		this.setState({reviewerId: filters[0]?.id || '', reviewerName: filters[0]?.label || ''})
	}

	onNewReviewerSubmit = (reviewer) => {
		this.setState({reviewerSearchFilters: [{
			id: reviewer.id,
			label: `${reviewer.firstName || ''} ${reviewer.lastName || ''}`,
			type: 'Reviewer',
			value: reviewer.id
		}], reviewerId: reviewer?.id || ''});
	}

	onQuoteChange = (newQuote, i) => {
		const quotes = this.state.quotes;
		quotes[i] = newQuote;
		const quotesCreatedBy = [...this.state.quotesCreatedBy];
		quotesCreatedBy[i] = this.props.viewer;
		this.setState({ quotes, quotesCreatedBy });
	}

	onMakePrimaryQuote = (value, i) => {
		this.setState({ primaryQuoteIndex: i });
	}

	onDeleteQuote = (i) => {
		const quotes = this.state.quotes.filter((q, idx) => i != idx);
		const quotesCreatedBy = this.state.quotesCreatedBy.filter((q, idx) => i != idx);
		this.setState({ quotes, quotesCreatedBy });
	}
	
	onGradeInputChange = (idx, hasHalf, e, pickedGradingScaleIndex, setNull?) => {
		//todo clarify set null functionalitY: what happens if you de-select nul stars - because the default value is still 0?!
		if(setNull){
			e.stopPropagation();
		} else {
			e.preventDefault();
			e.stopPropagation();
		}

		const grade = idx + (hasHalf ? 0.5 : 0);
		
		this.setState({grade, pickedGradingScaleIndex});
	}

	onOverrideClose = () => {
		this.collapsibleChild.current.toggleExpanded();
	}

	renderGradingScales = () => {
		if(this.state.gradingScales.length === 1 && this.state.gradingScales[0].max >= 100) {
			return null;
		}
		return (
			<div className={`grading-scales`}>
				<Collapsible.Item itemClassName={'no-cover'} expanded={this.state.showAllGradingScales}
					header={
						<Fragment>
						{this.renderGradingScale(this.state.gradingScales[0], 0)}
						{ this.state.gradingScales.length > 1 && this.state.gradingScales.findIndex((gradingScale, idx) => idx!=0 && gradingScale.max < 100) != -1 ? 
						<a className="standard-color versals add-new-button" onClick={ () => this.setState({showAllGradingScales: !this.state.showAllGradingScales})} >
							<Icon name="add" />
							Vis mere
						</a> : null}
						</Fragment>
					}>
					{this.state.gradingScales.map((gradingScale, idx) => idx!=0 && gradingScale.max < 100 && this.renderGradingScale(gradingScale, idx))}
				</Collapsible.Item>
			</div>
		);
	}

	renderGradingScale = (gradingScale, idx) => {
		const mainGradingScaleMin = gradingScale.min;
		const mainGradingScaleMax = gradingScale.max;
		const precision = gradingScale.precision || 1;
		const formatedPrecision = precision > 0.5 ? 1 : 0.5;
		const symbol = mainGradingScaleMax < 100 && gradingScale.symbol === '' ? 'stars' : gradingScale.symbol;
		const reviewId = this.state.titleIds[0]; // todo double check
		const currentGrade = this.state.grade || 0;
		const closestFormattedGrade = formatedPrecision === 1 ? Math.floor(currentGrade) : Math.floor(currentGrade)+(currentGrade % 1 >= 0.5 ? 0.5 : 0);
		const formatedMax = formatedPrecision === 1 ? mainGradingScaleMax : mainGradingScaleMax * 2;
		const displaySymbol = symbol === 'hearts' ? 'hjerter' : symbol === 'stars' ?  'stjerner' : '';
		let children = [];
	
		if(symbol != null || mainGradingScaleMax < 100) {
			if(formatedPrecision === 0.5) {
				for (let i = mainGradingScaleMin; i < formatedMax; i++) {
					const wholeGrade = Math.floor(i/2 + 0.5);
					const hasHalf = (i/2 + 0.5) % 1 === 0 ? false : true;
					
					const checked = this.state.pickedGradingScaleIndex === idx && closestFormattedGrade === (i+1)/2;
					children.unshift(
						<Fragment key={i}>
							<input onChange={()=>{}} checked={checked} type="radio" id={`${reviewId}-rating-${i}-${formatedMax}`} name={`${symbol}-rate${mainGradingScaleMax}`} value={`${ hasHalf ? wholeGrade + 0.5 : wholeGrade }/${mainGradingScaleMax} ${displaySymbol}`} />
							<label onClick={(e)=> this.onGradeInputChange(wholeGrade, hasHalf, e, idx)} className={`${i} ${hasHalf ? 'half' : ''}`} htmlFor={`${reviewId}-rating-${i}-${formatedMax}`} title={`${wholeGrade}${hasHalf ? ' 1/2' : ''} ${displaySymbol}`}></label>
						</Fragment>
					);
				}
			} else {
				for (let i = mainGradingScaleMin; i < formatedMax; i++) {
					const wholeGrade = i+1;
					const hasHalf = false;
					
					const checked = this.state.pickedGradingScaleIndex === idx && closestFormattedGrade === wholeGrade;
					children.unshift(
						<Fragment key={i}>
							<input onChange={()=>{}} checked={checked} type="radio" id={`${reviewId}-rating-${i}-${formatedMax}`} name={`${symbol}-rate${mainGradingScaleMax}`} value={`${ hasHalf ? wholeGrade + 0.5 : wholeGrade }/${mainGradingScaleMax} ${displaySymbol}`} />
							<label onClick={(e)=> this.onGradeInputChange(wholeGrade, hasHalf, e, idx)} className={`${i} ${hasHalf ? 'half' : ''}`} htmlFor={`${reviewId}-rating-${i}-${formatedMax}`} title={`${wholeGrade}${hasHalf ? ' 1/2' : ''} ${displaySymbol}`}></label>
						</Fragment>
					);
				}
				
			}
		}
		const checked = this.state.pickedGradingScaleIndex === idx && currentGrade === 0;
		return (
			<Fragment key={idx}>
				<div className={`rating editable ${symbol}-rating`}>{children}</div>
				<Checkbox key={`null-${idx}`} label={`Nul ${displaySymbol}`} value={checked} onChange={(val, e) => this.onGradeInputChange(0, false, e, idx, true)} />
			</Fragment>
			
		);
	}

	render() {
		const {
			content,
			viewer,
			shouldDisplayPublishingHouse,
		} = this.props;

		const {
			loading,
			submitting,
			extraActions,
			error,
			reviewerId,
			mediaId,
			titleIds,
			displayDate,
			headline,
			underHeading,
			url,
			date,
			quotes,
			quotesCreatedBy,
			grade,
			gradingScales,
			pickedGradingScaleIndex,
			summaryHeadline,
			originalContent,
			mediaUrl,
			createdBy,
			primaryQuoteIndex,
			reviewerSearchFilters,
			mediaSearchFilters,
			mediaName,
			reviewerName,
		} = this.state;
		const {collapsibleChild, reviewerSearchBar} = this;
	 
		 
		const pickedGradingScale = gradingScales[pickedGradingScaleIndex];
		const { coverImage } = content;

		let coverImageUrl = coverImage?.sizes?.find(({ name }) => name === '450x')?.url || coverImage?.sizes?.filter(url => !!url)[0];

		coverImageUrl = coverImageUrl?.length > 0
			? coverImageUrl
			: null;
	

		return(
			<Collapsible.Item ref={collapsibleChild} 
				header={
					<Fragment>
						<span className="media-icon media-icon media-unknown">?</span>

						<p className="headline1">
							Tilføj ny anmeldelse til 
						</p>
						
						<p className="headline2">
							{content.title}
						</p>

						<ContentPublishingDescriptionHeadline content={content} />

						{coverImageUrl &&
							<img
								className="news-cover right hide-on-small-only"
								src={coverImageUrl} />}

						{coverImageUrl &&
							<img
								className="news-cover-shadow right hide-on-small-only"
								src={coverGradientUrl}
							/>}
					</Fragment>
				}>

				<div className="xs-margin-top">
					<MediaSearchBar filters={mediaSearchFilters} onSubmit={this.onMediaSubmit} />
					<AddNewMediaDataWrapper onSubmit={this.onNewMediaSubmit.bind(this)} />
				</div>

				<div className="lg-margin-top">
					<ReviewerSearchBar filters={reviewerSearchFilters} onSubmit={this.onReviewerSubmit} />
					<AddNewReviewerCard onSubmit={this.onNewReviewerSubmit.bind(this)} />
				</div>

				<Form
					onSubmit={this.handleSubmit}
					error={this.state.error}
					loading={this.state.loading}
					leftActions={this.state.extraActions}
					submitting={submitting}>

					<div className="lg-margin-top">
						<Input value={headline} onChange={headline => this.setState({headline})} autoComplete="off" label="Overskrift" />
					</div>
					
					<Textarea value={underHeading} label="Evt. underrubrik eller kort uddrag" onChange={underHeading => this.setState({ underHeading })}  />
					
					{
						quotes.map((q, i) => {
							return (
								<div className="lg-margin-top bog-review-edit-quote" key={i}>
									<Textarea value={quotes[i]} label={i === primaryQuoteIndex ? 'Tilføj primært citat' : 'Ekstra citat'} onChange={(quote) => this.onQuoteChange(quote, i)}  />
									<Checkbox label="Primært citat" value={i === primaryQuoteIndex} onChange={(value) => this.onMakePrimaryQuote(value, i)} />
									{ <a className="right delete-quote" onClick={() => this.onDeleteQuote(i)}> <Icon name="clear"/> Slet dette citat </a> }
								</div>
							);
						})
					}

					<a className="standard-color versals add-new-button" onClick={ () => this.setState({quotes: [...this.state.quotes, ''], quotesCreatedBy: [...quotesCreatedBy, viewer]})} >
						<Icon name="add" />
						Tilføj citat
					</a>

					{mediaId && gradingScales.length > 0 &&
						<div className="lg-margin-top">
							<p>Vurdering: {grade}/{pickedGradingScale.max ? pickedGradingScale.max : '100'} {pickedGradingScale.symbol === 'hearts' ? 'hjerter' : pickedGradingScale.symbol === 'stars' ?  'stjerner' : ''}</p>
							{this.renderGradingScales()}
						</div>
					}

					<div className="sm-margin-top">
						<Input value={url} onChange={url => this.setState({url})} autoComplete="off" label="Link" />
						{mediaUrl && 
							<a className="standard-color versals add-new-button" target="_blank" href={`https://www.google.dk/search?q=site:${mediaUrl} ${content.title} ${reviewerName}`}>
								<Icon name="link" />
								Søg efter online-udgave
							</a>
						}
						{!mediaUrl && mediaName &&
							<a className="standard-color versals add-new-button" target="_blank" href={`https://www.google.dk/search?q=${mediaName} ${content.title} ${reviewerName}`}>
								<Icon name="link" />
								Søg efter online-udgave
							</a> }
					</div>

					<div className="lg-margin-top review-date-picker">
						<LinkDatePicker
							minDate={1}
							value={date}
							customFormat="DD. MMMM YYYY"
							onChange={(newDate) => this.setState({date: newDate, displayDate: moment(newDate).format('DD. MMMM YYYY', 'da')})}
							/> 
					</div>
				</Form>

			</Collapsible.Item>
		);
	}
}

export default withToast(withEnvironment(AddNewReviewCard));