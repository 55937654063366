// @flow
import React from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import equal from 'fast-deep-equal';
import reviewerPersonIcon from '../../images/reviewer-person.svg';

import { SEARCH_FILTER_TYPE_LABEL_BY_TYPE, SEARCH_FILTER_TYPE_LABEL_BY_COLLECTION_TYPE } from '../../models';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import { SearchBar, type SearchBarFilterSuggestion } from '../../components';
import withManageSearch from './withManageSearch';
import type { ManageSearchProps } from './ManageSearchProps';
import type { SearchFilter } from '../../models';

/** Reviewer search bar props. */
type ReviewerSearchBarProps = EnvironmentProps & {
	onSubmit: (filters: any) => void,
	filters?: ?SearchFilter[],
};
/** Reviewer search bar state. */
type ReviewerSearchBarState = {
  suggestedQuery: ?string,
  suggestions: ?SearchBarFilterSuggestion[],
  query: string,
  filters: SearchFilter[],
  filterLabels: {[id: string]: string},
  filterSubtypes: {[id: string]: string;}
};

/** Reviewer search bar. */
class ReviewerSearchBar extends React.Component<ReviewerSearchBarProps, ReviewerSearchBarState> {
  suggestQuery: ?string = null;
  suggestTimeout: ?Timeout = null;
  state = {
    suggestedQuery: null,
    suggestions: null,
	query: '',
	filters: this.props.filters || [],
	filterLabels: !this.props.filters?.length ? {} : (() => {
		const labels = {};
		const first = this.props.filters ? this.props.filters[0] : {};
		labels[first.id] = first.value;
		return labels;
	})(),
	filterSubtypes: {}
  };

  componentDidMount(){
	if(this.props.filters?.length ){
		this.handleSetFilters(this.props.filters);
	}
}
componentDidUpdate(prevProps){
	if(!equal(prevProps.filters, this.props.filters) && this.props.filters?.length ){
	this.handleSetFilters(this.props.filters);
	}
}
  fetchSuggestions = () => {
    const { environment } = this.props;
    const fetchingQuery = this.state.query.trim();

    if (!fetchingQuery) {
      return;
    }

    fetchQuery(environment, graphql`
query ReviewerSearchBarSuggestionsQuery($search: String!) {
  reviewers(first: 5, search: $search) {
    edges {
      node {
        id
        firstName
		lastName
		media  {
			name
		}
      }
    }
  }
 
}`, { search: fetchingQuery })
      .then(({ reviewers }) => {
        if (this.state.query?.trim() !== fetchingQuery) {
          return;
        }

        const suggestions: SearchBarFilterSuggestion[] = [];
 
		reviewers?.edges?.forEach(({ node: { id, firstName, lastName } }) => { // eslint-disable-line no-unused-expressions
			suggestions.push({
			  filter: {
				id,
				type: 'Reviewer',
				value: '',
				label: `${firstName} ${lastName}`,
			  },
			  description: `${firstName} ${lastName}`,
			  descriptionSuffix: '- anmelder/journalist',
			  imageUrl: reviewerPersonIcon,
			});
		  });
 

        this.setState({
          suggestedQuery: fetchingQuery,
          suggestions,
        });
      });
  }

  handleSetFilters = (filters: ({
    label?: ?string,
    subtype?: ?string,
  } & SearchFilter)[]) => {
    const { filterLabels, filterSubtypes } = this.state;

    // Discover filter labels.
    let newFilterLabels: ?{ [id: string]: string } = null;
    let newFilterSubtypes: ?{ [id: string]: string } = null;

    filters.forEach(({ type, id, label, subtype }) => {
      if (type !== 'QueryString' && label) {
        if (!newFilterLabels) {
          newFilterLabels = {};
        }

        newFilterLabels[id] = label;
      }

      if (type !== 'QueryString' && subtype) {
        if (!newFilterSubtypes) {
          newFilterSubtypes = {};
        }

        newFilterSubtypes[id] = subtype;
      }
    });

    this.setState({
      filters: filters.map(({ type, id, value }) => ({ type, id, value })),
      filterLabels: newFilterLabels
        ? { ...filterLabels, ...newFilterLabels }
        : filterLabels,
      filterSubtypes: newFilterSubtypes
        ? { ...filterSubtypes, ...newFilterSubtypes }
        : filterSubtypes,
    });
 
	this.props.onSubmit(filters);
  }
  
  render() {
    const {
	  query, 
	  suggestedQuery, 
	  suggestions,
	  filters,
	  filterLabels
    } = this.state;
	 

    const { onSubmit } = this.props;

    if (this.suggestQuery !== query) {
      clearTimeout(this.suggestTimeout);
      this.suggestQuery = query;

      if (query?.trim()) {
        this.suggestTimeout = setTimeout(this.fetchSuggestions, 500);
      }
    }

    return (
      <SearchBar
	  	maxChoices={1}
	  	hideSearchIcon={true}
	    extraClass='review-search-bar'
        filters={filters.map(({ subtype, id, type, value }) => ({
          id,
          type,
          value,
          label: type === 'QueryString' ? value : (filterLabels[id] || 'Indlæser...'),
          typeLabel: null
        }))}
        onQueryChange={query => this.setState({ query })}
        onFiltersChange={this.handleSetFilters}
        onSubmit={()=>{}}
        query={query}
        filterSuggestions={suggestedQuery === query?.trim() ? suggestions : null}
		placeholderText='Anmelder'
		moveLabelOnFocus={true}
	  />
    );
  }
}

export default withEnvironment(ReviewerSearchBar);
