// @flow
import React from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Icon from '../Icon';
import FloatingActionButtonItem from './FloatingActionButtonItem';

/** Floating action button props. */
export type FloatingActionButtonProps = {
  children: React$Node,
};

/** Floating action button state. */
type FloatingActionButtonState = {
  open: boolean,
};

/** Floating action button. */
export default class FloatingActionButton extends React.Component<FloatingActionButtonProps, FloatingActionButtonState> {
  static Item = FloatingActionButtonItem;

  state = {
    open: false,
  };

  handleOpenClick = (evt) => {
    evt.preventDefault();
    document.removeEventListener('click', this.handleDocumentClick);

    this.setState({
      open: true,
    }, () => {
      document.addEventListener('click', this.handleDocumentClick);
    });
  }

  handleDocumentClick = (evt) => {
    if (this.state.open) {
      this.setState({
        open: false,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  render() {
    const { children } = this.props;
    const { open } = this.state;

    return (
      <CSSTransition
        classNames="fixed-action-btn"
        in={open}
        timeout={300}
      >
        <div
          className={classNames('fixed-action-btn toolbar direction-top', {
            active: open,
          })}
        >
          <a className="btn-floating btn-large standard-bg-color" href="#" onClick={this.handleOpenClick}>
            <Icon name="more_vert" />
            <div
              className="fab-backdrop"
              style={{
                transform: open ? `scale(${Math.round(window.innerWidth / 40)})` : null,
              }}
            >
            </div>
          </a>

          <ul>
            {children}
          </ul>
        </div>
      </CSSTransition>
    );
  }
}
