// @flow
import React, { Fragment } from 'react';
import { commitMutation, graphql, createFragmentContainer } from 'react-relay';
import copyToClipboard from 'copy-to-clipboard';

import { Icon, Modal, withToast, type ToastProps } from '../../components';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import type { Viewer } from '../../models';
import CustomerForm from './CustomerForm';

/** Create non-department customer mutation. */
const createNonDepartmentCustomerMutation = graphql`
mutation CustomerListCreateNonDepartmentCustomerMutation($input: CreateNonDepartmentCustomerInput!) {
  createNonDepartmentCustomer(input: $input) {
    viewer {
      ...SettingsLayoutOverride_viewer
    }
  }
}`;

/** Create department customer mutation. */
const createDepartmentCustomerMutation = graphql`
mutation CustomerListCreateDepartmentCustomerMutation($input: CreateDepartmentCustomerInput!) {
  createDepartmentCustomer(input: $input) {
    viewer {
      ...SettingsLayoutOverride_viewer
    }
  }
}`;

/** Update non-department customer mutation. */
const updateNonDepartmentCustomerMutation = graphql`
mutation CustomerListUpdateNonDepartmentCustomerMutation($input: UpdateNonDepartmentCustomerInput!) {
  updateNonDepartmentCustomer(input: $input) {
    customer {
      id
      type
      name
      isAdministrator
      hasBogintraAccess
      isNewsletterSubscriber

      group {
        name
        reference
      }

      ...CustomerForm_customer
    }
  }
}`;

/** Update department customer mutation. */
const updateDepartmentCustomerMutation = graphql`
mutation CustomerListUpdateDepartmentCustomerMutation($input: UpdateDepartmentCustomerInput!) {
  updateDepartmentCustomer(input: $input) {
    customer {
      id
      type
      name
      isAdministrator
      hasBogintraAccess
      isNewsletterSubscriber

      group {
        name
        reference
      }

      ...CustomerForm_customer
    }
  }
}`;

/** Delete  customer mutation. */
const deleteCustomerMutation = graphql`
mutation CustomerListDeleteCustomerMutation($input: DeleteCustomerInput!) {
  deleteCustomer(input: $input) {
    viewer {
      ...CustomerList_viewer
    }
  }
}`;

/** Customer list props. */
export type CustomerListProps = {
  viewer: Viewer,
  departmentCustomers: boolean,
} & EnvironmentProps & ToastProps;

/** Customer list state. */
export type CustomerListState = {
  actionState: null | {
    action: 'new',
    loading: boolean,
  } | {
    action: 'edit',
    customer: {},
    loading: boolean,
    error: ?string,
  };
};

/** Customer list. */
class CustomerList extends React.Component<CustomerListProps, CustomerListState> {
  state = {
    actionState: null,
  };

  handleNewClick = (evt) => {
    evt.preventDefault();

    this.setState({
      actionState: {
        action: 'new',
        loading: false,
      },
    });
  }

  handleEditSubmit = (data) => {
    if (!data.email || !data.email.trim() || !data.name || !data.name.trim()) {
      this.setState({
        actionState: {
          ...this.state.actionState,
          error: 'Navn og email-adresse skal udfyldes',
        },
      });

      return;
    }

    this.setState({
      actionState: {
        ...this.state.actionState,
        loading: true,
        error: null,
      },
    }, () => {
      const { environment, departmentCustomers } = this.props;
      const { actionState: { customer } } = this.state;

      commitMutation(environment, {
        mutation: departmentCustomers ? updateDepartmentCustomerMutation : updateNonDepartmentCustomerMutation,
        variables: {
          input: {
            id: customer.id,
            clientMutationId: '1',
            ...data,
          },
        },
        onCompleted: (data, errors) => {
          const { toast } = this.props;

          if (errors?.length) {
            this.setState({
              actionState: {
                ...this.state.actionState,
                loading: false,
                error: 'Der er opstået en fejl.',
              },
            });
          }
          else {
            toast(departmentCustomers ? 'Butikken/afdelingen er opdateret' : 'Brugeren er opdateret');

            this.setState({
              actionState: null,
            });
          }
        },
      });
    });
  }

  handleNewSubmit = (data) => {
    if (!data.email || !data.email.trim() || !data.name || !data.name.trim()) {
      this.setState({
        actionState: {
          ...this.state.actionState,
          error: 'Navn og email-adresse skal udfyldes',
        },
      });

      return;
    }

    this.setState({
      actionState: {
        ...this.state.actionState,
        loading: true,
        error: null,
      },
    }, () => {
      const { environment, departmentCustomers, viewer } = this.props;

      commitMutation(environment, {
        mutation: departmentCustomers ? createDepartmentCustomerMutation : createNonDepartmentCustomerMutation,
        variables: {
          input: {
            groupId: viewer.group.id,
            clientMutationId: '1',
            ...data,
          },
        },
        onCompleted: (data, errors) => {
          const { toast } = this.props;

          if (errors?.length) {
            this.setState({
              actionState: {
                ...this.state.actionState,
                loading: false,
                error: 'Der er opstået en fejl.',
              },
            });
          }
          else {
            toast(departmentCustomers ? 'Butikken/afdelingen er oprettet' : 'Brugeren er oprettet');

            this.setState({
              actionState: null,
            });
          }
        },
      });
    });
  }

  handleDelete = () => {
    this.setState({
      actionState: {
        ...this.state.actionState,
        loading: true,
        error: null,
      },
    }, () => {
      const { environment, departmentCustomers } = this.props;
      const { actionState: { customer } } = this.state;

      commitMutation(environment, {
        mutation: deleteCustomerMutation,
        variables: {
          input: {
            id: customer.id,
            clientMutationId: '1',
          },
        },
        onCompleted: (data, errors) => {
          const { toast } = this.props;

          if (errors?.length) {
            this.setState({
              actionState: {
                ...this.state.actionState,
                loading: false,
                error: 'Der er opstået en fejl.',
              },
            });
          }
          else {
            toast(departmentCustomers ? 'Butikken/afdelingen er slettet' : 'Brugeren er slettet');

            this.setState({
              actionState: null,
            });
          }
        },
      });
    });
  }

  handleCopyEditLongTermToken = (evt) => {
    evt.preventDefault();

    const { toast } = this.props;
    const { actionState: { customer: { longTermToken } } } = this.state;

    copyToClipboard(`https://bogintra.dk/login?token=${encodeURIComponent(longTermToken)}`);
    toast('Permanent link til Bogintra er kopieret til din udklipsholder. Indsæt med Ctrl + V');
  }

  render() {
    const { actionState } = this.state;
    const { departmentCustomers, viewer } = this.props;
    const singular = departmentCustomers ? 'butik/afdeling' : 'bruger';
    const plural = departmentCustomers ? 'butikker/afdelinger' : 'brugere';

    return (
      <Fragment>
        <div className="section-headline">
          Rediger {plural}
        </div>

        {actionState?.action === 'new' &&
         <Modal
           open={true}
           onClose={() => !actionState.loading && this.setState({ actionState: null })}
         >
           <div className="modal-content">
             <div className="modal-headline">
               Opret ny {singular}
             </div>

             <CustomerForm
               viewer={viewer}
               customer={null}
               newIsDepartment={departmentCustomers}
               onSubmit={this.handleNewSubmit}
               loading={actionState.loading}
               error={actionState.error}
             />
           </div>
         </Modal>}

        {actionState?.action === 'edit' &&
         <Modal
           open={true}
           onClose={() => !actionState.loading && this.setState({ actionState: null })}
         >
           <div className="modal-content">
             <div className="modal-headline">
               Rediger {singular}

               {departmentCustomers &&
               actionState.customer.longTermToken &&
               <a
                 href="#"
                 onClick={this.handleCopyEditLongTermToken}
                 className="right"
               >
                 <i className="material-icons standard-color tooltipped" data-rh-at="top" data-rh="Kopier permanent link til bogintra">link</i>
               </a>}
             </div>

             <CustomerForm
               viewer={viewer}
               customer={actionState.customer}
               onSubmit={this.handleEditSubmit}
               onDelete={this.handleDelete}
               loading={actionState.loading}
               error={actionState.error}
             />
           </div>
         </Modal>}

        <div className="card">
          <a
            href="#"
            className="btn-floating halfway-fab waves-effect waves-light white modal-trigger"
            onClick={this.handleNewClick}
          >
            <Icon name="add" className="black-text" />
          </a>

          <table className="highlight responsive-table">
            <thead>
              <tr>
                <th></th>
                <th>Navn</th>
                <th>{departmentCustomers ? 'Boghandlernr.' : 'Butik/afdeling'}</th>
                <th>Nyheds­brev</th>
                <th>Bogintra-adgang</th>
                <th>Admin­i­strator</th>
              </tr>
            </thead>
            <tbody>
              {viewer.managableCustomers?.filter(c => c.id !== viewer.id && (c.type === 'DEPARTMENT') === departmentCustomers).map(customer =>
                <tr key={customer.id}>
                  <td>
                    <a
                      href="#"
                      onClick={evt => {
                        evt.preventDefault();
                        this.setState({
                          actionState: {
                            action: 'edit',
                            loading: false,
                            customer,
                            error: null,
                          },
                        });
                      }}
                    >
                      <Icon name="edit" className="standard-color edit-icon" />
                    </a>
                  </td>
                  <td>{customer.name}</td>
                  <td>{departmentCustomers ? customer.group.reference : customer.group.name}</td>
                  <td className="leftspace12">{customer.isNewsletterSubscriber && <Icon name="check" />}</td>
                  <td>{customer.hasBogintraAccess && <Icon name="check" />}</td>
                  <td>{customer.isAdministrator && <Icon name="check" />}</td>
                </tr>)}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

export default createFragmentContainer(
  withToast(withEnvironment(CustomerList)), {
    viewer: graphql`
fragment CustomerList_viewer on Customer {
  id

  group {
    id
  }

  managableCustomers {
    id
    type
    name
    isAdministrator
    hasBogintraAccess
    isNewsletterSubscriber
    longTermToken

    group {
      name
      reference
    }

    ...CustomerForm_customer
  }

  ...CustomerForm_viewer
  ...ViewerProfile_viewer
}`,
  }
);
