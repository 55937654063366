// @flow
import React, { Fragment } from 'react';

import { Icon, Input } from '../../components';

/** Message link. */
export type MessageLink = {
  url: string,
  description: string,
};

/** Message links sub-form props. */
export type MessageLinksProps = {
  value: MessageLink[],
  onChange: (links: MessageLink[]) => any,
};

/** Message links sub-form. */
export default function MessageLinks({
  value: propValue,
  onChange,
}: MessageLinksProps) {
  const value = propValue.length ? propValue : [{
    url: '',
    description: '',
  }];

  return (
    <Fragment>
      {value.map(({ url, description }, idx) =>
        <div className="card message-link-card" key={idx}>
          {(idx > 0 || url || description) &&
         <a
           className="btn-floating halfway-fab waves-effect waves-light red"
           style={idx === value.length - 1 && value.length < 3 ? { right: '72px' } : null}
           onClick={() => onChange(value.filter((_, i) => i !== idx))}
         >
           <Icon name="clear" />
         </a>}

          {idx === value.length - 1 &&
                      value.length < 3 &&
                      <a
                        className="btn-floating halfway-fab waves-effect waves-light white"
                        onClick={() => onChange([...value, {
                          description: '',
                          url: '',
                        }])}
                      >
                        <Icon name="add" className="black-text" />
                      </a>}

          <Input
            label="Web-adresse"
            value={url}
            type="url"
            onChange={url => onChange(value.map((l, i) => i === idx ? { ...l, url } : l))}
          />

          <Input
            label="Kort beskrivelse"
            value={description}
            maxLength={20}
            onChange={description => onChange(value.map((l, i) => i === idx ? { ...l, description } : l))}
          />
        </div>
      )}
    </Fragment>
  );
}

/** Deserialize message links. */
MessageLinks.deserialize = function (value: MessageLink[]): MessageLink[] {
  return value;
};

/** Serialize message links. */
MessageLinks.serialize = function (value: MessageLink[]): MessageLink[] {
  return value
    .map(({ url, description }) => ({ url: url.trim(), description: description.trim() }))
    .filter(({ url, description }) => !!url && !!description);
};
