// @flow
import React, { Fragment } from 'react';

import { Icon, Input } from '../../components';

/** Message attachment. */
export type MessageAttachment = {
  fileUpload: ?File,
  file: ?{
    id: string,
    filename: string,
  },
  description: string,
};

/** Message attachments sub-form props. */
export type MessageAttachmentsProps = {
  value: MessageAttachment[],
  onChange: (attachments: MessageAttachment[]) => any,
};

/** Message attachments sub-form. */
export default function MessageAttachments({
  value: propValue,
  onChange,
}: MessageAttachmentsProps) {
  const value = propValue.length ? propValue : [{
    url: '',
    file: null,
    fileUpload: null,
  }];

  return (
    <Fragment>
      {value.map(({ description, file, fileUpload }, idx) =>
        <div className="card message-attachment-card" key={idx}>
          {(idx > 0 || file || fileUpload || description) &&
         <a
           className="btn-floating halfway-fab waves-effect waves-light red"
           style={idx === value.length - 1 && value.length < 3 ? { right: '72px' } : null}
           onClick={() => onChange(value.filter((_, i) => i !== idx))}
         >
           <Icon name="clear" />
         </a>}

          {idx === value.length - 1 &&
                      value.length < 3 &&
                      <a
                        className="btn-floating halfway-fab waves-effect waves-light white"
                        onClick={() => onChange([...value, {
                          description: '',
                          file: null,
                          fileUpload: null,
                        }])}
                      >
                        <Icon name="add" className="black-text" />
                      </a>}

          <div className="file-field input-field">
            <div className="btn white">
              <span className="black-text">TILFØJ FIL</span>
              <input
                type="file"
                onChange={evt => {
                  const fileUpload = event.target.files[0];

                  if (!fileUpload) {
                    return;
                  }

                  if (fileUpload.size > 50 * 1024 * 1024) {
                    alert('Filer må maksimalt være 50 MB');
                    return;
                  }

                  onChange(value.map((l, i) =>
                    i === idx
                      ? {
                        ...l,
                        fileUpload,
                        file: null,
                        description: l.description || fileUpload.name.substring(0, 20),
                      }
                      : l
                  ));
                }}
              />
            </div>
            <div className="file-path-wrapper">
              <input
                className="file-path validate"
                type="text"
                value={fileUpload?.name || file?.filename || ' '}
                readOnly
                disabled
              />
            </div>
          </div>

          <Input
            label="Kort beskrivelse"
            value={description}
            maxLength={20}
            onChange={description => onChange(value.map((l, i) => i === idx ? { ...l, description } : l))}
          />
        </div>
      )}
    </Fragment>
  );
}

/** Deserialize message attachments. */
MessageAttachments.deserialize = function (value: MessageAttachment[]): MessageAttachment[] {
  return value.map(({ description, file }) => ({
    description,
    file: {
      id: file.id,
      filename: file.filename,
    },
    fileUpload: null,
  }));
};

/** Serialize message attachments for creation. */
MessageAttachments.serializeForCreate = function (value: MessageAttachment[]): {
  attachments: {
    file: null,
    description: string,
  }[],
  uploadables: { [string]: File },
} {
  const uploadables = {};
  const attachments = value
    .filter(({ fileUpload }) => !!fileUpload)
    .map(({ fileUpload, description }, idx) => {
      const fileRef = `input.attachments.${idx}.file`;
      uploadables[fileRef] = fileUpload;

      return {
        file: null,
        description: description.trim() || fileUpload.name.substring(0, 20),
      };
    });

  return {
    attachments,
    uploadables,
  };
};

/** Serialize message attachments for updating. */
MessageAttachments.serializeForUpdate = function (value: MessageAttachment[]): MessageAttachment[] {
  const uploadables = {};
  const attachments = value
    .filter(({ file, fileUpload }) => fileUpload || file)
    .map(({ file, fileUpload, description }, idx) => {
      if (fileUpload) {
        const fileRef = `input.attachments.${idx}.file`;
        uploadables[fileRef] = fileUpload;

        return {
          file: null,
          fileId: null,
          description: description.trim() || fileUpload.name.substring(0, 20),
        };
      }
      else {
        return {
          file: null,
          fileId: file.id,
          description: description.trim() || file.filename.substring(0, 20),
        };
      }
    });

  return {
    attachments,
    uploadables,
  };
};
