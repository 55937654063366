// @flow
import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import FeaturesContext from './FeaturesContext';
import type { FeaturesProps } from './FeaturesProvider';

export default function withMainLayoutOverride<Props: FeaturesProps>(Component: React$ComponentType<Props>): React$ComponentType<$Diff<Props, FeaturesProps>> {
  function C(props) {
    return (
      <FeaturesContext.Consumer>
        {searchSettingsContext => <Component {...props} {...searchSettingsContext} />}
      </FeaturesContext.Consumer>
    );
  }

  C.WrappedComponent = Component;
  C.displayName = `withFeatures(${Component.displayName || Component.name || 'unnamed'})`;

  return hoistStatics(C, Component);
}
