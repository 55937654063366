/**
 * @flow
 * @relayHash b0a2a07e4533fa131ee95551fccc8628
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NotificationSubscriptionList_viewer$ref = any;
export type CategoryGroup = "BIOGRAFI" | "BOERN" | "DIGTE" | "ERHVERV" | "FAGLITTERATUR" | "FAGLITTERATUR_BOERN" | "FRITID" | "KALENDERE_AAR" | "KORT" | "KRIMI" | "KULTUR" | "KUNST" | "LEKSIKA" | "MAD" | "MALE" | "MUSIK_FILM" | "NATUR" | "NATURVIDENSKAB" | "ROMANER" | "SKOENLITTERATUR" | "SKOLE" | "SUNDHED" | "TEGNESERIER" | "UNGDOM" | "%future added value";
export type UpdateCustomerNotificationSubscriptionInput = {|
  notification: string,
  clientMutationId: string,
  id: string,
  categoryGroup?: ?CategoryGroup,
  description: string,
|};
export type NotificationSubscriptionListUpdateNotificationSubscriptionMutationVariables = {|
  input: UpdateCustomerNotificationSubscriptionInput
|};
export type NotificationSubscriptionListUpdateNotificationSubscriptionMutationResponse = {|
  +updateCustomerNotificationSubscription: ?{|
    +viewer: ?{|
      +$fragmentRefs: NotificationSubscriptionList_viewer$ref
    |}
  |}
|};
export type NotificationSubscriptionListUpdateNotificationSubscriptionMutation = {|
  variables: NotificationSubscriptionListUpdateNotificationSubscriptionMutationVariables,
  response: NotificationSubscriptionListUpdateNotificationSubscriptionMutationResponse,
|};
*/


/*
mutation NotificationSubscriptionListUpdateNotificationSubscriptionMutation(
  $input: UpdateCustomerNotificationSubscriptionInput!
) {
  updateCustomerNotificationSubscription(input: $input) {
    viewer {
      ...NotificationSubscriptionList_viewer
      id
    }
  }
}

fragment NotificationSubscriptionForm_notificationSubscription on CustomerNotificationSubscription {
  categoryGroup
  description
  id
  isDefault
  notification
  type
}

fragment NotificationSubscriptionList_viewer on Customer {
  id
  name
  notificationSubscriptions {
    id
    categoryGroup
    description
    isDefault
    notification
    type
    ...NotificationSubscriptionForm_notificationSubscription
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCustomerNotificationSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NotificationSubscriptionListUpdateNotificationSubscriptionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateCustomerNotificationSubscription",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomerNotificationSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "NotificationSubscriptionList_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NotificationSubscriptionListUpdateNotificationSubscriptionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateCustomerNotificationSubscription",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomerNotificationSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "notificationSubscriptions",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomerNotificationSubscription",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "categoryGroup",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isDefault",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notification",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "NotificationSubscriptionListUpdateNotificationSubscriptionMutation",
    "id": null,
    "text": "mutation NotificationSubscriptionListUpdateNotificationSubscriptionMutation(\n  $input: UpdateCustomerNotificationSubscriptionInput!\n) {\n  updateCustomerNotificationSubscription(input: $input) {\n    viewer {\n      ...NotificationSubscriptionList_viewer\n      id\n    }\n  }\n}\n\nfragment NotificationSubscriptionForm_notificationSubscription on CustomerNotificationSubscription {\n  categoryGroup\n  description\n  id\n  isDefault\n  notification\n  type\n}\n\nfragment NotificationSubscriptionList_viewer on Customer {\n  id\n  name\n  notificationSubscriptions {\n    id\n    categoryGroup\n    description\n    isDefault\n    notification\n    type\n    ...NotificationSubscriptionForm_notificationSubscription\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '008e6ca4ccde2266978f907198dad396';
module.exports = node;
