// @flow
import React from 'react';
import { Environment } from 'relay-runtime';
import hoistStatics from 'hoist-non-react-statics';

import EnvironmentContext from './EnvironmentContext';

export type EnvironmentProps = {
  environment: Environment
};

/**
 * With environment.
 *
 * Higher-order components that passes the global Relay environment into the wrapped component.
 */
export default function withEnvironment<Props: EnvironmentProps>(Component: React$ComponentType<Props>): React$ComponentType<$Diff<Props, EnvironmentProps>> {
  const C = (props) => (
    <EnvironmentContext.Consumer>
      {environment => <Component environment={environment} {...props} />}
    </EnvironmentContext.Consumer>
  );

  C.WrappedComponent = Component;
  C.displayName = `withEnvironment(${Component.displayName || Component.name || 'unnamed'})`;

  return hoistStatics(C, Component);
}
