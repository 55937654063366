// @flow
import React from 'react';

/** Form props. */
export type FormProps = {
  children: React$Node,
  leftActions?: React$Node,
  error?: ?string,
  onSubmit?: ?() => any,
  submitLabel?: ?string,
  submitting?: ?boolean,
};

/** Form. */
const Form: React$ComponentType<FormProps> = function Form({
  children,
  error,
  leftActions,
  onSubmit,
  submitLabel,
  submitting,
}: FormProps) {
  return (
    <form
      onSubmit={evt => { evt.preventDefault(); onSubmit && onSubmit() }}
    >
      {children}

      <div className="row bottomrow">
        <div className="col s8">
          {leftActions}
          {error &&
           <div className="red-text">
             {error}
           </div>}
        </div>
        <div className="col right">
          <button
            type="submit"
            className="waves-effect waves-light btn-large right standard-bg-color"
            disabled={submitting}
          >
            {submitLabel || 'GODKEND'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
