/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FavoriteStar_viewer$ref = any;
type PublicationCard_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BaseTitleCard_viewer$ref: FragmentReference;
declare export opaque type BaseTitleCard_viewer$fragmentType: BaseTitleCard_viewer$ref;
export type BaseTitleCard_viewer = {|
  +favoriteIds: ?$ReadOnlyArray<string>,
  +$fragmentRefs: FavoriteStar_viewer$ref & PublicationCard_viewer$ref,
  +$refType: BaseTitleCard_viewer$ref,
|};
export type BaseTitleCard_viewer$data = BaseTitleCard_viewer;
export type BaseTitleCard_viewer$key = {
  +$data?: BaseTitleCard_viewer$data,
  +$fragmentRefs: BaseTitleCard_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "BaseTitleCard_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "favoriteIds",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "FavoriteStar_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PublicationCard_viewer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8f72ad5c4847b887dc1a94e898ef5ea2';
module.exports = node;
