/**
 * @flow
 * @relayHash cfc01d567b3a39da469d983d552c6ae0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NotificationSubscriptionList_viewer$ref = any;
export type DeleteCustomerNotificationSubscriptionInput = {|
  clientMutationId: string,
  id: string,
|};
export type NotificationSubscriptionListDeleteNotificationSubscriptionMutationVariables = {|
  input: DeleteCustomerNotificationSubscriptionInput
|};
export type NotificationSubscriptionListDeleteNotificationSubscriptionMutationResponse = {|
  +deleteCustomerNotificationSubscription: ?{|
    +viewer: ?{|
      +$fragmentRefs: NotificationSubscriptionList_viewer$ref
    |}
  |}
|};
export type NotificationSubscriptionListDeleteNotificationSubscriptionMutation = {|
  variables: NotificationSubscriptionListDeleteNotificationSubscriptionMutationVariables,
  response: NotificationSubscriptionListDeleteNotificationSubscriptionMutationResponse,
|};
*/


/*
mutation NotificationSubscriptionListDeleteNotificationSubscriptionMutation(
  $input: DeleteCustomerNotificationSubscriptionInput!
) {
  deleteCustomerNotificationSubscription(input: $input) {
    viewer {
      ...NotificationSubscriptionList_viewer
      id
    }
  }
}

fragment NotificationSubscriptionForm_notificationSubscription on CustomerNotificationSubscription {
  categoryGroup
  description
  id
  isDefault
  notification
  type
}

fragment NotificationSubscriptionList_viewer on Customer {
  id
  name
  notificationSubscriptions {
    id
    categoryGroup
    description
    isDefault
    notification
    type
    ...NotificationSubscriptionForm_notificationSubscription
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteCustomerNotificationSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NotificationSubscriptionListDeleteNotificationSubscriptionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteCustomerNotificationSubscription",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCustomerNotificationSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "NotificationSubscriptionList_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NotificationSubscriptionListDeleteNotificationSubscriptionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteCustomerNotificationSubscription",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCustomerNotificationSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "notificationSubscriptions",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomerNotificationSubscription",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "categoryGroup",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isDefault",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notification",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "NotificationSubscriptionListDeleteNotificationSubscriptionMutation",
    "id": null,
    "text": "mutation NotificationSubscriptionListDeleteNotificationSubscriptionMutation(\n  $input: DeleteCustomerNotificationSubscriptionInput!\n) {\n  deleteCustomerNotificationSubscription(input: $input) {\n    viewer {\n      ...NotificationSubscriptionList_viewer\n      id\n    }\n  }\n}\n\nfragment NotificationSubscriptionForm_notificationSubscription on CustomerNotificationSubscription {\n  categoryGroup\n  description\n  id\n  isDefault\n  notification\n  type\n}\n\nfragment NotificationSubscriptionList_viewer on Customer {\n  id\n  name\n  notificationSubscriptions {\n    id\n    categoryGroup\n    description\n    isDefault\n    notification\n    type\n    ...NotificationSubscriptionForm_notificationSubscription\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '81390fd95d1009f16d5bdf0cfdfede26';
module.exports = node;
