// @flow
import React, { Fragment } from 'react';
import anim from 'animejs';

import DropdownContent from './DropdownContent';
import DropdownItem from './DropdownItem';
import DropdownDivider from './DropdownDivider';
import { getDropdownPosition, stylesFromDropdownPosition } from './utils';

/** Dropdown props. */
export type DropdownProps = {
  /** Trigger render. */
  trigger: ({ toggleDropdown: () => any, ref: { current: null | HTMLElement } }) => React$Node,

  /** Dropdown content. */
  children: React$Node,

  /** Cover trigger. Default false. */
  coverTrigger?: ?boolean,

  /** Alignment. Default left. */
  alignment?: ?('left' | 'right' | 'top' | 'bottom'),

  /** Auto-focus. Default true. */
  autoFocus?: ?boolean,

  /** Close on click. Default true. */
  closeOnClick?: ?boolean,
};

/** Dropdown. */
export default class Dropdown extends React.Component<DropdownProps> {
  static Divider = DropdownDivider;
  static Item = DropdownItem;

  triggerRef: React$ElementRef<HTMLElement> = React.createRef();
  contentRef: React$ElementRef<HTMLULElement> = React.createRef();

  expanded = false;
  toggling = false;
  positionInfo = null;
  positionStyle = stylesFromDropdownPosition(null);

  toggleExpanded = (evt?: ?Event) => {
    if (evt) {
      evt.preventDefault();
    }

    // Animations are modeled after https://github.com/Dogfalo/materialize/blob/v1-dev/js/dropdown.js
    const nextExpanded = !this.expanded;
    const { contentRef: { current: content }, triggerRef: { current: trigger } } = this;
    const { alignment, autoFocus, coverTrigger } = this.props;
    this.expanded = nextExpanded;
    this.toggling = true;

    if (!content || !trigger) {
      return;
    }

    anim.remove(content);

    if (nextExpanded) {
      // * Opening.

      // Display the dropdown.
      content.style.display = 'block';
      content.style.transform = '';

      // Determine the position of the dropdown.
      this.positionInfo = getDropdownPosition({
        triggerNode: trigger,
        contentNode: content,
        coverTrigger,
        alignment,
      });
      this.positionStyle = stylesFromDropdownPosition(this.positionInfo);

      // flow-ignore-next-line
      Object.entries(this.positionStyle).map(([key, value]) => { content.style[key] = value === null ? '' : value });

      // Animate the opening.
      anim({
        targets: content,
        opacity: {
          value: [0, 1],
          easing: 'easeOutQuad',
        },
        scaleX: [0.3, 1],
        scaleY: [0.3, 1],
        duration: 150,
        easing: 'easeOutQuint',
        complete: (anim) => {
          if (autoFocus !== false) {
            content.focus();
          }
        },
      });
    }
    else {
      // * Closing.

      // Reset styling.
      this.positionInfo = null;
      this.positionStyle = stylesFromDropdownPosition(null);

      // Animate the closing.
      anim({
        targets: content,
        opacity: {
          value: 0,
          easing: 'easeOutQuint',
        },
        scaleX: 0.3,
        scaleY: 0.3,
        duration: 250,
        easing: 'easeOutQuint',
        complete: (anim) => {
          content.style.display = '';
          // flow-ignore-next-line
          Object.entries(this.positionStyle).map(([key, value]) => { content.style[key] = value === null ? '' : value });
        },
      });
    }

    setTimeout(() => { this.toggling = false }, 1);
  }

  handleBodyClick = (evt: MouseEvent) => {
    // flow-ignore-next-line
    if (!this.toggling && this.expanded && !this.contentRef.current?.contains(evt.target)) {
      this.toggleExpanded();
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleBodyClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBodyClick, false);
  }

  handleContentClick = () => {
    if (this.props.closeOnClick !== false) {
      this.toggleExpanded();
    }
  }

  render() {
    const { children, trigger } = this.props;

    return (
      <Fragment>
        {trigger({ toggleDropdown: this.toggleExpanded, ref: this.triggerRef })}

        <DropdownContent>
          <ul
            className="dropdown-content"
            ref={this.contentRef}
            style={({
              ...this.positionStyle,
              display: this.expanded ? 'block' : null,
              opacity: this.expanded ? 1 : null,
            })}
            onClick={this.handleContentClick}
          >
            {children}
          </ul>
        </DropdownContent>
      </Fragment>
    );
  }
}
