/**
 * @flow
 * @relayHash 1c5fd2d18946e9e743b38e238c653dc5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CustomerList_viewer$ref = any;
export type DeleteCustomerInput = {|
  id: string,
  clientMutationId: string,
|};
export type CustomerListDeleteCustomerMutationVariables = {|
  input: DeleteCustomerInput
|};
export type CustomerListDeleteCustomerMutationResponse = {|
  +deleteCustomer: ?{|
    +viewer: ?{|
      +$fragmentRefs: CustomerList_viewer$ref
    |}
  |}
|};
export type CustomerListDeleteCustomerMutation = {|
  variables: CustomerListDeleteCustomerMutationVariables,
  response: CustomerListDeleteCustomerMutationResponse,
|};
*/


/*
mutation CustomerListDeleteCustomerMutation(
  $input: DeleteCustomerInput!
) {
  deleteCustomer(input: $input) {
    viewer {
      ...CustomerList_viewer
      id
    }
  }
}

fragment CustomerForm_customer on Customer {
  id
  name
  email
  phoneNumber
  position
  isAdministrator
  hasBogintraAccess
  isNewsletterSubscriber
  address
  postalCode
  city
  type
  group {
    id
    reference
  }
}

fragment CustomerForm_viewer on Customer {
  id
  isAdministrator
  group {
    id
    name
    descendentGroups {
      id
      name
    }
  }
}

fragment CustomerList_viewer on Customer {
  id
  group {
    id
  }
  managableCustomers {
    id
    type
    name
    isAdministrator
    hasBogintraAccess
    isNewsletterSubscriber
    longTermToken
    group {
      name
      reference
      id
    }
    ...CustomerForm_customer
  }
  ...CustomerForm_viewer
  ...ViewerProfile_viewer
}

fragment ViewerProfile_viewer on Customer {
  id
  name
  type
  longTermToken
  ...CustomerForm_customer
  ...CustomerForm_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteCustomerInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reference",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdministrator",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasBogintraAccess",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isNewsletterSubscriber",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "longTermToken",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phoneNumber",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "position",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "address",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "postalCode",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CustomerListDeleteCustomerMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteCustomer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCustomerPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "CustomerList_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CustomerListDeleteCustomerMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteCustomer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCustomerPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "group",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomerGroup",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "descendentGroups",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomerGroup",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  },
                  (v4/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "managableCustomers",
                "storageKey": null,
                "args": null,
                "concreteType": "Customer",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "group",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomerGroup",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ]
              },
              (v6/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CustomerListDeleteCustomerMutation",
    "id": null,
    "text": "mutation CustomerListDeleteCustomerMutation(\n  $input: DeleteCustomerInput!\n) {\n  deleteCustomer(input: $input) {\n    viewer {\n      ...CustomerList_viewer\n      id\n    }\n  }\n}\n\nfragment CustomerForm_customer on Customer {\n  id\n  name\n  email\n  phoneNumber\n  position\n  isAdministrator\n  hasBogintraAccess\n  isNewsletterSubscriber\n  address\n  postalCode\n  city\n  type\n  group {\n    id\n    reference\n  }\n}\n\nfragment CustomerForm_viewer on Customer {\n  id\n  isAdministrator\n  group {\n    id\n    name\n    descendentGroups {\n      id\n      name\n    }\n  }\n}\n\nfragment CustomerList_viewer on Customer {\n  id\n  group {\n    id\n  }\n  managableCustomers {\n    id\n    type\n    name\n    isAdministrator\n    hasBogintraAccess\n    isNewsletterSubscriber\n    longTermToken\n    group {\n      name\n      reference\n      id\n    }\n    ...CustomerForm_customer\n  }\n  ...CustomerForm_viewer\n  ...ViewerProfile_viewer\n}\n\nfragment ViewerProfile_viewer on Customer {\n  id\n  name\n  type\n  longTermToken\n  ...CustomerForm_customer\n  ...CustomerForm_viewer\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c7d46acf018c899d53c056cf27c71bec';
module.exports = node;
