// @flow
import React from 'react';

export type DropdownDividerProps = {};

const DropdownDivider: React$ComponentType<DropdownDividerProps> = function DropdownDivider() {
  return <li className="divider" tabIndex={-1} />;
};

export default DropdownDivider;
