// @flow
import React, { Fragment } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type CustomerNotificationSubscriptionNotification } from '../../models';
import { Collapsible, Icon } from '../../components';
import { withMainLayoutOverride, type MainLayoutOverrideProps } from '../MainLayoutOverrideManager';
import type { NotificationCard_title } from './__generated__/NotificationCard_title.graphql';

const DURATION_DESCRIPTIONS = {
  '12m': '12 måneder',
  '14d': '2 uger',
  '24h': '24 timer',
  '24m': '24 måneder',
  '28d': '4 uger',
  '2d': '2 dage',
  '6m': '6 måneder',
  '7d': '7 dage',
};

/** Notification card props. */
export type NotificationCardProps = {
  title: NotificationCard_title,
  notification: CustomerNotificationSubscriptionNotification,
} | MainLayoutOverrideProps;

/**
 * Notification card.
 *
 * Collapsible item that contains the contents of a given notification.
 */
function NotificationCard({
  notification,
  setMainLayoutOverride,
  title,
}: NotificationCardProps) {
  const { authors, hasMoreAuthors } = title;

  return (
    <Collapsible.Item
      itemClassName="message notification"
      header={
        <Fragment>
          <span className="media-icon white">
            <Icon name="notifications_active" />
          </span>
          <p className="headline2">
            Notifikation om<br />
            {{
              BESTSELLER: 'potentiel bestseller',
              SUDDEN_RISE: 'pludselig stigning i popularitet',
              STEADYSELLER: 'steadyseller',
            }[notification.type]}
          </p>
          <div className="article-container">
            <p>
                              "{title.title}"

              {authors &&
                               authors.length > 0 &&
                               <Fragment>
                                 {' af '}
                                 {authors.map((author, idx) =>
                                   <Fragment key={idx}>
                                     {idx > 0 && ', '}
                                     {author.name}
                                   </Fragment>)}

                                 {hasMoreAuthors && ' m.fl.'}
                               </Fragment>}

              {' '}

              {notification.type === 'BESTSELLER' &&
                               `har i de seneste ${DURATION_DESCRIPTIONS[notification.sustainedDuration]} haft en popularitet på ${notification.minScore.toLocaleString('da-DK')} eller mere!`}

              {notification.type === 'SUDDEN_RISE' &&
                               `er indenfor de sidste ${DURATION_DESCRIPTIONS[notification.overDuration]} steget i popularitet med mere end ${notification.percentageRise.toLocaleString('da-DK')} procent fra en popularitet på ${notification.minBaseScore.toLocaleString('da-DK')} eller mere!`}

              {notification.type === 'STEADYSELLER' &&
                               `er udkommet for mere end ${DURATION_DESCRIPTIONS[notification.minPublishedAgo]} siden og har i de seneste ${DURATION_DESCRIPTIONS[notification.sustainedDuration]} haft en popularitet på ${notification.minScore.toLocaleString('da-DK')} eller mere!`}
            </p>
          </div>
        </Fragment>
      }
    >

      <div className="action-more">
        <a
          className="standard-color"
          href="#"
          onClick={evt => {
            evt.preventDefault();
            setMainLayoutOverride({
              section: 'settings',
              initialPage: 'notifications',
            });
          }}
        >
          REDIGER NOTIFIKATIONER
        </a>
      </div>

    </Collapsible.Item>
  );
}

export default createFragmentContainer(
  withMainLayoutOverride(NotificationCard), {
    title: graphql`
fragment NotificationCard_title on Title {
  id
  title
  hasMoreAuthors

  authors {
    id
    name
  }
}`,
  }
);
