/**
 * @flow
 * @relayHash ae2b9f0638a2c5a6046ad8c7b5d70254
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateReviewInput = {|
  underHeading?: ?string,
  createdBy: string,
  summaryHeadline?: ?string,
  clientMutationId: string,
  grade: number,
  titleIds?: ?$ReadOnlyArray<string>,
  url?: ?string,
  mediaId: string,
  headline: string,
  date: string,
  quotes?: ?$ReadOnlyArray<?string>,
  originalContent?: ?string,
  content?: ?string,
  reviewerId?: ?string,
  quotesCreatedBy?: ?$ReadOnlyArray<?string>,
  gradingScale: CreateReviewGradingScaleInput,
|};
export type CreateReviewGradingScaleInput = {|
  min: number,
  max: number,
  symbol: string,
  precision: number,
|};
export type AddNewReviewCardcreateReviewMutationVariables = {|
  input: CreateReviewInput
|};
export type AddNewReviewCardcreateReviewMutationResponse = {|
  +createReview: ?{|
    +review: ?{|
      +id: string,
      +grade: ?number,
      +content: ?string,
      +date: ?any,
      +headline: string,
      +quotes: ?$ReadOnlyArray<string>,
    |},
    +viewer: ?{|
      +email: string,
      +id: string,
    |},
  |}
|};
export type AddNewReviewCardcreateReviewMutation = {|
  variables: AddNewReviewCardcreateReviewMutationVariables,
  response: AddNewReviewCardcreateReviewMutationResponse,
|};
*/


/*
mutation AddNewReviewCardcreateReviewMutation(
  $input: CreateReviewInput!
) {
  createReview(input: $input) {
    review {
      id
      grade
      content
      date
      headline
      quotes
    }
    viewer {
      email
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateReviewInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createReview",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateReviewPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "review",
        "storageKey": null,
        "args": null,
        "concreteType": "Review",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "grade",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "content",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "date",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "headline",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "quotes",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddNewReviewCardcreateReviewMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddNewReviewCardcreateReviewMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddNewReviewCardcreateReviewMutation",
    "id": null,
    "text": "mutation AddNewReviewCardcreateReviewMutation(\n  $input: CreateReviewInput!\n) {\n  createReview(input: $input) {\n    review {\n      id\n      grade\n      content\n      date\n      headline\n      quotes\n    }\n    viewer {\n      email\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '838a9e6ac396c10e881d1cbc9eaaeee7';
module.exports = node;
