// @flow
import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import ToastContext from './ToastContext';
import type { ToastProps } from './ToastProps';

export default function withToast<Props: ToastProps>(Component: React$ComponentType<Props>): React$ComponentType<$Diff<Props, ToastProps>> {
  function C(props) {
    return (
      <ToastContext.Consumer>
        {toastContext => <Component {...props} {...toastContext} />}
      </ToastContext.Consumer>
    );
  }

  C.WrappedComponent = Component;
  C.displayName = `withToast(${Component.displayName || Component.name || 'unnamed'})`;

  return hoistStatics(C, Component);
}
