// @flow
import { Environment, RecordSource, Store } from 'relay-runtime';
import { setupNetwork } from './network';

/**
 * Set up Relay environment.
 *
 * @returns {Environment} - Relay environment.
 */
export function setupEnvironment(): Environment {
  const source = new RecordSource();
  const store = new Store(source);
  const network = setupNetwork();
  const handlerProvider = null;

  return new Environment({
    handlerProvider,
    network,
    store,
  });
}
