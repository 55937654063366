// @flow
import React from 'react';
import { graphql } from 'react-relay';

import { Collapsible } from '../../components';
import { QueryRenderer } from '../../utils/relay';

/** FAQ page props. */
export type FaqPageProps = {};

/** FAQ page. */
const FaqPage: React$ComponentType<FaqPageProps> = function FaqPage() {
  return (
    <div id="faq">
      <div className="section-headline">
        <br />
      </div>

      <QueryRenderer
        query={graphql`
query FaqPageQuery {
  faqs {
    id
    question
    answer
  }
}`}
        render={({ error, props }) => {
          if (error) {
            return <div className="no-results-headline">Et eller andet gik galt. Prøv venligst igen.</div>;
          }

          if (!props) {
            return <div className="no-results-headline">Indlæser...</div>;
          }

          return (
            <Collapsible popout>
              {props.faqs.map(({ id, question, answer }) =>
                <Collapsible.Item
                  key={id}
                  header={<span dangerouslySetInnerHTML={{ __html: question }} />}
                >
                  <p dangerouslySetInnerHTML={{ __html: answer }} />
                </Collapsible.Item>
              )}
            </Collapsible>
          );
        }}
      />
    </div>
  );
};

export default FaqPage;
