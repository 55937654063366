// @flow
import React from 'react';
import ReactDOM from 'react-dom';

/** Toast container portal. */
export default class ToastContainer extends React.Component<{}> {
  constructor(props) {
    super(props);

    this.el = document.createElement('div');
    this.el.id = 'toast-container';
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el,
    );
  }
}
