// @flow
import React, { Fragment } from 'react';
import { graphql } from 'react-relay';

import { Dropdown, Icon } from '../../components';
import FilterDescriptionLabel from '../../containers/FilterDescriptionLabel';
import { withSearchSettings, type SearchSettingsProps } from '../../containers/SearchSettings';
import { withFeatures, type FeaturesProps } from '../../containers/FeaturesProvider';
import { withMainLayoutOverride, type MainLayoutOverrideProps } from '../../containers/MainLayoutOverrideManager';
import NewsAndReviewPaginator from '../../containers/NewsAndReviewPaginator';
import UnreadMessageList from '../../containers/UnreadMessageList';
import { type RouterProps } from '../../utils/router';
import { QueryRenderer } from '../../utils/relay';

type NewsResultsPageProps = {
  searchMode: 'STORE' | 'PUBLISHING_HOUSE',
  shouldDisplayPublishingHouse: (id: ?string) => boolean,
} & RouterProps<{}> & SearchSettingsProps & FeaturesProps & MainLayoutOverrideProps;

class NewsResultsPage extends React.Component<NewsResultsPageProps> {
  handleResetClick = (evt) => {
    evt.preventDefault();
    this.props.resetSearchSettings();
  }

  render() {
    const {
      features,
      searchMode,
      searchSettings,
      setShowNews,
      setShowReviews,
      shouldDisplayPublishingHouse,
	  getRefetchQuery,
    } = this.props;
	const variables = {
		filterBy: searchSettings.filterBy,
		order: 'DESC',
		orderBy: 'DATE',
		search: searchSettings.searchQuery,
		includeNews: searchSettings.showNews,
		includeReviews: searchSettings.showReviews,
		includeGeneralMessages: features.messages && searchSettings.showNews && searchSettings.showReviews && !searchSettings.filterBy.length,
		mode: searchMode,
		onlyFavorites: searchSettings.onlyFavorites,
		refetchQuery: getRefetchQuery()
	  };
	 
	 
    return (
      <div id="news">

        <QueryRenderer
          variables={variables}
          query={graphql`
query NewsResultsPageQuery(
  $orderBy: NewsAndReviewOrderBy,
  $order: Order,
  $search: String,
  $filterBy: [ID!],
  $includeNews: Boolean!,
  $includeReviews: Boolean!,
  $includeGeneralMessages: Boolean!,
  $mode: SearchMode!,
  $onlyFavorites: Boolean!
) {
  ...NewsAndReviewPaginator_query @arguments(
    orderBy: $orderBy,
    order: $order,
    search: $search,
    filterBy: $filterBy
    includeNews: $includeNews,
    includeReviews: $includeReviews,
    includeGeneralMessages: $includeGeneralMessages,
    mode: $mode,
    onlyFavorites: $onlyFavorites
  )

  viewer {
    ...UnreadMessageList_viewer
    ...FavoriteStar_viewer
  }
}`}
          render={({ error, props }) => {
            const FirstHeadline = ({ children }) => (
              <div className="section-headline">
                <Dropdown
                  alignment="right"
                  closeOnClick={false}
                  trigger={({ toggleDropdown, ref }) =>
                    <span className="dropdown-trigger btn-flat right" onClick={toggleDropdown} ref={ref}>
                      <Icon name="filter_list" className="grey-text light" />
                    </span>
                  }
                >
                  <Dropdown.Item>
                    <label>
                      <input
                        type="checkbox"
                        className="filled-in"
                        checked={searchSettings.showReviews}
                        onChange={() => setShowReviews(!searchSettings.showReviews)}
                      />
                      <span>Vis anmeldelser</span>
                    </label>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <label>
                      <input
                        type="checkbox"
                        className="filled-in"
                        value="NEWS"
                        checked={searchSettings.showNews}
                        onChange={() => setShowNews(!searchSettings.showNews)}
                      />
                      <span>Vis nyheder</span>
                    </label>
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item>
                    <a href="#" className="waves-effect waves-light" onClick={this.handleResetClick}>
                      <i className="material-icons standard-color">refresh</i>
                      <span className="dropdown-date">Nulstil søgeindstillinger</span>
                    </a>
                  </Dropdown.Item>
                </Dropdown>

                {children}
                {!!children && '. '}
                <FilterDescriptionLabel mode="content" />
              </div>
            );

            const AdditionalHeadline = ({ children }) => (
              <div className="section-headline">
                {children}
              </div>
            );
		 
            return (
              <Fragment>
                {(error || !props) &&
                <FirstHeadline />}

                {error
                  ? <div className="no-results-headline">Et eller andet gik galt. Prøv venligst igen.</div>
                  : !props
                    ? <div className="no-results-headline">Indlæser...</div>
                    : null}

                {props &&
                 <UnreadMessageList viewer={props.viewer} />}

                {props &&
                 <NewsAndReviewPaginator
                   query={props}
                   firstHeadline={FirstHeadline}
                   additionalHeadline={AdditionalHeadline}
                   shouldDisplayPublishingHouse={shouldDisplayPublishingHouse}
                 />}

              </Fragment>
            );
          }}
        />
      </div>
    );
  }
}

export default withFeatures(withMainLayoutOverride(withSearchSettings(NewsResultsPage)));
