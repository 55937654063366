// @flow
import React from 'react';
import { graphql } from 'react-relay';

import { AppLayout } from '../../components';
import { QueryRenderer } from '../../utils/relay';
import EditMessageLayoutOverride from './EditMessageLayoutOverride';

function EditMessageLayoutOverrideDataWrapper({
  id,
  ...forwardedProps
}) {
  return (
    <QueryRenderer
      query={graphql`
query EditMessageLayoutOverrideDataWrapperQuery($id: ID!) {
  node(id: $id) {
    ... on Message {
      id
      subject
      body
      status
      activeFrom
      activeTo
      includeLinkToBogportalen

      referencedPublications {
        id
        isDigital
      }

      aggregateReferencedTitles {
        id
        title

        publications {
          id
          isbn13
          format
        }
      }

      referencedEntities {
        id
        name
      }

      referencedPublishingHouses {
        id
        name
      }

      recipientGroups {
        id
      }

      links {
        url
        description
      }

      attachments {
        description

        file {
          id
          filename
        }
      }
    }
  }

  viewer {
    group {
      type
    }

    messageRecipients {
      id
    }

    ...MessageRecipientSelector_viewer
    ...MessageStatusSelector_viewer
  }
}`}
      variables={{
        id,
      }}
      render={({ error, props }) => {
        return (
          <EditMessageLayoutOverride
            {...AppLayout.Override.forwardProps(forwardedProps)}
            query={{ error, data: props }}
          />
        );
      }}
    />
  );
}

export default EditMessageLayoutOverrideDataWrapper;
