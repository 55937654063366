/**
 * @flow
 * @relayHash 06bff5596001f522d30c922c02c4628e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateMediaInput = {|
  mediaType: string,
  mediaName: string,
  webAddress?: ?string,
  gradingScale: CreateReviewGradingScaleInput,
  clientMutationId: string,
|};
export type CreateReviewGradingScaleInput = {|
  min: number,
  max: number,
  symbol: string,
  precision: number,
|};
export type AddNewMediaCardcreateMediaMutationVariables = {|
  input: CreateMediaInput
|};
export type AddNewMediaCardcreateMediaMutationResponse = {|
  +createMedia: ?{|
    +media: ?{|
      +id: string
    |}
  |}
|};
export type AddNewMediaCardcreateMediaMutation = {|
  variables: AddNewMediaCardcreateMediaMutationVariables,
  response: AddNewMediaCardcreateMediaMutationResponse,
|};
*/


/*
mutation AddNewMediaCardcreateMediaMutation(
  $input: CreateMediaInput!
) {
  createMedia(input: $input) {
    media {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateMediaInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createMedia",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateMediaPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "media",
        "storageKey": null,
        "args": null,
        "concreteType": "Media",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddNewMediaCardcreateMediaMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddNewMediaCardcreateMediaMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddNewMediaCardcreateMediaMutation",
    "id": null,
    "text": "mutation AddNewMediaCardcreateMediaMutation(\n  $input: CreateMediaInput!\n) {\n  createMedia(input: $input) {\n    media {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1dbc67467e68a10dd3b0ac880e2f00f3';
module.exports = node;
