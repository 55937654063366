// @flow
import React from 'react';

import Up from '!babel-loader!svg-react-loader!./up.svg';
import Right from '!babel-loader!svg-react-loader!./right.svg';
import Down from '!babel-loader!svg-react-loader!./down.svg';

/** Development icon props. */
export type DevelopmentIconProps = {
  direction: 'up' | 'down' | 'stable' | 'unknown',
};

/** Development icon. */
const DevelopmentIcon: React$ComponentType<DevelopmentIconProps> = function DevelopmentIcon({
  direction,
}: DevelopmentIconProps) {
  switch (direction) {
    case 'up':
      return <Up />;

    case 'down':
      return <Down />;

    case 'stable':
    case 'unknown':
      return <Right />;

    default:
      return null;
  }
};

export default DevelopmentIcon;
