// @flow
import React, { Fragment } from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import ReactHintFactory from 'react-hint';
import danishLocaleData from 'react-intl/locale-data/da';

import { EnvironmentProvider } from './utils/relay';
import { setupEnvironment } from './config';
import { ToastProvider } from './components';
import './styles/global.css';
import Routes from './Routes';

addLocaleData(danishLocaleData);

const environment = setupEnvironment();
const ReactHint = ReactHintFactory(React);

const App: React$ComponentType<{}> = function App() {
  return (
    <Fragment>
      <ReactHint autoPosition events delay={{ show: 0, hide: 200 }} />
      <IntlProvider locale="da-DK" textComponent={Fragment}>
        <EnvironmentProvider environment={environment}>
          <ToastProvider>
            <Routes />
          </ToastProvider>
        </EnvironmentProvider>
      </IntlProvider>
    </Fragment>
  );
};

export default App;
