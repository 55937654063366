// @flow
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Icon from '../Icon';
import Portal from '../Portal';

/** Lightbox props. */
export type LightboxProps = {
  open: boolean,
  src: string,
  onClose: () => any,
};

/** Lightbox. */
const Lightbox: React$ComponentType<LightboxProps> = function Lightbox({
  open,
  src,
  onClose,
}: LightboxProps) {
  return (
    <CSSTransition
      timeout={250}
      classNames="custom-lightbox"
      mountOnEnter
      unmountOnExit
      in={open}
    >
      <Portal>
        <div className="custom-lightbox">
          <div
            className="custom-lightbox-image"
            style={{
              backgroundImage: `url(${src})`,
            }}
            onClick={() => onClose()}
          />

          <div className="custom-lightbox-actions">
            <a
              target="_blank"
              href={src}
            >
              <Icon name="arrow_downward" />
            </a>

            <a
              href="#"
              onClick={evt => {
                evt.preventDefault();
                onClose();
              }}
            >
              <Icon name="close" />
            </a>
          </div>
        </div>
      </Portal>
    </CSSTransition>
  );
};

export default Lightbox;
