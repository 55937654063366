/**
 * @flow
 * @relayHash d3cc5d31e8b907884ba9c91bb469c65a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateReviewerInput = {|
  mediaId: string,
  firstName: string,
  lastName: string,
  clientMutationId: string,
|};
export type AddNewReviewerCardcreateReviewerMutationVariables = {|
  input: CreateReviewerInput
|};
export type AddNewReviewerCardcreateReviewerMutationResponse = {|
  +createReviewer: ?{|
    +reviewer: ?{|
      +id: string
    |}
  |}
|};
export type AddNewReviewerCardcreateReviewerMutation = {|
  variables: AddNewReviewerCardcreateReviewerMutationVariables,
  response: AddNewReviewerCardcreateReviewerMutationResponse,
|};
*/


/*
mutation AddNewReviewerCardcreateReviewerMutation(
  $input: CreateReviewerInput!
) {
  createReviewer(input: $input) {
    reviewer {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateReviewerInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createReviewer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateReviewerPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reviewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Reviewer",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddNewReviewerCardcreateReviewerMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddNewReviewerCardcreateReviewerMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddNewReviewerCardcreateReviewerMutation",
    "id": null,
    "text": "mutation AddNewReviewerCardcreateReviewerMutation(\n  $input: CreateReviewerInput!\n) {\n  createReviewer(input: $input) {\n    reviewer {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd53c9e35ba14ffc537366d160cd686ef';
module.exports = node;
