// @flow

/**
 * Unauthenticated.
 *
 * Raised by the network layer when the current authorization is no longer valid.
 */
export default class UnauthenticatedError {

}
