// @flow
import React from 'react';

import type { SearchFilter } from '../../models';
import Icon from '../Icon';

/** Filter refs. */
type FilterRefs = { [id: string]: { current: null | HTMLDivElement } };

/** Search bar filter. */
export type SearchBarFilter = {
  label: string,
  typeLabel: ?string,
} & SearchFilter;

/** Filter refs from filters. */
function filterRefsFromFilters(
  filters: SearchBarFilter[],
  prevFilterRefs: FilterRefs
): FilterRefs {
  const result = {};

  filters.forEach(filter => {
    result[filter.id] = prevFilterRefs[filter.id] || React.createRef();
  });

  return result;
}

/** Search filter suggestion. */
export type SearchBarFilterSuggestion = {
  filter: SearchBarFilter,
  description: string,
  descriptionSuffix?: ?string,
  imageUrl: string,
};

/** Search bar props. */
export type SearchBarProps = {
  extraClass: string,
  filters: SearchBarFilter[],
  query: string,
  onQueryChange: (query: string) => any,
  onFiltersChange: (filters: SearchBarFilter[]) => any,
  onSubmit: () => any,
  filterSuggestions: ?SearchBarFilterSuggestion[],
  hideSearchIcon: boolean,
  maxChoices: number,
  placeholderText: string,
  label: string,
  moveLabelOnFocus: boolean
};

/** Search bar state. */
type SearchBarState = {
  filterRefs: FilterRefs,
  prevFilterSuggestions: ?SearchBarFilterSuggestion[],
  prevFilters: SearchBarFilter[],
  selectedFilterSuggestionIndex: ?number,
  isQueryFocused: boolean,
  queryLength: number
};

/** Search bar. */
export default class SearchBar extends React.Component<SearchBarProps, SearchBarState> {
  queryRef: { current: null | HTMLDivElement } = React.createRef();

  constructor(props: SearchBarProps) {
    super(props);

    this.state = {
      filterRefs: filterRefsFromFilters(props.filters, {}),
      isQueryFocused: false,
      prevFilterSuggestions: props.filterSuggestions,
      prevFilters: props.filters,
      selectedFilterSuggestionIndex: null,
    };
  }

  static getDerivedStateFromProps(props: SearchBarProps, state: SearchBarState): ?$Shape<SearchBarState> {
    let result = null;

    if (props.filters !== state.prevFilters) {
      result = {
        prevFilters: props.filters,
        filterRefs: filterRefsFromFilters(props.filters, state.filterRefs),
      };
    }

    if (props.filterSuggestions !== state.prevFilterSuggestions) {
      result = {
        ...(result || {}),
        prevFilterSuggestions: props.filterSuggestions,
        selectedFilterSuggestionIndex: null,
      };
    }

    return result;
  }

  handleSubmit = (evt: SyntheticEvent<HTMLFormElement>) => {
    evt.preventDefault();
    this.props.onSubmit();
  }

  handleBarClick = (evt: SyntheticMouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();

    if (this.queryRef.current) {
      this.queryRef.current.focus();
    }
  }

  handleQueryClick = (evt: SyntheticMouseEvent<HTMLInputElement>) => {
    evt.stopPropagation();
  }

  handleQueryBlur = () => {
    this.setState({
      isQueryFocused: false,
    });
  }

  handleQueryFocus = () => {
    this.setState({
      isQueryFocused: true,
    });
  }

  handleQueryChange = (evt: SyntheticInputEvent<HTMLInputElement>) => {
	this.setState({queryLength: evt.target.value.length})
    this.props.onQueryChange(evt.target.value);
  }

  handleQueryKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === 'Backspace') {
      const { filters, query } = this.props;

      if (!query && filters.length) {
        const filterRef = this.state.filterRefs[filters[filters.length - 1].id];

        if (filterRef && filterRef.current) {
          filterRef.current.focus();
        }
      }
    }
    else if (evt.key === 'Enter') {
      const { selectedFilterSuggestionIndex } = this.state;
      const { filterSuggestions } = this.props;

      if (filterSuggestions && selectedFilterSuggestionIndex !== null && selectedFilterSuggestionIndex !== undefined) {
        evt.preventDefault();
        evt.stopPropagation();

        const selectedSuggestion = filterSuggestions[selectedFilterSuggestionIndex];
        if (selectedSuggestion) {
          this.addFilter(selectedSuggestion.filter);
        }
      }
    }
    else if (evt.key === 'ArrowDown') {
      evt.preventDefault();

      const { selectedFilterSuggestionIndex } = this.state;
      const { filterSuggestions } = this.props;

      if (filterSuggestions &&
          filterSuggestions.length > 0 &&
          (selectedFilterSuggestionIndex === null ||
           selectedFilterSuggestionIndex + 1 < filterSuggestions.length)) {
        this.setState({
          selectedFilterSuggestionIndex: selectedFilterSuggestionIndex === null ? 0 : selectedFilterSuggestionIndex + 1,
        });
      }
    }
    else if (evt.key === 'ArrowUp') {
      evt.preventDefault();

      const { selectedFilterSuggestionIndex } = this.state;

      if (selectedFilterSuggestionIndex !== null && selectedFilterSuggestionIndex > 0) {
        this.setState({
          selectedFilterSuggestionIndex: selectedFilterSuggestionIndex - 1,
        });
      }
    }
  }

  handleFilterClick = (filter: SearchBarFilter, evt: SyntheticMouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
  }

  handleFilterKeyDown = (filter: SearchBarFilter, evt: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Backspace') {
      const { filters, onFiltersChange } = this.props;

      const filterIdx = filters.findIndex(f => f.id === filter.id);
      const nextFilter = filterIdx > 0
        ? filters[filterIdx - 1]
        : filters.length > 1
          ? filters[1]
          : null;

      if (nextFilter) {
        this.state.filterRefs[nextFilter.id].current.focus();
      }

      onFiltersChange(filters.filter(f => f.id !== filter.id));
    }
  }

  handleFilterRemoveClick = (filter: SearchBarFilter, evt) => {
    const { filters, onFiltersChange } = this.props;

    onFiltersChange(filters.filter(f => f.id !== filter.id));
    this.queryRef.current.focus();
  }

  handleFilterSuggestionClick = (suggestion: SearchBarFilterSuggestion, evt) => {
    evt.preventDefault();
    this.addFilter(suggestion.filter);
  }

  addFilter(filter: SearchBarFilter) {
    const { filters, onFiltersChange, onQueryChange } = this.props;

    if (!filters.some(f => f.id === filter.id)) {
      onFiltersChange([...filters, filter]);
    }

    onQueryChange('');
  }

  render() {
    const { filters, query, filterSuggestions, extraClass, hideSearchIcon, maxChoices, placeholderText, moveLabelOnFocus} = this.props;
    const { filterRefs, isQueryFocused, selectedFilterSuggestionIndex, queryLength } = this.state;

    return (
      <div className={`searchbar ${extraClass}`}>
        <form onSubmit={this.handleSubmit}>
          <div className={`input-field chips ${moveLabelOnFocus ? 'with-label': ''}`}  onClick={this.handleBarClick}>
            {filters.map(filter =>
              <div
                className="chip"
                tabIndex="0"
                key={filter.id}
                onClick={evt => this.handleFilterClick(filter, evt)}
                onKeyDown={evt => this.handleFilterKeyDown(filter, evt)}
                ref={filterRefs[filter.id]}
              >
                {filter.typeLabel &&
                 <span className="light-standard-color">{filter.typeLabel}: </span>}
                {filter.label}
                <Icon name="close" className="close" onClick={evt => this.handleFilterRemoveClick(filter, evt)} />
              </div>
            )}

            <input
              autoComplete="off"
              className="chips-autocomplete input"
              id="search-auto"
              onClick={this.handleQueryClick}
              onBlur={this.handleQueryBlur}
              onChange={this.handleQueryChange}
              onFocus={this.handleQueryFocus}
              onKeyDown={this.handleQueryKeyDown}
              placeholder={filters.length ? 
			  	!maxChoices || (maxChoices && filters.length < maxChoices) ? 'mere...?' : `Kun ${maxChoices} valg` 
			  	: moveLabelOnFocus ? '' : placeholderText ? placeholderText : 'Søg på alt'}
              type="text"
              ref={this.queryRef}
              value={query}
			  disabled={maxChoices && filters.length >= maxChoices}
            />
			{ moveLabelOnFocus &&
				<label className={`search-label ${isQueryFocused || queryLength || filters.length ? 'focused': ''}`}>
				{placeholderText ? placeholderText : 'Søg på alt'}
			</label>}
            {isQueryFocused &&
           filterSuggestions &&
          filterSuggestions.length > 0 &&
           <ul
             className="autocomplete-content dropdown-content"
             tabIndex="0"
             style={{ display: 'block', opacity: 1 }}
             onMouseDown={evt => { evt.preventDefault(); evt.stopPropagation() }}
           >
             {filterSuggestions.map((suggestion, idx) =>
               <li
                 className={selectedFilterSuggestionIndex === idx ? 'active' : null}
                 key={idx}
                 onClick={evt => this.handleFilterSuggestionClick(suggestion, evt)}
               >
                 <img src={suggestion.imageUrl} className="right circle" />
                 <span>{suggestion.description}{suggestion.descriptionSuffix && <span className="extract"> {suggestion.descriptionSuffix}</span>}</span>
               </li>
             )}
           </ul>}

			{!hideSearchIcon ? <button
              className="right btn waves-effect waves-light  white-text standard-bg-color searchbutton"
              type="submit"
              name="action"
            >
              <Icon name="search" />
            </button> : null}
           

            {false &&
           <button className="btn-flat waves-effect waves-light standard-color deletebutton">
             <Icon name="delete_outline" />
           </button>}
          </div>
        </form>
      </div>
    );
  }
};
