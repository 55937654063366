// @flow

/** Meta score color class. */
export function getColorClassForMetaScore(metaScore: number, reviewCount: number): string {
  if (reviewCount === 0 || metaScore <= 0) {
    return 'grey darken-1';
  }

  if (metaScore <= 20) {
    return 'red darken-3';
  }

  if (metaScore <= 33) {
    return 'deep-orange';
  }

  if (metaScore <= 47) {
    return 'orange';
  }

  if (metaScore <= 57) {
    return 'yellow darken-1';
  }

  if (metaScore <= 67) {
    return 'light-green';
  }

  if (metaScore <= 85) {
    return 'light-green';
  }

  if (metaScore <= 94) {
    return 'green';
  }

  return 'green darken-4';
}

/** Review prefix by meta score. */
export function getReviewPrefixForMetaScore(metaScore: number): string {
  if (metaScore <= 20) {
    return 'Meget ringe';
  }

  if (metaScore <= 33) {
    return 'Dårlig';
  }

  if (metaScore <= 47) {
    return 'Under middel';
  }

  if (metaScore <= 57) {
    return 'Gennemsnitlig';
  }

  if (metaScore <= 67) {
    return 'Glimrende';
  }

  if (metaScore <= 85) {
    return 'Virkelig god';
  }

  if (metaScore <= 94) {
    return 'Fremragende';
  }

  return 'Enestående god';
}
