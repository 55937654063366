/**
 * @flow
 * @relayHash 28e58b4581dc56523b7fb188bb836c40
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MainSidebar_viewer$ref = any;
export type CustomerNotificationSubscriptionType = "BESTSELLER" | "STEADYSELLER" | "SUDDEN_RISE" | "%future added value";
export type PublishedFilter = "ANY" | "FUTURE" | "LAST_12_MONTHS" | "LAST_4_MONTHS" | "NEXT_14_DAYS" | "TODAY" | "%future added value";
export type SearchMode = "PUBLISHING_HOUSE" | "STORE" | "%future added value";
export type MainLayoutQueryVariables = {|
  search?: ?string,
  filterBy?: ?$ReadOnlyArray<string>,
  excludeDigital?: ?boolean,
  excludeUnavailable?: ?boolean,
  published?: ?PublishedFilter,
  includeNews: boolean,
  includeReviews: boolean,
  mode: SearchMode,
  onlyFavorites: boolean,
  onlyWithNotificationTypes?: ?$ReadOnlyArray<CustomerNotificationSubscriptionType>,
|};
export type MainLayoutQueryResponse = {|
  +newsAndReviews: ?{|
    +totalCount: number
  |},
  +titles: ?{|
    +totalCount: number
  |},
  +viewer: {|
    +isAdministrator: boolean,
    +type: string,
    +$fragmentRefs: MainSidebar_viewer$ref,
  |},
|};
export type MainLayoutQuery = {|
  variables: MainLayoutQueryVariables,
  response: MainLayoutQueryResponse,
|};
*/


/*
query MainLayoutQuery(
  $search: String
  $filterBy: [ID!]
  $excludeDigital: Boolean
  $excludeUnavailable: Boolean
  $published: PublishedFilter
  $includeNews: Boolean!
  $includeReviews: Boolean!
  $mode: SearchMode!
  $onlyFavorites: Boolean!
  $onlyWithNotificationTypes: [CustomerNotificationSubscriptionType!]
) {
  newsAndReviews(first: 0, search: $search, filterBy: $filterBy, includeNews: $includeNews, includeReviews: $includeReviews, mode: $mode, onlyFavorites: $onlyFavorites) {
    totalCount
  }
  titles(first: 0, search: $search, filterBy: $filterBy, published: $published, excludeDigital: $excludeDigital, excludeUnavailable: $excludeUnavailable, mode: $mode, onlyFavorites: $onlyFavorites, onlyWithNotificationTypes: $onlyWithNotificationTypes) {
    totalCount
  }
  viewer {
    isAdministrator
    type
    ...MainSidebar_viewer
    id
  }
}

fragment MainSidebar_viewer on Customer {
  favoriteIds
  name
  links {
    id
    description
    url
  }
  group {
    type
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filterBy",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "excludeDigital",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "excludeUnavailable",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "published",
    "type": "PublishedFilter",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeNews",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeReviews",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "mode",
    "type": "SearchMode!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "onlyFavorites",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "onlyWithNotificationTypes",
    "type": "[CustomerNotificationSubscriptionType!]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "filterBy",
  "variableName": "filterBy"
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v3 = {
  "kind": "Variable",
  "name": "mode",
  "variableName": "mode"
},
v4 = {
  "kind": "Variable",
  "name": "onlyFavorites",
  "variableName": "onlyFavorites"
},
v5 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "newsAndReviews",
  "storageKey": null,
  "args": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "Variable",
      "name": "includeNews",
      "variableName": "includeNews"
    },
    {
      "kind": "Variable",
      "name": "includeReviews",
      "variableName": "includeReviews"
    },
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "concreteType": "NewsAndReviewConnection",
  "plural": false,
  "selections": (v6/*: any*/)
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "titles",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "excludeDigital",
      "variableName": "excludeDigital"
    },
    {
      "kind": "Variable",
      "name": "excludeUnavailable",
      "variableName": "excludeUnavailable"
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "kind": "Variable",
      "name": "onlyWithNotificationTypes",
      "variableName": "onlyWithNotificationTypes"
    },
    {
      "kind": "Variable",
      "name": "published",
      "variableName": "published"
    },
    (v5/*: any*/)
  ],
  "concreteType": "TitleConnection",
  "plural": false,
  "selections": (v6/*: any*/)
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdministrator",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MainLayoutQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "MainSidebar_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MainLayoutQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "favoriteIds",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "links",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerLink",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/)
            ]
          },
          (v11/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MainLayoutQuery",
    "id": null,
    "text": "query MainLayoutQuery(\n  $search: String\n  $filterBy: [ID!]\n  $excludeDigital: Boolean\n  $excludeUnavailable: Boolean\n  $published: PublishedFilter\n  $includeNews: Boolean!\n  $includeReviews: Boolean!\n  $mode: SearchMode!\n  $onlyFavorites: Boolean!\n  $onlyWithNotificationTypes: [CustomerNotificationSubscriptionType!]\n) {\n  newsAndReviews(first: 0, search: $search, filterBy: $filterBy, includeNews: $includeNews, includeReviews: $includeReviews, mode: $mode, onlyFavorites: $onlyFavorites) {\n    totalCount\n  }\n  titles(first: 0, search: $search, filterBy: $filterBy, published: $published, excludeDigital: $excludeDigital, excludeUnavailable: $excludeUnavailable, mode: $mode, onlyFavorites: $onlyFavorites, onlyWithNotificationTypes: $onlyWithNotificationTypes) {\n    totalCount\n  }\n  viewer {\n    isAdministrator\n    type\n    ...MainSidebar_viewer\n    id\n  }\n}\n\nfragment MainSidebar_viewer on Customer {\n  favoriteIds\n  name\n  links {\n    id\n    description\n    url\n  }\n  group {\n    type\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a6a17bf4fd6b9c01687c76f5c33fc48';
module.exports = node;
