// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { commitMutation, graphql } from 'react-relay';

import { Icon, Checkbox, Form, Input, Select, Modal, Textarea, withToast, type ToastProps } from '../../components';
import { type Viewer } from '../../models';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';


/** Create Media mutation. */
const createMediaMutation = graphql`
mutation AddNewMediaCardcreateMediaMutation($input: CreateMediaInput!) {
  createMedia(input: $input) {
    media {
      id
    }
  }
}`;

type AddNewMediaProps = {
	query: {
		error: ?Error,
		data: any
	},
	onSubmit: (media:any) => any
} & EnvironmentProps & ToastProps;

type AddNewMediaState = {
	loading: string,
	submitting: boolean,
	error: any,
	errorField: string,
	showModal: boolean,
	chosenMediaId: string,
	chosenSymbolId: string,
	chosenRatingId: string,
	name: string,
	address: string,
	symbols: {id: string, name: string}[],
	addressIsEmpty: boolean,
	ratingOptions: {id: string, value: number, precision: number}[],
};


/** Content item. */
class AddNewMediaCard extends React.Component<AddNewMediaProps, AddNewMediaState>{
	state = {
		loading: '',
		submitting: false,
		error: null,
		chosenMediaId: '',
		chosenSymbolId: 'hearts',
		chosenRatingId: '',
		name: '',
		errorField: '',
		address: '',
		addressIsEmpty: false,
		symbols: [
			{id:'hearts', name: 'Hjerter'},
			{id:'stars', name: 'Stjerner'},
			{id:'none', name: 'Ingen symboler'}
		],
		ratingOptions: [
			{id: '5normal', value: 5, precision: 1},
			{id: '6normal', value: 6, precision: 1},
			{id: '10normal', value: 10, precision: 1},
			{id: '5half', value: 5, precision: 0.5},
			{id: '6half', value: 6, precision: 0.5},
			{id: '10half', value: 10, precision: 0.5},
		],
		showModal: false
	};

	handleSubmit = (evt) => {
	 
		if (this.state.loading) {
			return;
		}
		const {
			environment,
		} = this.props;

		const {
			chosenMediaId,
			chosenSymbolId,
			chosenRatingId,
			name,
			address,
			addressIsEmpty,
			ratingOptions
		} = this.state;
		
		if(!chosenMediaId) {
			this.setState({
				error: 'Vælg medietype.',
				errorField: 'type',
				submitting: false,
			  });
			return;
		}
		if(!name) {
			this.setState({
				error: 'Tilføj mediets navn.',
				errorField: 'name',
				submitting: false,
			  });
			return;
		}
		if(!addressIsEmpty){
			var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
			'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
			const isCorrectUrl = !!pattern.test(address);
			if(!isCorrectUrl) {
				this.setState({
					error: 'Webadressen er ikke korrekt (husk http ...)',
					errorField: 'address',
					submitting: false,
				  });
				return;
			}
		}
		let gradingScale;
		if(chosenSymbolId === 'none'){
			gradingScale = Object.assign({}, {
				min: 0,
				max: 100,
				symbol: '',
				precision: 1
			});
		} else {
			const chosenRating = ratingOptions.find(ro => ro.id === chosenRatingId);
			if(!chosenRating) {
				this.setState({
					error: 'Tilføj antal symboler',
					errorField: 'rating',
					submitting: false,
				  });
				return;
			}

			gradingScale = Object.assign({}, {
				min: 0,
				max: chosenRating.value,
				symbol: chosenSymbolId,
				precision: chosenRating.precision
			});

		}

		const payload = {
			mediaType: chosenMediaId,
			mediaName: name,
			webAddress: addressIsEmpty ? '' : address,
			gradingScale
		};

		this.setState({
			submitting: true,
			loading: 'loading'
		  }, () => commitMutation(environment, {
			mutation: createMediaMutation,
			variables: {
			  input: {
				clientMutationId: '1',
				...payload,
			  },
			},
 
			onCompleted: (data, errors) => {
			  const { toast, onSubmit } = this.props;
	  
			  if (errors?.length) {
				this.setState({
				  loading: '',
				  submitting: false,
				  error: 'Der er opstået en fejl.',
				});
			  }
			  else {
				
				toast('Mediet er oprettet');
				const media = Object.assign({}, data.createMedia.media, {name, gradingScales: [gradingScale], address});
				onSubmit(media);
				this.setState({
					loading: '',
					submitting: false,
					error: null,
					chosenMediaId: '',
					chosenSymbolId: 'hearts',
					chosenRatingId: '',
					name: '',
					address: '',
					addressIsEmpty: false,
					symbols: [
						{id:'hearts', name: 'Hjerter'},
						{id:'stars', name: 'Stjerner'},
						{id:'none', name: 'Ingen symboler'}
					],
					ratingOptions: [
						{id: '5normal', value: 5, precision: 1},
						{id: '6normal', value: 6, precision: 1},
						{id: '10normal', value: 10, precision: 1},
						{id: '5half', value: 5, precision: 0.5},
						{id: '6half', value: 6, precision: 0.5},
						{id: '10half', value: 10, precision: 0.5},
					],
					showModal: false
				});
			  }
			},
		  }));
	}

	toggleModal(){
		this.setState({showModal: !this.state.showModal});
	}

	onChooseMedia(id){
		this.setState({errorField:'', chosenMediaId: id});
	}

	onChooseSymbol(id){
		this.setState({errorField:'', chosenSymbolId: id});
	}

	onChooseRating(id){
		this.setState({errorField:'', chosenRatingId: id});
	}

	onToggleAddressEmpty(){
		this.setState({errorField:'', addressIsEmpty: !this.state.addressIsEmpty});
	}
	
	checkAndSetWebAddress(address){
		this.setState({errorField:'', address});
	}

	render() {
		const {
			viewer,
			query: {
				error,
				data
			}
		} = this.props;

		const {
			loading,
			submitting,
			chosenMediaId,
			chosenSymbolId,
			chosenRatingId,
			name,
			errorField,
			address,
			symbols,
			ratingOptions,
			addressIsEmpty,
		} = this.state;

		const chosenSymbolName = chosenSymbolId && symbols.find(s=> s.id === chosenSymbolId)?.name;
		
		return(
			<Fragment>
				<Modal open={this.state.showModal} onClose={this.toggleModal.bind(this)}>
					<div className="modal-content">
						<div className="modal-headline">
							Opret nyt medie
						</div>
						<Form
							onSubmit={this.handleSubmit}
							error={this.state.error}
							loading={this.state.loading} >

							<div className={`step-title ${errorField==='type' ? 'error' :''}`}>
								<p>Vælg medietype {chosenMediaId && `: ${data?.mediaTypes.find(mt => mt.id === chosenMediaId)?.name}`}</p>
								<div className="row step-content">
									{
										data?.mediaTypes && data.mediaTypes.map((mt) => {
											return (
												<div className="col s6" key={mt.id}>
													<Checkbox onChange={()=> this.onChooseMedia(mt.id)} value={chosenMediaId === mt.id} label={mt.name}/>
												</div>
											);
										})
									}
								</div>
							</div>

							<div className={`step-title ${errorField==='name' ? 'error' :''}`}>
								<p>Navn: </p>
								<div className="row step-content">
									<Input validate value={name} onChange={name => this.setState({errorField:'', name})} autoComplete="off" label="Mediets navn" />
								</div>
							</div>
							
							<div className={`step-title ${errorField==='address' ? 'error' :''}`}>
								<p>Webadresse: </p>
								<div className="row step-content">
									<Input disabled={addressIsEmpty} value={address} onChange={address => this.checkAndSetWebAddress(address) } autoComplete="off" label="Link" />
									<Checkbox onChange={()=> this.onToggleAddressEmpty()} value={addressIsEmpty} label="Mediet har ingen webadresse" />
								</div>
							</div>
							
							<div className="step-title">
								<p>Symboler: </p>
								<div className="row step-content">
									{ symbols && symbols.map((s) => {
										return (
											<div className="col s6" key={s.id}>
												<Checkbox onChange={()=> this.onChooseSymbol(s.id)} value={chosenSymbolId === s.id} label={s.name}/>
											</div>
										);
									})}
								</div>
							</div>
						
							{ chosenSymbolId != 'none' && <div className={`step-title ${errorField==='rating' ? 'error' :''}`}>
								<p>Maks. antal {`${chosenSymbolName || ''}`}: </p>
								<div className="row step-content">
								{ ratingOptions && ratingOptions.map((ro) => {
									return (
										<div className="col s6" key={ro.id}>
											<Checkbox onChange={()=> this.onChooseRating(ro.id)} value={chosenRatingId === ro.id} label={`${ro.value} ${chosenSymbolName?.toLowerCase() || ''} ${ro.precision === 0.5 ? '(inkl. halve)': ''}`}/>
										</div>
									);
								})}
								</div>
							</div>
							}
							{/* <div className="row bottomrow">
								<div className="col left">
									<button type="submit" className="waves-effect waves-light btn-large right standard-bg-color" disabled={submitting}>
										GODKEND
									</button>
								</div>
							</div> */}
						</Form>
					</div>
				</Modal>

				<a className="standard-color versals add-new-button" onClick={this.toggleModal.bind(this)} >
					<Icon name="add" />
					Opret nyt medie
				</a>
			</Fragment>
		);
	}
}

export default withToast(withEnvironment(AddNewMediaCard));