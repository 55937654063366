// @flow
import { CATEGORY_GROUP_BY_VALUE } from './CategoryGroup';

/** Customer notification subscription type. */
export type CustomerNotificationSubscriptionType = {
  defaultNotification: string,
  description: string,
  hasCategory: boolean,
  value: string,
};

/** Customer notification subscription types. */
const CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPES: CustomerNotificationSubscriptionType[] = [
  {
    defaultNotification: 'bestseller:50000:7d',
    description: 'Potentielle bestsellere',
    hasCategory: true,
    shortDescription: 'Potentielle bestsellere',
    value: 'BESTSELLER',
  },
  {
    defaultNotification: 'sudden_rise:1000:500:2d',
    description: 'Pludselige stigninger i popularitet',
    hasCategory: false,
    shortDescription: 'Pludselige stigninger',
    value: 'SUDDEN_RISE',
  },
  {
    defaultNotification: 'steadyseller:24m:500:24m',
    description: 'Steadysellers',
    hasCategory: false,
    shortDescription: 'Steadysellers',
    value: 'STEADYSELLER',
  },
];

/** Customer notification subscription type by value. */
const CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPE_BY_VALUE: {[string]: CustomerNotificationSubscriptionType} = CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPES.reduce((memo, typ) => {
  memo[typ.value] = typ;
  return memo;
}, {});

/** Notification. */
export type CustomerNotificationSubscriptionNotification = {
  type: 'BESTSELLER',
  minScore: number,
  sustainedDuration: '2d' | '7d' | '14d' | '28d',
} | {
  type: 'SUDDEN_RISE',
  percentageRise: number,
  minBaseScore: number,
  overDuration: '24h' | '2d' | '7d',
} | {
  type: 'STEADYSELLER',
  minPublishedAgo: '6m' | '12m' | '24m',
  minScore: number,
  sustainedDuration: '6m' | '12m' | '24m',
};

/** Parse notification. */
export function parseNotification(notification: string): null | CustomerNotificationSubscriptionNotification {
  // Parse bestseller notifications.
  //
  // Format: bestseller:<minimum score>:<duration over which score is sustained>
  // Example: bestseller:5000:2d
  const bestsellerMatch = notification.match(/^bestseller:(\d+):(2d|7d|14d|28d)$/);

  if (bestsellerMatch) {
    return {
      type: 'BESTSELLER',
      minScore: parseInt(bestsellerMatch[1], 10),
      sustainedDuration: bestsellerMatch[2],
    };
  }

  // Parse sudden rise notifications.
  //
  // Format: sudden_rise:<percentage rise in percent>:<minimum base>:<duration>
  // Example: sudden_rise:500:100:24h
  const suddenRiseMatch = notification.match(/^sudden_rise:(\d+):(\d+):(24h|2d|7d)$/);

  if (suddenRiseMatch) {
    return {
      type: 'SUDDEN_RISE',
      percentageRise: parseInt(suddenRiseMatch[1], 10),
      minBaseScore: parseInt(suddenRiseMatch[2], 10),
      overDuration: suddenRiseMatch[3],
    };
  }

  // Parse steadyseller notifications.
  //
  // Format: steadyseller:<minimum time since published>:<minimum score>:<duration over which score is sustained>
  // Example: steadyseller:6m:500:6m
  const steadySellerMatch = notification.match(/^steadyseller:(6m|12m|24m):(\d+):(6m|12m|24m)$/);

  if (steadySellerMatch) {
    return {
      type: 'STEADYSELLER',
      minPublishedAgo: steadySellerMatch[1],
      minScore: parseInt(steadySellerMatch[2], 10),
      sustainedDuration: steadySellerMatch[3],
    };
  }

  return null;
}

/** Serialize notification. */
export function serializeNotification(notification: CustomerNotificationSubscriptionNotification): string {
  switch (notification.type) {
    case 'BESTSELLER':
      return `bestseller:${notification.minScore}:${notification.sustainedDuration}`;

    case 'SUDDEN_RISE':
      return `sudden_rise:${notification.percentageRise}:${notification.minBaseScore}:${notification.overDuration}`;

    case 'STEADYSELLER':
      return `steadyseller:${notification.minPublishedAgo}:${notification.minScore}:${notification.sustainedDuration}`;
  }
}

/** Describe notification. */
export function describeNotification({
  categoryGroup,
  notification,
}: {
  categoryGroup: null | string,
  notification: string,
}): string {
  const parsedNotification = parseNotification(notification);
  switch (parsedNotification?.type) {
    case 'BESTSELLER': {
      const timeSustained = {
        '2d': '2 dage',
        '7d': '7 dage',
        '14d': '2 uger',
        '28d': '4 uger',
      }[parsedNotification.sustainedDuration];
      const categoryGroupDescription = CATEGORY_GROUP_BY_VALUE[categoryGroup]?.label;
      const categoryDescription = categoryGroupDescription
        ? `kategorien ${categoryGroupDescription.charAt(0).toLowerCase() + categoryGroupDescription.slice(1)}`
        : 'alle kategorier';

      return `Bøger i ${categoryDescription}, der i de seneste ${timeSustained} har haft en popularitet på mere end ${parsedNotification.minScore.toLocaleString('da-DK')}`;
    }

    case 'SUDDEN_RISE': {
      const timeRange = {
        '24h': '24 timer',
        '2d': '2 dage',
        '7d': '7 dage',
      }[parsedNotification.overDuration];

      return `Bøger, der indenfor de sidste ${timeRange} er steget i popularitet med mere end ${parsedNotification.percentageRise.toLocaleString('da-DK')} procent fra en popularitet på ${parsedNotification.minBaseScore.toLocaleString('da-DK')} eller mere`;
    }

    case 'STEADYSELLER': {
      const timeSincePublished = {
        '12m': '12 måneder',
        '24m': '24 måneder',
        '6m': '6 måneder',
      }[parsedNotification.minPublishedAgo];
      const timeSustained = {
        '12m': '12 måneder',
        '24m': '24 måneder',
        '6m': '6 måneder',
      }[parsedNotification.sustainedDuration];

      return `Bøger der er udkommet for mere end ${timeSincePublished} siden og i de seneste ${timeSustained} har haft en popularitet på ${parsedNotification.minScore.toLocaleString('da-DK')} eller mere`;
    }

    default:
      return 'Ukendt notifikationstype';
  }
}

export {
  CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPES,
  CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPE_BY_VALUE,
};
