/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CategoryGroup = "BIOGRAFI" | "BOERN" | "DIGTE" | "ERHVERV" | "FAGLITTERATUR" | "FAGLITTERATUR_BOERN" | "FRITID" | "KALENDERE_AAR" | "KORT" | "KRIMI" | "KULTUR" | "KUNST" | "LEKSIKA" | "MAD" | "MALE" | "MUSIK_FILM" | "NATUR" | "NATURVIDENSKAB" | "ROMANER" | "SKOENLITTERATUR" | "SKOLE" | "SUNDHED" | "TEGNESERIER" | "UNGDOM" | "%future added value";
export type CustomerNotificationSubscriptionType = "BESTSELLER" | "STEADYSELLER" | "SUDDEN_RISE" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type NotificationSubscriptionForm_notificationSubscription$ref: FragmentReference;
declare export opaque type NotificationSubscriptionForm_notificationSubscription$fragmentType: NotificationSubscriptionForm_notificationSubscription$ref;
export type NotificationSubscriptionForm_notificationSubscription = {|
  +categoryGroup: ?CategoryGroup,
  +description: string,
  +id: string,
  +isDefault: boolean,
  +notification: string,
  +type: CustomerNotificationSubscriptionType,
  +$refType: NotificationSubscriptionForm_notificationSubscription$ref,
|};
export type NotificationSubscriptionForm_notificationSubscription$data = NotificationSubscriptionForm_notificationSubscription;
export type NotificationSubscriptionForm_notificationSubscription$key = {
  +$data?: NotificationSubscriptionForm_notificationSubscription$data,
  +$fragmentRefs: NotificationSubscriptionForm_notificationSubscription$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "NotificationSubscriptionForm_notificationSubscription",
  "type": "CustomerNotificationSubscription",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "categoryGroup",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isDefault",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notification",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6027b1f96f62df95ec0917fa46f7f1eb';
module.exports = node;
