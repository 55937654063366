/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PublicationCard_publication$ref = any;
export type CollectionType = "ANNUAL_PUBLICATION" | "BESTSELLER_LIST" | "BOOK_COLLECTION" | "LITERATURE_AWARD" | "OTHER" | "SERIES" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BaseTitleCard_title$ref: FragmentReference;
declare export opaque type BaseTitleCard_title$fragmentType: BaseTitleCard_title$ref;
export type BaseTitleCard_title = {|
  +id: string,
  +title: string,
  +publishingHouseDescription: ?string,
  +yearPublishedFirst: ?number,
  +datePublishedFirst: ?any,
  +yearPublishedLatest: ?number,
  +datePublishedLatest: ?any,
  +hasMoreAuthors: boolean,
  +popularityScore: number,
  +popularityScoreWeekAgo: ?number,
  +popularityScoreYesterday: ?number,
  +metaScore: number,
  +onlineMetaScore: number,
  +newspaperMetaScore: number,
  +reviewCount: number,
  +onlineReviewCount: number,
  +newspaperReviewCount: number,
  +category: ?{|
    +id: string,
    +name: string,
  |},
  +searchCategory: ?{|
    +id: string,
    +name: string,
  |},
  +authors: ?$ReadOnlyArray<{|
    +id: string,
    +name: string,
  |}>,
  +publishingHouses: ?$ReadOnlyArray<{|
    +id: string,
    +name: string,
  |}>,
  +coverImage: ?{|
    +sizes: ?$ReadOnlyArray<{|
      +name: string,
      +url: string,
    |}>
  |},
  +publications: ?$ReadOnlyArray<{|
    +id: string,
    +availability: ?string,
    +isDigital: ?boolean,
    +publishingHouse: ?{|
      +id: string
    |},
    +$fragmentRefs: PublicationCard_publication$ref,
  |}>,
  +collections: ?$ReadOnlyArray<{|
    +id: string,
    +description: ?string,
    +name: string,
    +type: ?CollectionType,
    +isVerified: boolean,
    +titles: ?$ReadOnlyArray<{|
      +id: string,
      +title: string,
      +yearPublishedFirst: ?number,
    |}>,
  |}>,
  +$refType: BaseTitleCard_title$ref,
|};
export type BaseTitleCard_title$data = BaseTitleCard_title;
export type BaseTitleCard_title$key = {
  +$data?: BaseTitleCard_title$data,
  +$fragmentRefs: BaseTitleCard_title$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "yearPublishedFirst",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v3/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "BaseTitleCard_title",
  "type": "Title",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "publishingHouseDescription",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "datePublishedFirst",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "yearPublishedLatest",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "datePublishedLatest",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasMoreAuthors",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "popularityScore",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "popularityScoreWeekAgo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "popularityScoreYesterday",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "metaScore",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "onlineMetaScore",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "newspaperMetaScore",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reviewCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "onlineReviewCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "newspaperReviewCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "category",
      "storageKey": null,
      "args": null,
      "concreteType": "Category",
      "plural": false,
      "selections": (v4/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "searchCategory",
      "storageKey": null,
      "args": null,
      "concreteType": "SearchCategory",
      "plural": false,
      "selections": (v4/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "authors",
      "storageKey": null,
      "args": null,
      "concreteType": "Entity",
      "plural": true,
      "selections": (v4/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "publishingHouses",
      "storageKey": null,
      "args": null,
      "concreteType": "PublishingHouse",
      "plural": true,
      "selections": (v4/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "coverImage",
      "storageKey": null,
      "args": null,
      "concreteType": "PublicationCoverImage",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "sizes",
          "storageKey": null,
          "args": null,
          "concreteType": "PublicationCoverImageSize",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "url",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "publications",
      "storageKey": null,
      "args": null,
      "concreteType": "Publication",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "availability",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isDigital",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "publishingHouse",
          "storageKey": null,
          "args": null,
          "concreteType": "PublishingHouse",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "PublicationCard_publication",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "collections",
      "storageKey": null,
      "args": null,
      "concreteType": "Collection",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isVerified",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "titles",
          "storageKey": null,
          "args": null,
          "concreteType": "Title",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cccc04024e3a86805fed91b3fef6a450';
module.exports = node;
