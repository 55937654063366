/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EditReviewsPaginator_query$ref: FragmentReference;
declare export opaque type EditReviewsPaginator_query$fragmentType: EditReviewsPaginator_query$ref;
export type EditReviewsPaginator_query = {|
  +newsAndReviews: ?{|
    +totalCount: number,
    +edges: ?$ReadOnlyArray<?{|
      +node: ?({|
        +__typename: "Review",
        +id: string,
        +headline: string,
        +summaryHeadline: ?string,
        +underHeading: string,
        +content: ?string,
        +originalContent: ?string,
        +quotes: ?$ReadOnlyArray<string>,
        +date: ?any,
        +grade: ?number,
        +gradeNormalized: ?number,
        +url: ?string,
        +quotesCreatedBy: ?$ReadOnlyArray<{|
          +email: string,
          +id: string,
        |}>,
        +createdBy: ?{|
          +id: string,
          +isSuperAdmin: boolean,
          +isAdministrator: boolean,
          +name: string,
        |},
        +gradingScale: ?{|
          +min: ?number,
          +max: ?number,
          +symbol: ?string,
          +precision: ?number,
        |},
        +media: ?{|
          +id: string,
          +name: string,
          +initials: string,
          +colorClass: string,
          +limitOriginalContentDisplay: boolean,
          +url: string,
          +mediaType: ?{|
            +id: string
          |},
        |},
        +reviewer: ?{|
          +id: string,
          +firstName: string,
          +lastName: string,
        |},
        +referencedTitles: ?$ReadOnlyArray<{|
          +id: string,
          +title: string,
          +hasMoreAuthors: boolean,
          +datePublishedFirst: ?any,
          +yearPublishedFirst: ?number,
          +authors: ?$ReadOnlyArray<{|
            +id: string,
            +name: string,
          |}>,
          +publishingHouses: ?$ReadOnlyArray<{|
            +id: string,
            +name: string,
          |}>,
          +coverImage: ?{|
            +sizes: ?$ReadOnlyArray<{|
              +name: string,
              +url: string,
            |}>
          |},
          +publications: ?$ReadOnlyArray<{|
            +format: ?string,
            +isbn13: ?string,
            +isDigital: ?boolean,
            +availability: ?string,
          |}>,
        |}>,
      |} | {|
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        +__typename: "%other"
      |})
    |}>,
  |},
  +viewer: {|
    +id: string,
    +isAdministrator: boolean,
    +isSuperAdmin: boolean,
  |},
  +$refType: EditReviewsPaginator_query$ref,
|};
export type EditReviewsPaginator_query$data = EditReviewsPaginator_query;
export type EditReviewsPaginator_query$key = {
  +$data?: EditReviewsPaginator_query$data,
  +$fragmentRefs: EditReviewsPaginator_query$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSuperAdmin",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdministrator",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v4/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "EditReviewsPaginator_query",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "newsAndReviews"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 25
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "orderBy",
      "type": "NewsAndReviewOrderBy",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "order",
      "type": "Order",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "filterBy",
      "type": "[ID!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "includeNews",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "includeReviews",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "includeGeneralMessages",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "mode",
      "type": "SearchMode!",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "onlyFavorites",
      "type": "Boolean!",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "newsAndReviews",
      "name": "__EditReviewsPaginator_newsAndReviews_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filterBy",
          "variableName": "filterBy"
        },
        {
          "kind": "Variable",
          "name": "includeGeneralMessages",
          "variableName": "includeGeneralMessages"
        },
        {
          "kind": "Variable",
          "name": "includeNews",
          "variableName": "includeNews"
        },
        {
          "kind": "Variable",
          "name": "includeReviews",
          "variableName": "includeReviews"
        },
        {
          "kind": "Variable",
          "name": "mode",
          "variableName": "mode"
        },
        {
          "kind": "Variable",
          "name": "onlyFavorites",
          "variableName": "onlyFavorites"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "NewsAndReviewConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "NewsAndReviewEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": null,
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "type": "Review",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "headline",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "summaryHeadline",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "underHeading",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "content",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "originalContent",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "quotes",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "date",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "grade",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "gradeNormalized",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "quotesCreatedBy",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Customer",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "email",
                          "args": null,
                          "storageKey": null
                        },
                        (v0/*: any*/)
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "createdBy",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Customer",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/)
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "gradingScale",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "GradingScale",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "min",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "max",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "symbol",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "precision",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "media",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Media",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "initials",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "colorClass",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "limitOriginalContentDisplay",
                          "args": null,
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "mediaType",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "MediaType",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "reviewer",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Reviewer",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "firstName",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "lastName",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "referencedTitles",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Title",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "title",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "hasMoreAuthors",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "datePublishedFirst",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "yearPublishedFirst",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "authors",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Entity",
                          "plural": true,
                          "selections": (v5/*: any*/)
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "publishingHouses",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "PublishingHouse",
                          "plural": true,
                          "selections": (v5/*: any*/)
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "coverImage",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "PublicationCoverImage",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "sizes",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "PublicationCoverImageSize",
                              "plural": true,
                              "selections": [
                                (v4/*: any*/),
                                (v1/*: any*/)
                              ]
                            }
                          ]
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "publications",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Publication",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "format",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "isbn13",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "isDigital",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "availability",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "viewer",
      "storageKey": null,
      "args": null,
      "concreteType": "Customer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v2/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '29c07de27c81b756e1e313f37b1eb2da';
module.exports = node;
