// @flow
import React from 'react';

import type { MainLayoutOverride } from './MainLayoutOverride';
import MainLayoutOverrideManagerContext from './MainLayoutOverrideManagerContext';

/** Main layout override manager props. */
type MainLayoutOverrideManagerProps = {
  children: React$Node,
};

/** Main layout override manager state. */
type MainLayoutOverrideManagerState = {
  /** Override. */
  mainLayoutOverride: MainLayoutOverride,
  refetchQuery: boolean,
};

/**
 * Main layout override manager.
 *
 * Contextual provider for the main layout override.
 */
class MainLayoutOverrideManager extends React.Component<MainLayoutOverrideManagerProps, MainLayoutOverrideManagerState> {
  state = {
    mainLayoutOverride: null,
	refetchQuery: false,
  };

  handleSetMainLayoutOverride = (mainLayoutOverride: MainLayoutOverride) => {
    if(mainLayoutOverride != null) {
		this.setState({
			mainLayoutOverride,
			refetchQuery: false
		  });
		 
	} else {
		this.setState({
			mainLayoutOverride,
		});
	}
  }

  handleSetRefetchQuery = (refetchQuery: boolean) => {
    this.setState({
		refetchQuery
    });
  }

  getRefetchQuery = () => {
	  return this.state.refetchQuery;
  }

  render() {
    const {
      state: { mainLayoutOverride },
      handleSetMainLayoutOverride: setMainLayoutOverride,
      handleSetRefetchQuery: setRefetchQuery,
	  getRefetchQuery,
    } = this;

    return (
      <MainLayoutOverrideManagerContext.Provider value={{
        mainLayoutOverride,
        setMainLayoutOverride,
		setRefetchQuery,
		getRefetchQuery
      }}
      >
        {this.props.children}
      </MainLayoutOverrideManagerContext.Provider>
    );
  }
}

export default MainLayoutOverrideManager;
