// @flow
import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import type { SearchSettingsProps } from './SearchSettingsProps';
import SearchSettingsContext from './SearchSettingsContext';

export default function withSearchSettings<Props: SearchSettingsProps>(Component: React$ComponentType<Props>): React$ComponentType<$Diff<Props, SearchSettingsProps>> {
  function C(props) {
    return (
      <SearchSettingsContext.Consumer>
        {searchSettingsContext => <Component {...props} {...searchSettingsContext} />}
      </SearchSettingsContext.Consumer>
    );
  }

  C.WrappedComponent = Component;
  C.displayName = `withSearchSettings(${Component.displayName || Component.name || 'unnamed'})`;

  return hoistStatics(C, Component);
}
