// @flow
import React from 'react';
import anim from 'animejs';
import classNames from 'classnames';

/** Collapsible item props. */
export type CollapsibleItemProps = {
  children?: ?React$Node,
  expanded?: ?boolean,
  initiallyExpanded?: ?boolean,
  itemClassName?: ?string,
  header?: React$Node,
  headerClassName?: ?string,
  headerHighlighted?: ?boolean,
  headerNode?: ?React$ElementType,
  onHeaderClick?: ?() => any,
};

/** Collapsible item. */
export default class CollapsibleItem extends React.Component<CollapsibleItemProps> {
  itemRef: { current: null | HTMLLIElement } = React.createRef();
  bodyRef: { current: null | HTMLDivElement } = React.createRef();
  expanded = false;
  shown = false;

  toggleExpanded() {
    // Animations are modeled after https://github.com/Dogfalo/materialize/blob/v1-dev/js/collapsible.js
    const nextExpanded = !this.expanded;
    const { itemRef: { current: item }, bodyRef: { current: body } } = this;
    this.expanded = nextExpanded;

    if (!item || !body) {
      return;
    }

    if (nextExpanded) {
      // Opening.
      item.classList.add('active');

      anim.remove(body);

      body.style.display = 'block';
      body.style.overflow = 'hidden';
      body.style.height = '0';
      body.style.paddingTop = '';
      body.style.paddingBottom = '';

      const computedStyle = getComputedStyle(body);
      const finalHeight = body.scrollHeight;
      const paddingTop = computedStyle.paddingTop;
      const paddingBottom = computedStyle.paddingBottom;

      anim({
        targets: body,
        height: finalHeight,
        paddingTop,
        paddingBottom,
        duration: 300,
        easing: 'easeInOutCubic',
        complete: (anim) => {
          body.style.overflow = '';
          body.style.paddingTop = '';
          body.style.paddingBottom = '';
          body.style.height = '';
        },
      });
    }
    else {
      // Closing.
      item.classList.remove('active');

      anim.remove(body);
      body.style.overflow = 'hidden';

      anim({
        targets: body,
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
        duration: 300,
        easing: 'easeInOutCubic',
        complete: () => {
          body.style.overflow = '';
          body.style.paddingTop = '';
          body.style.paddingBottom = '';
          body.style.height = '';
          body.style.display = '';
        },
      });
    }

    if (this.props.onHeaderClick) {
      this.props.onHeaderClick();
    }
  }

  render() {
    const {
      children,
      expanded: wantsExpanded,
      initiallyExpanded: wantsInitiallyExpanded,
      header,
      headerClassName,
      headerHighlighted,
      headerNode,
      itemClassName,
    } = this.props;

    const HeaderNode = headerNode || 'div';
	 
    if (wantsExpanded !== null && wantsExpanded !== undefined && wantsExpanded !== this.expanded) {
      setTimeout(() => this.toggleExpanded(), 0);
    }
    else if (!this.shown) {
      if (wantsInitiallyExpanded) {
        this.expanded = true;
      }

      this.shown = true;
    }

    return (
      <li className={classNames(itemClassName, this.expanded && 'active')} ref={this.itemRef}>
        <HeaderNode
          className={classNames('collapsible-header', headerHighlighted && 'highlighted', headerClassName)}
          onClick={() => this.toggleExpanded()}
        >
          {header}
        </HeaderNode>
        <div className="collapsible-body" ref={this.bodyRef} style={{ display: this.expanded ? 'block' : null }}>
          {children}
        </div>
      </li>
    );
  }
}
