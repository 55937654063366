// @flow
import 'whatwg-fetch';
import { Network } from 'relay-runtime';
import UnauthenticatedError from './UnauthenticatedError';

/**
 * Set up Relay network.
 *
 * @returns {Network} - a configured network.
 */
export function setupNetwork(): Network {
  async function fetchQuery(
    operation,
    variables,
    cacheConfig,
    uploadables,
  ) {
    const postData = new FormData();

    postData.append('operations', JSON.stringify({
      query: operation.text,
      variables,
    }));

    if (uploadables) {
      const uploadableKeys = Object.keys(uploadables).filter(key => Object.prototype.hasOwnProperty.call(uploadables, key));

      if (uploadableKeys.length) {
        // Name and map each file out.
        const map = {};

        uploadableKeys.forEach((key, idx) => {
          const field = `file_${idx}`;
          map[field] = [`variables.${key}`];
          postData.append(field, uploadables[key]);
        });

        // Rewrite uploadables.
        postData.append('map', JSON.stringify(map));
      }
    }

    const response = await fetch('/api/graphql', {
      method: 'POST',
      body: postData,
      credentials: 'same-origin',
    });

    if (response.status === 403) {
      throw new UnauthenticatedError();
    }

    return response.json();
  }

  return Network.create(fetchQuery);
}
