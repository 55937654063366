// @flow
import React, { Fragment } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { Collapsible, FormattedDate } from '../../components';
import { placeholderCoverImageForTitle } from '../../models';
import FavoriteStar from '../FavoriteStar';
import PublicationCard from '../PublicationCard';
import { FilterAdder } from '../SearchManager';
import type { BaseTitleCard_title } from './__generated__/BaseTitleCard_title.graphql';
import type { BaseTitleCard_viewer } from './__generated__/BaseTitleCard_viewer.graphql';

/** Base title card props. */
type BaseTitleCardProps = {
  excludeDigital?: ?boolean,
  excludeUnavailable?: ?boolean,
  renderScores: (title: BaseTitleCard_title) => React$Node,
  showingOnlyFavorites: boolean,
  shouldDisplayPublishingHouse: (id: ?string) => boolean,
  title: BaseTitleCard_title,
  viewer: BaseTitleCard_viewer,
  beforePublications?: React$Node,
};

/** Base title card state. */
export type BaseTitleCardState = {
  showPublications: boolean,
  showPublishingHouseDescription: boolean,
  showCollectionId: ?string,
  shownPublications: ?{ [id: string]: boolean },
};

/** Base title card. */
class BaseTitleCard extends React.Component<BaseTitleCardProps, BaseTitleCardState> {
  constructor(props) {
    super(props);

    const { showingOnlyFavorites, viewer, title: { id, publications } } = this.props;
    const favorites = viewer.favoriteIds.reduce((memo, id) => {
      memo[id] = true;
      return memo;
    }, {});
    const titleIsFavorited = showingOnlyFavorites && favorites[id];

    this.state = {
      showPublications: showingOnlyFavorites && !titleIsFavorited,
      showPublishingHouseDescription: false,
      shownPublications: showingOnlyFavorites && !titleIsFavorited
        ? publications.reduce((memo, { id }) => {
          memo[id] = favorites[id];
          return memo;
        }, {})
        : null,
      showCollectionId: null,
    };
  }

  handleTogglePublishingHouseDescription = (event) => {
    event.preventDefault();
    this.setState({
      showPublishingHouseDescription: !this.state.showPublishingHouseDescription,
    });
  }

  render() {
    const {
      beforePublications,
      excludeDigital,
      excludeUnavailable,
      renderScores,
      shouldDisplayPublishingHouse,
      title: titleDoc,
      viewer,
    } = this.props;
    const {
      id: titleId,
      authors,
      category,
      coverImage,
      datePublishedFirst,
      datePublishedLatest,
      hasMoreAuthors,
      publications: allPublications,
      publishingHouseDescription,
      publishingHouses: titlePublishingHouses,
      searchCategory,
      title,
      yearPublishedFirst,
      yearPublishedLatest,
      collections,
    } = titleDoc;
    const {
      showPublications,
      showPublishingHouseDescription,
      shownPublications,
      showCollectionId,
    } = this.state;
    const coverImageUrl = coverImage?.sizes?.find(({ name }) => name === '450x')?.url;
    const publishingHouses = titlePublishingHouses?.filter(ph => shouldDisplayPublishingHouse(ph.id));

    let unorderedPublications = allPublications?.filter(({ id, publishingHouse }) => (!shownPublications || shownPublications[id]) && (!publishingHouse || shouldDisplayPublishingHouse(publishingHouse.id)));

    if (excludeDigital && unorderedPublications) {
      unorderedPublications = unorderedPublications.filter(({ isDigital }) => !isDigital);
    }

    if (excludeUnavailable && unorderedPublications) {
      unorderedPublications = unorderedPublications.filter(({ availability }) => availability !== 'UNAVAILABLE');
    }

    const physicalPublications = [];
    const digitalPublications = [];
    const unavailablePublications = [];

    unorderedPublications?.forEach(pub => { // eslint-disable-line no-unused-expressions
      if (pub.availability !== 'AVAILABLE') {
        unavailablePublications.push(pub);
      }
      else if (pub.isDigital) {
        digitalPublications.push(pub);
      }
      else {
        physicalPublications.push(pub);
      }
    });

    const publications = physicalPublications.concat(digitalPublications).concat(unavailablePublications);

    const publishedFirst = datePublishedFirst || yearPublishedFirst;
    const publishedLatest = datePublishedLatest || yearPublishedLatest;

    const showableCollections = (collections || []).filter(({ type, isVerified }) => type === 'SERIES' && isVerified);
    showableCollections.sort((a, b) => (a.titles?.length || 0) - (b.titles?.length || 0));

    return (
      <FilterAdder>
        {({ addFilter }) =>
          <Fragment>

            <div className="card z-depth-1 horizontal">
              <div className="card-image cover-horizontal">
                <div
                  className="book-cover-list activator"
                  style={{ backgroundImage: `url(${coverImageUrl || placeholderCoverImageForTitle(titleDoc)})` }}
                  onClick={() => addFilter({
                    id: titleId,
                    type: 'Title',
                    label: title,
                  })}
                />
                {(searchCategory || category) &&
        <span
          className="cat-title white grey-text text-darken-3 activator"
          onClick={this.handleTogglePublishingHouseDescription}
        >
          {searchCategory?.name ?? category?.name}
        </span>}
              </div>
              <div className="card-stacked">
                <div className="card-content">
                  <FavoriteStar
                    id={titleId}
                    viewer={viewer}
                    description="Denne titel"
                  />

                  <div className="card-text-horizontal">

                    <a
                      className="standard-color book-title"
                      onClick={() => addFilter({
                        id: titleId,
                        type: 'Title',
                        label: title,
                      })}
                    >
                      {title}
                    </a>

                    {showableCollections.filter((_, idx) => idx < 2).map(({ id, name, type, titles }) =>
                      <div key={id}>
                        <a
                          className="standard-color book-author"
                          onClick={() => addFilter({
                            id,
                            type: 'Collection',
                            label: name,
                            subtype: type,
                          })}
                        >
                          {name}
                        </a>
                        {' #'}{titles.findIndex(t => t.id === titleId) + 1}
                      </div>
                    )}

                    {authors &&
              authors.length > 0 &&
               <div>
                 {authors.map((author, idx) =>
                   <Fragment key={idx}>
                     {idx > 0 ? ', ' : null}
                     <a
                       className="standard-color book-author"
                       onClick={() => addFilter({
                         id: author.id,
                         type: 'Entity',
                         label: author.name,
                       })}
                     >
                       {author.name}
                     </a>
                   </Fragment>)}

                 {hasMoreAuthors &&
                 ' m.fl.'}

               </div>}
                    <div className="book-info">
                      <div className="publisher">
                        {publishingHouses &&
                   publishingHouses.length > 0 &&
                   <Fragment>
                     <span className="black-text">forlag:</span>{' '}
                     <a
                       className="standard-color"
                       onClick={() => addFilter({
                         id: publishingHouses[0].id,
                         type: 'PublishingHouse',
                         label: publishingHouses[0].name,
                       })}
                     >
                       {publishingHouses[0].name}
                     </a>
                     <br />
                   </Fragment>}
                        {publishingHouseDescription &&
                   <Fragment>
                     <span className="black-text">forlagets </span>
                     <a
                       className="standard-color activator"
                       onClick={this.handleTogglePublishingHouseDescription}
                     >
                       beskrivelse
                     </a>
                   </Fragment>}
                      </div>
                      {publishedFirst &&
                <div className="edition-date black-text">
                  første udgivelsesdato:{' '}
                  {datePublishedFirst
                    ? <FormattedDate value={publishedFirst} />
                    : yearPublishedFirst}
                </div>}
                      {publishedLatest &&
                 publishedLatest !== publishedFirst &&
                 yearPublishedFirst !== yearPublishedLatest &&
                <div className="edition-date black-text">
                  seneste udgivelsesdato:{' '}
                  {datePublishedLatest
                    ? <FormattedDate value={publishedLatest} />
                    : yearPublishedLatest}
                </div>}
                    </div>
                  </div>
                  {renderScores(titleDoc)}
                </div>
                <div className="action-more">
                  <a
                    className="standard-color"
                    onClick={evt => {
                      evt.preventDefault();
                      this.setState({
                        showPublications: !showPublications,
                      });
                    }}
                  >
                    {showPublications
                      ? 'FJERN UDGAVER'
                      : 'VIS UDGAVER'}
                  </a>

                  {showableCollections.map(({ id, name }, idx) =>
                    idx === 0 &&
                  <a
                    className="standard-color"
                    onClick={evt => {
                      evt.preventDefault();
                      this.setState({
                        showCollectionId: id,
                      });
                    }}
                    key={id}
                  >
                    VIS HELE SERIEN
                  </a>
                  )}
                </div>
              </div>

              {publishingHouseDescription &&
          <div
            className="card-reveal publishing-house-description"
            style={showPublishingHouseDescription ? { display: 'block', transform: 'translateY(-100%)' } : null}
          >
            <span
              className="card-title grey-text text-darken-4"
              onClick={() => this.setState({ showPublishingHouseDescription: false })}
            >
            Forlagets beskrivelse:
              <i className="material-icons right">close</i>
            </span>
            <p dangerouslySetInnerHTML={{ __html: publishingHouseDescription }} />
          </div>}

              {showableCollections.map(({ id, description, name, type, titles }) =>
                <div
                  key={id}
                  className="card-reveal publishing-house-description"
                  style={showCollectionId === id ? { display: 'block', transform: 'translateY(-100%)' } : null}
                >
                  <span
                    className="card-title grey-text text-darken-4"
                    onClick={() => this.setState({ showCollectionId: null })}
                  >
                    {name}:
                    <i className="material-icons right">close</i>
                  </span>
                  {description &&
             <p dangerouslySetInnerHTML={{ __html: description }} />}
                  <p>
                    {titles.map(({ id, title, yearPublishedFirst }, idx) =>
                      <Fragment key={id}>
                        {`#${idx + 1} `}
                        <a
                          className="standard-color"
                          onClick={() => addFilter({
                            id,
                            type: 'Title',
                            label: title,
                          })}
                        >
                          {title}
                        </a>
                        {yearPublishedFirst && ` (${yearPublishedFirst})`}
                        <br />
                      </Fragment>
                    )}
                  </p>
                </div>
              )}

            </div>

            {beforePublications}

            <div className="publications">
              <Collapsible>
                <Collapsible.Item
                  expanded={showPublications}
                  headerClassName="thin"
                >
                  {publications.map(publication => <PublicationCard key={publication.id} publication={publication} viewer={viewer} />)}
                </Collapsible.Item>
              </Collapsible>
            </div>
          </Fragment>}
      </FilterAdder>
    );
  }
}

export default createFragmentContainer(
  BaseTitleCard, {
    title: graphql`
fragment BaseTitleCard_title on Title {
  id
  title
  publishingHouseDescription
  yearPublishedFirst
  datePublishedFirst
  yearPublishedLatest
  datePublishedLatest
  hasMoreAuthors
  popularityScore
  popularityScoreWeekAgo
  popularityScoreYesterday
  metaScore
  onlineMetaScore
  newspaperMetaScore
  reviewCount
  onlineReviewCount
  newspaperReviewCount

  category {
    id
    name
  }

  searchCategory {
    id
    name
  }

  authors {
    id
    name
  }

  publishingHouses {
    id
    name
  }

  coverImage {
    sizes {
      name
      url
    }
  }

  publications {
    id
    availability
    isDigital

    publishingHouse {
      id
    }

    ...PublicationCard_publication
  }

  collections {
    id
    description
    name
    type
    isVerified

    titles {
      id
      title
      yearPublishedFirst
    }
  }
}`,
    viewer: graphql`
fragment BaseTitleCard_viewer on Customer {
  favoriteIds

  ...FavoriteStar_viewer
  ...PublicationCard_viewer
}`,
  }
);
