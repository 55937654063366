// @flow

/** Category group. */
export type CategoryGroup = {
  value: string,
  label: string,
  filterBy: string[],
  parentGroup: null | string,
};

/** Category groups. */
const CATEGORY_GROUPS: CategoryGroup[] = [
  // Skoenlitteratur.
  {
    value: 'SKOENLITTERATUR',
    label: 'Skønlitteratur',
    filterBy: ['Q2F0ZWdvcnk6Mg=='],
    parentGroup: null,
  },
  {
    value: 'ROMANER',
    label: 'Romaner og noveller',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MTI='],
    parentGroup: 'SKOENLITTERATUR',
  },
  {
    value: 'KRIMI',
    label: 'Krimi og spænding',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6Mg=='],
    parentGroup: 'SKOENLITTERATUR',
  },
  {
    value: 'DIGTE',
    label: 'Digte',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MTU='],
    parentGroup: 'SKOENLITTERATUR',
  },
  {
    value: 'TEGNESERIER',
    label: 'Tegneserier/graphic novels',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6MjE=',
      'U2VhcmNoQ2F0ZWdvcnk6NjE=',
      'U2VhcmNoQ2F0ZWdvcnk6NjI=',
    ],
    parentGroup: 'SKOENLITTERATUR',
  },
  {
    value: 'UNGDOM',
    label: 'Ungdomsbøger',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MjY='],
    parentGroup: 'SKOENLITTERATUR',
  },
  {
    value: 'BOERN',
    label: 'Børne- og billedbøger',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6Mw==',
      'U2VhcmNoQ2F0ZWdvcnk6Njk=',
    ],
    parentGroup: 'SKOENLITTERATUR',
  },

  // Biografi.
  {
    value: 'BIOGRAFI',
    label: 'Biografier og erindringer',
    filterBy: ['Q2F0ZWdvcnk6MzE='],
    parentGroup: null,
  },

  // Faglitteratur.
  {
    value: 'FAGLITTERATUR',
    label: 'Faglitteratur',
    filterBy: ['Q2F0ZWdvcnk6MQ=='],
    parentGroup: null,
  },
  {
    value: 'MAD',
    label: 'Mad og drikke',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MzI='],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'SUNDHED',
    label: 'Krop, sind og sundhed',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6NA==', 'U2VhcmNoQ2F0ZWdvcnk6NTA='],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'KULTUR',
    label: 'Historie, kultur, religion og filosofi',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6MzU=',
      'U2VhcmNoQ2F0ZWdvcnk6NjY=',
      'U2VhcmNoQ2F0ZWdvcnk6MTE=',
      'U2VhcmNoQ2F0ZWdvcnk6NjM=',
      'U2VhcmNoQ2F0ZWdvcnk6NTI=',
      'U2VhcmNoQ2F0ZWdvcnk6Mzk=',
    ],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'ERHVERV',
    label: 'Politik, økonomi, erhverv og jura',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6OA==',
      'U2VhcmNoQ2F0ZWdvcnk6NDY=',
      'U2VhcmNoQ2F0ZWdvcnk6NjA=',
      'U2VhcmNoQ2F0ZWdvcnk6NjU=',
    ],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'MUSIK_FILM',
    label: 'Musik, film og teater',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6MTM=',
      'U2VhcmNoQ2F0ZWdvcnk6NDI=',
      'U2VhcmNoQ2F0ZWdvcnk6Njg=',
    ],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'KUNST',
    label: 'Kunst, design og arkitektur',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6Nw==',
      'U2VhcmNoQ2F0ZWdvcnk6NDM=',
    ],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'MALE',
    label: 'Malebøger',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6NjQ='],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'FRITID',
    label: 'Bolig, sport og fritid',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6MTQ=',
      'U2VhcmNoQ2F0ZWdvcnk6NTE=',
    ],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'NATUR',
    label: 'Natur og husdyr',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6Ng=='],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'NATURVIDENSKAB',
    label: 'Naturvidenskab, matematik og IT',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6MTA=',
      'U2VhcmNoQ2F0ZWdvcnk6Mzg=',
    ],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'FAGLITTERATUR_BOERN',
    label: 'Faglitteratur for børn',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MTk='],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'KORT',
    label: 'Kort og rejseførere',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6MzY='],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'KALENDERE_AAR',
    label: 'Kalendere og årspublikationer',
    filterBy: [
      'U2VhcmNoQ2F0ZWdvcnk6Njc=',
      'U2VhcmNoQ2F0ZWdvcnk6MTc=',
    ],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'LEKSIKA',
    label: 'Leksika og ordbøger',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6NQ=='],
    parentGroup: 'FAGLITTERATUR',
  },
  {
    value: 'SKOLE',
    label: 'Skole- og lærebøger',
    filterBy: ['U2VhcmNoQ2F0ZWdvcnk6OQ=='],
    parentGroup: 'FAGLITTERATUR',
  },
];

const CATEGORY_GROUP_BY_VALUE: { [string]: CategoryGroup } = CATEGORY_GROUPS.reduce((memo, group) => {
  memo[group.value] = group;
  return memo;
}, {});

export { CATEGORY_GROUPS, CATEGORY_GROUP_BY_VALUE };
