// @flow

const placeholderCoverImages = [
  'https://d1ixebpu9pg2je.cloudfront.net/assets/images/placeholders/md-1-682bbce69dc7eccabb7b9f95e2b4e82e.jpg',
  'https://d1ixebpu9pg2je.cloudfront.net/assets/images/placeholders/md-10-0c869e23baea20a5ce435fe474b45356.jpg',
  'https://d1ixebpu9pg2je.cloudfront.net/assets/images/placeholders/md-11-6247d6c588652c2d91313b2f2ce4054f.jpg',
  'https://d1ixebpu9pg2je.cloudfront.net/assets/images/placeholders/md-12-c929f6fbac621645c4d1fbf768ee0bef.jpg',
  'https://d1ixebpu9pg2je.cloudfront.net/assets/images/placeholders/md-2-fb3b8971c0395ea599e9308172494340.jpg',
  'https://d1ixebpu9pg2je.cloudfront.net/assets/images/placeholders/md-22-3b820a686cfe237a175ba1345ebc2f1a.jpg',
  'https://d1ixebpu9pg2je.cloudfront.net/assets/images/placeholders/md-23-2902ddcf408ed8ad17de322940dafbd8.jpg',
  'https://d1ixebpu9pg2je.cloudfront.net/assets/images/placeholders/md-24-fb66b01eff7a255d5a674009ff80074f.jpg',
  'https://d1ixebpu9pg2je.cloudfront.net/assets/images/placeholders/md-8-6cf97a247421a8cd3c880774190e94d3.jpg',
];

/** Placeholder cover image for title. */
export function placeholderCoverImageForTitle({ id: encodedId }) {
  try {
    const strId = atob(encodedId).split(':')[1];
    const id = parseInt(strId, 10);

    return placeholderCoverImages[id % placeholderCoverImages.length];
  }
  catch {
    return placeholderCoverImages[0];
  }
}
