/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type MessageStatus = "ACTIVE" | "INACTIVE" | "TIMED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MessageCard_message$ref: FragmentReference;
declare export opaque type MessageCard_message$fragmentType: MessageCard_message$ref;
export type MessageCard_message = {|
  +id: string,
  +activeFrom: ?any,
  +body: ?string,
  +canEdit: boolean,
  +includeLinkToBogportalen: boolean,
  +isActive: boolean,
  +status: MessageStatus,
  +subject: string,
  +timestampCreated: any,
  +sender: {|
    +id: string,
    +name: string,
    +type: string,
    +isAdministrator: boolean,
    +group: ?{|
      +name: string,
      +type: string,
      +parentGroups: $ReadOnlyArray<{|
        +name: string
      |}>,
    |},
  |},
  +links: $ReadOnlyArray<{|
    +url: string,
    +description: string,
  |}>,
  +attachments: $ReadOnlyArray<{|
    +description: string,
    +file: {|
      +url: string
    |},
  |}>,
  +referencedPublications: ?$ReadOnlyArray<{|
    +isbn13: ?string
  |}>,
  +aggregateReferencedTitles: ?$ReadOnlyArray<{|
    +title: string
  |}>,
  +referencedEntities: ?$ReadOnlyArray<{|
    +name: string
  |}>,
  +referencedPublishingHouses: ?$ReadOnlyArray<{|
    +name: string
  |}>,
  +$refType: MessageCard_message$ref,
|};
export type MessageCard_message$data = MessageCard_message;
export type MessageCard_message$key = {
  +$data?: MessageCard_message$data,
  +$fragmentRefs: MessageCard_message$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MessageCard_message",
  "type": "Message",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "activeFrom",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "body",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "canEdit",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "includeLinkToBogportalen",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isActive",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subject",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "timestampCreated",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sender",
      "storageKey": null,
      "args": null,
      "concreteType": "Customer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isAdministrator",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "group",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomerGroup",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "parentGroups",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomerGroup",
              "plural": true,
              "selections": (v3/*: any*/)
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "links",
      "storageKey": null,
      "args": null,
      "concreteType": "MessageLink",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v5/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "attachments",
      "storageKey": null,
      "args": null,
      "concreteType": "MessageAttachhment",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "file",
          "storageKey": null,
          "args": null,
          "concreteType": "File",
          "plural": false,
          "selections": [
            (v4/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "referencedPublications",
      "storageKey": null,
      "args": null,
      "concreteType": "Publication",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isbn13",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "aggregateReferencedTitles",
      "storageKey": null,
      "args": null,
      "concreteType": "Title",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "title",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "referencedEntities",
      "storageKey": null,
      "args": null,
      "concreteType": "Entity",
      "plural": true,
      "selections": (v3/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "referencedPublishingHouses",
      "storageKey": null,
      "args": null,
      "concreteType": "PublishingHouse",
      "plural": true,
      "selections": (v3/*: any*/)
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '8200e155a84df8ddf3087c9ac5302513';
module.exports = node;
