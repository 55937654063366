// @flow
import React from 'react';

import FieldWrapper from '../FieldWrapper';

/** Input props. */
export type InputProps = {
  value: ?string,
  onChange: (value: string) => any,
  type?: string,
  autoComplete?: string,
  label: string,
  disabled?: ?boolean,
  maxLength?: ?number,
  validate?: ?boolean
};

/** Input state. */
type InputState = {
  focused: boolean,
};

/** Input. */
export default class Input extends React.Component<InputProps, InputState> {
  state = {
    focused: false,
  };

  handleFocus = () => {
    this.setState({
      focused: true,
    });
  }

  handleBlur = () => {
    this.setState({
      focused: false,
    });
  }

  handleChange = (evt) => {
    this.props.onChange(evt.target.value);
  }

  render() {
    const {
      autoComplete,
      disabled,
      label,
      maxLength,
      type,
      value,
	  validate
    } = this.props;
    const { focused } = this.state;
    const trimmedValue = (value || '').trim();

    return (
      <FieldWrapper
        label={label}
        hasValue={!!trimmedValue}
        focused={focused}
      >
        <input
		  className={validate ? 'validate' : ''}
          autoComplete={autoComplete}
          disabled={disabled}
          maxLength={maxLength}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          type={type || 'text'}
          value={value || ''}
        />
      </FieldWrapper>
    );
  }
}
