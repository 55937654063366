// @flow

/**
 * Format possessive name.
 */
export function formatPossessiveName(name) {
  const lastChar = name.length && name[name.length - 1];

  switch (lastChar) {
    case 's':
    case 'S':
    case 'x':
    case 'X':
      return `${name}'`;

    default:
      return `${name}s`;
  }
}
