// @flow
import React from 'react';
import queryString from 'query-string';

import { CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPES, type SearchFilter } from '../../models';
import { withRouter, type RouterProps } from '../../utils/router';
import parseSearchFilters from './parseSearchFilters';
import SearchSettingsContext from './SearchSettingsContext';
import { VALID_PUBLISHED_VALUES, type SearchPublished } from './SearchPublished';
import {
  CATEGORY_OPTIONS,
  FILTER_BY_BY_CATEGORY_VALUE,
  FILTER_BY_BY_MEDIA_TYPE_VALUE,
  MEDIA_TYPE_OPTIONS,
  VALID_CATEGORY_VALUES,
  VALID_MEDIA_TYPE_VALUES,
  VALID_MESSAGES_MODE_OPTIONS,
} from './data';
import type { SearchSettings } from './SearchSettings';

/** All notification types. */
const ALL_NOTIFICATION_TYPES = CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPES.map(({ value }) => value);
ALL_NOTIFICATION_TYPES.sort();

/** Default notification types. */
const DEFAULT_NOTIFICATION_TYPES = ALL_NOTIFICATION_TYPES;

/** Search settings provider props. */
export type SearchSettingsProviderProps = {
  children: React$Node,
  defaultExcludeDigital: boolean,
  defaultExcludeUnavailable: boolean,
  defaultMediaTypes: ?string[],
  defaultPublished: SearchPublished,
};

/** Search settings provider internal props. */
type SearchSettingsProviderInternalProps = {
  children: React$Node,
  defaultExcludeDigital: boolean,
  defaultExcludeUnavailable: boolean,
  defaultMediaTypes: ?string[],
  defaultPublished: SearchPublished,
} & RouterProps<{}>;

/**
 * Search settings provider.
 *
 * Parses search settings from the current URL and exposes an interface for manipulating the URL and thus the search
 * settings.
 */
class SearchSettingsProvider extends React.Component<SearchSettingsProviderInternalProps> {
  preFavoritesQuery: ?Object = null;

  render() {
    const {
      children,
      defaultExcludeDigital,
      defaultExcludeUnavailable,
      defaultMediaTypes,
      defaultPublished,
      location,
      history,
    } = this.props;
    const query = queryString.parse(location.search);
    const queryMediaTypes = query.media_types === 'all'
      ? null
      : (query.media_types || '').split(',').filter(t => VALID_MEDIA_TYPE_VALUES[t]);
    const categories = (query.categories || '').split(',').filter(c => VALID_CATEGORY_VALUES[c]);
    const mediaTypes = queryMediaTypes === null ? null : queryMediaTypes.length === 0 ? defaultMediaTypes : queryMediaTypes;
    const filters = parseSearchFilters(query.filters);

    function pushQuery(newQuery) {
      history.push({
        ...location,
        search: queryString.stringify({
          ...query,
          ...newQuery,
        }),
      });
    }

    let filterBy = [];
    const searchQueryParts = [];

    categories.forEach(value => { filterBy = filterBy.concat(FILTER_BY_BY_CATEGORY_VALUE[value]) });
    if (mediaTypes) {
      mediaTypes.forEach(value => { filterBy = filterBy.concat(FILTER_BY_BY_MEDIA_TYPE_VALUE[value]) });
    }

    filters.forEach(({ id, type, value }) => {
      /*
      if (type === 'QueryString') {
        searchQueryParts.push(value);
      }
      else {
        filterBy.push(id);
      }
      */

      filterBy.push(id);
    });

    const rawNotificationTypes = query.notification_types?.split(',') || [];
    const notificationTypes = query.notification_types
      ? ALL_NOTIFICATION_TYPES.filter(typ => rawNotificationTypes.includes(typ))
      : DEFAULT_NOTIFICATION_TYPES;

    filterBy.sort();
    const messagesMode = VALID_MESSAGES_MODE_OPTIONS[query.messages] || 'active';

    const searchSettings: SearchSettings = {
      categories: categories.length ? categories : null,
      excludeDigital: query.exclude_digital ? query.exclude_digital === '1' : defaultExcludeDigital,
      excludeUnavailable: query.exclude_unavailable ? query.exclude_unavailable === '1' : defaultExcludeUnavailable,
      mediaTypes,
      onlyFavorites: query.only_favorites === '1',
      messagesMode,
      published: VALID_PUBLISHED_VALUES[query.published] || defaultPublished,
      showNews: query.show_news !== '0' || query.show_reviews === '0',
      showReviews: query.show_reviews !== '0',
      showNotifications: query.show_notifications !== '0',
      showOnlyNotifications: query.show_only_notifications === '1',
      sort: query.sort,
      searchQuery: searchQueryParts.join(' ') || null,
      filters,
      filterBy,
      notificationTypes,
	  messages: null
    };

    const searchSettingsContext = {
      searchSettings,
      setExcludeDigital: (nextExcludeDigital: boolean) => pushQuery({
        exclude_digital: nextExcludeDigital === defaultExcludeDigital ? undefined : nextExcludeDigital ? '1' : '0',
      }),
      setExcludeUnavailable: (nextExcludeUnavailable: boolean) => pushQuery({
        exclude_unavailable: nextExcludeUnavailable === defaultExcludeUnavailable ? undefined : nextExcludeUnavailable ? '1' : '0',
      }),
      setMessagesMode: (nextMessagesMode: 'sent' | 'active' | 'all') => pushQuery({
        messages: nextMessagesMode === 'active' ? undefined : nextMessagesMode,
      }),
      setOnlyFavorites: (nextOnlyFavorites: boolean) => {
        if (nextOnlyFavorites) {
          this.preFavoritesQuery = query;

          pushQuery({
            only_favorites: '1',
            categories: undefined,
            filters: undefined,
            media_types: 'all',
            published: 'any',
            show_news: undefined,
            show_reviews: undefined,
            exclude_digital: '0',
            exclude_unavailable: '0',
          });
        }
        else if (this.preFavoritesQuery) {
          history.push({
            ...location,
            search: queryString.stringify(this.preFavoritesQuery),
          });
        }
        else {
          pushQuery({
            categories: undefined,
            exclude_digital: undefined,
            exclude_unavailable: undefined,
            filters: undefined,
            media_types: undefined,
            only_favorites: undefined,
            published: undefined,
            show_news: undefined,
            show_reviews: undefined,
            sort: undefined,
          });
        }
      },
      setPublished: (published: SearchPublished) => pushQuery({
        published: published === defaultPublished ? undefined : published,
      }),
      setSort: (sort: ?string) => pushQuery({
        sort: sort || undefined,
      }),
      setShowReviews: (showReviews: boolean) => pushQuery({
        show_reviews: showReviews || !searchSettings.showNews ? undefined : '0',
      }),
      setShowNews: (showNews: boolean) => pushQuery({
        show_news: showNews || !searchSettings.showReviews ? undefined : '0',
      }),
      setShowNotifications: (showNotifications: boolean) => pushQuery({
        show_notifications: showNotifications ? undefined : '0',
      }),
      setShowOnlyNotifications: (showOnlyNotifications: boolean) => pushQuery({
        show_only_notifications: showOnlyNotifications ? '1' : undefined,
      }),
      setFilters: (filters: SearchFilter[]) => pushQuery({
        categories: undefined,
        filters: (filters || []).map(({ id }) => id).join(','),
        media_types: 'all',
        only_favorites: undefined,
        published: 'any',
        show_news: undefined,
        show_reviews: undefined,
      }),
      resetMediaTypes: () => pushQuery({
        media_types: defaultMediaTypes ? 'all' : undefined,
      }),
      toggleMediaType: (mediaType: string) => {
        const nextIncludesMediaType = !searchSettings.mediaTypes || !searchSettings.mediaTypes.includes(mediaType);
        const nextMediaTypes = nextIncludesMediaType
          ? [...(mediaTypes || []), mediaType]
          : mediaTypes?.filter(t => t !== mediaType);

        pushQuery({
          media_types: nextMediaTypes?.length && !MEDIA_TYPE_OPTIONS.every(({ value }) => nextMediaTypes.includes(value))
            ? nextMediaTypes.join(',')
            : 'all',
        });
      },
      resetSearchSettings: () => pushQuery({
        categories: undefined,
        exclude_digital: undefined,
        exclude_unavailable: undefined,
        filters: undefined,
        media_types: undefined,
        messages: undefined,
        notification_types: undefined,
        only_favorites: undefined,
        published: undefined,
        show_news: undefined,
        show_notifications: undefined,
        show_only_notifications: undefined,
        show_reviews: undefined,
        sort: undefined,
      }),
      resetCategories: () => pushQuery({
        categories: undefined,
      }),
      toggleCategory: (category: string) => {
        const categories = searchSettings.categories || [];
        const categoryIncluded = categories.includes(category);
        let mainCategory = CATEGORY_OPTIONS.find(({ value }) => value === category);
        let nextCategories = [...categories];

        if (mainCategory) {
          if (categoryIncluded) {
            nextCategories = nextCategories.filter(c => c !== category);
          }
          else {
            nextCategories.push(category);
          }

          nextCategories = nextCategories.filter(c => !mainCategory?.subCategories.find(({ value }) => value === c));
        }
        else {
          mainCategory = CATEGORY_OPTIONS.find(({ subCategories }) => subCategories.find(({ value }) => value === category));

          if (categoryIncluded) {
            nextCategories = nextCategories.filter(c => c !== category);
          }
          else {
            nextCategories.push(category);

            if (mainCategory?.subCategories.every(({ value }) => nextCategories.includes(value))) {
              nextCategories.push(mainCategory?.value);
              nextCategories = nextCategories.filter(c => !mainCategory?.subCategories.find(({ value }) => value === c));
            }
            else {
              nextCategories = nextCategories.filter(c => c !== mainCategory?.value);
            }
          }
        }

        pushQuery({
          categories: nextCategories.length ? nextCategories.join(',') : undefined,
        });
      },
      toggleNotificationType: (notificationType: string) => {
        const nextNotificationTypes =
          ALL_NOTIFICATION_TYPES.filter(typ =>
            typ === notificationType
              ? !searchSettings.notificationTypes.includes(typ)
              : searchSettings.notificationTypes.includes(typ)
          );
        const nextNotificationTypesParam = nextNotificationTypes.join(',');

        pushQuery({
          notification_types: nextNotificationTypesParam === DEFAULT_NOTIFICATION_TYPES.join(',') ? undefined : nextNotificationTypesParam,
        });
      },
    };

    return (
      <SearchSettingsContext.Provider value={searchSettingsContext}>
        {children}
      </SearchSettingsContext.Provider>
    );
  }
}

export default withRouter(SearchSettingsProvider);
