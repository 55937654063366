/**
 * @flow
 * @relayHash ffdd20a1fcbada79b13314dd3fe92205
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteReviewInput = {|
  id: string,
  clientMutationId: string,
|};
export type EditReviewCardReviewDeleteMutationVariables = {|
  input: DeleteReviewInput
|};
export type EditReviewCardReviewDeleteMutationResponse = {|
  +deleteReview: ?{|
    +viewer: ?{|
      +email: string,
      +isAdministrator: boolean,
      +isSuperAdmin: boolean,
    |}
  |}
|};
export type EditReviewCardReviewDeleteMutation = {|
  variables: EditReviewCardReviewDeleteMutationVariables,
  response: EditReviewCardReviewDeleteMutationResponse,
|};
*/


/*
mutation EditReviewCardReviewDeleteMutation(
  $input: DeleteReviewInput!
) {
  deleteReview(input: $input) {
    viewer {
      email
      isAdministrator
      isSuperAdmin
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteReviewInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAdministrator",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSuperAdmin",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EditReviewCardReviewDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteReview",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteReviewPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditReviewCardReviewDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteReview",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteReviewPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "EditReviewCardReviewDeleteMutation",
    "id": null,
    "text": "mutation EditReviewCardReviewDeleteMutation(\n  $input: DeleteReviewInput!\n) {\n  deleteReview(input: $input) {\n    viewer {\n      email\n      isAdministrator\n      isSuperAdmin\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1135f95dfa5979f9432da6377f1094a1';
module.exports = node;
