// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames';

import { getColorClassForMetaScore, getReviewPrefixForMetaScore } from '../../models';
import BaseTitleCard from './BaseTitleCard';
import type { BaseTitleCard_title } from './__generated__/BaseTitleCard_title.graphql';
import type { BaseTitleCard_viewer } from './__generated__/BaseTitleCard_viewer.graphql';

/** Popularity-rendering title card props. */
type ReviewTitleCardProps = {
  excludeDigital?: ?boolean,
  excludeUnavailable?: ?boolean,
  showingOnlyFavorites: boolean,
  shouldDisplayPublishingHouse: (id: ?string) => boolean,
  title: BaseTitleCard_title,
  viewer: BaseTitleCard_viewer,
};

/** Popularity-rendering title card. */
const ReviewTitleCard: React$ComponentType<> = function ReviewTitleCard({
  beforePublications,
  excludeDigital,
  excludeUnavailable,
  shouldDisplayPublishingHouse,
  showingOnlyFavorites,
  title,
  viewer,
}: ReviewTitleCardProps) {
  return (
    <BaseTitleCard
      excludeDigital={excludeDigital}
      excludeUnavailable={excludeUnavailable}
      title={title}
      viewer={viewer}
      showingOnlyFavorites={showingOnlyFavorites}
      shouldDisplayPublishingHouse={shouldDisplayPublishingHouse}
      beforePublications={beforePublications}
      renderScores={({
        metaScore,
        newspaperMetaScore,
        newspaperReviewCount,
        onlineMetaScore,
        onlineReviewCount,
        reviewCount,
      }) =>
        <div className="pop-score right">
          <div className="minor-pop-score valign-wrapper">
            <div className={classNames(
              'small-metascore',
              getColorClassForMetaScore(newspaperMetaScore, newspaperReviewCount)
            )}
            >
              {newspaperReviewCount === 0 ? '?' : newspaperMetaScore}/100
            </div>
            {newspaperReviewCount === 0
              ? <Fragment>ingen avis-<br />anmeldelser</Fragment>
              : <Fragment>ifølge<br />{newspaperReviewCount === 1 ? '1 avis' : `${newspaperReviewCount} aviser`}</Fragment>}
          </div>

          <div className="minor-pop-score valign-wrapper">
            <div className={classNames(
              'small-metascore',
              getColorClassForMetaScore(onlineMetaScore, onlineReviewCount)
            )}
            >
              {onlineReviewCount === 0 ? '?' : onlineMetaScore}/100
            </div>
            {onlineReviewCount === 0
              ? <Fragment>ingen online-<br />anmeldelser</Fragment>
              : <Fragment>ifølge<br />{onlineReviewCount === 1 ? '1 blogger' : `${onlineReviewCount} bloggere`}</Fragment>}
          </div>

          <div className={classNames(
            'major-pop-score',
            getColorClassForMetaScore(metaScore, reviewCount)
          )}
          >
            <div className="meta-score">{reviewCount === 0 || metaScore <= 0 ? '?' : metaScore}<span className="toned">/100</span></div>
            <div className="small-text">
              {(reviewCount === 0 || metaScore <= 0)
                ? 'Endnu ingen anmeldelser'
                : <Fragment>
                  {getReviewPrefixForMetaScore(metaScore)} <span className="hide-on-small-only">bog</span><br />
                 ifølge {reviewCount} anmelder{reviewCount !== 1 && 'e'}
                </Fragment>}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ReviewTitleCard;
