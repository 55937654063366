// @flow
import classNames from 'classnames';
import { Set as ImmutableSet } from 'immutable';
import React, { Fragment } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { Collapsible, Icon } from '../../components';
import type { MessageRecipientSelector_viewer } from './__generated__/MessageRecipientSelector_viewer.graphql';

/** Tree-structured group node. */
type GroupTreeNode = {|
  +id: string,
  +name: string,
  +type: string,
  +count: number,
  +subcount: number,
  +children: TreeGroup[],
  +subIds: string[],
|};

/** Message recipient selector props. */
export type MessageRecipientSelectorProps = {
  viewer: MessageRecipientSelector_viewer,

  /** Disabled state. */
  disabled: boolean,

  /** Selected recipient IDs. */
  selectedIds: ImmutableSet<string>,

  /** Change handler. */
  onChange: (selectedIds: ImmutableSet<string>) => any,
};

/** Message recipient selector state. */
type MessageRecipientSelectorState = {
  groupTree: GroupTreeNode[],
  prevViewer: ?MessageRecipientSelector_viewer,
};

/** Message recipient selector. */
class MessageRecipientSelector extends React.Component<MessageRecipientSelectorProps, MessageRecipientSelectorState> {
  state = {
    groupTree: [],
    prevViewer: null,
  };

  static getDerivedStateFromProps(
    props: MessageRecipientSelectorProps,
    prevState: MessageRecipientSelectorState
  ): ?$Shape<MessageRecipientSelectorState> {
    if (props.viewer === prevState.viewer) {
      return null;
    }

    // Map out all the groups.
    const groups = props.viewer.messageRecipients;
    const groupById = {};
    const groupsByParentId = {};

    groups.forEach(group => {
      groupById[group.id] = group;

      if (group.parentId) {
        if (!groupsByParentId[group.parentId]) {
          groupsByParentId[group.parentId] = [group];
        }
        else {
          groupsByParentId[group.parentId].push(group);
        }
      }
    });

    // Determine the observable local roots.
    const localRoots = groups.filter(({ parentId }) => !groupById[parentId]);

    // Recursively build the tree.
    function recursivelyBuildTree(groups): GroupTreeNode {
      return groups.map(({ id, name, type, customerWithBogintraAccessCount }) => {
        const children = recursivelyBuildTree(groupsByParentId[id] || []);

        return {
          id,
          name,
          type,
          children,
          count: customerWithBogintraAccessCount,
          subcount: customerWithBogintraAccessCount + children.reduce((accum, { subcount }) => accum + subcount, 0),
          subIds: [id].concat(children.flatMap(({ subIds }) => subIds)),
        };
      }).filter(({ subcount }) => subcount > 0);
    }

    let groupTree = recursivelyBuildTree(localRoots);

    // Group all booksellers together if the viewer is a publishing house.
    if (props.viewer.group.type === 'PUBLISHING_HOUSE') {
      const booksellerRoots = groupTree.filter(({ type }) => type === 'BOOKSELLER');
      const publishingHouseRoots = groupTree.filter(({ type }) => type !== 'BOOKSELLER');

      if (booksellerRoots.length) {
        groupTree = [
          ...publishingHouseRoots,
          {
            id: 'BOOKSELLERS',
            name: 'Alle boghandlere',
            type: 'BOOKSELLER',
            children: booksellerRoots,
            count: 0,
            subcount: booksellerRoots.reduce((accum, { subcount }) => accum + subcount, 0),
            subIds: booksellerRoots.flatMap(({ subIds }) => subIds),
          },
        ];
      }
    }

    return {
      groupTree,
      prevViewer: props.viewer,
    };
  }

  normalizeAndCommitChange(selectedIds: ImmutableSet<string>) {
    const { onChange } = this.props;

    // Perform a depth-first cleanup.
    function cleanUp(groups) {
      groups.forEach(group => {
        if (group.children.length > 0) {
          cleanUp(group.children);

          const anyChildrenSelected = group.subIds.some(id => id !== group.id && selectedIds.has(id));

          if (anyChildrenSelected) {
            selectedIds = selectedIds.add(group.id);
          }
          else {
            selectedIds = selectedIds.remove(group.id);
          }
        }
      });
    }

    cleanUp(this.state.groupTree);

    // Remove any meta IDs.
    selectedIds = selectedIds.filter(id => id !== 'BOOKSELLERS');

    onChange(selectedIds);
  }

  renderGroupTree(groups: GroupTreeNode[]): {
    node: React$Node,
    selectedSubcount: number,
  } {
    const { disabled, selectedIds } = this.props;
    const childNodes = [];
    let selectedSubcount = 0;

    groups.forEach(({ id, name, type, count, subcount, children, subIds }) => {
      const {
        node: childrenNode,
        selectedSubcount: childrenSelectedSubcount,
      } = this.renderGroupTree(children);
      const selectedSubIds = subIds.filter(id => selectedIds.has(id));
      selectedSubcount += (selectedIds.has(id) ? count : 0) + childrenSelectedSubcount;

      const anySelected = selectedSubIds.length > 0;
      const someSelected = anySelected && selectedSubIds.length < subIds.length;

      childNodes.push(
        <Collapsible.Item
          key={id}
          header={
            <Fragment>
              <label onClick={evt => evt.stopPropagation()}>
                <input
                  disabled={disabled}
                  type="checkbox"
                  className={classNames(
                    'filled-in',
                    someSelected && 'indeterminate'
                  )}
                  checked={anySelected}
                  onChange={evt => {
                    evt.stopPropagation();

                    const subIdsSet = ImmutableSet(subIds);
                    const nextSelectedIds = !anySelected || someSelected
                      ? selectedIds.union(subIdsSet)
                      : selectedIds.subtract(subIdsSet);

                    this.normalizeAndCommitChange(nextSelectedIds);
                  }}
                />
                <span>{name}</span>
              </label>
              {children.length > 0 &&
               <Icon name="arrow_left" />}

              <span className="right headline3 hide-on-small-only">
                {subcount === 1 ? '1 modtager' : `${subcount} modtagere`}
              </span>
            </Fragment>
          }
        >
          {childrenNode}
        </Collapsible.Item>
      );
    });

    return {
      node: childNodes.length
        ? <Collapsible>{childNodes}</Collapsible>
        : null,
      selectedSubcount,
    };
  }

  render() {
    const {
      node: groups,
      selectedSubcount,
    } = this.renderGroupTree(this.state.groupTree);

    return (
      <div className="message-recipient-selector">
        {groups}

        <div className="bottom-headline">
          Antal modtagere: {selectedSubcount}
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(
  MessageRecipientSelector, {
    viewer: graphql`
fragment MessageRecipientSelector_viewer on Customer {
  group {
    type
  }

  messageRecipients {
    id
    parentId
    name
    type
    customerWithBogintraAccessCount
  }
}`,
  }
);
