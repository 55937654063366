// @flow
import React from 'react';
import { commitMutation, createFragmentContainer, graphql } from 'react-relay';

import { withToast, type ToastProps } from '../../components';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';

import type { FavoriteStar_viewer } from './__generated__/FavoriteStar_viewer.graphql';

/** Add favorite mutation. */
const addFavoriteMutation = graphql`
mutation FavoriteStarAddFavoriteMutation($input: AddFavoriteInput!) {
  addFavorite(input: $input) {
    customer {
      favoriteIds
    }
  }
}`;

/** Remove favorite mutation. */
const removeFavoriteMutation = graphql`
mutation FavoriteStarRemoveFavoriteMutation($input: RemoveFavoriteInput!) {
  removeFavorite(input: $input) {
    customer {
      favoriteIds
    }
  }
}`;

/** Favorite star props. */
type FavoriteStarProps = {
  description: string,
  id: string,
  viewer: FavoriteStar_viewer,
} & EnvironmentProps & ToastProps;

/** Favorite star state. */
type FavoriteStarState = {
  prevFavoriteIds: string[],
  isFavorite: boolean,
};

/** Favorite star. */
class FavoriteStar extends React.Component<FavoriteStarProps> {
  constructor(props: FavoriteStarProps) {
    super(props);

    this.state = {
      prevFavoriteIds: props.viewer.favoriteIds,
      isFavorite: props.viewer.favoriteIds?.includes(props.id) || false,
    };
  }

  static getDerivedStateFromProps(props: FavoriteStarProps, state: FavoriteStarState): ?$Shape<FavoriteStarState> {
    if (state.prevFavoriteIds !== props.viewer.favoriteIds) {
      return {
        prevFavoriteIds: props.viewer.favoriteIds,
        isFavorite: props.viewer.favoriteIds?.includes(props.id) || false,
      };
    }

    return null;
  }

  handleToggleClick = (evt) => {
    const { environment, id } = this.props;
    const { isFavorite } = this.state;

    evt.preventDefault();
    evt.stopPropagation();

    commitMutation(environment, {
      mutation: isFavorite ? removeFavoriteMutation : addFavoriteMutation,
      variables: {
        input: {
          id,
          clientMutationId: '1',
        },
      },
      onCompleted: () => {
        const { description, toast } = this.props;

        toast(
          isFavorite
            ? `${description} er glemt igen`
            : `${description} er gemt`
        );
      },
    });

    this.setState({
      isFavorite: !isFavorite,
    });
  }

  render() {
    const { isFavorite } = this.state;

    return (
      <span
        className="secondary-content title-list"
        onClick={this.handleToggleClick}
      >
        <i id="art180" className="material-icons">{isFavorite ? 'star' : 'star_border'}</i>
      </span>
    );
  }
}

export default createFragmentContainer(
  withToast(withEnvironment(FavoriteStar)), {
    viewer: graphql`
fragment FavoriteStar_viewer on Customer {
  favoriteIds
}`,
  }
);
