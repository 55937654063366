// @flow
import React, { Fragment } from 'react';
import { commitMutation, graphql, createFragmentContainer } from 'react-relay';

import { Icon, Modal, withToast, type ToastProps } from '../../components';
import { describeNotification, CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPE_BY_VALUE } from '../../models';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import { formatPossessiveName } from '../../utils/ownership';
import type { Viewer } from '../../models';
import NotificationSubscriptionForm from './NotificationSubscriptionForm';

/** Create notification subscription mutation. */
const createNotificationSubscriptionMutation = graphql`
mutation NotificationSubscriptionListCreateNotificationSubscriptionMutation($input: CreateCustomerNotificationSubscriptionInput!) {
  createCustomerNotificationSubscription(input: $input) {
    customerNotificationSubscription {
      id
    }

    viewer {
      ...NotificationSubscriptionList_viewer
    }
  }
}
`;

/** Update notification subscription mutation. */
const updateNotificationSubscriptionMutation = graphql`
mutation NotificationSubscriptionListUpdateNotificationSubscriptionMutation($input: UpdateCustomerNotificationSubscriptionInput!) {
  updateCustomerNotificationSubscription(input: $input) {
    viewer {
      ...NotificationSubscriptionList_viewer
    }
  }
}
`;

/** Delete notification subscription mutation. */
const deleteNotificationSubscriptionMutation = graphql`
mutation NotificationSubscriptionListDeleteNotificationSubscriptionMutation($input: DeleteCustomerNotificationSubscriptionInput!) {
  deleteCustomerNotificationSubscription(input: $input) {
    viewer {
      ...NotificationSubscriptionList_viewer
    }
  }
}`;

/** Notification subscription list props. */
export type NotificationSubscriptionListProps = {
  viewer: Viewer,
} & EnvironmentProps & ToastProps;

/** Notification subscription list state. */
export type NotificationSubscriptionListState = {
  actionState: null | {
    action: 'new',
    loading: boolean,
  } | {
    action: 'edit',
    subscription: {},
    loading: boolean,
    error: ?string,
  };
};

/** Notification subscription list. */
class NotificationSubscriptionList extends React.Component<NotificationSubscriptionListProps, NotificationSubscriptionListState> {
  state = {
    actionState: null,
  };

  handleNewClick = (evt) => {
    evt.preventDefault();

    this.setState({
      actionState: {
        action: 'new',
        loading: false,
      },
    });
  }

  handleEditSubmit = (data) => {
    const { actionState: { subscription } } = this.state;

    if (!subscription.isDefault && (!data.description || !data.description.trim())) {
      this.setState({
        actionState: {
          ...this.state.actionState,
          error: 'Beskrivelse skal udfyldes',
        },
      });

      return;
    }

    this.setState({
      actionState: {
        ...this.state.actionState,
        loading: true,
        error: null,
      },
    }, () => {
      const { environment } = this.props;
      const { actionState: { subscription } } = this.state;

      commitMutation(environment, {
        mutation: updateNotificationSubscriptionMutation,
        variables: {
          input: {
            categoryGroup: data.categoryGroup,
            clientMutationId: '1',
            description: data.description,
            id: subscription.id,
            notification: data.notification,
          },
        },
        onCompleted: (data, errors) => {
          const { toast } = this.props;

          if (errors?.length) {
            this.setState({
              actionState: {
                ...this.state.actionState,
                loading: false,
                error: 'Der er opstået en fejl.',
              },
            });
          }
          else {
            toast('Notifikationen er opdateret');

            this.setState({
              actionState: null,
            });
          }
        },
      });
    });
  }

  handleNewSubmit = (data) => {
    data.description = data.description.trim();
    if (!data.description) {
      data.description = `Ny notifikation oprettet ${new Date().toLocaleDateString('da-DK')}`;
    }

    this.setState({
      actionState: {
        ...this.state.actionState,
        loading: true,
        error: null,
      },
    }, () => {
      const { environment } = this.props;

      commitMutation(environment, {
        mutation: createNotificationSubscriptionMutation,
        variables: {
          input: {
            clientMutationId: '1',
            ...data,
          },
        },
        onCompleted: (data, errors) => {
          const { toast } = this.props;

          if (errors?.length) {
            this.setState({
              actionState: {
                ...this.state.actionState,
                loading: false,
                error: 'Der er opstået en fejl.',
              },
            });
          }
          else {
            toast('Notifikationen er oprettet');

            this.setState({
              actionState: null,
            });
          }
        },
      });
    });
  }

  handleDelete = () => {
    this.setState({
      actionState: {
        ...this.state.actionState,
        loading: true,
        error: null,
      },
    }, () => {
      const { environment } = this.props;
      const { actionState: { subscription } } = this.state;

      commitMutation(environment, {
        mutation: deleteNotificationSubscriptionMutation,
        variables: {
          input: {
            id: subscription.id,
            clientMutationId: '1',
          },
        },
        onCompleted: (data, errors) => {
          const { toast } = this.props;

          if (errors?.length) {
            this.setState({
              actionState: {
                ...this.state.actionState,
                loading: false,
                error: 'Der er opstået en fejl.',
              },
            });
          }
          else {
            toast('Notifikationen er slettet');

            this.setState({
              actionState: null,
            });
          }
        },
      });
    });
  }

  render() {
    const { actionState } = this.state;
    const { viewer } = this.props;

    return (
      <Fragment>
        <div className="section-headline">
          Rediger {formatPossessiveName(viewer.name)} notifikationer
        </div>

        {actionState?.action === 'new' &&
         <Modal
           open={true}
           onClose={() => !actionState.loading && this.setState({ actionState: null })}
         >
           <div className="modal-content">
             <div className="modal-headline">
               Opret ny notifikation
             </div>

             <NotificationSubscriptionForm
               notificationSubscription={null}
               onSubmit={this.handleNewSubmit}
               loading={actionState.loading}
               error={actionState.error}
             />
           </div>
         </Modal>}

        {actionState?.action === 'edit' &&
         <Modal
           open={true}
           onClose={() => !actionState.loading && this.setState({ actionState: null })}
         >
           <div className="modal-content">
             <div className="modal-headline">
               Rediger notifikation
             </div>

             <NotificationSubscriptionForm
               notificationSubscription={actionState.subscription}
               onSubmit={this.handleEditSubmit}
               onDelete={this.handleDelete}
               loading={actionState.loading}
               error={actionState.error}
             />
           </div>
         </Modal>}

        <div className="card">
          <a
            href="#"
            className="btn-floating halfway-fab waves-effect waves-light white modal-trigger"
            onClick={this.handleNewClick}
          >
            <Icon name="add" className="black-text" />
          </a>

          <table className="highlight">
            <thead>
              <tr>
                <th></th>
                <th>Type</th>
                <th>Beskrivelse</th>
              </tr>
            </thead>
            <tbody>
              {viewer.notificationSubscriptions?.map((sub, idx) =>
                <tr key={sub.id}>
                  <td>
                    <a
                      href="#"
                      onClick={evt => {
                        evt.preventDefault();
                        this.setState({
                          actionState: {
                            action: 'edit',
                            loading: false,
                            subscription: sub,
                            error: null,
                          },
                        });
                      }}
                    >
                      <Icon name="edit" className="standard-color edit-icon" />
                    </a>
                  </td>
                  <td>{CUSTOMER_NOTIFICATION_SUBSCRIPTION_TYPE_BY_VALUE[sub.type]?.description}</td>
                  <td>
                    {!sub.isDefault &&
                    `${sub.description}: `}
                    {describeNotification({
                       categoryGroup: sub.categoryGroup,
                       notification: sub.notification,
                    })}
                  </td>
                </tr>)}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

export default createFragmentContainer(
  withToast(withEnvironment(NotificationSubscriptionList)), {
    viewer: graphql`
fragment NotificationSubscriptionList_viewer on Customer {
  id
  name

  notificationSubscriptions {
    id
    categoryGroup
    description
    isDefault
    notification
    type

    ...NotificationSubscriptionForm_notificationSubscription
  }
}`,
  }
);
