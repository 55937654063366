// @flow
import { Set as ImmutableSet } from 'immutable';
import moment from 'moment';
import React, { Fragment } from 'react';
import { commitMutation, graphql } from 'react-relay';

import { AppLayout, Checkbox, Input, Sidebar, Textarea, withToast, type ToastProps } from '../../components';
import type { Viewer } from '../../models';
import { joinNodesNaturally } from '../../utils/language';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import MessageAttachments, { type MessageAttachment } from '../MessageAttachments';
import MessageLinks, { type MessageLink } from '../MessageLinks';
import MessageRecipientSelector from '../MessageRecipientSelector';
import MessageStatusSelector from '../MessageStatusSelector';
import type { EditMessageLayoutOverrideDataWrapperQueryResponse } from './__generated__/EditMessageLayoutOverrideDataWrapperQuery.graphql';

/** Update message mutation. */
const updateMessageMutation = graphql`
mutation EditMessageLayoutOverrideUpdateMessageMutation($input: UpdateMessageInput!) {
  updateMessage(input: $input) {
    message {
      id
      subject
      body
      status
      activeFrom
      activeTo
      includeLinkToBogportalen

      links {
        url
        description
      }

      attachments {
        description

        file {
          id
          filename
        }
      }
    }

    viewer {
      ...UnreadMessageList_viewer
    }
  }
}`;

/** Delete message mutation. */
const deleteMessageMutation = graphql`
mutation EditMessageLayoutOverrideDeleteMessageMutation($input: DeleteMessageInput!) {
  deleteMessage(input: $input) {
    message {
      isDeleted
    }

    viewer {
      ...UnreadMessageList_viewer
    }
  }
}`;

/** Print layout override props. */
export type EditMessageLayoutOverrideProps = {
  viewer: Viewer,
  query: {
    error: ?Error,
    data: ?EditMessageLayoutOverrideDataWrapperQueryResponse,
  },
} & SearchSettingsProps & EnvironmentProps & ToastProps;

/** Print layout override state. */
type EditMessageLayoutOverrideState = {
  prevData: ?EditMessageLayoutOverrideDataWrapperQueryResponse,
  status: 'ACTIVE' | 'INACTIVE' | 'TIMED',
  activeFrom: string,
  activeTo: string,
  links: MessageLink[],
  attachments: MessageAttachment[],
  subject: string,
  body: string,
  includeLinkToBogportalen: boolean,
  recipientIds: ImmutableSet<string>,
  error: ?string,
  submitting: boolean,
  referencedNodes: React$Node[],
  selectedPublicationCount: number,
  selectedPhysicalPublicationCount: number,
  shouldDelete: boolean,
};

/** Print layout override. */
class EditMessageLayoutOverride extends React.Component<EditMessageLayoutOverrideProps, EditMessageLayoutOverrideState> {
  state = {
    prevData: null,
    status: 'TIMED',
    activeFrom: moment().format('YYYY-MM-DD'),
    activeTo: moment().add(7, 'days').format('YYYY-MM-DD'),
    links: [],
    attachments: [],
    body: '',
    subject: '',
    includeLinkToBogportalen: true,
    recipientIds: ImmutableSet(),
    submitting: false,
    error: null,
    referencedNodes: [],
    selectedPublicationCount: 0,
    selectedPhysicalPublicationCount: 0,
    shouldDelete: false,
  };

  static getDerivedStateFromProps(
    props: EditMessageLayoutOverrideProps,
    prevState: EditMessageLayoutOverrideState
  ): ?$Shape<EditMessageLayoutOverrideState> {
    const data = props.query.data;
    if (!data || prevState.prevData) {
      return null;
    }

    const {
      node: {
        activeFrom,
        activeTo,
        attachments,
        body,
        includeLinkToBogportalen,
        links,
        recipientGroups,
        status,
        subject,
        aggregateReferencedTitles,
        referencedEntities,
        referencedPublishingHouses,
        referencedPublications,
      },
    } = data;

    const referencedNodes = [];
    const referencedPublicationIds = referencedPublications
      .reduce(
        (memo, { id }) => { memo.add(id); return memo },
        new Set()
      );
    const selectedPublicationCount = referencedPublications.length;
    const selectedPhysicalPublicationCount = referencedPublications.reduce(
      (memo, { isDigital }) => memo + (!isDigital ? 1 : 0),
      0,
    );

    aggregateReferencedTitles.forEach(({ id, title, publications }) => {
      const selectedCount = publications.reduce(
        (accum, { id }) => accum + (referencedPublicationIds.has(id) ? 1 : 0),
        0
      );

      if (selectedCount) {
        referencedNodes.push(
          <Fragment key={id}>
            <strong>{title}</strong>
            {selectedCount === publications.length
              ? ' (alle udgaver)'
              : ` (${selectedCount} udgave${selectedCount === 1 ? '' : 'r'})`}
          </Fragment>
        );
      }
    });

    referencedEntities.forEach(({ id, name }) => {
      referencedNodes.push(
        <strong key={id}>
          {name}
        </strong>
      );
    });

    referencedPublishingHouses.forEach(({ id, name }) => {
      referencedNodes.push(
        <strong key={id}>
          {name}
        </strong>
      );
    });

    return {
      prevData: data,
      status,
      activeFrom: activeFrom || prevState.activeFrom,
      activeTo: activeTo || prevState.activeTo,
      links: MessageLinks.deserialize(links || []),
      attachments: MessageLinks.deserialize(attachments || []),
      body,
      subject,
      includeLinkToBogportalen,
      recipientIds: ImmutableSet(recipientGroups.map(({ id }) => id)),
      referencedNodes,
      selectedPublicationCount,
      selectedPhysicalPublicationCount,
    };
  }

  handleSubmit = (evt) => {
    evt.preventDefault();

    if (this.state.loading) {
      return;
    }

    const {
      environment,
      query: {
        data: {
          node: {
            id,
          },
        },
      },
    } = this.props;
    const {
      activeFrom,
      activeTo,
      attachments: rawAttachments,
      body: rawBody,
      includeLinkToBogportalen,
      links: rawLinks,
      status,
      subject: rawSubject,
      shouldDelete,
    } = this.state;

    // Handle the deletion case.
    if (shouldDelete) {
      if (confirm('Er du sikker på at du vil slette denne meddelelse?')) {
        this.setState({
          submitting: true,
        }, () => commitMutation(environment, {
          mutation: deleteMessageMutation,
          variables: {
            input: {
              clientMutationId: '1',
              id,
            },
          },
          onCompleted: (data, errors) => {
            const { toast } = this.props;

            if (errors?.length) {
              this.setState({
                submitting: false,
                error: 'Der er opstået en fejl.',
              });
            }
            else {
              toast('Meddelelsen er slettet');

              this.props.onOverrideClose();
            }
          },
        }));
      }

      return;
    }

    // Clean and validate the data.
    const subject = rawSubject.trim();
    const body = rawBody.trim();
    const links = MessageLinks.serialize(rawLinks);
    const { attachments, uploadables } = MessageAttachments.serializeForUpdate(rawAttachments);

    if (subject === '') {
      this.setState({
        error: 'Meddelelsen skal have en overskrift.',
        submitting: false,
      });
      return;
    }

    const payload = {
      id,
      activeFrom,
      activeTo,
      attachments,
      body,
      includeLinkToBogportalen,
      links,
      status,
      subject,
    };

    this.setState({
      submitting: true,
    }, () => commitMutation(environment, {
      mutation: updateMessageMutation,
      variables: {
        input: {
          clientMutationId: '1',
          ...payload,
        },
      },
      uploadables,
      onCompleted: (data, errors) => {
        const { toast } = this.props;

        if (errors?.length) {
          this.setState({
            submitting: false,
            error: 'Der er opstået en fejl.',
          });
        }
        else {
          toast('Meddelelsen er opdateret');

          this.props.onOverrideClose();
        }
      },
    }));
  }

  render() {
    const {
      query: {
        error,
        data,
      },
    } = this.props;
    const {
      activeFrom,
      activeTo,
      attachments,
      body,
      error: submitError,
      includeLinkToBogportalen,
      links,
      recipientIds,
      status,
      subject,
      submitting,
      referencedNodes,
      selectedPublicationCount,
      selectedPhysicalPublicationCount,
      shouldDelete,
    } = this.state;

    const canSelectRecipients = data?.viewer?.messageRecipients?.length > 1;

    return (
      <AppLayout.Override
        {...AppLayout.Override.forwardProps(this.props)}
        sidebar={
          <Sidebar>

          </Sidebar>
        }
        content={
          <Fragment>
            {error &&
           'Et eller andet gik galt. Prøv venligst igen.'}

            {!data &&
           'Indlæser...'}

            {!!data &&
             <form
               className="row"
               onSubmit={this.handleSubmit}
             >
               {canSelectRecipients &&
               <div className="col s12">
                 <div className="section-headline">
                   {referencedNodes.length > 0
                     ? 'Modtagere af meddelelse om '
                     : 'Modtagere af generel meddelelse'}
                   {joinNodesNaturally(referencedNodes)}
                 </div>

                 <div className="card">
                   <MessageRecipientSelector
                     disabled
                     viewer={data.viewer}
                     selectedIds={recipientIds}
                     onChange={() => {}}
                   />
                 </div>
               </div>}

               <div className="col s12">
                 <div className="section-headline">
                   {canSelectRecipients
                     ? 'Meddelelsens indhold'
                     : referencedNodes.length > 0
                       ? 'Vælg indhold af meddelelse om '
                       : 'Vælg indhold af generel meddelelse'}
                   {!canSelectRecipients &&
                    joinNodesNaturally(referencedNodes)}
                 </div>

                 <div className="card">
                   <Input
                     value={subject}
                     label="En fængende overskrift"
                     onChange={subject => this.setState({ subject })}
                   />

                   <Textarea
                     value={body}
                     label="Yderligere oplysninger"
                     onChange={body => this.setState({ body })}
                   />

                   <Checkbox
                     label="Tilføj link til Bogportalen"
                     value={(selectedPublicationCount === 1 && selectedPhysicalPublicationCount === 1) && includeLinkToBogportalen}
                     onChange={includeLinkToBogportalen => this.setState({ includeLinkToBogportalen })}
                     disabled={!(selectedPublicationCount === 1 && selectedPhysicalPublicationCount === 1)}
                   />
                 </div>
               </div>

               <div className="col s12 m6">
                 <div className="section-headline">
                   Tilføj link
                 </div>

                 <MessageLinks
                   value={links}
                   onChange={links => this.setState({ links })}
                 />
               </div>

               <div className="col s12 m6">
                 <div className="section-headline">
                   Tilføj fil til download
                 </div>

                 <MessageAttachments
                   value={attachments}
                   onChange={attachments => this.setState({ attachments })}
                 />
               </div>

               <div className="col s12">
                 <div className="section-headline">
                   Vælg datoer for aktiv periode og gem meddelelsen
                 </div>

                 <div className="card">
                   <MessageStatusSelector
                     viewer={data.viewer}
                     status={status}
                     activeFrom={activeFrom}
                     activeTo={activeTo}
                     onChange={({ status, activeFrom, activeTo }) => this.setState({ status, activeFrom, activeTo })}
                     minDate={moment().format('YYYY-MM-DD')}
                   />

                   <Checkbox
                     label="Slet denne meddelelse"
                     value={shouldDelete}
                     onChange={shouldDelete => this.setState({ shouldDelete })}
                   />

                   <div className="row bottomrow">
                     <div className="col s12 m6">
                       <div className="red-text">{submitError}</div>
                     </div>
                     <div className="col s12 m6">
                       <button
                         className="waves-effect waves-light btn-large right standard-bg-color"
                         type="submit"
                         disabled={submitting}
                       >
                         {shouldDelete
                           ? 'SLET MEDDELELSE'
                           : 'GEM MEDDELELSE'}
                       </button>
                     </div>
                   </div>
                 </div>

               </div>
             </form>}
          </Fragment>
        }
      />
    );
  }
}

export default withToast(withEnvironment(EditMessageLayoutOverride));
