// @flow
import React, { Fragment } from 'react';

/** Join naturally. */
export function joinNaturally(items: string[]): string {
  if (items.length < 2) {
    return items.join('');
  }

  return items.slice(0, items.length - 1).join(', ') + ' og ' + items[items.length - 1];
}

/** Join Relay nodes naturally. */
export function joinNodesNaturally(items: React$Node[]): React$Node {
  if (!items.length) {
    return null;
  }

  if (items.length === 1) {
    return items[0];
  }

  return (
    <Fragment>
      {items.slice(0, items.length - 1).map((item, idx) =>
        <Fragment key={idx}>
          {idx > 0 && ', '}
          {item}
        </Fragment>
      )}
      {' og '}
      {items[items.length - 1]}
    </Fragment>
  );
}
