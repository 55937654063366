// @flow
import React, { Fragment } from 'react';

import FieldWrapper from '../FieldWrapper';

const OPAQUE_PLACEHOLDER_VALUE = 'idfijk2km!@#!@#kmxmfv____';
const OPAQUE_NULL_VALUE = 'idfijk2km!@#!@#kmxmfz____';

/** Select props. */
export type SelectProps = {
  bare?: ?boolean,
  value: ?string,
  onChange: (value: string) => any,
  label: string,
  options: {
    value: string,
    label: string,
  }[],
  placeholder?: ?string,
  disabled?: ?boolean,
};

/** Select state. */
type SelectState = {
  focused: boolean,
};

/** Select. */
export default class Select extends React.Component<SelectProps, SelectState> {
  state = {
    focused: false,
  };

  handleFocus = () => {
    this.setState({
      focused: true,
    });
  }

  handleBlur = () => {
    this.setState({
      focused: false,
    });
  }

  handleChange = (evt) => {
    this.props.onChange(!evt.target.value || evt.target.value === OPAQUE_PLACEHOLDER_VALUE || evt.target.value === OPAQUE_NULL_VALUE ? null : evt.target.value);
  }

  render() {
    const {
      bare,
      disabled,
      label,
      options,
      placeholder,
      value,
    } = this.props;
    const { focused } = this.state;

    const innerHtml = (
      <Fragment>
        <svg className="caret" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 10l5 5 5-5z"></path>
        </svg>

        <select
          value={value || ''}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          disabled={disabled}
        >
          {placeholder &&
          <option value={OPAQUE_PLACEHOLDER_VALUE}>{placeholder}</option>}
          {options.map(({ value, label }) =>
            <option
              value={value === null ? OPAQUE_NULL_VALUE : value}
              key={value}
            >
              {label}
            </option>
          )}
        </select>
      </Fragment>
    );

    if (bare) {
      return (
        <div className="input-field select-wrapper standardinput">
          {innerHtml}
        </div>
      );
    }

    return (
      <FieldWrapper
        label={label}
        hasValue={!!value || placeholder}
        focused={focused}
      >
        {innerHtml}
      </FieldWrapper>
    );
  }
}
