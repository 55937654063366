// @flow
import React from 'react';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Header from '../Header';
import AppLayoutOverride from './AppLayoutOverride';

/** Application layout container props. */
export type AppLayoutProps = {
  header: React$Node,
  sidebar: React$Node,
  content: React$Node,
  override: React$Node,
  onOverrideClose: ?() => any,
  mode: 'PUBLISHING_HOUSE' | 'STORE',
};

/** Application layout container state. */
type AppLayoutState = {
  desktopSidebarVisible: boolean,
  mobileSidebarVisible: boolean,
};

/**
 * Application layout.
 *
 * Layout wrapper for the main layout of the application.
 */
class AppLayout extends React.Component<AppLayoutProps, AppLayoutState> {
  static Override = AppLayoutOverride;

  state = {
    desktopSidebarVisible: true,
    mobileSidebarVisible: false,
  };

  render() {
    const { desktopSidebarVisible, mobileSidebarVisible } = this.state;
    const {
      content,
      header,
      mode,
      onOverrideClose,
      sidebar,
      override,
    } = this.props;

    document.body.className = classNames(
      'main-wrapper',
      mode === 'PUBLISHING_HOUSE' && 'main-wrapper--publishing-house',
    );
    document.body.style.overflow = override ? 'hidden' : null;

    return (
      <div>
        <Header
          onDesktopSidebarToggle={() => this.setState({ desktopSidebarVisible: !desktopSidebarVisible })}
          onMobileSidebarToggle={() => this.setState({ mobileSidebarVisible: !mobileSidebarVisible })}
          onOverrideClose={override ? onOverrideClose : null}
        >
          {header}
        </Header>

        <div id="main" className={classNames({
          'main--desktop-sidebar-hidden': !desktopSidebarVisible,
          'main--mobile-sidebar-visible': mobileSidebarVisible,
        })}
        >
          {sidebar}

          {content}

          <TransitionGroup>
            {override &&
             React.cloneElement(override, { onOverrideClose })}
          </TransitionGroup>

          <CSSTransition
            timeout={250}
            classNames="sidenav-overlay"
            mountOnEnter
            unmountOnExit
            in={mobileSidebarVisible}
          >
            <div
              className="sidenav-overlay"
              onClick={() => this.setState({ mobileSidebarVisible: !mobileSidebarVisible })}
            />
          </CSSTransition>
        </div>
      </div>
    );
  }
}

export default AppLayout;
