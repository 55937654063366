// @flow
import React from 'react';
import queryString from 'query-string';
import { graphql } from 'react-relay';

import { UnauthenticatedError } from '../../config';
import { AuthPage, FullscreenLoader } from '../../components';
import { QueryRenderer } from '../../utils/relay';
import { withRouter, type RouterProps } from '../../utils/router';
import type { Viewer } from '../../models';

export type ViewerManagerProps = {
  children: ({
    mode: 'PUBLISHING_HOUSE' | 'STORE',
    viewer: Viewer,
    shouldDisplayPublishingHouse: (id: ?string) => boolean,
  }) => React$Node,
} & RouterProps;

const ViewerManager: React$ComponentType<ViewerManagerProps> = function ({
  children,
  location,
}: ViewerManagerProps) {
  const { mode: modeOverride } = queryString.parse(location.search);

  return (
    <QueryRenderer
      query={graphql`
query ViewerManagerQuery {
  viewer {
    id
    name
    email
    phoneNumber
    position
    isAdministrator
    hasBogintraAccess
    address
    postalCode
    city
    type
    canDownloadBogintraData
    canDownloadBogintraPopulartiyTrendData

    messageRecipients {
      id
    }

    group {
      id
      name
      type
      subtype
      publishingHouseIds
      reference

      printTemplates {
        filename
        title
        defaultFormat
      }
    }
  }
}`}
      render={({ error, props }) => {
        if (error && error instanceof UnauthenticatedError) {
          return (
            <AuthPage>
              <h2>Velkommen til bogintra</h2>
              <p>... hvor forlag og boghandlere kan finde uddrag af alle boganmeldelser og bognyheder, holde sig orienteret om alle danske bogudgivelsers popularitet og meget andet.</p>
              <p></p>
              <p>Log ind via linket i bog.nu's daglige nyhedsbrev.</p>
            </AuthPage>
          );
        }

        if (!props) {
          return <FullscreenLoader />;
        }

        let mode = props.viewer.group.type === 'PUBLISHING_HOUSE' ? 'PUBLISHING_HOUSE' : 'STORE';

        if (props.viewer.group.type === 'PUBLISHING_HOUSE' && modeOverride) {
          mode = {
            store: 'STORE',
          }[modeOverride] || mode;
        }

        const publishingHouseIdMap = props.viewer.group.publishingHouseIds?.reduce((memo, id) => {
          memo[id] = true;
          return memo;
        }, {});

        return children({
          mode,
          viewer: props.viewer,
          shouldDisplayPublishingHouse: (id: ?string) => !id || mode === 'STORE' || !publishingHouseIdMap || !!publishingHouseIdMap[id],
        });
      }}
    />
  );
};

export default withRouter(ViewerManager);
