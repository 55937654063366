// @flow
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { MainLayoutOverrideManager } from './containers/MainLayoutOverrideManager';
import MainLayout from './containers/MainLayout';
import { SearchManagerProvider } from './containers/SearchManager';
import { SearchSettingsProvider } from './containers/SearchSettings';
import { FeaturesProvider } from './containers/FeaturesProvider';
import ViewerManager from './containers/ViewerManager';
import FaqPage from './pages/FaqPage';
import LogInPage from './pages/LogInPage';
import LogOutPage from './pages/LogOutPage';
import MessagesResultsPage from './pages/MessagesResultsPage';
import NewsResultsPage from './pages/NewsResultsPage';
import TitlesResultsPage from './pages/TitlesResultsPage';

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/login" exact component={LogInPage} />
      <Route path="/logout" exact component={LogOutPage} />

      <ViewerManager>
        {({ mode, shouldDisplayPublishingHouse, viewer }) =>
          <FeaturesProvider viewer={viewer}>
            <SearchSettingsProvider
              defaultPublished={mode === 'PUBLISHING_HOUSE' ? 'any' : 'last_4_months'}
              defaultMediaTypes={mode === 'PUBLISHING_HOUSE' ? null : ['national_newspaper', 'periodical']}
              defaultExcludeDigital={mode !== 'PUBLISHING_HOUSE'}
              defaultExcludeUnavailable={false}
              viewer={viewer}
            >
              <SearchManagerProvider>
                <MainLayoutOverrideManager>
                  <MainLayout mode={mode} viewer={viewer}>
                    <Route path="/" exact component={props => <NewsResultsPage searchMode={mode} shouldDisplayPublishingHouse={shouldDisplayPublishingHouse} {...props} />} />
                    <Route path="/meddelelser" exact component={props => <MessagesResultsPage shouldDisplayPublishingHouse={shouldDisplayPublishingHouse} {...props} />} />
                    <Route path="/mest-populaere-boeger" exact component={props => <TitlesResultsPage mode="popular" searchMode={mode} shouldDisplayPublishingHouse={shouldDisplayPublishingHouse} {...props} />} />
                    <Route path="/bedst-anmeldte-boeger" exact component={props => <TitlesResultsPage mode="reviews" searchMode={mode} shouldDisplayPublishingHouse={shouldDisplayPublishingHouse} {...props} />} />
                    <Route path="/faq" exact component={FaqPage} />
                  </MainLayout>
                </MainLayoutOverrideManager>
              </SearchManagerProvider>
            </SearchSettingsProvider>
          </FeaturesProvider>}
      </ViewerManager>
    </Switch>
  </Router>
);

export default Routes;
