// @flow
import React, { Fragment } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedNumber } from 'react-intl';

import { FormattedDate, Lightbox } from '../../components';
import FavoriteStar from '../FavoriteStar';
import type { PublicationCard_publication } from './__generated__/PublicationCard_publication.graphql';
import type { PublicationCard_viewer } from './__generated__/PublicationCard_viewer.graphql';

/** Publication card props. */
export type PublicationCardProps = {
  publication: PublicationCard_publication,
  viewer: PublicationCard_viewer,
};

/** Publication card state. */
type PublicationCardState = {
  showCoverLightbox: boolean,
};

/** Publication card. */
class PublicationCard extends React.Component<PublicationCardProps, PublicationCardState> {
  state = {
    showCoverLightbox: false,
  };

  render() {
    const {
      publication: {
        authors,
        availability,
        coverImage,
        datePublished,
        format,
        hasMoreAuthors,
        hasMoreTranslators,
        id,
        isDigital,
        isbn13,
        pageCount,
        priceRetail,
        publishingHouse,
        subtitle,
        title,
        translators,
        yearPublished,
      },
      viewer,
    } = this.props;
    const { showCoverLightbox } = this.state;
    const coverImagePreviewUrl = coverImage?.sizes?.find(({ name }) => name === '450x')?.url;
    const coverImageUrl = coverImage?.sizes?.find(({ name }) => name === '750x')?.url;

    return (
      <div
        className={availability === 'UNAVAILABLE'
          ? 'card-panel grey lighten-3'
          : 'card-panel light-light-standard-bg-color'}
      >
        {coverImagePreviewUrl &&
        <div className="publication-cover-container">
          <div className="lightbox-selector">
            <a
              href={coverImageUrl || coverImagePreviewUrl}
              onClick={evt => {
                evt.preventDefault();
                this.setState({
                  showCoverLightbox: true,
                });
              }}
            >
              <img
                className={availability === 'UNAVAILABLE' ? 'publication-cover graytone' : 'publication-cover'}
                src={coverImagePreviewUrl}
              />
            </a>
          </div>
        </div>}

        {(coverImageUrl || coverImagePreviewUrl) &&
         <Lightbox
           src={coverImageUrl || coverImagePreviewUrl}
           open={showCoverLightbox}
           onClose={() => this.setState({ showCoverLightbox: false })}
         />}

        <FavoriteStar
          description="Denne publikation"
          id={id}
          viewer={viewer}
        />

        <div><strong>{title}</strong></div>

        {subtitle &&
        <Fragment>
          undertitel: {subtitle}<br />
        </Fragment>}

        {authors?.length > 0 &&
        <Fragment>
          {authors.length > 1 || hasMoreAuthors
            ? 'forfattere: '
            : 'forfatter: '}
          {authors.map(({ name }) => name).join(', ')}
          {hasMoreAuthors && ' m.fl.'}
          <br />
        </Fragment>}

        {translators?.length > 0 &&
        <Fragment>
          {translators.length > 1 || hasMoreTranslators
            ? 'oversættere: '
            : 'oversætter: '}
          {translators.map(({ name }) => name).join(', ')}
          {hasMoreTranslators && ' m.fl.'}
          <br />
        </Fragment>}

        {publishingHouse &&
        <Fragment>
          forlag: {publishingHouse.name}
          <br />
        </Fragment>}

        {(format || pageCount > 0) &&
       <Fragment>
        format:{' '}
         {format}
         {format && pageCount > 0 && ', '}
         {pageCount > 0 && <Fragment><FormattedNumber value={pageCount} /> sider</Fragment>}
         <br />
       </Fragment>}

        {isbn13 &&
       <Fragment>
         isbn: {isbn13}<br />
       </Fragment>}

        {(datePublished || yearPublished) &&
       <Fragment>
         udgivelsesdato: {datePublished ? <FormattedDate value={datePublished} /> : yearPublished}<br />
       </Fragment>}

        {priceRetail > 0 &&
       <Fragment>
         butikspris: <FormattedNumber value={priceRetail} minimumFractionDigits={2} maximumFractionDigits={2} /><br />
       </Fragment>}

      lagerstatus:{' '}
        {availability !== 'UNAVAILABLE'
          ? 'OK'
          : <span className="red-text">UDSOLGT FRA FORLAGET</span>}
        <br />

        {!isDigital &&
        <Fragment>
          <br />

          <a href={`https://www.bogportalen.dk/webapp/wcs/stores/servlet/SimpleSearchCmd?sType=SimpleSearch&searchString=${isbn13}`} target="_blank" className="waves-effect waves-dark btn white">
            <span className="standard-color">
              Vis på Bogportalen
            </span>
          </a>
        </Fragment>}
      </div>
    );
  }
}

export default createFragmentContainer(
  PublicationCard, {
    publication: graphql`
fragment PublicationCard_publication on Publication {
  id
  title
  subtitle
  format
  pageCount
  isbn13
  datePublished
  yearPublished
  priceRetail
  availability
  isDigital
  hasMoreTranslators
  hasMoreAuthors

  authors {
    id
    name
  }

  translators {
    id
    name
  }

  publishingHouse {
    id
    name
  }

  coverImage {
    sizes {
      name
      url
    }
  }
}`,
    viewer: graphql`
fragment PublicationCard_viewer on Customer {
  ...FavoriteStar_viewer
}`,
  }
);
