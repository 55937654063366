// @flow
import React from 'react';
import { graphql } from 'react-relay';

import { AppLayout } from '../../components';
import { QueryRenderer } from '../../utils/relay';
import { withManageSearch, type ManageSearchProps } from '../SearchManager';
import NewMessageLayoutOverride from './NewMessageLayoutOverride';

function NewMessageLayoutOverrideDataWrapper({
  filters,
  ...forwardedProps
}: ManageSearchProps) {
  const nodeIds = filters.reduce((memo, { type, id }) => {
    if (type === 'Title' || type === 'Entity' || type === 'PublishingHouse') {
      memo.push(id);
    }

    return memo;
  }, []);

  return (
    <QueryRenderer
      query={graphql`
query NewMessageLayoutOverrideDataWrapperQuery($nodeIds: [ID!]!) {
  nodes(ids: $nodeIds) {
    __typename

    ... on Title {
      id
      title
      hasMoreAuthors

      publications {
        id
        isbn13
        format
        isDigital

        publishingHouse {
          id
        }
      }

      authors {
        name
      }
    }

    ... on Entity {
      id
      name
    }

    ... on PublishingHouse {
      id
      name
    }
  }

  viewer {
    group {
      type
      publishingHouseIds
    }

    messageRecipients {
      id
    }

    ...MessageRecipientSelector_viewer
    ...MessageStatusSelector_viewer
  }
}`}
      variables={{
        nodeIds,
      }}
      render={({ error, props }) => {
        return (
          <NewMessageLayoutOverride
            {...AppLayout.Override.forwardProps(forwardedProps)}
            query={{ error, data: props }}
          />
        );
      }}
    />
  );
}

export default withManageSearch(NewMessageLayoutOverrideDataWrapper);
