// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { commitMutation, graphql } from 'react-relay';
import moment from 'moment';

import { AppLayout, FloatingActionButton, Icon, TabNav, withToast, type ToastProps } from '../../components';
import { DEFAULT_TITLE_SORT_VALUE, TITLE_SORT_OPTIONS, VALID_TITLE_SORT_VALUES, type Viewer } from '../../models';
import { QueryRenderer, withEnvironment, type EnvironmentProps } from '../../utils/relay';
import { withRouter, type RouterProps } from '../../utils/router';
import { ManagedSearchBar, withManageSearch, type ManageSearchProps } from '../SearchManager';
import { withSearchSettings, type SearchSettingsProps } from '../SearchSettings';

import { withMainLayoutOverride, type MainLayoutOverrideProps } from '../MainLayoutOverrideManager';
import { withFeatures, type FeaturesProps } from '../FeaturesProvider';
import MainSidebar from '../MainSidebar';
import NewMessageLayoutOverride from '../NewMessageLayoutOverride';
import EditMessageLayoutOverride from '../EditMessageLayoutOverride';
import PublishersEditLayoutOverride from '../PublishersEditLayoutOverride';
import PrintLayoutOverride from '../PrintLayoutOverride';
import SettingsLayoutOverride from '../SettingsLayoutOverride';

/** Create link mutation. */
const createLinkMutation = graphql`
mutation MainLayoutCreateLinkMutation($input: CreateCustomerLinkInput!) {
  createCustomerLink(input: $input) {
    viewer {
      links {
        id
        url
        description
      }
    }
  }
}
`;

/** Main layout container props. */
export type MainLayoutProps = {
  children: React$Node,
  viewer: Viewer,
  mode: 'PUBLISHING_HOUSE' | 'STORE',
} & RouterProps<{}> & SearchSettingsProps & ToastProps & EnvironmentProps & MainLayoutOverrideProps & FeaturesProps & ManageSearchProps;

/**
 * Main layout container.
 *
 * Authenticated application layout.
 */
class MainLayout extends React.Component<MainLayoutProps> {
  renderOverride(): React$Node {
    const { mainLayoutOverride, viewer } = this.props;
	
    switch (mainLayoutOverride?.section) {
      case 'settings':
        return (
          <SettingsLayoutOverride
            initialPage={mainLayoutOverride.initialPage}
            viewer={viewer}
          />
        );

      case 'print':
        return (
          <PrintLayoutOverride
            viewer={viewer}
          />
        );

      case 'newMessage':
        return (
          <NewMessageLayoutOverride
            viewer={viewer}
          />
        );

      case 'editMessage':
        return (
          <EditMessageLayoutOverride
            viewer={viewer}
            id={mainLayoutOverride.id}
          />
        );

      case 'publishersEdit':
        return (
          <PublishersEditLayoutOverride
            viewer={viewer}
			{...this.props}
          />
        );

      default:
        return null;
    }
  }

  handleDownloadClick(): void {
    const { filters, mode, searchSettings, location } = this.props;
    const emulatedMode = location.pathname === '/bedst-anmeldte-boeger' ? 'reviews' : 'popular';
    const sort = VALID_TITLE_SORT_VALUES[emulatedMode][searchSettings.sort] || DEFAULT_TITLE_SORT_VALUE[emulatedMode];
    const sortOption = TITLE_SORT_OPTIONS[emulatedMode].find(({ value }) => value === sort);
    const orderBy = sortOption.orderBy;
    const order = sortOption.orderBy === 'PUBLISHED_FIRST' && (searchSettings.published === 'next_14_days' || searchSettings.published === 'future') ? 'ASC' : sortOption.order;

    const settings = {
      excludeDigital: searchSettings.excludeDigital,
      excludeUnavailable: searchSettings.excludeUnavailable,
      filterBy: (filters || []).map(({ id }) => id).join(','),
      order,
      orderBy,
      published: searchSettings.published?.toUpperCase(),
      search: searchSettings.searchQuery,
      mode,
      onlyFavorites: searchSettings.onlyFavorites,
    };

    window.open(`/api/titles?settings=${encodeURIComponent(JSON.stringify(settings))}`);
  }

  handleDownloadPopularityTrendClick(): void {
    const { mode, searchSettings, location } = this.props;
    const emulatedMode = location.pathname === '/bedst-anmeldte-boeger' ? 'reviews' : 'popular';
    const sort = VALID_TITLE_SORT_VALUES[emulatedMode][searchSettings.sort] || DEFAULT_TITLE_SORT_VALUE[emulatedMode];
    const sortOption = TITLE_SORT_OPTIONS[emulatedMode].find(({ value }) => value === sort);
    const orderBy = sortOption.orderBy;
    const order = sortOption.orderBy === 'PUBLISHED_FIRST' && (searchSettings.published === 'next_14_days' || searchSettings.published === 'future') ? 'ASC' : sortOption.order;

    const settings = {
      excludeDigital: searchSettings.excludeDigital,
      excludeUnavailable: searchSettings.excludeUnavailable,
      filterBy: searchSettings.filterBy,
      order,
      orderBy,
      published: searchSettings.published?.toUpperCase(),
      search: searchSettings.searchQuery,
      mode,
      onlyFavorites: searchSettings.onlyFavorites,
    };

    window.open(`/api/title-popularity-trends?settings=${encodeURIComponent(JSON.stringify(settings))}`);
  }

  handleSaveClick(): void {
    const { environment } = this.props;
    const now = moment();

    commitMutation(environment, {
      mutation: createLinkMutation,
      variables: {
        input: {
          clientMutationId: '1',
          url: location.href,
          description: `Bogintra-link oprettet ${now.format('D.M.YYYY')} kl. ${now.format('HH:mm')}`,
        },
      },
      onCompleted: (data, errors) => {
        const { toast } = this.props;

        if (errors?.length) {
          toast('Der er opstået en fejl.');
        }
        else {
          toast('Denne søgning er gemt i Mine links. Rediger under Indstillinger.');
        }
      },
    });
  }

  render() {
    const {
      children,
      features,
      mainLayoutOverride,
      mode,
      searchSettings,
      setMainLayoutOverride,
	  setRefetchQuery,
	  getRefetchQuery,
      viewer,
    } = this.props;

    document.body.className = classNames(
      'main-wrapper',
      mode === 'PUBLISHING_HOUSE' && 'main-wrapper--publishing-house',
    );

    return (
      <QueryRenderer
        variables={{
          excludeDigital: searchSettings.excludeDigital,
          excludeUnavailable: searchSettings.excludeUnavailable,
          filterBy: searchSettings.filterBy,
          includeNews: searchSettings.showNews,
          includeReviews: searchSettings.showReviews,
          published: searchSettings.published?.toUpperCase(),
          search: searchSettings.searchQuery,
          onlyFavorites: searchSettings.onlyFavorites,
          onlyWithNotificationTypes:
          searchSettings.showOnlyNotifications
            ? searchSettings.notificationTypes
            : null,
          mode,
		  refetchQuery: getRefetchQuery()
        }}
        query={graphql`
query MainLayoutQuery(
  $search: String,
  $filterBy: [ID!],
  $excludeDigital: Boolean,
  $excludeUnavailable: Boolean,
  $published: PublishedFilter,
  $includeNews: Boolean!,
  $includeReviews: Boolean!,
  $mode: SearchMode!,
  $onlyFavorites: Boolean!,
  $onlyWithNotificationTypes: [CustomerNotificationSubscriptionType!]
) {
  newsAndReviews(first: 0, search: $search, filterBy: $filterBy, includeNews: $includeNews, includeReviews: $includeReviews, mode: $mode, onlyFavorites: $onlyFavorites) {
    totalCount
  }

  titles(first: 0, search: $search, filterBy: $filterBy, published: $published, excludeDigital: $excludeDigital, excludeUnavailable: $excludeUnavailable, mode: $mode, onlyFavorites: $onlyFavorites, onlyWithNotificationTypes: $onlyWithNotificationTypes) {
    totalCount
  }

  viewer {
    isAdministrator
	type
    ...MainSidebar_viewer
  }
}`}
        render={({ props }) => {
          return (
            <AppLayout
              mode={mode}
              header={!mainLayoutOverride && <ManagedSearchBar />}
              sidebar={
                <MainSidebar
                  mode={mode}
                  viewer={props?.viewer || null}
                  onSettingsClick={() => setMainLayoutOverride({ section: 'settings' })}
                />
              }
              content={
                <Fragment>

                  <TabNav>
                    <TabNav.Tab count={props?.newsAndReviews?.totalCount} active linkTo="/" linkPreservesQuery={true} overrideQuery={{ sort: undefined }}>
                      Nyheder<span className="hide-on-med-and-down"> og anmeldelser</span>
                    </TabNav.Tab>
                    <TabNav.Tab count={props?.titles?.totalCount} linkTo="/mest-populaere-boeger" linkPreservesQuery={true} overrideQuery={{ sort: undefined }}>
                    Mest populære<span className="hide-on-med-and-down"> bøger</span>
                    </TabNav.Tab>
                    <TabNav.Tab count={props?.titles?.totalCount} linkTo="/bedst-anmeldte-boeger" linkPreservesQuery={true} overrideQuery={{ sort: undefined }}>
                    Bedst anmeldte<span className="hide-on-med-and-down"> bøger</span>
                    </TabNav.Tab>
                    {features.messages &&
                    <TabNav.Tab linkTo="/meddelelser" linkPreservesQuery={true} overrideQuery={{ sort: undefined }}>
                      <span style={{ fontSize: '1.3rem', lineHeight: 'inherit' }}>
                        <Icon name="message" />
                      </span>
                    </TabNav.Tab>}
                    <TabNav.Tab className="faqtab" linkTo="/faq" linkPreservesQuery={true} overrideQuery={{ sort: undefined }}>
                      <span style={{ fontSize: '1.3rem', lineHeight: 'inherit' }}>?</span>
                    </TabNav.Tab>
                  </TabNav>

                  <div className="below-navigation">
                    {children}
                  </div>

                  {!mainLayoutOverride &&
                   <FloatingActionButton>
                     {features.messages &&
                      viewer?.messageRecipients?.length > 0 &&
                      (viewer.type !== 'PUBLISHING_HOUSE' || mode === 'PUBLISHING_HOUSE') &&
                     <FloatingActionButton.Item
                       icon="message"
                       tooltip="Tilføj meddelelse"
                       onClick={() => setMainLayoutOverride({ section: 'newMessage' })}
                     />}

					{viewer.type === 'PUBLISHING_HOUSE' && mode === 'PUBLISHING_HOUSE' && viewer.isAdministrator && <FloatingActionButton.Item
                       icon="reviews"
                       tooltip="Tilføj/rediger anmeldelser"
                       onClick={() => setMainLayoutOverride({ section: 'publishersEdit' })}
                     />}

                     {viewer.group.printTemplates &&
                      viewer.group.printTemplates.length > 0 &&
                     <FloatingActionButton.Item
                       icon="print"
                       tooltip="Udskriv salgsfremmende citater"
                       onClick={() => setMainLayoutOverride({ section: 'print' })}
                     />}

                     {viewer?.canDownloadBogintraData &&
                     <FloatingActionButton.Item
                       icon="cloud_download"
                       tooltip="Download bogdata"
                       onClick={() => this.handleDownloadClick()}
                     />}

                     {viewer?.canDownloadBogintraPopulartiyTrendData &&
                     <FloatingActionButton.Item
                       icon="cloud_download"
                       tooltip="Download popularitets-trend-data"
                       onClick={() => this.handleDownloadPopularityTrendClick()}
                     />}

                     <FloatingActionButton.Item
                       icon="link"
                       tooltip="Gem denne søgning"
                       onClick={() => this.handleSaveClick()}
                     />
                   </FloatingActionButton>}
                </Fragment>
              }
              override={this.renderOverride()}
              onOverrideClose={() => {setMainLayoutOverride(null); setRefetchQuery(true);}}
            />
          );
        }}
      />
    );
  }
}

export default withManageSearch(withFeatures(withMainLayoutOverride(withEnvironment(withToast(withRouter(withSearchSettings(MainLayout)))))));
