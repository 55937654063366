// @flow
import React from 'react';

import SidebarSection from './SidebarSection';

/** Sidebar props. */
export type SidebarProps = {
  children: React$Node,
};

/** Sidebar. */
export default class Sidebar extends React.Component<SidebarProps> {
  static Section = SidebarSection;

  render() {
    return (
      <ul id="slide-out" className="sidenav sidenav-fixed">
        {this.props.children}
      </ul>
    );
  }
}
