// @flow
import React from 'react';
import { fetchQuery, graphql } from 'relay-runtime';

import authorPersonIcon from '../../images/author-person.svg';
import publisherBookIcon from '../../images/publisher-book.svg';
import reviewerPersonIcon from '../../images/reviewer-person.svg';
import mediaPrintIcon from '../../images/media-print.svg';
import mediaOnlineIcon from '../../images/media-online.svg';
import { SEARCH_FILTER_TYPE_LABEL_BY_TYPE, SEARCH_FILTER_TYPE_LABEL_BY_COLLECTION_TYPE } from '../../models';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import { SearchBar, type SearchBarFilterSuggestion } from '../../components';
import withManageSearch from './withManageSearch';
import type { ManageSearchProps } from './ManageSearchProps';

/** Managed search bar props. */
type ManagedSearchBarProps = EnvironmentProps & ManageSearchProps;

/** Managed search bar state. */
type ManagedSearchBarState = {
  suggestedQuery: ?string,
  suggestions: ?SearchBarFilterSuggestion[],
};

/** Managed search bar. */
class ManagedSearchBar extends React.Component<ManagedSearchBarProps, ManagedSearchBarState> {
  suggestQuery: ?string = null;
  suggestTimeout: ?Timeout = null;
  state = {
    suggestedQuery: null,
    suggestions: null,
  };

  fetchSuggestions = () => {
    const { environment, query: rawQuery } = this.props;
    const fetchingQuery = rawQuery.trim();

    if (!fetchingQuery) {
      return;
    }

    fetchQuery(environment, graphql`
query ManagedSearchBarSuggestionsQuery($search: String!) {
  titles(first: 5, search: $search, orderBy: POPULARITY_SCORE, order: DESC) {
    edges {
      node {
        id
        title

        authors {
          name
        }

        coverImage {
          sizes {
            name
            url
          }
        }
      }
    }
  }

  collections(first: 5, search: $search) {
    edges {
      node {
        id
        name
        type
      }
    }
  }

  entities(first: 5, search: $search) {
    edges {
      node {
        id
        name
      }
    }
  }

  reviewers(first: 5, search: $search) {
    edges {
      node {
        id
        firstName
        lastName
      }
    }
  }

  medias(first: 5, search: $search) {
    edges {
      node {
        id
        name

        mediaType {
          id
        }
      }
    }
  }

  publishingHouses(first: 5, search: $search, orderBy: TITLE_COUNT, order: DESC) {
    edges {
      node {
        id
        name
      }
    }
  }
}`, { search: fetchingQuery })
      .then(({ collections, titles, entities, reviewers, medias, publishingHouses }) => {
        if (this.props.query.trim() !== fetchingQuery) {
          return;
        }

        const suggestions: SearchBarFilterSuggestion[] = [];

        titles?.edges?.forEach(({ node: { id, title, authors, coverImage } }) => { // eslint-disable-line no-unused-expressions
          const coverImageUrl = coverImage?.sizes?.find(({ name }) => name === '80x')?.url;

          suggestions.push({
            filter: {
              id,
              type: 'Title',
              value: '',
              label: title,
            },
            description: title,
            descriptionSuffix: authors && authors.length ? `af ${authors.map(a => a.name).join(', ')}` : null,
            imageUrl: coverImageUrl,
          });
        });

        entities?.edges?.forEach(({ node: { id, name } }) => { // eslint-disable-line no-unused-expressions
          suggestions.push({
            filter: {
              id,
              type: 'Entity',
              value: '',
              label: name,
            },
            description: name,
            descriptionSuffix: ' - forfatter',
            imageUrl: authorPersonIcon,
          });
        });

        reviewers?.edges?.forEach(({ node: { id, firstName, lastName } }) => { // eslint-disable-line no-unused-expressions
          suggestions.push({
            filter: {
              id,
              type: 'Reviewer',
              value: '',
              label: `${firstName} ${lastName}`,
            },
            description: `${firstName} ${lastName}`,
            descriptionSuffix: '- anmelder/journalist',
            imageUrl: reviewerPersonIcon,
          });
        });

        medias?.edges?.forEach(({ node: { id, name, mediaType } }) => { // eslint-disable-line no-unused-expressions
          const isOnline = [
            'TWVkaWFUeXBlOjU=', // Blog
            'TWVkaWFUeXBlOjY=', // Onlinemedie
            'TWVkaWFUeXBlOjk=', // Onlinemedie uden citat
          ].includes(mediaType?.id);

          suggestions.push({
            filter: {
              id,
              type: 'Media',
              value: '',
              label: name,
            },
            description: name,
            descriptionSuffix: isOnline ? '- online-medie' : '- print-medie',
            imageUrl: isOnline ? mediaOnlineIcon : mediaPrintIcon,
          });
        });

        publishingHouses?.edges?.forEach(({ node: { id, name } }) => { // eslint-disable-line no-unused-expressions
          suggestions.push({
            filter: {
              id,
              type: 'PublishingHouse',
              value: '',
              label: name,
            },
            description: name,
            descriptionSuffix: '- forlag',
            imageUrl: publisherBookIcon,
          });
        });

        collections?.edges?.forEach(({ node: { id, name, type } }) => { // eslint-disable-line no-unused-expressions
          suggestions.push({
            filter: {
              id,
              type: 'Collection',
              value: '',
              label: name,
              subtype: type,
            },
            description: name,
            descriptionSuffix: {
              ANNUAL_PUBLICATION: ' - årspublikation',
              BESTSELLER_LIST: ' - bestsellerliste',
              BOOK_COLLECTION: ' - bogsamling',
              LITERATURE_AWARD: ' - litteraturpris',
              SERIES: ' - serie',
            }[type] || ' - samling',
          });
        });

        this.setState({
          suggestedQuery: fetchingQuery,
          suggestions,
        });
      });
  }

  render() {
    const {
      query,
      filters,
      filterLabels,
      filterSubtypes,
      setFilters,
      setQuery,
      apply,
    } = this.props;
    const { suggestedQuery, suggestions } = this.state;

    if (this.suggestQuery !== query) {
      clearTimeout(this.suggestTimeout);
      this.suggestQuery = query;

      if (query.trim()) {
        this.suggestTimeout = setTimeout(this.fetchSuggestions, 500);
      }
    }

    return (
      <SearchBar
        filters={filters.map(({ subtype, id, type, value }) => ({
          id,
          type,
          value,
          label: type === 'QueryString' ? value : (filterLabels[id] || 'Indlæser...'),
          typeLabel: type === 'QueryString'
            ? null
            : type === 'Collection'
              ? SEARCH_FILTER_TYPE_LABEL_BY_COLLECTION_TYPE[filterSubtypes[id]]
              : SEARCH_FILTER_TYPE_LABEL_BY_TYPE[type],
        }))}
        onQueryChange={setQuery}
        onFiltersChange={setFilters}
        onSubmit={apply}
        query={query}
        filterSuggestions={suggestedQuery === query.trim() ? suggestions : null}
      />
    );
  }
}

export default withEnvironment(withManageSearch(ManagedSearchBar));
