// @flow
import React from 'react';

import Icon from '../Icon';

/** Floating action button item props. */
export type FloatingActionButtonItemProps = {
  icon: string,
  tooltip: string,
  onClick: () => any,
};

/** Floating action button item. */
const FloatingActionButtonItem: React$ComponentType<FloatingActionButtonItemProps> = function FloatingActionButtonItem({
  icon,
  tooltip,
  onClick,
}: FloatingActionButtonItemProps) {
  return (
    <li>
      <a
        href="#"
        onClick={evt => {
          evt.preventDefault();
          onClick();
        }}
        className="btn-floating"
        data-rh={tooltip}
        data-rh-at="top"
      >
        <Icon name={icon} />
      </a>
    </li>
  );
};

export default FloatingActionButtonItem;
