/**
 * @flow
 * @relayHash 9f29eeaaa198389cc06d52fdfaae9fc2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UnreadMessageList_viewer$ref = any;
export type MessageStatus = "ACTIVE" | "INACTIVE" | "TIMED" | "%future added value";
export type UpdateMessageInput = {|
  clientMutationId: string,
  subject: string,
  status: MessageStatus,
  links?: ?$ReadOnlyArray<MessageLinkInput>,
  includeLinkToBogportalen: boolean,
  activeTo?: ?string,
  body: string,
  activeFrom?: ?string,
  attachments?: ?$ReadOnlyArray<UpdateMessageAttachmentInput>,
  id: string,
|};
export type MessageLinkInput = {|
  description: string,
  url: string,
|};
export type UpdateMessageAttachmentInput = {|
  file?: ?any,
  description: string,
  fileId?: ?string,
|};
export type EditMessageLayoutOverrideUpdateMessageMutationVariables = {|
  input: UpdateMessageInput
|};
export type EditMessageLayoutOverrideUpdateMessageMutationResponse = {|
  +updateMessage: ?{|
    +message: ?{|
      +id: string,
      +subject: string,
      +body: ?string,
      +status: MessageStatus,
      +activeFrom: ?any,
      +activeTo: ?any,
      +includeLinkToBogportalen: boolean,
      +links: $ReadOnlyArray<{|
        +url: string,
        +description: string,
      |}>,
      +attachments: $ReadOnlyArray<{|
        +description: string,
        +file: {|
          +id: string,
          +filename: string,
        |},
      |}>,
    |},
    +viewer: ?{|
      +$fragmentRefs: UnreadMessageList_viewer$ref
    |},
  |}
|};
export type EditMessageLayoutOverrideUpdateMessageMutation = {|
  variables: EditMessageLayoutOverrideUpdateMessageMutationVariables,
  response: EditMessageLayoutOverrideUpdateMessageMutationResponse,
|};
*/


/*
mutation EditMessageLayoutOverrideUpdateMessageMutation(
  $input: UpdateMessageInput!
) {
  updateMessage(input: $input) {
    message {
      id
      subject
      body
      status
      activeFrom
      activeTo
      includeLinkToBogportalen
      links {
        url
        description
      }
      attachments {
        description
        file {
          id
          filename
        }
      }
    }
    viewer {
      ...UnreadMessageList_viewer
      id
    }
  }
}

fragment MessageCard_message on Message {
  id
  activeFrom
  body
  canEdit
  includeLinkToBogportalen
  isActive
  status
  subject
  timestampCreated
  sender {
    id
    name
    type
    isAdministrator
    group {
      name
      type
      parentGroups {
        name
        id
      }
      id
    }
  }
  links {
    url
    description
  }
  attachments {
    description
    file {
      url
      id
    }
  }
  referencedPublications {
    isbn13
    id
  }
  aggregateReferencedTitles {
    title
    id
  }
  referencedEntities {
    name
    id
  }
  referencedPublishingHouses {
    name
    id
  }
}

fragment MessageCard_viewer on Customer {
  id
}

fragment UnreadMessageList_viewer on Customer {
  unreadMessages {
    id
    ...MessageCard_message
  }
  ...MessageCard_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateMessageInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subject",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "body",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "activeFrom",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "includeLinkToBogportalen",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "links",
  "storageKey": null,
  "args": null,
  "concreteType": "MessageLink",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/)
  ]
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "message",
  "storageKey": null,
  "args": null,
  "concreteType": "Message",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "activeTo",
      "args": null,
      "storageKey": null
    },
    (v7/*: any*/),
    (v10/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "attachments",
      "storageKey": null,
      "args": null,
      "concreteType": "MessageAttachhment",
      "plural": true,
      "selections": [
        (v9/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "file",
          "storageKey": null,
          "args": null,
          "concreteType": "File",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filename",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v14 = [
  (v12/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EditMessageLayoutOverrideUpdateMessageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateMessage",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMessagePayload",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "UnreadMessageList_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditMessageLayoutOverrideUpdateMessageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateMessage",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMessagePayload",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "unreadMessages",
                "storageKey": null,
                "args": null,
                "concreteType": "Message",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "canEdit",
                    "args": null,
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isActive",
                    "args": null,
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "timestampCreated",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "sender",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Customer",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isAdministrator",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "group",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomerGroup",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "parentGroups",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomerGroup",
                            "plural": true,
                            "selections": (v14/*: any*/)
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "attachments",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MessageAttachhment",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "file",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "File",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "referencedPublications",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Publication",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isbn13",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "aggregateReferencedTitles",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Title",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "referencedEntities",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Entity",
                    "plural": true,
                    "selections": (v14/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "referencedPublishingHouses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PublishingHouse",
                    "plural": true,
                    "selections": (v14/*: any*/)
                  }
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "EditMessageLayoutOverrideUpdateMessageMutation",
    "id": null,
    "text": "mutation EditMessageLayoutOverrideUpdateMessageMutation(\n  $input: UpdateMessageInput!\n) {\n  updateMessage(input: $input) {\n    message {\n      id\n      subject\n      body\n      status\n      activeFrom\n      activeTo\n      includeLinkToBogportalen\n      links {\n        url\n        description\n      }\n      attachments {\n        description\n        file {\n          id\n          filename\n        }\n      }\n    }\n    viewer {\n      ...UnreadMessageList_viewer\n      id\n    }\n  }\n}\n\nfragment MessageCard_message on Message {\n  id\n  activeFrom\n  body\n  canEdit\n  includeLinkToBogportalen\n  isActive\n  status\n  subject\n  timestampCreated\n  sender {\n    id\n    name\n    type\n    isAdministrator\n    group {\n      name\n      type\n      parentGroups {\n        name\n        id\n      }\n      id\n    }\n  }\n  links {\n    url\n    description\n  }\n  attachments {\n    description\n    file {\n      url\n      id\n    }\n  }\n  referencedPublications {\n    isbn13\n    id\n  }\n  aggregateReferencedTitles {\n    title\n    id\n  }\n  referencedEntities {\n    name\n    id\n  }\n  referencedPublishingHouses {\n    name\n    id\n  }\n}\n\nfragment MessageCard_viewer on Customer {\n  id\n}\n\nfragment UnreadMessageList_viewer on Customer {\n  unreadMessages {\n    id\n    ...MessageCard_message\n  }\n  ...MessageCard_viewer\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '902b48b672b6f93ac67cdb121ad450f3';
module.exports = node;
