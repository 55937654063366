// @flow
export type SearchPublished = 'any' | 'last_4_months' | 'last_12_months' | 'today' | 'next_14_days' | 'future';

const PUBLISHED_OPTIONS: {
  value: SearchPublished,
  label: string,
}[] = [{
  value: 'any',
  label: 'Alle',
}, {
  value: 'last_4_months',
  label: 'Seneste 4 måneder',
}, {
  value: 'last_12_months',
  label: 'Seneste 12 måneder',
}, {
  value: 'today',
  label: 'I dag',
}, {
  value: 'next_14_days',
  label: 'De kommende 14 dage',
}, {
  value: 'future',
  label: 'Alle kommende udgivelser',
}];
const VALID_PUBLISHED_VALUES = PUBLISHED_OPTIONS.reduce((memo, option) => {
  memo[option.value] = option.value;
  return memo;
}, {});

export {
  PUBLISHED_OPTIONS,
  VALID_PUBLISHED_VALUES,
};
