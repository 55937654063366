// @flow
import React from 'react';

import logo from '../../images/logo.svg';
import Icon from '../Icon';

/** Header props. */
export type HeaderProps = {
  children: React$Node,
  onDesktopSidebarToggle: () => any,
  onMobileSidebarToggle: () => any,
  onOverrideClose: ?() => any,
};

/** Header. */
class Header extends React.Component<HeaderProps> {
  containerRef = React.createRef();
  headerRef = React.createRef();

  render() {
    const {
      children,
      onDesktopSidebarToggle,
      onMobileSidebarToggle,
      onOverrideClose,
    } = this.props;

    requestAnimationFrame(() => {
      const {
        containerRef: { current: container },
        headerRef: { current: header },
      } = this;

      if (container && header) {
        container.style.height = `${header.offsetHeight}px`;
      }
    });

    return (
      <div className="global-header-container" ref={this.containerRef}>
        <nav className="standard-bg-color global-header" ref={this.headerRef}>
          <div className="nav-wrapper standard-bg-color">
            <div className="left">
              <a
                href="#"
                className="sidenav-trigger"
                onClick={evt => { evt.preventDefault(); onMobileSidebarToggle() }}
              >
                <Icon name="menu" />
              </a>
              {onOverrideClose &&
              <a
                href="#"
                className="sidenav-trigger hide-on-large-and-up"
                onClick={evt => { evt.preventDefault(); onOverrideClose() }}
              >
                <Icon name="close" />
              </a>}
              <a
                href="#"
                className="show-on-large hide-on-med-and-down burgermargin"
                onClick={evt => { evt.preventDefault(); onDesktopSidebarToggle() }}
              >
                <Icon name="menu" />
              </a>
            </div>

            <div className="logo left hide-on-med-and-down">
              <img src={logo} height="40" />
            </div>

            <div className={onOverrideClose ? 'hide-on-med-and-down' : null}>
              {children}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
