// @flow
import React from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import { Base64 } from 'js-base64';

import {
  withSearchSettings,
  type SearchSettingsProps,
} from '../../containers/SearchSettings';
import type { SearchFilter } from '../../models';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import { withRouter, type RouterProps } from '../../utils/router';
import SearchManagerContext from './SearchManagerContext';

/** Search manager provider props. */
type SearchManagerProviderProps = {
  children: React$Node,
} & RouterProps<{}> & SearchSettingsProps & EnvironmentProps;

/** Search manager provider state. */
type SearchManagerProviderState = {
  /** Query. */
  query: string,

  /** Filters. */
  filters: ?SearchFilter[],

  /** Filter labels. */
  filterLabels: { [id: string]: string },

  /** Filter subtypes. */
  filterSubtypes: { [id: string]: string },

  /** Previous property filters. */
  prevPropFilters: ?SearchFilter[],
};

/**
 * Search manager provider.
 *
 * Contextual provider for the search query manipulation manager.
 */
class SearchManagerProvider extends React.Component<SearchManagerProviderProps, SearchManagerProviderState> {
  filtersWhenLastFetchingMissingLabels: ?SearchFilter[] = null;
  fetchingMissingLabelIds: { [id: string]: boolean } = {};

  state = {
    query: '',
    filters: null,
    filterLabels: {},
    filterSubtypes: {},
    prevPropFilters: null,
  };

  static getDerivedStateFromProps(
    props: SearchManagerProviderProps,
    prevState: SearchManagerProviderState
  ): ?$Shape<SearchManagerProviderState> {
    if (props.searchSettings.filters !== prevState.prevPropFilters) {
      return {
        prevPropFilters: props.searchSettings.filters,
        query: '',
        filters: null,
      };
    }

    return null;
  }

  handleSetQuery = (query: string) => {
    this.setState({ query });
  }

  handleSetFilters = (filters: ({
    label?: ?string,
    subtype?: ?string,
  } & SearchFilter)[]) => {
    const { filterLabels, filterSubtypes } = this.state;

    // Discover filter labels.
    let newFilterLabels: ?{ [id: string]: string } = null;
    let newFilterSubtypes: ?{ [id: string]: string } = null;

    filters.forEach(({ type, id, label, subtype }) => {
      if (type !== 'QueryString' && label) {
        if (!newFilterLabels) {
          newFilterLabels = {};
        }

        newFilterLabels[id] = label;
      }

      if (type !== 'QueryString' && subtype) {
        if (!newFilterSubtypes) {
          newFilterSubtypes = {};
        }

        newFilterSubtypes[id] = subtype;
      }
    });

    this.setState({
      filters: filters.map(({ type, id, value }) => ({ type, id, value })),
      filterLabels: newFilterLabels
        ? { ...filterLabels, ...newFilterLabels }
        : filterLabels,
      filterSubtypes: newFilterSubtypes
        ? { ...filterSubtypes, ...newFilterSubtypes }
        : filterSubtypes,
    });
  }

  handleApply = () => {
    const { setFilters, searchSettings: { filters: settingsFilters } } = this.props;
    let { query, filters } = this.state;
    query = query.trim();

    if (!filters) {
      filters = settingsFilters;
    }

    if (query) {
      filters.push({
        id: Base64.encode(`QueryString:${query}`),
        type: 'QueryString',
        value: query,
        label: query,
      });
    }

    setFilters(filters);
    this.setState({
      query: '',
      filters: null,
    });
  }

  fetchMissingLabels() {
    const { environment, searchSettings: { filters: settingsFilters } } = this.props;
    const { filters: stateFilters, filterLabels } = this.state;
    const filters = stateFilters || settingsFilters;

    if (this.filtersWhenLastFetchingMissingLabels !== filters) {
      this.filtersWhenLastFetchingMissingLabels = filters;

      const missingFilterLabelIds = filters.filter(({ type, id }) =>
        type !== 'QueryString' &&
        (filterLabels[id] === null || filterLabels[id] === undefined) &&
        (!this.fetchingMissingLabelIds[id])
      ).map(({ id }) => id);

      if (missingFilterLabelIds.length) {
        missingFilterLabelIds.forEach(id => { this.fetchingMissingLabelIds[id] = true });

        fetchQuery(environment, graphql`
query SearchManagerProviderMissingLabelQuery($ids: [ID!]!) {
  nodes(ids: $ids) {
    __typename
    id

    ... on Title {
      title
    }

    ... on Reviewer {
      firstName
      lastName
    }

    ... on Media {
      name
    }

    ... on Entity {
      name
    }

    ... on PublishingHouse {
      name
    }

    ... on Collection {
      type
      name
    }
  }
}
        `, { ids: missingFilterLabelIds })
          .then(({ nodes }) => {
            const filterLabels = { ...this.state.filterLabels };
            const filterSubtypes = { ...this.state.filterSubtypes };

            nodes.forEach(({ __typename, id, ...rest }) => {
              switch (__typename) {
                case 'Title':
                  filterLabels[id] = rest.title;
                  break;

                case 'Reviewer':
                  filterLabels[id] = `${rest.firstName} ${rest.lastName}`;
                  break;

                case 'Media':
                case 'Entity':
                case 'PublishingHouse':
                  filterLabels[id] = rest.name;
                  break;

                case 'Collection':
                  filterLabels[id] = rest.name;
                  filterSubtypes[id] = rest.type;
                  break;
              }
            });

            this.setState({
              filterLabels,
              filterSubtypes,
            });
          });
      }
    }
  }

  render() {
    const { searchSettings: { filters: settingsFilters } } = this.props;
    const { filters: stateFilters, filterLabels, filterSubtypes, query } = this.state;
    const filters = stateFilters || settingsFilters;

    // Initiate discovery of missing labels.
    //
    // While it's frowned upon to do this in the render method, it's really tough to avoid it, to be honest.
    this.fetchMissingLabels();

    return (
      <SearchManagerContext.Provider value={{
        apply: this.handleApply,
        filterLabels,
        filterSubtypes,
        filters,
        query,
        setFilters: this.handleSetFilters,
        setQuery: this.handleSetQuery,
      }}
      >
        {this.props.children}
      </SearchManagerContext.Provider>
    );
  }
}

export default withEnvironment(withRouter(withSearchSettings(SearchManagerProvider)));
