// @flow
import classNames from 'classnames';
import React, { Fragment } from 'react';
import moment from 'moment';
import { createPaginationContainer, graphql } from 'react-relay';

import { Collapsible, FormattedDate, Icon } from '../../components';
import MessageCard from '../MessageCard';
import { PopularityTitleCard } from '../TitleCard';
import PublicationCard from '../PublicationCard';
import { withSearchSettings, type SearchSettingsProps } from '../SearchSettings';

/** Split messages by day. */
function splitMessagesByDay(messages): Object[][] {
  const result = [];
  let group = [];

  messages.forEach(node => {
    if (node.isDeleted) {
      return;
    }

    if (group.length && node.date !== group[0].date) {
      result.push(group);
      group = [];
    }

    group.push(node);
  });

  if (group.length) {
    result.push(group);
  }

  return result;
}

/** News and review paginator props. */
export type MessagePaginatorProps = {
  shouldDisplayPublishingHouse: (id: ?string) => boolean,
} & SearchSettingsProps;

/** News and review paginator. */
class MessagePaginator extends React.Component<MessagePaginatorProps> {
  handleLoadMoreClick = (evt) => {
    evt.preventDefault();

    const { relay } = this.props;
    if (relay.isLoading()) {
      return;
    }

    relay.loadMore(25);
  }

  renderMessage = (message) => {
    const {
      query: { viewer },
      shouldDisplayPublishingHouse,
      searchSettings: {
        excludeDigital,
        excludeUnavailable,
      },
    } = this.props;

    const referencedTitleIds = new Set(message.referencedTitles.map(({ id }) => id));
    const independentReferencedPublications = message.referencedPublications.filter(publication =>
      !referencedTitleIds.has(publication.titleId) &&
                                                                                                 (!excludeDigital || !publication.isDigital) &&
                                                                                                 (!excludeUnavailable || publication.availability === 'AVAILABLE')
    );
    const referencedIds = message.aggregateReferencedTitles.map(({ id }) => id).concat(
      message.referencedEntities.map(({ id }) => id)
    ).concat(
      message.referencedPublishingHouses.map(({ id }) => id)
    );

    return (
      <Collapsible
        key={message.id}
        className={classNames(
          'message-list',
          (message.referencedTitles?.length > 0 || independentReferencedPublications?.length > 0) && 'message-list--round-top',
        )}
      >
        <MessageCard
          message={message}
          viewer={viewer}
          actionLinks={
            referencedIds.length
              ? [{
                label: 'SØG ALLE TITLER',
                linkTo: `/mest-populaere-boeger?filters=${encodeURIComponent(referencedIds.join(','))}&media_types=all&published=any`,
              }]
              : null
          }
          roundTop={message.referencedTitles?.length > 0 || independentReferencedPublications?.length > 0}
        />

        {message.referencedTitles.slice(0, 1).map(title =>
          <PopularityTitleCard
            key={title.id}
            title={title}
            viewer={viewer}
            shouldDisplayPublishingHouse={shouldDisplayPublishingHouse}
          />
        )}

        {independentReferencedPublications.map(publication =>
          <PublicationCard
            publication={publication}
            viewer={viewer}
            key={publication.id}
          />
        )}
      </Collapsible>
    );
  }

  renderDay = (messages, idx) => {
    const date = messages[0].date;
    const todayDate = moment().format('YYYY-MM-DD');

    return (
      <Fragment key={idx}>
        <div className="section-headline">
          {'Meddelelser fra '}
          {todayDate === date && 'i dag - '}
          {date && <FormattedDate value={date} />}
        </div>

        {messages.map(this.renderMessage)}
      </Fragment>
    );
  }

  render() {
    const {
      query: { viewer: { messages: connection } },
      relay,
    } = this.props;

    if (connection.totalCount === 0) {
      return (
        <Fragment>
          <div className="no-results-headline">Ingen resultater. Prøv at ændre søgeord eller søgefiltre.</div>
        </Fragment>
      );
    }

    const messages = connection.edges.map(({ node }) => node);
    const messagesGroupedByDays = splitMessagesByDay(messages);

    return (
      <Fragment>
        {messagesGroupedByDays.map(this.renderDay)}

        {relay.hasMore() &&
        <div className="center-align">
          <a
            className="btn-floating btn-large pulse standard-bg-color"
            onClick={this.handleLoadMoreClick}
          >
            <Icon name="expand_more" />
          </a>
        </div>}
      </Fragment>
    );
  }
}

export default createPaginationContainer(
  withSearchSettings(MessagePaginator),
  {
    query: graphql`
fragment MessagePaginator_query on Query
@argumentDefinitions(
  count: {type: "Int", defaultValue: 25}
  cursor: {type: "String"}
  search: {type: "String"}
  filterBy: {type: "[ID!]"}
  onlyActive: {type: "Boolean!"}
  onlySent: {type: "Boolean!"}
) {
  viewer {
    messages(
      first: $count,
      after: $cursor,
      search: $search,
      filterBy: $filterBy,
      onlyActive: $onlyActive,
      onlySent: $onlySent
    ) @connection(key: "MessagePaginator_messages") {
      totalCount

      edges {
        node {
          id
          isDeleted
          date

          aggregateReferencedTitles {
            id
          }

          referencedTitles {
            id

            ...PopularityTitleCard_title
          }

          referencedPublications {
            id
            titleId
            availability
            isDigital

            ...PublicationCard_publication
          }

          referencedPublishingHouses {
            id
          }

          referencedEntities {
            id
          }

          ...MessageCard_message
        }
      }
    }

    ...MessageCard_viewer
    ...PublicationCard_viewer
    ...PopularityTitleCard_viewer
  }
}`,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props?.query?.viewer?.messages;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, { search, filterBy, mode, onlyActive, onlySent }) {
      return {
        count,
        cursor,
        search,
        filterBy,
        onlyActive,
        onlySent,
      };
    },
    query: graphql`
query MessagePaginatorQuery(
  $count: Int!,
  $cursor: String,
  $search: String,
  $filterBy: [ID!],
  $onlyActive: Boolean!,
  $onlySent: Boolean!
) {
  ...MessagePaginator_query @arguments(
    count: $count,
    cursor: $cursor,
    search: $search,
    filterBy: $filterBy,
    onlyActive: $onlyActive,
    onlySent: $onlySent
  )
}`,
  }
);
