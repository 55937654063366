// @flow
import React, { Fragment } from 'react';

import heartGrayIconUrl from '../../images/heart-gray.svg';
import heartHalfIconUrl from '../../images/heart-half.svg';
import heartSolidIconUrl from '../../images/heart-red.svg';
import starGaryIconUrl from '../../images/star-gray.svg';
import starHalfIconUrl from '../../images/star-half.svg';
import starSolidIconUrl from '../../images/star-solid.svg';

export default function ReviewGradeDescription({
  review: {
    id,
    grade,
    gradeNormalized,
    gradingScale,
  },
}) {
  if (gradeNormalized === null || gradeNormalized === undefined) {
    return 'Anmeldelse';
  }

  if (gradingScale?.max <= 6) {
    const { max, symbol: scaleSymbol } = gradingScale;
    const symbol = scaleSymbol === 'hearts' ? 'hearts' : 'stars';
    const [grayIconUrl, halfIconUrl, solidIconUrl] = {
      hearts: [heartGrayIconUrl, heartHalfIconUrl, heartSolidIconUrl],
      stars: [starGaryIconUrl, starHalfIconUrl, starSolidIconUrl],
    }[symbol];

    const result = [];

    for (let symbolGrade = 1; symbolGrade <= max; symbolGrade++) {
      result.push(
        <img
          key={symbolGrade}
          src={grade >= symbolGrade ? solidIconUrl : grade > symbolGrade - 0.6 ? halfIconUrl : grayIconUrl}
          style={{ height: '15px' }}
        />
      );
    }

    return <Fragment>{result}</Fragment>;
  }

  if (gradeNormalized <= 16) {
    return 'Sønderknusende anmeldelse';
  }

  if (gradeNormalized <= 32) {
    return 'Meget dårlig anmeldelse';
  }

  if (gradeNormalized <= 49) {
    return 'Dårlig anmeldelse';
  }

  if (gradeNormalized <= 66) {
    return 'Middelmådig anmeldelse';
  }

  if (gradeNormalized <= 82) {
    return 'Glimrende anmeldelse';
  }

  if (gradeNormalized <= 99) {
    return 'Fremragende anmeldelse';
  }

  return 'Enestående god anmeldelse';
}
