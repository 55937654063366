// @flow
import type { SearchFilter } from '../../models';
import { Base64 } from 'js-base64';

/**
 * Parse search filters.
 */
export default function parseSearchFilters(queryFilters: ?string): SearchFilter[] {
  const rawFilters = (queryFilters || '').split(',');
  const filterIds = {};
  const filters = [];

  rawFilters.forEach(rawFilter => {
    if (!rawFilter) {
      return;
    }

    if (filterIds[rawFilter]) {
      return;
    }
    filterIds[rawFilter] = true;

    const filter = Base64.decode(rawFilter);
    const [filterType, filterValue] = filter.split(':');

    filters.push({
      id: rawFilter,
      type: filterType,
      value: filterValue,
    });
  });

  return filters;
}
