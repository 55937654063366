// @flow
import React from 'react';
import type { Environment } from 'react-relay';

/**
 * Environment context.
 */
const EnvironmentContext: React$Context<Environment> = React.createContext(((null: any): Environment));

export default EnvironmentContext;
