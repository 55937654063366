/**
 * @flow
 * @relayHash c7de9671c6c44421ddae361e806b6fa2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MessageRecipientSelector_viewer$ref = any;
type MessageStatusSelector_viewer$ref = any;
export type NewMessageLayoutOverrideDataWrapperQueryVariables = {|
  nodeIds: $ReadOnlyArray<string>
|};
export type NewMessageLayoutOverrideDataWrapperQueryResponse = {|
  +nodes: $ReadOnlyArray<?({|
    +__typename: "Title",
    +id: string,
    +title: string,
    +hasMoreAuthors: boolean,
    +publications: ?$ReadOnlyArray<{|
      +id: string,
      +isbn13: ?string,
      +format: ?string,
      +isDigital: ?boolean,
      +title: string,
      +publishingHouse: ?{|
        +id: string
      |},
    |}>,
    +authors: ?$ReadOnlyArray<{|
      +name: string
    |}>,
  |} | {|
    +__typename: "Entity",
    +id: string,
    +name: string,
  |} | {|
    +__typename: "PublishingHouse",
    +id: string,
    +name: string,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other"
  |})>,
  +viewer: {|
    +group: ?{|
      +type: string,
      +publishingHouseIds: ?$ReadOnlyArray<?string>,
      +name: string,
    |},
    +name: string,
    +messageRecipients: ?$ReadOnlyArray<{|
      +id: string
    |}>,
    +$fragmentRefs: MessageRecipientSelector_viewer$ref & MessageStatusSelector_viewer$ref,
  |},
|};
export type NewMessageLayoutOverrideDataWrapperQuery = {|
  variables: NewMessageLayoutOverrideDataWrapperQueryVariables,
  response: NewMessageLayoutOverrideDataWrapperQueryResponse,
|};
*/


/*
query NewMessageLayoutOverrideDataWrapperQuery(
  $nodeIds: [ID!]!
) {
  nodes(ids: $nodeIds) {
    __typename
    ... on Title {
      id
      title
      hasMoreAuthors
      publications {
        id
        isbn13
        format
        isDigital
        title
        publishingHouse {
          id
        }
      }
      authors {
        name
        id
      }
    }
    ... on Entity {
      id
      name
    }
    ... on PublishingHouse {
      id
      name
    }
    id
  }
  viewer {
    group {
      type
      publishingHouseIds
      name
      id
    }
    name
    messageRecipients {
      id
    }
    ...MessageRecipientSelector_viewer
    ...MessageStatusSelector_viewer
    id
  }
}

fragment MessageRecipientSelector_viewer on Customer {
  group {
    type
    id
  }
  messageRecipients {
    id
    parentId
    name
    type
    customerWithBogintraAccessCount
  }
}

fragment MessageStatusSelector_viewer on Customer {
  group {
    type
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "nodeIds",
    "type": "[ID!]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "nodeIds"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasMoreAuthors",
  "args": null,
  "storageKey": null
},
v6 = [
  (v3/*: any*/)
],
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "publications",
  "storageKey": null,
  "args": null,
  "concreteType": "Publication",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isbn13",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "format",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isDigital",
      "args": null,
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "publishingHouse",
      "storageKey": null,
      "args": null,
      "concreteType": "PublishingHouse",
      "plural": false,
      "selections": (v6/*: any*/)
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = [
  (v3/*: any*/),
  (v8/*: any*/)
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishingHouseIds",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NewMessageLayoutOverrideDataWrapperQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Title",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "authors",
                "storageKey": null,
                "args": null,
                "concreteType": "Entity",
                "plural": true,
                "selections": (v9/*: any*/)
              }
            ]
          },
          {
            "kind": "InlineFragment",
            "type": "Entity",
            "selections": (v10/*: any*/)
          },
          {
            "kind": "InlineFragment",
            "type": "PublishingHouse",
            "selections": (v10/*: any*/)
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v8/*: any*/)
            ]
          },
          (v8/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "messageRecipients",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": true,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "FragmentSpread",
            "name": "MessageRecipientSelector_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "MessageStatusSelector_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NewMessageLayoutOverrideDataWrapperQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Title",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "authors",
                "storageKey": null,
                "args": null,
                "concreteType": "Entity",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v3/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "InlineFragment",
            "type": "Entity",
            "selections": (v9/*: any*/)
          },
          {
            "kind": "InlineFragment",
            "type": "PublishingHouse",
            "selections": (v9/*: any*/)
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v8/*: any*/),
              (v3/*: any*/)
            ]
          },
          (v8/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "messageRecipients",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "parentId",
                "args": null,
                "storageKey": null
              },
              (v8/*: any*/),
              (v11/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "customerWithBogintraAccessCount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "NewMessageLayoutOverrideDataWrapperQuery",
    "id": null,
    "text": "query NewMessageLayoutOverrideDataWrapperQuery(\n  $nodeIds: [ID!]!\n) {\n  nodes(ids: $nodeIds) {\n    __typename\n    ... on Title {\n      id\n      title\n      hasMoreAuthors\n      publications {\n        id\n        isbn13\n        format\n        isDigital\n        title\n        publishingHouse {\n          id\n        }\n      }\n      authors {\n        name\n        id\n      }\n    }\n    ... on Entity {\n      id\n      name\n    }\n    ... on PublishingHouse {\n      id\n      name\n    }\n    id\n  }\n  viewer {\n    group {\n      type\n      publishingHouseIds\n      name\n      id\n    }\n    name\n    messageRecipients {\n      id\n    }\n    ...MessageRecipientSelector_viewer\n    ...MessageStatusSelector_viewer\n    id\n  }\n}\n\nfragment MessageRecipientSelector_viewer on Customer {\n  group {\n    type\n    id\n  }\n  messageRecipients {\n    id\n    parentId\n    name\n    type\n    customerWithBogintraAccessCount\n  }\n}\n\nfragment MessageStatusSelector_viewer on Customer {\n  group {\n    type\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '847b97e3ebd4f6f21e6ce9c678292709';
module.exports = node;
