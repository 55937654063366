/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CustomerForm_customer$ref = any;
type CustomerForm_viewer$ref = any;
type CustomerList_viewer$ref = any;
type LinkList_viewer$ref = any;
type NotificationSubscriptionList_viewer$ref = any;
type ViewerProfile_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SettingsLayoutOverride_viewer$ref: FragmentReference;
declare export opaque type SettingsLayoutOverride_viewer$fragmentType: SettingsLayoutOverride_viewer$ref;
export type SettingsLayoutOverride_viewer = {|
  +id: string,
  +name: string,
  +type: string,
  +isAdministrator: boolean,
  +managableCustomers: ?$ReadOnlyArray<{|
    +id: string,
    +type: string,
  |}>,
  +group: ?{|
    +type: string
  |},
  +$fragmentRefs: CustomerForm_customer$ref & CustomerForm_viewer$ref & CustomerList_viewer$ref & LinkList_viewer$ref & NotificationSubscriptionList_viewer$ref & ViewerProfile_viewer$ref,
  +$refType: SettingsLayoutOverride_viewer$ref,
|};
export type SettingsLayoutOverride_viewer$data = SettingsLayoutOverride_viewer;
export type SettingsLayoutOverride_viewer$key = {
  +$data?: SettingsLayoutOverride_viewer$data,
  +$fragmentRefs: SettingsLayoutOverride_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SettingsLayoutOverride_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isAdministrator",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "managableCustomers",
      "storageKey": null,
      "args": null,
      "concreteType": "Customer",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "group",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerGroup",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CustomerForm_customer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CustomerForm_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CustomerList_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "LinkList_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "NotificationSubscriptionList_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ViewerProfile_viewer",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b83c0ef549ae6cac83a4d727f9e30ce7';
module.exports = node;
