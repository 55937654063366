/**
 * @flow
 * @relayHash 75462c2e1758dc82f28d3ff293171c5f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type LinkList_viewer$ref = any;
export type PositionCustomerLinkBeforeOtherInput = {|
  id: string,
  beforeId?: ?string,
  clientMutationId: string,
|};
export type LinkListPositionCustomerLinkBeforeOtherMutationVariables = {|
  input: PositionCustomerLinkBeforeOtherInput
|};
export type LinkListPositionCustomerLinkBeforeOtherMutationResponse = {|
  +positionCustomerLinkBeforeOther: ?{|
    +viewer: ?{|
      +$fragmentRefs: LinkList_viewer$ref
    |}
  |}
|};
export type LinkListPositionCustomerLinkBeforeOtherMutation = {|
  variables: LinkListPositionCustomerLinkBeforeOtherMutationVariables,
  response: LinkListPositionCustomerLinkBeforeOtherMutationResponse,
|};
*/


/*
mutation LinkListPositionCustomerLinkBeforeOtherMutation(
  $input: PositionCustomerLinkBeforeOtherInput!
) {
  positionCustomerLinkBeforeOther(input: $input) {
    viewer {
      ...LinkList_viewer
      id
    }
  }
}

fragment LinkForm_link on CustomerLink {
  id
  description
  url
}

fragment LinkList_viewer on Customer {
  id
  name
  links {
    id
    url
    description
    ...LinkForm_link
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PositionCustomerLinkBeforeOtherInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LinkListPositionCustomerLinkBeforeOtherMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "positionCustomerLinkBeforeOther",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PositionCustomerLinkBeforeOtherPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "LinkList_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LinkListPositionCustomerLinkBeforeOtherMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "positionCustomerLinkBeforeOther",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PositionCustomerLinkBeforeOtherPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "links",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomerLink",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "LinkListPositionCustomerLinkBeforeOtherMutation",
    "id": null,
    "text": "mutation LinkListPositionCustomerLinkBeforeOtherMutation(\n  $input: PositionCustomerLinkBeforeOtherInput!\n) {\n  positionCustomerLinkBeforeOther(input: $input) {\n    viewer {\n      ...LinkList_viewer\n      id\n    }\n  }\n}\n\nfragment LinkForm_link on CustomerLink {\n  id\n  description\n  url\n}\n\nfragment LinkList_viewer on Customer {\n  id\n  name\n  links {\n    id\n    url\n    description\n    ...LinkForm_link\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c34ebfbfbb467ce83474e9519a40042e';
module.exports = node;
