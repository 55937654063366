// @flow
import React from 'react';
import { graphql } from 'react-relay';

import { AppLayout, Sidebar } from '../../components';
import type { Viewer } from '../../models';
import { QueryRenderer } from '../../utils/relay';
import { formatPossessiveName } from '../../utils/ownership';
import CustomerList from './CustomerList';
import LinkList from './LinkList';
import NotificationSubscriptionList from './NotificationSubscriptionList';
import ViewerProfile from './ViewerProfile';

graphql`fragment SettingsLayoutOverride_viewer on Customer {
  id
  name
  type
  isAdministrator

  managableCustomers {
    id
    type
  }

  group {
    type
  }

  ...CustomerForm_customer
  ...CustomerForm_viewer
  ...CustomerList_viewer
  ...LinkList_viewer
  ...NotificationSubscriptionList_viewer
  ...ViewerProfile_viewer
}`;

/** Settings layout override props. */
export type SettingsLayoutOverrideProps = {
  viewer: Viewer,
  initialPage?: ?'profile' | 'links' | 'notifications' | 'users' | 'departments',
};

/** Settings layout override state. */
type SettingsLayoutOverrideState = {
  page: 'profile' | 'links' | 'notifications' | 'users' | 'departments',
};

/** Settings layout override. */
export default class SettingsLayoutOverride extends React.Component<SettingsLayoutOverride, SettingsLayoutOverrideState> {
  constructor(props: SettingsLayoutOverride) {
    super(props);

    this.state = {
      page: props.initialPage || 'profile',
    };
  }

  renderContent(error, props) {
    if (error) {
      return (
        <div className="section-headline">
          En fejl er opstået. Prøv venligst igen.
        </div>
      );
    }

    if (!props) {
      return (
        <div className="section-headline">
          Indlæser...
        </div>
      );
    }

    const { viewer } = props;

    switch (this.state.page) {
      case 'profile':
        return <ViewerProfile viewer={viewer} />;

      case 'links':
        return <LinkList viewer={viewer} />;

      case 'notifications':
        return <NotificationSubscriptionList viewer={viewer} />;

      case 'users':
        return (
          <CustomerList
            viewer={viewer}
            departmentCustomers={false}
          />
        );

      case 'departments':
        return (
          <CustomerList
            viewer={viewer}
            departmentCustomers={true}
          />
        );

      default:
        return null;
    }
  }

  render() {
    const { viewer } = this.props;

    if (!viewer) {
      return null;
    }

    return (
      <QueryRenderer
        query={graphql`
query SettingsLayoutOverrideQuery {
  viewer {
    ...SettingsLayoutOverride_viewer @relay(mask: false)
  }
}`}
        render={({ error, props }) =>
          <AppLayout.Override
            {...AppLayout.Override.forwardProps(this.props)}
            sidebar={
              <Sidebar>
                <Sidebar.Section
                  icon={viewer.type === 'DEPARTMENT' ? 'store' : 'person'}
                  label={viewer.name}
                  onHeaderClick={() => this.setState({ page: 'profile' })}
                />

                <Sidebar.Section
                  icon="link"
                  label={`${formatPossessiveName(viewer.name)} links`}
                  onHeaderClick={() => this.setState({ page: 'links' })}
                />

                <Sidebar.Section
                  icon="notifications_active"
                  label="Notifikationer"
                  onHeaderClick={() => this.setState({ page: 'notifications' })}
                />

                {props?.viewer?.isAdministrator &&
            <Sidebar.Section
              count={props?.viewer?.managableCustomers?.filter(c => c.id !== props.viewer.id && c.type !== 'DEPARTMENT')?.length}
              icon="people"
              label="Brugere"
              onHeaderClick={() => this.setState({ page: 'users' })}
            />}

                {props?.viewer?.isAdministrator &&
             props?.viewer?.group?.type === 'BOOKSELLER' &&
            <Sidebar.Section
              count={props?.viewer?.managableCustomers?.filter(c => c.id !== props.viewer.id && c.type === 'DEPARTMENT')?.length}
              icon="store"
              label="Butikker/afdelinger"
              onHeaderClick={() => this.setState({ page: 'departments' })}
            />}
              </Sidebar>
            }
            content={this.renderContent(error, props)}
          />
        }
      />
    );
  }
}
