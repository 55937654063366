/**
 * @flow
 * @relayHash cbb8c1c5fe594264bae7e1f4753906e8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MessageRecipientSelector_viewer$ref = any;
type MessageStatusSelector_viewer$ref = any;
export type MessageStatus = "ACTIVE" | "INACTIVE" | "TIMED" | "%future added value";
export type EditMessageLayoutOverrideDataWrapperQueryVariables = {|
  id: string
|};
export type EditMessageLayoutOverrideDataWrapperQueryResponse = {|
  +node: ?{|
    +id?: string,
    +subject?: string,
    +body?: ?string,
    +status?: MessageStatus,
    +activeFrom?: ?any,
    +activeTo?: ?any,
    +includeLinkToBogportalen?: boolean,
    +referencedPublications?: ?$ReadOnlyArray<{|
      +id: string,
      +isDigital: ?boolean,
    |}>,
    +aggregateReferencedTitles?: ?$ReadOnlyArray<{|
      +id: string,
      +title: string,
      +publications: ?$ReadOnlyArray<{|
        +id: string,
        +isbn13: ?string,
        +format: ?string,
      |}>,
    |}>,
    +referencedEntities?: ?$ReadOnlyArray<{|
      +id: string,
      +name: string,
    |}>,
    +referencedPublishingHouses?: ?$ReadOnlyArray<{|
      +id: string,
      +name: string,
    |}>,
    +recipientGroups?: $ReadOnlyArray<{|
      +id: string
    |}>,
    +links?: $ReadOnlyArray<{|
      +url: string,
      +description: string,
    |}>,
    +attachments?: $ReadOnlyArray<{|
      +description: string,
      +file: {|
        +id: string,
        +filename: string,
      |},
    |}>,
  |},
  +viewer: {|
    +group: ?{|
      +type: string,
      +publishingHouseIds: ?$ReadOnlyArray<?string>,
      +name: string,
    |},
    +name: string,
    +messageRecipients: ?$ReadOnlyArray<{|
      +id: string
    |}>,
    +$fragmentRefs: MessageRecipientSelector_viewer$ref & MessageStatusSelector_viewer$ref,
  |},
|};
export type EditMessageLayoutOverrideDataWrapperQuery = {|
  variables: EditMessageLayoutOverrideDataWrapperQueryVariables,
  response: EditMessageLayoutOverrideDataWrapperQueryResponse,
|};
*/


/*
query EditMessageLayoutOverrideDataWrapperQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on Message {
      id
      subject
      body
      status
      activeFrom
      activeTo
      includeLinkToBogportalen
      referencedPublications {
        id
        isDigital
      }
      aggregateReferencedTitles {
        id
        title
        publications {
          id
          isbn13
          format
        }
      }
      referencedEntities {
        id
        name
      }
      referencedPublishingHouses {
        id
        name
      }
      recipientGroups {
        id
      }
      links {
        url
        description
      }
      attachments {
        description
        file {
          id
          filename
        }
      }
    }
    id
  }
  viewer {
    group {
      type
      publishingHouseIds
      name
      id
    }
    name
    messageRecipients {
      id
    }
    ...MessageRecipientSelector_viewer
    ...MessageStatusSelector_viewer
    id
  }
}

fragment MessageRecipientSelector_viewer on Customer {
  group {
    type
    id
  }
  messageRecipients {
    id
    parentId
    name
    type
    customerWithBogintraAccessCount
  }
}

fragment MessageStatusSelector_viewer on Customer {
  group {
    type
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subject",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "body",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "activeFrom",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "activeTo",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "includeLinkToBogportalen",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "referencedPublications",
  "storageKey": null,
  "args": null,
  "concreteType": "Publication",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isDigital",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "aggregateReferencedTitles",
  "storageKey": null,
  "args": null,
  "concreteType": "Title",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "publications",
      "storageKey": null,
      "args": null,
      "concreteType": "Publication",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isbn13",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "format",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v12 = [
  (v2/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "referencedEntities",
  "storageKey": null,
  "args": null,
  "concreteType": "Entity",
  "plural": true,
  "selections": (v12/*: any*/)
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "referencedPublishingHouses",
  "storageKey": null,
  "args": null,
  "concreteType": "PublishingHouse",
  "plural": true,
  "selections": (v12/*: any*/)
},
v15 = [
  (v2/*: any*/)
],
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "recipientGroups",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomerGroup",
  "plural": true,
  "selections": (v15/*: any*/)
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "links",
  "storageKey": null,
  "args": null,
  "concreteType": "MessageLink",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    (v17/*: any*/)
  ]
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "attachments",
  "storageKey": null,
  "args": null,
  "concreteType": "MessageAttachhment",
  "plural": true,
  "selections": [
    (v17/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "file",
      "storageKey": null,
      "args": null,
      "concreteType": "File",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "filename",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishingHouseIds",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EditMessageLayoutOverrideDataWrapperQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Message",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": false,
            "selections": [
              (v20/*: any*/),
              (v21/*: any*/),
              (v11/*: any*/)
            ]
          },
          (v11/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "messageRecipients",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": true,
            "selections": (v15/*: any*/)
          },
          {
            "kind": "FragmentSpread",
            "name": "MessageRecipientSelector_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "MessageStatusSelector_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditMessageLayoutOverrideDataWrapperQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Message",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": false,
            "selections": [
              (v20/*: any*/),
              (v21/*: any*/),
              (v11/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v11/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "messageRecipients",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerGroup",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "parentId",
                "args": null,
                "storageKey": null
              },
              (v11/*: any*/),
              (v20/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "customerWithBogintraAccessCount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "EditMessageLayoutOverrideDataWrapperQuery",
    "id": null,
    "text": "query EditMessageLayoutOverrideDataWrapperQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Message {\n      id\n      subject\n      body\n      status\n      activeFrom\n      activeTo\n      includeLinkToBogportalen\n      referencedPublications {\n        id\n        isDigital\n      }\n      aggregateReferencedTitles {\n        id\n        title\n        publications {\n          id\n          isbn13\n          format\n        }\n      }\n      referencedEntities {\n        id\n        name\n      }\n      referencedPublishingHouses {\n        id\n        name\n      }\n      recipientGroups {\n        id\n      }\n      links {\n        url\n        description\n      }\n      attachments {\n        description\n        file {\n          id\n          filename\n        }\n      }\n    }\n    id\n  }\n  viewer {\n    group {\n      type\n      publishingHouseIds\n      name\n      id\n    }\n    name\n    messageRecipients {\n      id\n    }\n    ...MessageRecipientSelector_viewer\n    ...MessageStatusSelector_viewer\n    id\n  }\n}\n\nfragment MessageRecipientSelector_viewer on Customer {\n  group {\n    type\n    id\n  }\n  messageRecipients {\n    id\n    parentId\n    name\n    type\n    customerWithBogintraAccessCount\n  }\n}\n\nfragment MessageStatusSelector_viewer on Customer {\n  group {\n    type\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e461f5047d357bb5f2434a606a4f6d16';
module.exports = node;
