/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type NotificationCard_title$ref: FragmentReference;
declare export opaque type NotificationCard_title$fragmentType: NotificationCard_title$ref;
export type NotificationCard_title = {|
  +id: string,
  +title: string,
  +hasMoreAuthors: boolean,
  +authors: ?$ReadOnlyArray<{|
    +id: string,
    +name: string,
  |}>,
  +$refType: NotificationCard_title$ref,
|};
export type NotificationCard_title$data = NotificationCard_title;
export type NotificationCard_title$key = {
  +$data?: NotificationCard_title$data,
  +$fragmentRefs: NotificationCard_title$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "NotificationCard_title",
  "type": "Title",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasMoreAuthors",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "authors",
      "storageKey": null,
      "args": null,
      "concreteType": "Entity",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a287405aafbb0b6080b5f2ece9598a92';
module.exports = node;
