// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { FormattedDate } from '../../components';
import { ONLINE_MEDIA_TYPE_IDS } from './data';

function naturalJoinEntities(entities) {
	if (entities.length === 0) {
		return '';
	}

	if (entities.length === 1) {
		return entities[0].name;
	}

	return entities.slice(0, entities.length - 1).map(e => e.name).join(', ') + ' og ' + entities[entities.length - 1].name;
}

function TitleDescription({
	title: {
		title,
		authors,
		hasMoreAuthors,
	},
}) {
	return (
		<Fragment>
			{title}
			{authors?.length > 0 &&
				` af ${naturalJoinEntities(authors)}${hasMoreAuthors ? ' m.f.l.' : ''}`}
		</Fragment>
	);
}

function TitlesDescription({
	titles,
}) {
	return (
		<Fragment>
			<TitleDescription title={titles[0]} />
			{titles.length === 2
				? ' og 1 anden bog'
				: titles.length > 2
					? ` og ${titles.length - 1} andre bøger`
					: null}
		</Fragment>
	);
}

function ContentPublishingDescriptionHeadline({
	content: {
		referencedTitles,
	},
}) {
	if (!referencedTitles?.length) {
		return null;
	}

	const today = moment();
	const { datePublishedFirst, yearPublishedFirst, publishingHouses } = referencedTitles[0];
	if (!datePublishedFirst && !yearPublishedFirst && !(publishingHouses?.length > 0)) {
		return null;
	}

	return (
		<p className="headline3">
			{datePublishedFirst
				? (moment(datePublishedFirst).isSameOrBefore(today)
					? <Fragment>Udkom <FormattedDate value={datePublishedFirst} /></Fragment>
					: <Fragment>Udkommer <FormattedDate value={datePublishedFirst} /></Fragment>)
				: yearPublishedFirst
					? (yearPublishedFirst <= today.year()
						? `Udkom ${yearPublishedFirst}`
						: `Udkommer ${yearPublishedFirst}`)
					: null}
			{publishingHouses?.length > 0 &&
				` på ${publishingHouses[0].name}`}
		</p>
	);
};

function ContentAction({
	content: {
		media,
		url,
	},
}) {
	if (!url) {
		return (
			<div className="action-more-disabled">
				IKKE LAGT ONLINE
			</div>
		);
	}

	const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;

	return (
		<div className="action-more">
			<a
				className="standard-color versals"
				href={url}
				target="_blank"
			>
				Læs {ONLINE_MEDIA_TYPE_IDS.includes(media?.mediaType?.id) ? 'hos' : 'i'} {media?.name}
			</a>
			<a
				className="facebook-link right"
				href={fbShareUrl}
				onClick={evt => {
					evt.preventDefault();
					window.open(
						fbShareUrl,
						'facebookwindow',
						'left=20,top=20,width=640,height=580,toolbar=0,resizable=1'
					);
				}}
			>
				<i className="fab fa-facebook-f standard-color"></i>
			</a>
		</div>
	);
}

export {
	ContentAction,
	ContentPublishingDescriptionHeadline,
	TitlesDescription,
	TitleDescription,
	naturalJoinEntities,
}