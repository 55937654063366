/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FavoriteStar_viewer$ref = any;
type MessageCard_message$ref = any;
type MessageCard_viewer$ref = any;
export type NewsType = "ARTICLE" | "COMMENT_ABOUT" | "COMMENT_BY" | "COMPETITION" | "EXCERPT" | "FEATURE_ARTICLE" | "FEATURE_INTERVIEW" | "INTERVIEW" | "MENTION" | "SPONSORED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type NewsAndReviewPaginator_query$ref: FragmentReference;
declare export opaque type NewsAndReviewPaginator_query$fragmentType: NewsAndReviewPaginator_query$ref;
export type NewsAndReviewPaginator_query = {|
  +newsAndReviews: ?{|
    +totalCount: number,
    +edges: ?$ReadOnlyArray<?{|
      +node: ?({|
        +__typename: "Message",
        +id: string,
        +date: ?any,
        +isDeleted: boolean,
        +aggregateReferencedTitles: ?$ReadOnlyArray<{|
          +id: string
        |}>,
        +referencedPublishingHouses: ?$ReadOnlyArray<{|
          +id: string
        |}>,
        +referencedEntities: ?$ReadOnlyArray<{|
          +id: string
        |}>,
        +$fragmentRefs: MessageCard_message$ref,
      |} | {|
        +__typename: "News",
        +id: string,
        +headline: string,
        +summaryHeadline: ?string,
        +details: ?string,
        +url: ?string,
        +date: ?any,
        +type: ?NewsType,
        +media: ?{|
          +id: string,
          +name: string,
          +initials: string,
          +colorClass: string,
          +limitOriginalContentDisplay: boolean,
          +mediaType: ?{|
            +id: string
          |},
        |},
        +reviewer: ?{|
          +id: string,
          +firstName: string,
          +lastName: string,
        |},
        +referencedTitles: ?$ReadOnlyArray<{|
          +id: string,
          +title: string,
          +hasMoreAuthors: boolean,
          +datePublishedFirst: ?any,
          +yearPublishedFirst: ?number,
          +authors: ?$ReadOnlyArray<{|
            +id: string,
            +name: string,
          |}>,
          +coverImage: ?{|
            +sizes: ?$ReadOnlyArray<{|
              +name: string,
              +url: string,
            |}>
          |},
          +publishingHouses: ?$ReadOnlyArray<{|
            +id: string,
            +name: string,
          |}>,
          +publications: ?$ReadOnlyArray<{|
            +format: ?string,
            +isbn13: ?string,
            +isDigital: ?boolean,
            +availability: ?string,
          |}>,
        |}>,
        +referencedEntities: ?$ReadOnlyArray<{|
          +id: string,
          +name: string,
          +publishingHouseIds: ?$ReadOnlyArray<?string>,
        |}>,
        +relatedMessages: ?$ReadOnlyArray<{|
          +id: string,
          +isActive: boolean,
          +isDeleted: boolean,
          +aggregateReferencedTitles: ?$ReadOnlyArray<{|
            +id: string
          |}>,
          +referencedPublishingHouses: ?$ReadOnlyArray<{|
            +id: string
          |}>,
          +referencedEntities: ?$ReadOnlyArray<{|
            +id: string
          |}>,
          +$fragmentRefs: MessageCard_message$ref,
        |}>,
      |} | {|
        +__typename: "Review",
        +id: string,
        +headline: string,
        +summaryHeadline: ?string,
        +underHeading: string,
        +content: ?string,
        +quotes: ?$ReadOnlyArray<string>,
        +date: ?any,
        +grade: ?number,
        +gradeNormalized: ?number,
        +url: ?string,
        +gradingScale: ?{|
          +min: ?number,
          +max: ?number,
          +symbol: ?string,
        |},
        +media: ?{|
          +id: string,
          +name: string,
          +initials: string,
          +colorClass: string,
          +limitOriginalContentDisplay: boolean,
          +mediaType: ?{|
            +id: string
          |},
        |},
        +reviewer: ?{|
          +id: string,
          +firstName: string,
          +lastName: string,
        |},
        +referencedTitles: ?$ReadOnlyArray<{|
          +id: string,
          +title: string,
          +hasMoreAuthors: boolean,
          +datePublishedFirst: ?any,
          +yearPublishedFirst: ?number,
          +authors: ?$ReadOnlyArray<{|
            +id: string,
            +name: string,
          |}>,
          +publishingHouses: ?$ReadOnlyArray<{|
            +id: string,
            +name: string,
          |}>,
          +coverImage: ?{|
            +sizes: ?$ReadOnlyArray<{|
              +name: string,
              +url: string,
            |}>
          |},
          +publications: ?$ReadOnlyArray<{|
            +format: ?string,
            +isbn13: ?string,
            +isDigital: ?boolean,
            +availability: ?string,
          |}>,
        |}>,
        +relatedMessages: ?$ReadOnlyArray<{|
          +id: string,
          +isActive: boolean,
          +isDeleted: boolean,
          +aggregateReferencedTitles: ?$ReadOnlyArray<{|
            +id: string
          |}>,
          +referencedPublishingHouses: ?$ReadOnlyArray<{|
            +id: string
          |}>,
          +referencedEntities: ?$ReadOnlyArray<{|
            +id: string
          |}>,
          +$fragmentRefs: MessageCard_message$ref,
        |}>,
      |} | {|
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        +__typename: "%other"
      |})
    |}>,
  |},
  +viewer: {|
    +$fragmentRefs: FavoriteStar_viewer$ref & MessageCard_viewer$ref
  |},
  +$refType: NewsAndReviewPaginator_query$ref,
|};
export type NewsAndReviewPaginator_query$data = NewsAndReviewPaginator_query;
export type NewsAndReviewPaginator_query$key = {
  +$data?: NewsAndReviewPaginator_query$data,
  +$fragmentRefs: NewsAndReviewPaginator_query$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "date",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDeleted",
  "args": null,
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "aggregateReferencedTitles",
  "storageKey": null,
  "args": null,
  "concreteType": "Title",
  "plural": true,
  "selections": (v3/*: any*/)
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "referencedPublishingHouses",
  "storageKey": null,
  "args": null,
  "concreteType": "PublishingHouse",
  "plural": true,
  "selections": (v3/*: any*/)
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "referencedEntities",
  "storageKey": null,
  "args": null,
  "concreteType": "Entity",
  "plural": true,
  "selections": (v3/*: any*/)
},
v7 = {
  "kind": "FragmentSpread",
  "name": "MessageCard_message",
  "args": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "headline",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summaryHeadline",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "media",
  "storageKey": null,
  "args": null,
  "concreteType": "Media",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v11/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "initials",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "colorClass",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "limitOriginalContentDisplay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "mediaType",
      "storageKey": null,
      "args": null,
      "concreteType": "MediaType",
      "plural": false,
      "selections": (v3/*: any*/)
    }
  ]
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "reviewer",
  "storageKey": null,
  "args": null,
  "concreteType": "Reviewer",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    }
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasMoreAuthors",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "datePublishedFirst",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "yearPublishedFirst",
  "args": null,
  "storageKey": null
},
v18 = [
  (v0/*: any*/),
  (v11/*: any*/)
],
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "authors",
  "storageKey": null,
  "args": null,
  "concreteType": "Entity",
  "plural": true,
  "selections": (v18/*: any*/)
},
v20 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "coverImage",
  "storageKey": null,
  "args": null,
  "concreteType": "PublicationCoverImage",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sizes",
      "storageKey": null,
      "args": null,
      "concreteType": "PublicationCoverImageSize",
      "plural": true,
      "selections": [
        (v11/*: any*/),
        (v10/*: any*/)
      ]
    }
  ]
},
v21 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "publishingHouses",
  "storageKey": null,
  "args": null,
  "concreteType": "PublishingHouse",
  "plural": true,
  "selections": (v18/*: any*/)
},
v22 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "publications",
  "storageKey": null,
  "args": null,
  "concreteType": "Publication",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "format",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isbn13",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isDigital",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "availability",
      "args": null,
      "storageKey": null
    }
  ]
},
v23 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "relatedMessages",
  "storageKey": null,
  "args": null,
  "concreteType": "Message",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isActive",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ]
};
return {
  "kind": "Fragment",
  "name": "NewsAndReviewPaginator_query",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "newsAndReviews"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 25
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "orderBy",
      "type": "NewsAndReviewOrderBy",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "order",
      "type": "Order",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "filterBy",
      "type": "[ID!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "includeNews",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "includeReviews",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "includeGeneralMessages",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "mode",
      "type": "SearchMode!",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "onlyFavorites",
      "type": "Boolean!",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "newsAndReviews",
      "name": "__NewsAndReviewPaginator_newsAndReviews_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filterBy",
          "variableName": "filterBy"
        },
        {
          "kind": "Variable",
          "name": "includeGeneralMessages",
          "variableName": "includeGeneralMessages"
        },
        {
          "kind": "Variable",
          "name": "includeNews",
          "variableName": "includeNews"
        },
        {
          "kind": "Variable",
          "name": "includeReviews",
          "variableName": "includeReviews"
        },
        {
          "kind": "Variable",
          "name": "mode",
          "variableName": "mode"
        },
        {
          "kind": "Variable",
          "name": "onlyFavorites",
          "variableName": "onlyFavorites"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "NewsAndReviewConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "NewsAndReviewEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": null,
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "type": "Message",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/)
                  ]
                },
                {
                  "kind": "InlineFragment",
                  "type": "News",
                  "selections": [
                    (v0/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "details",
                      "args": null,
                      "storageKey": null
                    },
                    (v10/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "type",
                      "args": null,
                      "storageKey": null
                    },
                    (v12/*: any*/),
                    (v13/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "referencedTitles",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Title",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        (v14/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        (v17/*: any*/),
                        (v19/*: any*/),
                        (v20/*: any*/),
                        (v21/*: any*/),
                        (v22/*: any*/)
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "referencedEntities",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Entity",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        (v11/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "publishingHouseIds",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    (v23/*: any*/)
                  ]
                },
                {
                  "kind": "InlineFragment",
                  "type": "Review",
                  "selections": [
                    (v0/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "underHeading",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "content",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "quotes",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "grade",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "gradeNormalized",
                      "args": null,
                      "storageKey": null
                    },
                    (v10/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "gradingScale",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "GradingScale",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "min",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "max",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "symbol",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    (v12/*: any*/),
                    (v13/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "referencedTitles",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Title",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        (v14/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        (v17/*: any*/),
                        (v19/*: any*/),
                        (v21/*: any*/),
                        (v20/*: any*/),
                        (v22/*: any*/)
                      ]
                    },
                    (v23/*: any*/)
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "viewer",
      "storageKey": null,
      "args": null,
      "concreteType": "Customer",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "FavoriteStar_viewer",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "MessageCard_viewer",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a3c7187f1cb359c98fcefc1e89128cc2';
module.exports = node;
