/**
 * @flow
 * @relayHash 87201b19b75f296e5cd7aa3873289f98
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ViewerManagerQueryVariables = {||};
export type ViewerManagerQueryResponse = {|
  +viewer: {|
    +id: string,
    +name: string,
    +email: string,
    +phoneNumber: ?string,
    +position: ?string,
    +isAdministrator: boolean,
    +hasBogintraAccess: boolean,
    +address: ?string,
    +postalCode: ?string,
    +city: ?string,
    +type: string,
    +canDownloadBogintraData: boolean,
    +canDownloadBogintraPopulartiyTrendData: boolean,
    +messageRecipients: ?$ReadOnlyArray<{|
      +id: string
    |}>,
    +group: ?{|
      +id: string,
      +name: string,
      +type: string,
      +subtype: string,
      +publishingHouseIds: ?$ReadOnlyArray<?string>,
      +reference: ?string,
      +printTemplates: ?$ReadOnlyArray<{|
        +filename: string,
        +title: string,
        +defaultFormat: string,
      |}>,
    |},
  |}
|};
export type ViewerManagerQuery = {|
  variables: ViewerManagerQueryVariables,
  response: ViewerManagerQueryResponse,
|};
*/


/*
query ViewerManagerQuery {
  viewer {
    id
    name
    email
    phoneNumber
    position
    isAdministrator
    hasBogintraAccess
    address
    postalCode
    city
    type
    canDownloadBogintraData
    canDownloadBogintraPopulartiyTrendData
    messageRecipients {
      id
    }
    group {
      id
      name
      type
      subtype
      publishingHouseIds
      reference
      printTemplates {
        filename
        title
        defaultFormat
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "viewer",
    "storageKey": null,
    "args": null,
    "concreteType": "Customer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "phoneNumber",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "position",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isAdministrator",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasBogintraAccess",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "address",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "postalCode",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "city",
        "args": null,
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "canDownloadBogintraData",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "canDownloadBogintraPopulartiyTrendData",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "messageRecipients",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomerGroup",
        "plural": true,
        "selections": [
          (v0/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "group",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomerGroup",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "subtype",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "publishingHouseIds",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reference",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "printTemplates",
            "storageKey": null,
            "args": null,
            "concreteType": "PrintTemplate",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filename",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "defaultFormat",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ViewerManagerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewerManagerQuery",
    "argumentDefinitions": [],
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ViewerManagerQuery",
    "id": null,
    "text": "query ViewerManagerQuery {\n  viewer {\n    id\n    name\n    email\n    phoneNumber\n    position\n    isAdministrator\n    hasBogintraAccess\n    address\n    postalCode\n    city\n    type\n    canDownloadBogintraData\n    canDownloadBogintraPopulartiyTrendData\n    messageRecipients {\n      id\n    }\n    group {\n      id\n      name\n      type\n      subtype\n      publishingHouseIds\n      reference\n      printTemplates {\n        filename\n        title\n        defaultFormat\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eca99f1a99407804c810d694bad6b889';
module.exports = node;
