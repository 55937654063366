// @flow
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { Checkbox, Form, Input, Select } from '../../components';
import type { CustomerForm_customer } from './__generated__/CustomerForm_customer.graphql';
import type { CustomerForm_viewer } from './__generated__/CustomerForm_viewer.graphql';

export type CustomerFormProps = {
  customer: ?CustomerForm_customer,
  loading: ?boolean,
  newIsDepartment: ?boolean,
  onDelete: ?() => any,
  onSubmit: () => any,
  viewer: CustomerForm_viewer,
  error: ?string,
  extraActions?: React$Node,
};

type CustomerFormState = {
  position: ?string,
  name: ?string,
  email: ?string,
  phoneNumber: ?string,
  groupId: ?string,
  isAdministrator: ?boolean,
  hasBogintraAccess: ?boolean,
  isNewsletterSubscriber: ?boolean,
  address: ?string,
  postalCode: ?string,
  city: ?string,
  groupReference: ?string,
  wantsDeletion: boolean,
};

class CustomerForm extends React.Component<CustomerFormProps, CustomerFormState> {
  constructor(props: CustomerFormProps) {
    super(props);

    const { customer, newIsDepartment, viewer } = props;

    if (customer) {
      this.state = {
        position: customer.position,
        name: customer.name,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
        groupId: customer.group.id,
        isAdministrator: customer.isAdministrator,
        hasBogintraAccess: customer.hasBogintraAccess,
        isNewsletterSubscriber: customer.isNewsletterSubscriber,
        address: customer.address,
        postalCode: customer.postalCode,
        city: customer.city,
        groupReference: customer.group.reference,
      };
    }
    else {
      this.state = {
        position: '',
        name: '',
        email: '',
        phoneNumber: '',
        groupId: newIsDepartment ? null : viewer.group.id,
        isAdministrator: false,
        hasBogintraAccess: true,
        isNewsletterSubscriber: true,
        address: '',
        postalCode: '',
        city: '',
        groupReference: '',
        wantsDeletion: false,
      };
    }
  }

  handleSubmit = () => {
    const { wantsDeletion } = this.state;
    const { customer, newIsDepartment, onDelete, onSubmit } = this.props;
    const isDepartment = customer?.type === 'DEPARTMENT' || newIsDepartment;

    if (wantsDeletion) {
      if (confirm(`Er du sikker på at du vil slette denne ${isDepartment ? 'butik/afdeling' : 'bruger'}?`)) {
        onDelete();
      }
    }
    else {
      onSubmit(isDepartment ? {
        name: this.state.name,
        city: this.state.city,
        address: this.state.address,
        postalCode: this.state.postalCode,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        groupReference: this.state.groupReference,
        isNewsletterSubscriber: this.state.isNewsletterSubscriber,
        hasBogintraAccess: this.state.hasBogintraAccess,
        isAdministrator: this.state.isAdministrator,
      } : {
        name: this.state.name,
        position: this.state.position,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        groupId: this.state.groupId,
        isNewsletterSubscriber: this.state.isNewsletterSubscriber,
        hasBogintraAccess: this.state.hasBogintraAccess,
        isAdministrator: this.state.isAdministrator,
      });
    }
  }

  render() {
    const { customer, error, extraActions, loading, newIsDepartment, viewer } = this.props;
    const {
      email,
      groupId,
      hasBogintraAccess,
      isAdministrator,
      isNewsletterSubscriber,
      name,
      phoneNumber,
      position,
      address,
      postalCode,
      city,
      groupReference,
      wantsDeletion,
    } = this.state;
    const isDepartment = customer?.type === 'DEPARTMENT' || newIsDepartment;

    return (
      <Form
        onSubmit={this.handleSubmit}
        error={error}
        loading={loading}
        leftActions={extraActions}
      >
        <Input
          value={name}
          onChange={name => this.setState({ name })}
          autoComplete="off"
          label="Navn"
        />

        {!isDepartment &&
        <Input
          value={position}
          onChange={position => this.setState({ position })}
          autoComplete="off"
          label="Stilling"
        />}

        {isDepartment &&
       viewer.isAdministrator &&
        <Input
          value={groupReference}
          onChange={groupReference => this.setState({ groupReference })}
          autoComplete="off"
          label="Boghandler-nummer"
        />}

        {isDepartment &&
        <Input
          value={address}
          onChange={address => this.setState({ address })}
          autoComplete="off"
          label="Adresse"
        />}

        {isDepartment &&
        <Input
          value={postalCode}
          onChange={postalCode => this.setState({ postalCode })}
          autoComplete="off"
          label="Postnummer"
        />}

        {isDepartment &&
        <Input
          value={city}
          onChange={city => this.setState({ city })}
          autoComplete="off"
          label="By"
        />}

        <Input
          type="text"
          value={email}
          onChange={email => this.setState({ email })}
          autoComplete="off"
          label="E-mail-adresse"
        />

        <Input
          type="text"
          value={phoneNumber}
          onChange={phoneNumber => this.setState({ phoneNumber })}
          autoComplete="off"
          label="Telefon-nr."
        />

        {!isDepartment &&
        <Select
          value={groupId}
          onChange={groupId => this.setState({ groupId })}
          options={[viewer.group].concat(viewer.group.descendentGroups).map(({ id, name }) => ({ value: id, label: name }))}
          label="Butik/afdeling"
          disabled={viewer.id === customer?.id}
        />}

        <div className="row toprow">
          <div className="col s12 m6 l4">
            <Checkbox
              label="Nyhedsbrev fra bog.nu"
              value={isNewsletterSubscriber}
              onChange={isNewsletterSubscriber => this.setState({ isNewsletterSubscriber, hasBogintraAccess: isNewsletterSubscriber })}
            />
          </div>
          <div className="col s12 m6 l4">
            <Checkbox
              label="Adgang til bogintra via nyhedsbrev"
              value={hasBogintraAccess}
              onChange={hasBogintraAccess => this.setState({ hasBogintraAccess })}
            />
          </div>
          <div className="col s12 m6 l4">
            {viewer.isAdministrator &&
            <Checkbox
              label="Administrator"
              value={isAdministrator}
              onChange={isAdministrator => this.setState({ isAdministrator })}
            />}
          </div>

          {customer &&
           viewer.id !== customer?.id &&
           !isDepartment &&
          <div className="col s12 m6 l4">
            <Checkbox
              label={isDepartment ? 'Slet denne butik/afdeling' : 'Slet denne bruger'}
              value={wantsDeletion}
              onChange={wantsDeletion => this.setState({ wantsDeletion })}
            />
          </div>}
        </div>
      </Form>
    );
  }
}

export default createFragmentContainer(CustomerForm, {
  customer: graphql`
fragment CustomerForm_customer on Customer {
  id
  name
  email
  phoneNumber
  position
  isAdministrator
  hasBogintraAccess
  isNewsletterSubscriber
  address
  postalCode
  city
  type

  group {
    id
    reference
  }
}`,
  viewer: graphql`
fragment CustomerForm_viewer on Customer {
  id
  isAdministrator

  group {
    id
    name

    descendentGroups {
      id
      name
    }
  }
}`,
});
