// @flow
import React from 'react';
import ReactDOM from 'react-dom';

/** Dropdown content portal. */
export default class DropdownContent extends React.Component<{}> {
  constructor(props) {
    super(props);

    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el,
    );
  }
}
