// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';

import { Collapsible, Icon, FormattedDate } from '../../components';
import { withMainLayoutOverride, type MainLayoutOverrideProps } from '../MainLayoutOverrideManager';

/** Message card props. */
export type MessageCardProps = {
  actionLinks?: ?{
    url?: ?string,
    linkTo?: ?string,
    label: string,
    target?: ?string,
    onClick?: ?() => any,
  }[],
  roundTop?: ?boolean,
} & MainLayoutOverrideProps;

/**
 * Message card.
 *
 * Collapsible item that contains the contents of a given message.
 */
function MessageCard({
  actionLinks: extraActionLinks,
  message: {
    id: messageId,
    activeFrom,
    aggregateReferencedTitles,
    attachments,
    body,
    canEdit,
    isActive,
    links,
    referencedEntities,
    referencedPublications,
    referencedPublishingHouses,
    sender,
    subject,
    timestampCreated,
  },
  setMainLayoutOverride,
  viewer,
  roundTop,
}: MessageCardProps) {
  let senderName = sender.group.name;

  if (sender.isAdministrator && sender.type === 'BOOKSELLER' && sender.group.parentGroups.length) {
    senderName = sender.group.parentGroups[sender.group.parentGroups.length - 1].name;
  }

  const senderNameParts = senderName.trim().split(' ');
  const senderNameInitials = (
    senderNameParts.length === 1
      ? senderNameParts[0][0]
      : `${senderNameParts[0][0]}${senderNameParts[senderNameParts.length - 1][0]}`
  ).toUpperCase();

  let headline = `Meddelelse fra ${senderName}`;

  if (aggregateReferencedTitles?.length) {
    headline += ` om ${aggregateReferencedTitles[0].title}`;

    if (aggregateReferencedTitles.length === 2) {
      headline += ' og 1 anden titel';
    }
    else if (aggregateReferencedTitles.length > 2) {
      headline += ` og ${aggregateReferencedTitles.length - 1} andre titler`;
    }

    if (referencedEntities?.length || referencedPublishingHouses?.length) {
      headline += ' m.m.';
    }
  }
  else if (referencedEntities?.length) {
    headline += ` om ${referencedEntities[0].name}`;

    if (referencedEntities.length === 2) {
      headline += ' og 1 anden forfatter';
    }
    else if (referencedEntities.length > 2) {
      headline += ` og ${referencedEntities.length - 1} andre forfattere`;
    }

    if (referencedPublishingHouses?.length) {
      headline += ' m.m.';
    }
  }
  else if (referencedPublishingHouses?.length) {
    headline += ` om ${referencedPublishingHouses[0].name}`;

    if (referencedPublishingHouses.length === 2) {
      headline += ' og 1 andet forlag';
    }
    else if (referencedPublishingHouses.length > 2) {
      headline += ` og ${referencedPublishingHouses.length - 1} andre forlag`;
    }
  }

  const actionLinks = [...(extraActionLinks || [])];

  if (referencedPublications?.length === 1) {
    actionLinks.push({
      href: `https://www.bogportalen.dk/webapp/wcs/stores/servlet/SimpleSearchCmd?sType=SimpleSearch&searchString=${referencedPublications[0].isbn13}`,
      target: '_blank',
      label: 'VIS PÅ BOGPORTALEN',
    });
  }

  const actionifiedLinks = (links || []).map(({ url, description }) => ({ href: url, label: description, target: '_blank' }));
  const actionifiedAttachments = (attachments || []).map(({ description, file: { url } }) => ({ href: url, label: description, target: '_blank' }));

  for (let i = 0; i < 3; ++i) {
    const actionifiedLink = actionifiedLinks[i];
    const actionifiedAttachment = actionifiedAttachments[i];

    if (actionifiedLink) {
      actionLinks.push(actionifiedLink);
    }

    if (actionifiedAttachment) {
      actionLinks.push(actionifiedAttachment);
    }
  }

  return (
    <Collapsible.Item
      itemClassName={classNames(
        'message',
        !isActive && 'message--inactive',
        roundTop && 'message--round-top',
      )}
      header={
        <Fragment>
          <span className={classNames(
            'media-icon',
            sender.group.type === 'PUBLISHING_HOUSE' && 'media-icon--publishing-house',
            sender.group.type !== 'PUBLISHING_HOUSE' && 'media-icon--bookseller',
          )}
          >
            {senderNameInitials}
          </span>
          <p className="headline2">{headline}</p>
          <p className="headline3">tilføjet <FormattedDate value={activeFrom || timestampCreated} /> af {sender.name}</p>
          <div className="article-container">
            <p>{subject}</p>
          </div>
        </Fragment>
      }
    >
      {body &&
       <p>{body.split('\n').map((line, idx) => <Fragment key={idx}>{idx > 0 && <br />}{line}</Fragment>)}</p>}

      {(actionLinks.length > 0 || canEdit) &&
      <div className={classNames(
        'action-more',
        !isActive && 'action-more-disabled'
      )}
      >
        {actionLinks.map((link, idx) => {
          const baseProps = {
            className: 'standard-color',
            key: idx,
            onClick: link.onClick,
          };

          if (link.linkTo) {
            return (
              <Link
                {...baseProps}
                to={link.linkTo}
              >
                {link.label}
              </Link>
            );
          }
          else {
            return (
              <a
                {...baseProps}
                href={link.href}
                target={link.target}
              >
                {link.label}
              </a>
            );
          }
        })}

        {canEdit &&
         <a
           className="right standard-color"
           href="#"
           onClick={evt => {
             evt.preventDefault();
             setMainLayoutOverride({
               section: 'editMessage',
               id: messageId,
             });
           }}
         >
           <Icon name="edit" />
         </a>}
      </div>}
    </Collapsible.Item>
  );
}

export default createFragmentContainer(
  withMainLayoutOverride(MessageCard), {
    message: graphql`
fragment MessageCard_message on Message {
  id
  activeFrom
  body
  canEdit
  includeLinkToBogportalen
  isActive
  status
  subject
  timestampCreated

  sender {
    id
    name
    type
    isAdministrator

    group {
      name
      type

      parentGroups {
        name
      }
    }
  }

  links {
    url
    description
  }

  attachments {
    description
    file {
      url
    }
  }

  referencedPublications {
    isbn13
  }

  aggregateReferencedTitles {
    title
  }

  referencedEntities {
    name
  }

  referencedPublishingHouses {
    name
  }
}`,
    viewer: graphql`
fragment MessageCard_viewer on Customer {
  id
}`,
  }
);
