/**
 * @flow
 * @relayHash 2a983919e9a91b056417274b3448ee21
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UnreadMessageList_viewer$ref = any;
export type DeleteMessageInput = {|
  clientMutationId: string,
  id: string,
|};
export type EditMessageLayoutOverrideDeleteMessageMutationVariables = {|
  input: DeleteMessageInput
|};
export type EditMessageLayoutOverrideDeleteMessageMutationResponse = {|
  +deleteMessage: ?{|
    +message: ?{|
      +isDeleted: boolean
    |},
    +viewer: ?{|
      +$fragmentRefs: UnreadMessageList_viewer$ref
    |},
  |}
|};
export type EditMessageLayoutOverrideDeleteMessageMutation = {|
  variables: EditMessageLayoutOverrideDeleteMessageMutationVariables,
  response: EditMessageLayoutOverrideDeleteMessageMutationResponse,
|};
*/


/*
mutation EditMessageLayoutOverrideDeleteMessageMutation(
  $input: DeleteMessageInput!
) {
  deleteMessage(input: $input) {
    message {
      isDeleted
      id
    }
    viewer {
      ...UnreadMessageList_viewer
      id
    }
  }
}

fragment MessageCard_message on Message {
  id
  activeFrom
  body
  canEdit
  includeLinkToBogportalen
  isActive
  status
  subject
  timestampCreated
  sender {
    id
    name
    type
    isAdministrator
    group {
      name
      type
      parentGroups {
        name
        id
      }
      id
    }
  }
  links {
    url
    description
  }
  attachments {
    description
    file {
      url
      id
    }
  }
  referencedPublications {
    isbn13
    id
  }
  aggregateReferencedTitles {
    title
    id
  }
  referencedEntities {
    name
    id
  }
  referencedPublishingHouses {
    name
    id
  }
}

fragment MessageCard_viewer on Customer {
  id
}

fragment UnreadMessageList_viewer on Customer {
  unreadMessages {
    id
    ...MessageCard_message
  }
  ...MessageCard_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteMessageInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDeleted",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v3/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EditMessageLayoutOverrideDeleteMessageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteMessage",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteMessagePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "message",
            "storageKey": null,
            "args": null,
            "concreteType": "Message",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "UnreadMessageList_viewer",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditMessageLayoutOverrideDeleteMessageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteMessage",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteMessagePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "message",
            "storageKey": null,
            "args": null,
            "concreteType": "Message",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "viewer",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "unreadMessages",
                "storageKey": null,
                "args": null,
                "concreteType": "Message",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "activeFrom",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "body",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "canEdit",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "includeLinkToBogportalen",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isActive",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "subject",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "timestampCreated",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "sender",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Customer",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isAdministrator",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "group",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomerGroup",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "parentGroups",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomerGroup",
                            "plural": true,
                            "selections": (v6/*: any*/)
                          },
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "links",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MessageLink",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "attachments",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MessageAttachhment",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "file",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "File",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "referencedPublications",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Publication",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isbn13",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "aggregateReferencedTitles",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Title",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "referencedEntities",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Entity",
                    "plural": true,
                    "selections": (v6/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "referencedPublishingHouses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PublishingHouse",
                    "plural": true,
                    "selections": (v6/*: any*/)
                  }
                ]
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "EditMessageLayoutOverrideDeleteMessageMutation",
    "id": null,
    "text": "mutation EditMessageLayoutOverrideDeleteMessageMutation(\n  $input: DeleteMessageInput!\n) {\n  deleteMessage(input: $input) {\n    message {\n      isDeleted\n      id\n    }\n    viewer {\n      ...UnreadMessageList_viewer\n      id\n    }\n  }\n}\n\nfragment MessageCard_message on Message {\n  id\n  activeFrom\n  body\n  canEdit\n  includeLinkToBogportalen\n  isActive\n  status\n  subject\n  timestampCreated\n  sender {\n    id\n    name\n    type\n    isAdministrator\n    group {\n      name\n      type\n      parentGroups {\n        name\n        id\n      }\n      id\n    }\n  }\n  links {\n    url\n    description\n  }\n  attachments {\n    description\n    file {\n      url\n      id\n    }\n  }\n  referencedPublications {\n    isbn13\n    id\n  }\n  aggregateReferencedTitles {\n    title\n    id\n  }\n  referencedEntities {\n    name\n    id\n  }\n  referencedPublishingHouses {\n    name\n    id\n  }\n}\n\nfragment MessageCard_viewer on Customer {\n  id\n}\n\nfragment UnreadMessageList_viewer on Customer {\n  unreadMessages {\n    id\n    ...MessageCard_message\n  }\n  ...MessageCard_viewer\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3df9c73925c957e0b5870435062e7a08';
module.exports = node;
