// @flow
import React from 'react';

import type { Features, Viewer } from '../../models';
import FeaturesContext from './FeaturesContext';

/** Features props. */
export type FeaturesProps = {
  features: Features,
};

/** Features provider props. */
type FeaturesProviderProps = {
  viewer: Viewer,
  children: React$Node,
};

/**
 * Features provider.
 */
class FeaturesProvider extends React.Component<FeaturesProviderProps> { // eslint-disable-line react/prefer-stateless-function
  render() {
    const {
      children,
      viewer,
    } = this.props;

    const messages = viewer.group.type !== 'PUBLISHING_HOUSE' || [
      12,
      11,
      9,
      30,
      43, // Nick
    ].map(id => btoa(`CustomerGroup:${id}`)).includes(viewer.group.id);

    return (
      <FeaturesContext.Provider value={{
        features: {
          messages,
        },
      }}
      >
        {children}
      </FeaturesContext.Provider>
    );
  }
}

export default FeaturesProvider;
