// @flow
import React, { Fragment } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import ToastContainer from './ToastContainer';
import ToastContext from './ToastContext';
import type { Toast } from './Toast';

/** Toast. */
type ToastState = {
  key: number,
  content: React$Node,
};

/** Toast provider props. */
type ToastProviderProps = {
  children: React$Node,
};

/** Toast provider state. */
type ToastProviderState = {
  lastToastKey: number,
  toasts: ToastState[],
};

/**
 * Toast provider.
 *
 * Contextual provider and manager for toast messages.
 */
class ToastProvider extends React.Component<ToastProviderProps, ToastProviderState> {
  state = {
    lastToastKey: 0,
    toasts: [],
  };

  /** Toast context. */
  toastContext = {
    toast: (content: React$Node): Toast => this.addToast(content),
  };

  /** Add toast. */
  addToast(content: React$Node): Toast {
    const { lastToastKey, toasts } = this.state;
    const toastKey = lastToastKey + 1;

    this.setState({
      lastToastKey: toastKey,
      toasts: [...toasts, {
        key: toastKey,
        content,
      }],
    });

    const dismiss = () => {
      const { toasts } = this.state;

      this.setState({
        toasts: toasts.filter(({ key }) => key !== toastKey),
      });
    };

    setTimeout(dismiss, 4000);

    return {
      dismiss,
    };
  }

  render() {
    const { toasts } = this.state;

    return (
      <ToastContext.Provider value={this.toastContext}>
        <Fragment>
          {this.props.children}

          <ToastContainer>
            <TransitionGroup>
              {toasts.map(({ content, key }) =>
                <CSSTransition
                  key={key}
                  unmountOnExit
                  classNames="toast-animation"
                  timeout={300}
                >
                  <div className="toast">{content}</div>
                </CSSTransition>
              )}
            </TransitionGroup>
          </ToastContainer>
        </Fragment>
      </ToastContext.Provider>
    );
  }
}

export default ToastProvider;
