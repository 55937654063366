// @flow

/** Search filter. */
export type SearchFilter = {
  /** Raw filter ID. */
  id: string,

  /**
   * Filter type.
   *
   * Equals the decoded ID type.
   */
  type: string,

  /**
   * Filter value.
   *
   * Equal the decoded ID value.
   */
  value: string,
};

/** Search filter type labels by type. */
export const SEARCH_FILTER_TYPE_LABEL_BY_TYPE = {
  Title: 'titel',
  Reviewer: 'journalist',
  PublishingHouse: 'forlag',
  Media: 'medie',
  Entity: 'forfatter',
};

/** Search filter type labels by collection type. */
export const SEARCH_FILTER_TYPE_LABEL_BY_COLLECTION_TYPE = {
  ANNUAL_PUBLICATION: 'årspublikation',
  BESTSELLER_LIST: 'bestsellerliste',
  BOOK_COLLECTION: 'bogsamling',
  LITERATURE_AWARD: 'litteraturpris',
  OTHER: 'samling',
  SERIES: 'serie',
};
