// @flow
import React from 'react';
import queryString from 'query-string';

import { FullscreenLoader } from '../../components';
import type { RouterProps } from '../../utils/router';

/** Log out page props. */
type LogInPageProps = RouterProps;

/** Log out page state. */
type LogInPageState = {};

/** Log out page. */
export default class LogInPage extends React.Component<LogInPageProps, LogInPageState> {
  state = {};

  componentDidMount() {
    const { location, history } = this.props;
    const { token } = queryString.parse(location.search);

    if (!token) {
      history.push('/');
      return;
    }

    const data = new FormData();
    data.append('token', token);

    fetch('/api/auth', {
      method: 'POST',
      body: data,
    }).then(() => history.push('/'));
  }

  render() {
    return (
      <FullscreenLoader />
    );
  }
}
