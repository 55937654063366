// @flow
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { commitMutation, createFragmentContainer, graphql } from 'react-relay';
import { Set } from 'immutable';

import { Collapsible } from '../../components';
import MessageCard from '../MessageCard';
import { withEnvironment, type EnvironmentProps } from '../../utils/relay';
import type { UnreadMessageList_viewer } from './__generated__/UnreadMessageList_viewer.graphql';

/** Mark message as read mutation. */
const markMessageAsReadMutation = graphql`
mutation UnreadMessageListMarkMessageAsReadMutation($input: MarkMessageAsReadInput!) {
  markMessageAsRead(input: $input) {
    viewer {
      ...UnreadMessageList_viewer
    }
  }
}`;

/** Unread message list props. */
export type UnreadMessageListProps = {
  viewer: UnreadMessageList_viewer,
} & EnvironmentProps;

/** List of unread messages for a user. */
class UnreadMessageList extends React.Component<UnreadMessageListProps> {
  state = {
    readIds: new Set(),
  }

  handleReadClick = (message, evt) => {
    const { environment } = this.props;

    evt.preventDefault();

    commitMutation(environment, {
      mutation: markMessageAsReadMutation,
      variables: {
        input: {
          id: message.id,
          clientMutationId: '1',
        },
      },
    });

    this.setState({
      readIds: this.state.readIds.add(message.id),
    });
  }

  render() {
    const { viewer } = this.props;
    const { readIds } = this.state;
    const messages = (viewer?.unreadMessages || []).filter(msg => !readIds.has(msg.id));

    return (
      <CSSTransition
        timeout={500}
        classNames={{
          exit: 'zoom-out',
        }}
        mountOnEnter
        unmountOnExit
        in={!!messages.length}
      >
        <div>
          <div className="section-headline">
      Ulæste meddelelser
          </div>

          <Collapsible popout>
            <TransitionGroup component={null}>
              {messages.map(msg =>
                <CSSTransition
                  timeout={500}
                  classNames={{
                    exit: 'zoom-out',
                  }}
                  mountOnEnter
                  unmountOnExit
                  key={msg.id}
                >
                  <MessageCard
                    actionLinks={[{
                      label: 'MODTAGET',
                      href: '#',
                      onClick: evt => this.handleReadClick(msg, evt),
                    }]}
                    key={msg.id}
                    message={msg}
                    viewer={viewer}
                  />
                </CSSTransition>
              )}
            </TransitionGroup>
          </Collapsible>
        </div>
      </CSSTransition>
    );
  }
}

export default createFragmentContainer(
  withEnvironment(UnreadMessageList), {
    viewer: graphql`
fragment UnreadMessageList_viewer on Customer {
  unreadMessages {
    id
    ...MessageCard_message
  }

  ...MessageCard_viewer
}`,
  }
);
