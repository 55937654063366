// @flow
import React, { Fragment } from 'react';

import {
  MEDIA_TYPE_OPTIONS,
  PUBLISHED_OPTIONS,
  withSearchSettings,
  type SearchSettingsProps,
} from '../SearchSettings';

/** Filter description label props. */
export type FilterDescriptionLabelProps = {
  mode: 'content' | 'titles',
} & SearchSettingsProps;

/** Filter description label. */
const FilterDescriptionLabel: React$ComponentType<FilterDescriptionLabelProps> = function FilterDescriptionLabel({
  mode,
  searchSettings,
}: FilterDescriptionLabelProps) {
  const publishedOption = PUBLISHED_OPTIONS.find(({ value }) => value === searchSettings.published);

  return (
    <Fragment>
      {mode === 'content' &&
       <Fragment>
         {!searchSettings.showNews && 'Kun anmeldelser vises. '}
         {!searchSettings.showReviews && 'Kun nyheder vises. '}
       </Fragment>}
      {mode === 'titles' &&
       (searchSettings.excludeDigital
         ? (searchSettings.excludeUnavailable
           ? 'Digitale og udsolgte bøger vises ikke. '
           : 'Digitale bøger vises ikke. ')
         : searchSettings.excludeUnavailable
           ? 'Udsolgte bøger vises ikke. '
           : null)}
      Kategorier: {searchSettings.categories ? 'Udvalgte. ' : 'Alle bøger. '}
      {mode === 'content' &&
      <Fragment>
        Medier:{' '}
        {searchSettings.mediaTypes
          ? MEDIA_TYPE_OPTIONS
            .filter(({ value }) => searchSettings.mediaTypes.includes(value))
            .map(({ value, label }) => <Fragment key={value}>{label}{'. '}</Fragment>)
          : 'Alle medier. '}
      </Fragment>}
      {mode === 'titles' &&
        <Fragment>
          Første udgivelsesdato:{' '}
          {publishedOption
            ? `${publishedOption.label}. `
            : 'Alle. '}
        </Fragment>}
    </Fragment>
  );
};

export default withSearchSettings(FilterDescriptionLabel);
