// @flow
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { AuthPage, FullscreenLoader } from '../../components';

/** Log out page props. */
type LogOutPageProps = {

};

/** Log out page state. */
type LogOutPageState = {
  loggedOut: boolean,
  previousToken: ?string,
  previousCustomerGroupType: ?string,
};

/** Log out page. */
export default class LogOutPage extends React.Component<LogOutPageProps, LogOutPageState> {
  state = {
    loggedOut: false,
    previousToken: null,
  };

  componentDidMount() {
    fetch('/api/auth', {
      method: 'DELETE',
    }).then(
      response => response.json()
    ).then(response => {
      this.setState({
        loggedOut: true,
        previousToken: response.token || null,
        previousCustomerGroupType: response.customer_group?.type,
      });
    });
  }

  render() {
    const { loggedOut, previousToken, previousCustomerGroupType } = this.state;

    if (!loggedOut) {
      return (
        <FullscreenLoader />
      );
    }

    return (
      <AuthPage
        publishingHouse={previousCustomerGroupType === 'publishing_house'}
      >
        <h2>Du er logget ud af bogintra</h2>

        {previousToken
          ? (
            <Fragment>
              <p>... men vil du virkelig forlade {previousCustomerGroupType === 'publishing_house' ? 'forlagsbranchens' : 'boghandlerbranchens'} bedste overblik?</p>

              <Link
                to={`/login?token=${encodeURIComponent(previousToken)}`}
                className="waves-effect btn-large white standard-color"
              >
           Nej, det var en fejl, luk mig ind igen
              </Link>

              <p></p>
              <p>Ellers kan du altid logge ind igen via det seneste nummer af bog.nu's daglige nyhedsbrev.</p>

            </Fragment>
          )
          : <p>Du kan altid logge ind igen via det seneste nummer af bog.nu's daglige nyhedsbrev.</p>}
      </AuthPage>
    );
  }
}
