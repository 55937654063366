// @flow
import classNames from 'classnames';
import React from 'react';

/** Icon props. */
export type IconProps = {
  name: string,
  className?: ?string,
  onClick?: ?() => any,
  rotate?: ?(90 | 180 | 270),
};

/** Icon. */
const Icon: React$ComponentType<IconProps> = function Icon({
  name,
  onClick,
  className,
  rotate,
}: IconProps) {
  // Handle Font Awesome case. This is a little simplistic, but it should do the trick, as all Material icons are
  // underscorized and do not use dashes. However, this is performance wise better than splitting or matching on the
  // string.
  if (name.indexOf('fa-') !== -1) {
    return (
      <i className={classNames(name, className, rotate && `turn${rotate}`)} onClick={onClick} />
    );
  }

  // Default case is material icons.
  return (
    <i className={classNames('material-icons', className, rotate && `turn${rotate}`)} onClick={onClick}>
      {name}
    </i>
  );
};

export default Icon;
