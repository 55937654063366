// @flow
import React from 'react';
import { QueryRenderer as RelayQueryRenderer } from 'react-relay';

import withEnvironment from './withEnvironment';
import type { Environment, GraphQLTaggedNode, ReadyState, Variables } from 'react-relay';

/**
 * Query renderer props.
 */
export type QueryRendererProps = {
  environment: Environment,
  query: ?GraphQLTaggedNode,
  render: (renderProps: ReadyState) => ?React$Element<*>,
  variables?: Variables,
};

/**
 * Query renderer.
 */
class QueryRenderer extends React.Component<QueryRendererProps> {
  render() {
    const {
      environment,
      query,
      render: renderImpl,
      variables,
    } = this.props;

    return (
      <RelayQueryRenderer
        query={query}
        environment={environment}
        variables={variables}
        render={({ error, props, retry }: ReadyState) => {
          return renderImpl({ error, props, retry });
        }}
      />
    );
  }
}

export default withEnvironment(QueryRenderer);
