// @flow
import React, { Fragment } from 'react';
import { graphql } from 'react-relay';

import { withSearchSettings, type SearchSettingsProps } from '../../containers/SearchSettings';
import MessagePaginator from '../../containers/MessagePaginator';
import { type RouterProps } from '../../utils/router';
import { QueryRenderer } from '../../utils/relay';

type MessagesResultsPageProps = {
  shouldDisplayPublishingHouse: (id: ?string) => boolean,
} & RouterProps<{}> & SearchSettingsProps;

class MessagesResultsPage extends React.Component<MessagesResultsPageProps> {
  handleResetClick = (evt) => {
    evt.preventDefault();

    this.props.resetSearchSettings();
  }

  render() {
    const { searchSettings, shouldDisplayPublishingHouse } = this.props;

    return (
      <div id="messages">
        <QueryRenderer
          variables={{
            filterBy: searchSettings.filterBy,
            search: searchSettings.searchQuery,
            onlyActive: searchSettings.messagesMode === 'active',
            onlySent: searchSettings.messagesMode === 'sent',
          }}
          query={graphql`
query MessagesResultsPageQuery(
  $search: String,
  $filterBy: [ID!],
  $onlyActive: Boolean!,
  $onlySent: Boolean!,
) {
  ...MessagePaginator_query @arguments(
    search: $search,
    filterBy: $filterBy
    onlyActive: $onlyActive,
    onlySent: $onlySent
  )

  viewer {
    ...FavoriteStar_viewer
  }
}`}
          render={({ error, props }) => {
            return (
              <Fragment>
                {error
                  ? <div className="no-results-headline">Et eller andet gik galt. Prøv venligst igen.</div>
                  : !props
                    ? <div className="no-results-headline">Indlæser...</div>
                    : null}

                {props &&
                 <MessagePaginator
                   query={props}
                   shouldDisplayPublishingHouse={shouldDisplayPublishingHouse}
                 />}
              </Fragment>
            );
          }}
        />
      </div>
    );
  }
}

export default withSearchSettings(MessagesResultsPage);
