// @flow
import React from 'react';
import classNames from 'classnames';

import logoUrl from '../../images/logo.svg';

/** Auth page props. */
export type AuthPageProps = {
  children: React$Node,
  publishingHouse?: ?boolean,
};

/** Auth page. */
const AuthPage: React$ComponentType<AuthPageProps> = function AuthPage({
  children,
  publishingHouse,
}: AuthPageProps) {
  return (
    <div className="auth-page">
      <div className={classNames('jumbotron', publishingHouse && 'jumbotron-publishing-house')}>
        <div className="jumbotron-content">
          {children}

          <img className="auth-logo" src={logoUrl} height="40" alt="Bogintra" />
          <small>Bogintra · Ved Dammen 9 · 3480 Fredensborg · <nobr>Tlf.: 2024 1667</nobr></small>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
