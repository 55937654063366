// @flow
import React, { Fragment } from 'react';
import { FormattedNumber } from 'react-intl';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import QueryPreservingLink from '../QueryPreservingLink';

/** Tab props. */
export type TabNavTabProps = {
  children: React$Node,
  className?: ?string,
  count?: ?number,
  linkTo: string,
  linkPreservesQuery?: ?boolean,
  overrideQuery?: ?{ [string]: ?string },
  isActive?: () => boolean,
  onClick?: () => void,
};

/** Tab. */
const TabNavTab: React$ComponentType<TabNavTabProps> = function TabNavTab({
  children,
  className,
  count,
  onClick,
  linkTo,
  linkPreservesQuery,
  overrideQuery,
  isActive
}: TabNavTabProps) {
  const LinkComp = linkPreservesQuery ? QueryPreservingLink : NavLink;

  return (
    <li className={classNames('tab', className)}>
	{linkPreservesQuery ? 
      <LinkComp to={linkTo} activeClassName="active" exact overrideQuery={overrideQuery}>
        {children}
        {count !== null &&
        count !== undefined &&
         <Fragment>
           &nbsp;&nbsp;
           <span className="new badge right hide-on-small-only" data-badge-caption="">
             <FormattedNumber value={count} />
           </span>
         </Fragment>}
      </LinkComp>
	: <LinkComp to={linkTo} activeClassName="active" onClick={onClick} isActive={isActive} exact>
        {children}
        {count !== null &&
        count !== undefined &&
         <Fragment>
           &nbsp;&nbsp;
           <span className="new badge right hide-on-small-only" data-badge-caption="">
             <FormattedNumber value={count} />
           </span>
         </Fragment>}
      </LinkComp>
	}
    </li>
  );
};

export default TabNavTab;
