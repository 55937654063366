/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type NotificationSubscriptionForm_notificationSubscription$ref = any;
export type CategoryGroup = "BIOGRAFI" | "BOERN" | "DIGTE" | "ERHVERV" | "FAGLITTERATUR" | "FAGLITTERATUR_BOERN" | "FRITID" | "KALENDERE_AAR" | "KORT" | "KRIMI" | "KULTUR" | "KUNST" | "LEKSIKA" | "MAD" | "MALE" | "MUSIK_FILM" | "NATUR" | "NATURVIDENSKAB" | "ROMANER" | "SKOENLITTERATUR" | "SKOLE" | "SUNDHED" | "TEGNESERIER" | "UNGDOM" | "%future added value";
export type CustomerNotificationSubscriptionType = "BESTSELLER" | "STEADYSELLER" | "SUDDEN_RISE" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type NotificationSubscriptionList_viewer$ref: FragmentReference;
declare export opaque type NotificationSubscriptionList_viewer$fragmentType: NotificationSubscriptionList_viewer$ref;
export type NotificationSubscriptionList_viewer = {|
  +id: string,
  +name: string,
  +notificationSubscriptions: ?$ReadOnlyArray<{|
    +id: string,
    +categoryGroup: ?CategoryGroup,
    +description: string,
    +isDefault: boolean,
    +notification: string,
    +type: CustomerNotificationSubscriptionType,
    +$fragmentRefs: NotificationSubscriptionForm_notificationSubscription$ref,
  |}>,
  +$refType: NotificationSubscriptionList_viewer$ref,
|};
export type NotificationSubscriptionList_viewer$data = NotificationSubscriptionList_viewer;
export type NotificationSubscriptionList_viewer$key = {
  +$data?: NotificationSubscriptionList_viewer$data,
  +$fragmentRefs: NotificationSubscriptionList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "NotificationSubscriptionList_viewer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "notificationSubscriptions",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerNotificationSubscription",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "categoryGroup",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isDefault",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "notification",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "NotificationSubscriptionForm_notificationSubscription",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e1cef8ad6fd283f459d92ce8cb6f6b92';
module.exports = node;
