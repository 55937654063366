// @flow
import React from 'react';
import { Environment } from 'relay-runtime';
import EnvironmentContext from './EnvironmentContext';

/**
 * Environment provider props.
 */
export type EnvironmentProviderProps = {
  children?: ?React$Node,
  environment: Environment,
};

/**
 * Environment provider.
 *
 * Provider component that exposes an instance of the provided Relay environment in the child context.
 */
const EnvironmentProvider: React$ComponentType<EnvironmentProviderProps> = function EnvironmentProvider({
  children,
  environment,
}: EnvironmentProviderProps) {
  return (
    <EnvironmentContext.Provider value={environment}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentProvider;
