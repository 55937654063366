// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { graphql } from 'react-relay';

import { AppLayout, Sidebar } from '../../components';
import type { Viewer } from '../../models';
import { ENVIRONMENT } from '../../utils/environment';
import { QueryRenderer } from '../../utils/relay';
import { withManageSearch, type ManageSearchProps } from '../SearchManager';
import { withSearchSettings, type SearchSettingsProps } from '../SearchSettings';
import { PRINT_FORMATS, type PrintFormat } from './PrintFormat';

/** Print layout override props. */
export type PrintLayoutOverrideProps = {
  viewer: Viewer,
} & SearchSettingsProps & ManageSearchProps;

/** Print layout override state. */
type PrintLayoutOverrideState = {
  format: ?PrintFormat,
  templateFilename: ?string,
};

/** Print layout override. */
class PrintLayoutOverride extends React.Component<PrintLayoutOverride, PrintLayoutOverrideState> {
  iframeRef = React.createRef();
  iframeReady = false;
  state = {
    format: null,
    templateFilename: null,
  };

  getTemplates() {
    const { viewer: { group: { printTemplates } } } = this.props;
    return printTemplates || [];
  }

  getTemplate() {
    const { templateFilename } = this.state;
    const templates = this.getTemplates();
    const template = templates.find(({ filename }) => filename === templateFilename);

    return template || templates[0];
  }

  setStylesheet(href) {
    if (!this.iframeReady) {
      return;
    }

    const iframeWindow = this.iframeRef.current.contentWindow;
    const iframeDoc = iframeWindow.document;

    // Remove all non-kept stylesheets.
    iframeDoc.querySelectorAll('link[rel=stylesheet]').forEach(element => {
      if (element.className !== 'keep') {
        element.parentNode.removeChild(element);
      }
    });

    // Insert the new stylesheet.
    const link = iframeDoc.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.id = 'extstylesheet';
    link.href = href;
    iframeDoc.getElementsByTagName('head')[0].appendChild(link);

    // Notify the template of a change.
    iframeWindow.templatechange(href);
  }

  setFormat(format) {
    if (!this.iframeReady) {
      return;
    }

    const iframeWindow = this.iframeRef.current.contentWindow;

    // Notify the template of a change.
    iframeWindow?.formatchange?.(format); // eslint-disable-line no-unused-expressions
  }

  handleIframeReady = () => {
    this.iframeReady = true;
    this.setStylesheet(this.getTemplate().filename);
  }

  handlePrintClick = () => {
    if (!this.iframeReady) {
      return;
    }

    const iframeWindow = this.iframeRef.current.contentWindow;
    const iframeDoc = iframeWindow.document;

    iframeDoc.getElementById('edit-button').style.display = 'none';
    iframeWindow.print();
    iframeDoc.getElementById('edit-button').style.display = '';
  }

  render() {
    const { filters, viewer } = this.props;
    const { format: stateFormat } = this.state;

    if (!viewer) {
      return null;
    }

    const template = this.getTemplate();
    const format = stateFormat || PRINT_FORMATS.find(({ id }) => id === template.defaultFormat);
    const titleIds = filters.reduce((memo, { type, id }) => {
      if (type === 'Title') {
        memo.push(id);
      }

      return memo;
    }, []);

    return (
      <AppLayout.Override
        {...AppLayout.Override.forwardProps(this.props)}
        sidebar={
          <Sidebar>

            <Sidebar.Section
              label="Skabeloner"
              icon="view_compact"
              initiallyExpanded
            >
              <ul>
                {this.getTemplates().map(({ title, filename }) =>
                  <li key={filename}>
                    <label>
                      <input
                        type="checkbox"
                        className="filled-in"
                        checked={template.filename === filename}
                        onChange={() => this.setState({ templateFilename: filename, format: null }, () => this.setStylesheet(filename))}
                      />
                      <span className="black-text">{title}</span>
                    </label>
                  </li>)}
              </ul>
            </Sidebar.Section>

            <Sidebar.Section
              label="Formater"
              icon="aspect_ratio"
              iconRotate={90}
              initiallyExpanded
            >
              <ul>
                {PRINT_FORMATS.map(fmt =>
                  <li key={fmt.id}>
                    <label>
                      <input
                        type="checkbox"
                        className="filled-in"
                        checked={format.id === fmt.id}
                        onChange={() => this.setState({ format: fmt }, () => this.setFormat(fmt))}
                      />
                      <span className="black-text">{fmt.label}</span>
                    </label>
                  </li>)}
              </ul>
            </Sidebar.Section>

            <Sidebar.Section
              icon="print"
              label="Print"
              onHeaderClick={this.handlePrintClick}
            />
          </Sidebar>
        }
        content={
          <Fragment>
            <div className="section-headline" style={{ width: '210mm' }}>
              {template.title}<br />
              Klik og rediger. Tilføj fed og kursiv skrift med <strong>(fed)</strong> og <strong>(-fed)</strong> samt <strong>(kursiv)</strong> og <strong>(-kursiv)</strong>
            </div>

            <div className={classNames('card', format.id)} id="paper">
              <div className="video-container">
                <QueryRenderer
                  query={graphql`
query PrintLayoutOverrideQuery($titleIds: [ID!]!) {
  nodes(ids: $titleIds) {
    ... on Title{
      title
      hasMoreAuthors

      publications {
        isbn13
      }

      authors {
        name
      }

      coverImage {
        sizes {
          name
          url
        }
      }
    }
  }
}`}
                  variables={{
                    titleIds,
                  }}
                  render={({ error, props }) => {
                    if (error) {
                      return 'Et eller andet gik galt. Prøv venligst igen.';
                    }

                    if (!props) {
                      return 'Indlæser...';
                    }

                    let url = ENVIRONMENT === 'production'
                      ? '/print-templates/template.html'
                      : '/print-templates/next-template.html';

                    props.nodes.forEach((title, idx) => {
                      if (!title || !title.publications.length || idx >= 12) {
                        return;
                      }

                      const coverImage = title.coverImage?.sizes?.find(({ name }) => name === '750x');

                      url += `${idx ? '&' : '?'}isbn=${encodeURIComponent(title.publications[0].isbn13)}`;
                      url += `&title=${encodeURIComponent(title.title)}`;
                      url += `&author=${encodeURIComponent(title.authors && title.authors.length ? title.authors[0].name : '')}`;
                      url += `&coverurl=${encodeURIComponent(coverImage ? coverImage.url : '')}`;
                    });

                    return (
                      <iframe
                        ref={this.iframeRef}
                        id="template-paper"
                        width="100%"
                        height="100%"
                        src={url}
                        frameBorder="0"
                        allowFullScreen=""
                        onLoad={this.handleIframeReady}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </Fragment>
        }
      />
    );
  }
}

export default withManageSearch(withSearchSettings(PrintLayoutOverride));
