// @flow
const TITLE_SORT_OPTIONS = {
  popular: [{
    value: 'most_popular',
    label: 'Mest populære øverst',
    orderBy: 'POPULARITY_SCORE',
    order: 'DESC',
  }, {
    value: 'best_match',
    label: 'Bedste match øverst',
    orderBy: 'POPULARITY_SCORE',
    order: 'DESC',
  }, {
    value: 'most_recently_published',
    label: 'Nyeste udgivelse øverst',
    orderBy: 'PUBLISHED_FIRST',
    order: 'DESC',
  }, {
    value: 'title',
    label: 'Sorteret efter titel',
    orderBy: 'TITLE',
    order: 'ASC',
  }, {
    value: 'author',
    label: 'Sorteret efter forfatter',
    orderBy: 'AUTHOR',
    order: 'ASC',
  }],
  reviews: [{
    value: 'best_reviewed',
    label: 'Bedst anmeldte øverst',
    orderBy: 'META_SCORE',
    order: 'DESC',
  }, {
    value: 'best_match',
    label: 'Bedste match øverst',
    orderBy: 'POPULARITY_SCORE',
    order: 'DESC',
  }, {
    value: 'most_recently_published',
    label: 'Nyeste udgivelse øverst',
    orderBy: 'PUBLISHED_FIRST',
    order: 'DESC',
  }, {
    value: 'title',
    label: 'Sorteret efter titel',
    orderBy: 'TITLE',
    order: 'ASC',
  }, {
    value: 'author',
    label: 'Sorteret efter forfatter',
    orderBy: 'AUTHOR',
    order: 'ASC',
  }],
};
const VALID_TITLE_SORT_VALUES = Object.entries(TITLE_SORT_OPTIONS).reduce((memo, [mode, options]) => {
  memo[mode] = options.reduce((memo, option) => {
    memo[option.value] = option.value;
    return memo;
  }, {});
  return memo;
}, {});
const DEFAULT_TITLE_SORT_VALUE = {
  popular: 'most_popular',
  reviews: 'best_reviewed',
};

export {
  DEFAULT_TITLE_SORT_VALUE,
  TITLE_SORT_OPTIONS,
  VALID_TITLE_SORT_VALUES,
};
