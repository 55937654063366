/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PublicationCard_publication$ref: FragmentReference;
declare export opaque type PublicationCard_publication$fragmentType: PublicationCard_publication$ref;
export type PublicationCard_publication = {|
  +id: string,
  +title: string,
  +subtitle: ?string,
  +format: ?string,
  +pageCount: ?number,
  +isbn13: ?string,
  +datePublished: ?any,
  +yearPublished: ?number,
  +priceRetail: ?number,
  +availability: ?string,
  +isDigital: ?boolean,
  +hasMoreTranslators: boolean,
  +hasMoreAuthors: boolean,
  +authors: $ReadOnlyArray<{|
    +id: string,
    +name: string,
  |}>,
  +translators: $ReadOnlyArray<{|
    +id: string,
    +name: string,
  |}>,
  +publishingHouse: ?{|
    +id: string,
    +name: string,
  |},
  +coverImage: ?{|
    +sizes: ?$ReadOnlyArray<{|
      +name: string,
      +url: string,
    |}>
  |},
  +$refType: PublicationCard_publication$ref,
|};
export type PublicationCard_publication$data = PublicationCard_publication;
export type PublicationCard_publication$key = {
  +$data?: PublicationCard_publication$data,
  +$fragmentRefs: PublicationCard_publication$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "PublicationCard_publication",
  "type": "Publication",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subtitle",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "format",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pageCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isbn13",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "datePublished",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "yearPublished",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "priceRetail",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "availability",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isDigital",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasMoreTranslators",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasMoreAuthors",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "authors",
      "storageKey": null,
      "args": null,
      "concreteType": "Entity",
      "plural": true,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "translators",
      "storageKey": null,
      "args": null,
      "concreteType": "Entity",
      "plural": true,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "publishingHouse",
      "storageKey": null,
      "args": null,
      "concreteType": "PublishingHouse",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "coverImage",
      "storageKey": null,
      "args": null,
      "concreteType": "PublicationCoverImage",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "sizes",
          "storageKey": null,
          "args": null,
          "concreteType": "PublicationCoverImageSize",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "url",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e49a1bafc5dc2997a0c7b09c60b8dc35';
module.exports = node;
