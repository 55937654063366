/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MessageCard_message$ref = any;
type MessageCard_viewer$ref = any;
type PopularityTitleCard_title$ref = any;
type PopularityTitleCard_viewer$ref = any;
type PublicationCard_publication$ref = any;
type PublicationCard_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MessagePaginator_query$ref: FragmentReference;
declare export opaque type MessagePaginator_query$fragmentType: MessagePaginator_query$ref;
export type MessagePaginator_query = {|
  +viewer: {|
    +messages: ?{|
      +totalCount: number,
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +isDeleted: boolean,
          +date: ?any,
          +aggregateReferencedTitles: ?$ReadOnlyArray<{|
            +id: string
          |}>,
          +referencedTitles: ?$ReadOnlyArray<{|
            +id: string,
            +$fragmentRefs: PopularityTitleCard_title$ref,
          |}>,
          +referencedPublications: ?$ReadOnlyArray<{|
            +id: string,
            +titleId: string,
            +availability: ?string,
            +isDigital: ?boolean,
            +$fragmentRefs: PublicationCard_publication$ref,
          |}>,
          +referencedPublishingHouses: ?$ReadOnlyArray<{|
            +id: string
          |}>,
          +referencedEntities: ?$ReadOnlyArray<{|
            +id: string
          |}>,
          +$fragmentRefs: MessageCard_message$ref,
        |}
      |}>,
    |},
    +$fragmentRefs: MessageCard_viewer$ref & PublicationCard_viewer$ref & PopularityTitleCard_viewer$ref,
  |},
  +$refType: MessagePaginator_query$ref,
|};
export type MessagePaginator_query$data = MessagePaginator_query;
export type MessagePaginator_query$key = {
  +$data?: MessagePaginator_query$data,
  +$fragmentRefs: MessagePaginator_query$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "MessagePaginator_query",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "viewer",
          "messages"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 25
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "filterBy",
      "type": "[ID!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "onlyActive",
      "type": "Boolean!",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "onlySent",
      "type": "Boolean!",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "viewer",
      "storageKey": null,
      "args": null,
      "concreteType": "Customer",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": "messages",
          "name": "__MessagePaginator_messages_connection",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterBy",
              "variableName": "filterBy"
            },
            {
              "kind": "Variable",
              "name": "onlyActive",
              "variableName": "onlyActive"
            },
            {
              "kind": "Variable",
              "name": "onlySent",
              "variableName": "onlySent"
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "search"
            }
          ],
          "concreteType": "MessageConnection",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "totalCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "MessageEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Message",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "isDeleted",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "date",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "aggregateReferencedTitles",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Title",
                      "plural": true,
                      "selections": (v1/*: any*/)
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "referencedTitles",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Title",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "FragmentSpread",
                          "name": "PopularityTitleCard_title",
                          "args": null
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "referencedPublications",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Publication",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "titleId",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "availability",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "isDigital",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "PublicationCard_publication",
                          "args": null
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "referencedPublishingHouses",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "PublishingHouse",
                      "plural": true,
                      "selections": (v1/*: any*/)
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "referencedEntities",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Entity",
                      "plural": true,
                      "selections": (v1/*: any*/)
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "__typename",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "MessageCard_message",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "cursor",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "pageInfo",
              "storageKey": null,
              "args": null,
              "concreteType": "PageInfo",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "endCursor",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "hasNextPage",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "MessageCard_viewer",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "PublicationCard_viewer",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "PopularityTitleCard_viewer",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a18cf1edeb2fb4cf5e4c3a51cf17e587';
module.exports = node;
